<template>
  <FormContainer
    :on-keydown="resetValidation"
    :error-message="authErrorMessage"
    @submit="() => step === loginSteps.email ? checkEmailForSso() : login()"
  >
    <div
      v-if="step === loginSteps.email"
      class="form-title"
    >
      Enter your email to begin
    </div>
    <TextInput
      ref="email"
      v-model="email"
      label="Email"
      autocomplete="email"
      :error-message="emailError"
    />
    <PasswordInput
      v-if="step === loginSteps.password"
      ref="password"
      v-model="password"
      :error-message="passwordError"
    />

    <template #buttons>
      <Button
        v-if="step === loginSteps.email"
        :is-loading="isLoading"
        text="Next"
        :on-click="checkEmailForSso"
      />
      <Button
        v-else-if="step === loginSteps.password"
        :is-loading="isLoading"
        text="Log in"
        :on-click="login"
      />
      <Button
        v-if="step === loginSteps.password"
        text="Forgot your password?"
        :on-click="() => $emit('forgotPassword')"
        is-text
      />
      <!-- <Button
        v-if="step === loginSteps.password"
        is-text
        text="Don't yet have an account? Sign up"
        :on-click="() => $emit('signUp')"
      /> -->
    </template>
  </FormContainer>
</template>
<script>
import TextInput from '../../atoms/input/text-input';
import PasswordInput from '../input/password-input';
import Button from '../../atoms/button/button';
import FormContainer from './form-container';
import rules from '../../../mixins/input-validation-rules';
import { extractDomainFromEmailAddress } from '../../../helpers/email-helper';
import { encodeBase64 } from '../../../helpers/base64-helper';

const loginSteps = {
  email: 'email',
  password: 'password',
};

function cleanEmail(email) {
  return email.toLowerCase().trim();
}

function cleanPassword(password) {
  return password.trim();
}

export default {
  name: 'LoginForm',
  components: {
    Button,
    TextInput,
    PasswordInput,
    FormContainer,
  },
  mixins: [rules],
  data() {
    return {
      step: loginSteps.email,
      emailError: '',
      email: '',
      password: '',
      passwordError: '',
      isLoading: false,
    };
  },
  computed: {
    signedIn() {
      return this.$store.state.auth.signedIn;
    },
    validPassword() {
      return this.password !== '';
    },
    authErrorMessage() {
      return this.$store.state.auth.errorMessage;
    },
    loginSteps() {
      return loginSteps;
    },
    identityProvider() { return this.$store.state.auth.identityProvider; },
  },
  watch: {
    identityProvider() {
      if (this.identityProvider) {
        this.redirectToIdentityProvider(this.identityProvider);
      } else if (this.identityProvider === null) {
        this.step = loginSteps.password;
      }
    },
  },
  created() {
    // to ensure that watch on identityProvider is triggered (after signup)
    this.$store.commit('auth/RESET_IDENTITY_PROVIDER');
  },
  methods: {
    resetValidation(event) {
      this.$store.commit('auth/CLEAR_ERROR_MESSAGE');
      if (this.$refs.email && event.target.name === this.$refs.email.name) {
        this.emailError = '';
      }
      if (this.$refs.password && event.target.name === this.$refs.password.name) {
        this.passwordError = '';
      }
    },
    validateForm() {
      let isValid = this.isValidEmail();
      if (!this.validPassword) {
        this.passwordError = 'Please enter a password';
        isValid = false;
      }

      return isValid;
    },
    isValidEmail() {
      if (!this.validateEmail(this.email)) {
        this.emailError = 'Please enter a valid email address';
        return false;
      }

      return true;
    },
    async checkEmailForSso() {
      // Is email address from domain that has single-sign-on enabled?
      if (this.isValidEmail()) {
        this.isLoading = true;
        const domain = extractDomainFromEmailAddress(cleanEmail(this.email));
        await this.$store.dispatch('auth/getIdentityProviderForDomain', domain);
        this.isLoading = false;
      }
    },
    async login() {
      if (this.validateForm() && !this.isLoading) {
        this.isLoading = true;
        const email = cleanEmail(this.email);
        await this.$store.dispatch('auth/login', {
          email,
          password: cleanPassword(this.password),
        });
        this.isLoading = false;
        this.$root.trackEvent.signedIn(email);
      }
    },
    redirectToIdentityProvider(idp) {
      const { clientId, idpName } = idp;

      const domain = process.env.VUE_APP_SSO_DOMAIN;
      // add some clientId into state
      const state = encodeBase64(JSON.stringify({ clientId }));
      const scope = 'openid';
      const redirectUri = window.location.origin; // 'http://localhost:8080';
      window.location.href = `https://${domain}.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${clientId}&identity_provider=${idpName}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-title {
  margin: 20px 0;
  font-size: 1.125rem;
}
</style>
