import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import breakpoints from '../styles/_breakpoints.scss';
import variables from '../styles/_variables.scss';
import clearIcon from '../components/atoms/icon/clear-icon';
import searchIcon from '../components/atoms/icon/search-icon';
import filterIcon from '../components/atoms/icon/filter-icon';
import filterAppliedIcon from '../components/atoms/icon/filter-applied-icon';
import downArrowIcon from '../components/atoms/icon/down-arrow-icon';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      // lists the upper limit of these breakpoints
      xs: breakpoints.sm,
      sm: breakpoints.md,
      md: Number(breakpoints.lg) + 16, // Vuetify adds 16 pixels on 'desktop'
      lg: Number(breakpoints.xl) + 16,
      xl: Infinity,
    },
  },
  icons: {
    values: {
      clearIcon: { component: clearIcon },
      downArrowIcon: { component: downArrowIcon },
      filterIcon: { component: filterIcon },
      filterAppliedIcon: { component: filterAppliedIcon },
      searchIcon: { component: searchIcon },
    },
  },
  theme: {
    // dark: true,
    themes: {
      light: {
        primary: variables.reelablesLightGrey,
        warning: variables.errorRed,
      },
      dark: {
      },
    },
  },
});
