/**
 * Split spaceName on space, and take first element
 * @param {*} spaceName - name of space
 * @returns group name
 */
export function constructGroupFromSpaceName(spaceName = '') {
  if (spaceName === null) {
    return '';
  }
  return spaceName.split(' ')[0];
}

function stripGroupNameFromSpaceName(spaceName, groupName) {
  return spaceName.replaceAll(groupName, '').trim();
}

export function groupAssetInSpacesOnSpaceName(assetInSpaces) {
  const distinctEnterExitSpells = [];

  assetInSpaces
    .sort((a, b) => new Date(a.enteredOn) - new Date(b.enteredOn))// oldest enteredOn first
    .forEach((assetInSpace) => {
      const groupName = constructGroupFromSpaceName(assetInSpace.spaceName);
      const currentEnterExitSpell = distinctEnterExitSpells.find((s) => s.groupName === groupName);

      if (!currentEnterExitSpell) { // no data yet for group
        distinctEnterExitSpells.unshift({
          ...assetInSpace,
          groupName,
          spaceNames: [stripGroupNameFromSpaceName(assetInSpace.spaceName, groupName)],
        });
      } else {
        // current has exited, but next event is still there...
        if (currentEnterExitSpell.hasExited
          && !assetInSpace.hasExited
          && new Date(currentEnterExitSpell.exitedOn) >= new Date(assetInSpace.enteredOn)
        ) {
          currentEnterExitSpell.hasExited = null;
          currentEnterExitSpell.exitedOn = null;
        }

        // current has exited, but next event overlaps...
        if (currentEnterExitSpell.hasExited && assetInSpace.hasExited
          && new Date(currentEnterExitSpell.exitedOn) >= new Date(assetInSpace.enteredOn)
          && new Date(currentEnterExitSpell.exitedOn) < new Date(assetInSpace.exitedOn)) {
          currentEnterExitSpell.exitedOn = assetInSpace.exitedOn;
        }

        // current has exited, no overlap...
        if (currentEnterExitSpell.hasExited
          && new Date(currentEnterExitSpell.exitedOn) < new Date(assetInSpace.enteredOn)) {
          distinctEnterExitSpells.unshift({
            ...assetInSpace,
            groupName,
            spaceNames: [stripGroupNameFromSpaceName(assetInSpace.spaceName, groupName)],
          });
        } else {
          currentEnterExitSpell.spaceNames.push(
            stripGroupNameFromSpaceName(assetInSpace.spaceName, groupName),
          );
        }
      }
    });

  return distinctEnterExitSpells
    .sort((a, b) => new Date(b.enteredOn) - new Date(a.enteredOn))
    .map((s) => ({
      ...s,
      spaceNames: [...new Set(s.spaceNames.sort((a, b) => a.localeCompare(b)))],
    }));
}
