<template>
  <v-switch
    :input-value="value"
    dense
    hide-details
    :readonly="readonly"
    @change="onChange"
  />
</template>
<script>
export default {
  name: 'ToggleSwitch',
  props: {
    value: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  methods: {
    onChange(value) {
      this.$emit('onChange', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input--switch { margin-top: 0; }
</style>
