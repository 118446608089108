<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    @clickRow="highlightRow"
  />
</template>
<script>
import ItemsTable from '../table/items-table';

export default {
  name: 'TeamsTable',
  components: {
    ItemsTable,
  },
  props: {
    selectedTeamId: { type: String, required: true },
  },
  computed: {
    headers() {
      return [{ name: 'Teams', key: 'name' }];
    },
    rows() {
      return this.$store.state.teams.teams.map(({ name, id }) => ({
        id,
        name,
        isSelected: this.selectedTeamId === id,
      }));
    },
    hasMoreData() {
      return this.$store.getters['teams/hasMoreTeams'];
    },
    isLoading() {
      return this.$store.state.teams.isLoadingGetTeams;
    },
  },
  created() {
    this.$store.dispatch('teams/refreshGetTeams');
  },
  methods: {
    highlightRow({ id }) {
      this.$emit('onClickRow', id);
    },
    getData() {
      if (this.hasMoreData) {
        return this.$store.dispatch('teams/getTeams');
      }
      return null;
    },
  },
};
</script>
