<template>
  <div
    :style="`height:${height}px`"
    class="is-bold table-title"
  >
    <div class="team-name">
      {{ tableTitle }}
    </div>
    <slot name="buttons" />
  </div>
</template>
<script>
export default {
  name: 'TableTitle',
  props: {
    height: { type: Number, default: 40 },
    title: { type: String, default: '' },
  },
  computed: {
    selectedTeamName() {
      return this.$store?.state?.teams?.selectedTeam?.name || 'No team selected';
    },
    tableTitle() {
      return this.title !== ''
        ? this.title
        : this.selectedTeamName;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';

.table-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $black;
  font-size: 0.75rem;
  color: $white !important;
  padding: 0 6px 0 16px;
  a.v-btn.button.v-btn--disabled {
    background-color: $grey-2 !important;
  }
}
.team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 6px;
}
</style>
