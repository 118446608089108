<template>
  <Layout
    ref="layout"
    :error-message="errorMessage"
    :is-loading="isLoading"
    class="nb-iot-container"
    :class="{
      'is-large-screen': isLargeScreen
    }"
    @isLargeScreen="onScreenSizeChange"
    @changeContainerHeight="setTimelineHeight"
  >
    <fragment>
      <RefreshMessage v-if="requiresRefresh" />
      <div
        v-else-if="hasData"
        ref="infoWrapper"
        class="info-wrapper"
      >
        <div class="info-group">
          <div class="info-container">
            <div class="info-title">
              Tracking Reference
            </div>
            <div>{{ nbIotData.name }}</div>
          </div>

          <div class="info-container">
            <div class="info-title">
              Status
            </div>
            <div
              class="status-chip"
              :class="statusClass"
            >
              {{ status }}
            </div>
            <div class="info-subscript">
              {{ infoText }} {{ getTimeAgo(nbIotData.lastSeen) }}
            </div>
          </div>
        </div>

        <div class="info-group">
          <div class="info-container">
            <div class="info-title">
              Current Location
            </div>
            <div class="highlight">
              {{ currentLocation }}
            </div>
          </div>

          <div class="info-container">
            <div class="info-title">
              Origin
            </div>
            <div>{{ origin }}</div>
          </div>

          <div class="info-container">
            <div class="info-title">
              Activated
            </div>
            <div>{{ activated }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="!requiresRefresh && hasData"
        class="tabs-wrapper"
        :style="{
          height: isLargeScreen ? timelineHeight + 'px' : 'initial'
        }"
      >
        <v-tabs
          v-if="!isLargeScreen"
          v-model="tab"
          grow
          background-color="#fff"
          color="#45BEC1"
          active-class="active-tab"
        >
          <v-tab>
            TIMELINE
          </v-tab>
          <v-tab>
            JOURNEY
          </v-tab>
        </v-tabs>
        <div
          v-if="isLargeScreen"
          class="info-title"
        >
          Shipment Timeline
        </div>
        <keep-alive>
          <component
            :is="tabComponent"
            :items="tabItems"
            is-grey-scale
            hide-right-if-same
            :latest-point-is-uncertain="isInMotionStationaryOverlap"
            :height="500"
          />
        </keep-alive>
      </div>
    </fragment>
    <template
      v-if="!requiresRefresh && hasData"
      slot="background-content"
    >
      <Map
        :items="mapItems"
        is-grey-scale
        :latest-point-is-uncertain="isInMotionStationaryOverlap"
        height="100vh"
      />
    </template>
  </Layout>
</template>
<script>
import dateTimeHelper from '../../mixins/date-time-helper';
import { constructLocationTimelineItemsForAsset } from '../../helpers/asset-timeline-helper';
import Layout from '../../components/organisms/layout/nb-iot-layout';
import Timeline from '../../components/atoms/timeline/timeline';
import Map from '../../components/molecules/map/map-with-loading';
import RefreshMessage from '../../components/atoms/message/refresh-message';
import { locationStates, pollingNbIotDataInterval } from '../../constants';
import constructDisplayAddress from '../../helpers/nb-iot-current-location-helper';

export default {
  name: 'NbIotDetail',
  components: {
    Layout,
    Timeline,
    Map,
    RefreshMessage,
  },
  mixins: [dateTimeHelper],
  props: {
    assetId: { type: String, required: true },
    t: { type: String, default: '' },
    bleId: { type: String, default: null },
  },
  data() {
    return {
      tab: 0,
      timelineHeight: 0,
      hasLoadedData: false,
      isLargeScreen: false,
    };
  },
  computed: {
    errorMessage() { return this.$store.state.nbIot.errorMessage; },
    isLoading() { return this.$store.state.nbIot.isLoading; },
    nbIotData() { return this.$store.state.nbIot.data; },
    hasData() { return !this.errorMessage && !this.isLoading && !!this.nbIotData; },
    status() { return this.hasData ? this.nbIotData.status : 'Loading...'; },
    statusClass() {
      if (this.status === 'Offline' || this.status === 'Not Activated') {
        return 'offline';
      }

      if (this.status === 'Connecting') {
        return 'connecting';
      }

      return '';
    },
    infoText() {
      return !this.latestLocation.state || this.latestLocation.state === locationStates.stationary
        ? 'Last seen'
        : 'In motion';
    },
    origin() {
      return this.hasData && this.nbIotData.origin && this.nbIotData.origin.city
        ? this.getAddressDisplayName(this.nbIotData.origin)
        : 'Loading...';
    },
    activated() {
      if (!this.hasData || !this.nbIotData.activatedAt) {
        return 'Activating...';
      }
      const date = new Date(this.nbIotData.activatedAt);
      const nowYear = new Date().getFullYear();
      return date.toLocaleString(navigator.language, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: date.getFullYear() !== nowYear ? 'numeric' : undefined,
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    currentLocation() {
      return this.locations.items.length > 0
        ? this.getAddressDisplayName(this.locations.items[0].address)
        : 'Loading...';
    },
    locations() {
      return Array.isArray((this.$store.state.nbIot.locations || {}).items)
        ? this.$store.state.nbIot.locations
        : { items: [] };
    },
    latestLocation() {
      return this.locations.items.length > 0 ? this.locations.items[0] : {};
    },
    isInMotionStationaryOverlap() {
      return this.latestLocation.state === locationStates.inMotionStationaryOverlap;
    },
    tabItems() { return this.tab === 0 ? this.timelineItems : this.mapItems; },
    tabComponent() { return this.tab === 0 ? Timeline : Map; },
    timelineItems() {
      return constructLocationTimelineItemsForAsset(
        {
          locations: this.locations,
          labelAddedOn: this.nbIotData.activatedAt,
          name: this.nbIotData.name,
        },
        true,
        true,
      );
    },
    mapItems() {
      return this.locations.items.map((item) => ({
        longitude: item.coordinate.longitude,
        latitude: item.coordinate.latitude,
        error: item.accuracy,
        properties: {
          id: this.assetId,
          name: (item.address || {}).city || '',
          timestamp: item.timestamp,
        },
      }));
    },
    gotDataAt() { return this.$store.state.nbIot.lastGotDataAt; },
    requiresRefresh() { return this.$store.state.nbIot.exceededMaxPollingDuration; },
  },
  watch: {
    nbIotData() {
      this.setDimensions();
    },
  },
  created() {
    this.getDataAndStartPolling();
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  },
  mounted() {
    this.setDimensions();
  },
  beforeDestroy() {
    this.stopPolling();
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  },
  methods: {
    setDimensions() {
      if (this.hasData && !this.hasLoadedData) {
        this.hasLoadedData = true;
        this.$refs.layout.setDimensions();
      }
    },
    getDataAndStartPolling() {
      if (!this.requiresRefresh
      && (!this.gotDataAt
        || (Date.now() > this.gotDataAt + pollingNbIotDataInterval))) {
        this.getData();
      }
      this.startPolling();
    },
    getData() {
      this.$store.dispatch(
        'nbIot/getNbIotAsset',
        { token: this.t, assetId: this.assetId, bleId: this.bleId },
      );
    },
    startPolling() {
      this.$store.dispatch(
        'nbIot/startPolling',
        { token: this.t, assetId: this.assetId, bleId: this.bleId },
      );
    },
    stopPolling() {
      // stop data polling
      this.$store.dispatch('nbIot/stopPolling');
    },
    onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.getDataAndStartPolling();
      } else {
        this.stopPolling();
      }
    },
    getAddressDisplayName(address) {
      return constructDisplayAddress(address);
    },
    setTimelineHeight(containerHeight) {
      if (this.hasData) {
        this.timelineHeight = containerHeight - this.$refs.infoWrapper.clientHeight - 20;
      }
    },
    onScreenSizeChange(isLargeScreen) {
      this.isLargeScreen = isLargeScreen;
      if (isLargeScreen) {
        this.tab = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_updated-variables.scss';

.is-large-screen {
  .info-wrapper {
    display: flex;
    flex-direction: row;
    height: fit-content;
    padding: 0;
  }
  .info-container:last-of-type {
    margin-bottom: 0px;
  }
  .info-group {
    padding: 20px;
    margin: 20px 10px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    width: 50%;
    min-width: 200px;
  }
  .tabs-wrapper {
    padding: 20px;
    margin: 0px 10px 20px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    overflow-y: auto;
    height: 50%;
  }
}

.info-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.info-container {
  margin-bottom: 20px;
  color: $gray1;
  font-size: 1.25rem;
  word-wrap: break-word;
  .highlight {
    color: $tealBright;
  }
}
.info-title {
  font-size: 1rem;
  text-transform: uppercase;
  color: $gray3;
  font-weight: bold;
  margin-bottom: 8px;
}
.info-subscript {
  margin-top: 6px;
  font-size: 1rem;
  color: $gray1;
}
.status-chip {
  height: 30px;
  border-radius: 15px;
  width: fit-content;
  min-width: 60px;
  padding: 10px;
  text-transform: uppercase;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: $tealBright;
  &.connecting {
    background-color: $yellowDark;
  }&.offline {
    background-color: $redDark;
  }
}
.tabs-wrapper {
  min-height: 400px;
}
.active-tab {
  background-color: #fff;
  color: $tealDark;
  // font-weight: bold !important;
  font-size: 1.125rem;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.2);
  &::before{
    background-color: #fff;
  }
}
</style>
<style lang="scss">
@import '@/styles/_updated-variables.scss';

.nb-iot-container {
  .tabs-wrapper {
    .v-tabs {
      box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.2);
    }
    .v-tabs-slider-wrapper {
      display: none !important;
    }
    .v-tab:not(.active-tab) {
      background-color: #000;
      color: $tealLight !important;
      font-size: 1.125rem;
      border-radius: 5px 5px 0 0;
      box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.2);
    }
    .v-tabs-items {
      min-height: 400px !important;
    }
  }
  .v-timeline {
    background-color: #fff;
    padding: 30px 15px 20px;
  }
  .v-timeline-item {
    color: $gray1;
    position: relative;
    font-size: 0.875rem;
    strong {
      font-weight: normal !important;
      // text-transform: uppercase;
    }
    &:not(:last-of-type) {
      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: $tealLight;
        bottom: -17px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .v-timeline-item__dot {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($tealLight, .5) !important;
  }
  .v-timeline-item__inner-dot {
    background-color: $tealLight !important;
    height: 16px;
    width: 16px;
    .v-icon {
      display: none;
    }
  }
  .v-timeline-item:first-of-type {
    color: $tealBright;
    .v-timeline-item__inner-dot {
      background-color: $tealDark !important;
    }
  }
  .v-timeline-item:last-of-type {
    .v-timeline-item__inner-dot {
      background-color: $gray2 !important;
    }
    .v-timeline-item__dot {
      background-color: rgba($gray2, .5) !important;
    }
  }
}
</style>
