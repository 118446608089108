import api from '../api';
import { assetFilterKeys } from '../constants';

const defaultSelectedId = '';

const getDefaultFilterState = () => ({
  isLoading: false,
  selectedId: defaultSelectedId,
  items: [],
  allLoaded: false,
});

const getDefaultState = () => ({
  [assetFilterKeys.consignments]: getDefaultFilterState(),
  [assetFilterKeys.facilities]: getDefaultFilterState(),
  [assetFilterKeys.zones]: getDefaultFilterState(),
});

function transformConsignment(consignment) {
  return {
    name: consignment.trackingRef || consignment.id,
    ...consignment,
  };
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    RESET_ZONES_STATE(state) {
      state.zones = Object.assign(state.zones, getDefaultFilterState());
    },
    SET_ALL_SELECTED_ITEMS(state, filters) {
      filters.forEach(({ key, value }) => {
        state[key].selectedId = value;
      });
    },
    RESET_ALL_SELECTED_ITEMS(state) {
      Object.keys(state).forEach((key) => {
        state[key].selectedId = defaultSelectedId;
      });
    },
    SET_SELECTED_ITEM(state, { key, id }) {
      state[key].selectedId = id;
    },
    RESET_SELECTED_ITEM(state, key) {
      state[key].selectedId = defaultSelectedId;
    },
    SET_ITEMS(state, { key, items }) {
      state[key].items = [
        ...state[key].items.filter((x) => !items.find((y) => y.id === x.id)),
        ...items,
      ];
    },
    SET_ALL_LOADED(state, key) {
      state[key].allLoaded = true;
    },
  },
  actions: {
    async getConsignments(context, requestParams = {}) {
      const response = await api.getConsignments(
        context.rootState.teams.selectedTeam.id,
        requestParams.nextToken,
        requestParams.searchTerm,
      );

      const transformedResponse = {
        ...response,
        items: response.items.map(transformConsignment),
      };

      context.commit('SET_ITEMS', { key: 'consignments', items: transformedResponse.items });

      if (!requestParams.searchTerm && response.nextToken === null) {
        context.commit('SET_ALL_LOADED', 'consignments');
      }

      return transformedResponse;
    },
    async getConsignment(context, id) {
      const item = await api.getConsignment(id);

      context.commit('SET_ITEMS', { key: 'consignments', items: [transformConsignment(item)] });
    },

    async getFacilities(context, requestParams = {}) {
      const response = await api.getFacilities(
        context.rootState.teams.selectedTeam.id,
        requestParams.nextToken,
        requestParams.searchTerm,
      );

      context.commit('SET_ITEMS', { key: 'facilities', items: response.items });

      if (!requestParams.searchTerm && response.nextToken === null) {
        context.commit('SET_ALL_LOADED', 'facilities');
      }

      return response;
    },
    async getFacility(context, id) {
      const item = await api.getFacility(id);

      context.commit('SET_ITEMS', { key: 'facilities', items: [item] });
    },

    async getZones(context, requestParams = {}) {
      const response = await api.getZones(
        context.rootState.teams.selectedTeam.id,
        requestParams.nextToken,
        requestParams.searchTerm,
      );

      context.commit('SET_ITEMS', { key: 'zones', items: response.items });

      if (!requestParams.searchTerm && response.nextToken === null) {
        context.commit('SET_ALL_LOADED', 'zones');
      }

      return response;
    },
    async getZone(context, id) {
      const item = await api.getZone(id);

      context.commit('SET_ITEMS', { key: 'zones', items: [item] });
    },
  },
};
