import { query, mutate } from './api-consumer';

export default {
  async getTeamMember(organisationId, teamMemberId) {
    return query('getOrganisationMember', { id: organisationId, userId: teamMemberId });
  },
  async getTeamMembers(organisationId, nextToken) {
    return query('getOrganisationMembers', { id: organisationId, limit: 20, nextToken });
  },
  async removeTeamMember(organisationId, userId) {
    return mutate('removeMemberFromOrganisation', { organisationId, userId });
  },
  async addTeamMember(organisationId, email, role) {
    return mutate('addMemberToOrganisation', { organisationId, email, role });
  },
  async updateTeamMemberRole(teamMemberId, organisationId, newRole) {
    return mutate(
      'setMemberRoleInOrganisation',
      { userId: teamMemberId, organisationId, role: newRole },
    );
  },
};
