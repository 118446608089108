<template>
  <Layout
    :error-message="errorMessage"
    :is-loading="isLoading"
    :short-id="id"
    :status="gatewayStatusData.status"
    :last-seen="gatewayStatusData.lastSeen"
    :include-status="!summaryIsVisible"
  >
    <RefreshMessage v-if="requiresRefresh" />
    <div
      v-else-if="!isLoading && !errorMessage"
      class="gateway-status-container"
    >
      <div
        v-observe-visibility="{
          callback: toggleSummaryVisibility,
          throttle: 300,
          throttleOptions: {
            leading: 'visible',
          },
          intersection: {
            rootMargin: '-86px 0px 0px 0px',
          },
        }"
        class="summary"
      >
        <div
          v-if="gatewayStatusData.status"
          class="summary-item"
        >
          <div class="item-title-row">
            <div class="item-title">
              Status
            </div>
            <StatusPill :status="gatewayStatusData.status" />
          </div>
          <div class="item-value small">
            Last seen {{ gatewayStatusData.lastSeen
              ? getTimeAgo(gatewayStatusData.lastSeen)
              : '(no data)' }}
          </div>
        </div>
        <div
          v-if="gatewayStatusData.facilityName"
          class="summary-item"
        >
          <div class="item-title">
            Facility
          </div>
          <div class="item-value">
            {{ gatewayStatusData.facilityName }}
          </div>
        </div>
        <div
          v-if="gatewayStatusData.connectivity"
          class="summary-item"
        >
          <div class="item-title">
            Connectivity
          </div>
          <div class="item-value">
            {{ gatewayStatusData.connectivity }}
          </div>
        </div>
        <div
          v-if="fullModelName"
          class="summary-item"
        >
          <div class="item-title">
            Model
          </div>
          <div class="item-value">
            {{ fullModelName }}
          </div>
        </div>
      </div>
      <div class="installation-instructions">
        <div class="title">
          Installation Instructions
        </div>
        <div class="device-info-container">
          <div>
            <img
              v-if="(images[modelName] || {}).main"
              :src="images[modelName].main"
              width="160"
              alt="Gateway device"
            >
          </div>
          <div class="device-info">
            <div class="subtitle">
              Dimensions
            </div>
            <p>
              85mm(D) x 254mm(H)
              <br>
              7.3inch(D) x 10inch(H)
            </p>
            <div class="subtitle">
              Weight
            </div>
            <p>
              1.45kg / 51oz
            </p>
            <div class="subtitle">
              Operating Temp.
            </div>
            <p>
              -40°C to +65°C
              <br>
              -40°F to +149°F
            </p>
          </div>
        </div>
        <div class="step">
          <div class="step-title">
            Step 1 - Unbox
          </div>
          <div class="subtitle">
            Box contents
          </div>
          <p>
            <ul>
              <li>{{ modelName }} Gateway Device</li>
              <li>1m / 3ft power cable</li>
            </ul>
          </p>
          <div class="subtitle">
            Power requirements
          </div>
          <p>
            Either via a 110V or 240V adapter or power over Ethernet
          </p>
          <div class="subtitle">
            Connectivity
          </div>
          <p>
            Cellular connectivity via a USB dongle inside the gateway device
          </p>
        </div>
        <div class="step">
          <div class="step-title">
            Step 2 - Mounting
          </div>
          <div class="subtitle">
            Range
          </div>
          <p>
            Range is approx. 40m/130ft radius
          </p>
          <div class="subtitle">
            Where to mount?
          </div>
          <p>
            <ul>
              <li>Somewhere high up: ideally at least 4m / 13ft from the ground</li>
              <li>A location with as much line of sight of items to be tracked</li>
              <li>Place centrally, avoid corners and door bays</li>
            </ul>
          </p>
        </div>
        <div class="step">
          <div class="step-title">
            Step 3 - Powering On
          </div>
          <div class="subtitle">
            LEDs
          </div>
          <div class="image-container">
            <img
              v-if="(images[modelName] || {}).greenLeds"
              :src="images[modelName].greenLeds"
              width="180"
              alt="Gateway LEDs"
            >
          </div>
          <p>
            When the device is powered on and has internet connection then the
            LEDs should light up green, as in the image above.
          </p>
        </div>
        <div class="step">
          <div class="step-title">
            Troubleshooting
          </div>
          <div class="image-container">
            <img
              v-if="(images[modelName] || {}).details"
              :src="images[modelName].details"
              width="300"
              alt="Gateway details"
            >
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import { routeNames, pollingGatewayStatusDataInterval } from '../../constants';
import Layout from '../../components/organisms/gateway-status/layout';
import StatusPill from '../../components/atoms/pill/status-pill';
import RefreshMessage from '../../components/atoms/message/refresh-message';
import { verifySignature } from '../../helpers/token-verification-helper';
import X2000 from '../../assets/images/gateway-status/x2000.png';
import X2000GreenLeds from '../../assets/images/gateway-status/x2000_green-leds.png';
import X2000Details from '../../assets/images/gateway-status/x2000_details.png';
import dateTimeHelper from '../../mixins/date-time-helper';

const PUBLIC_KEY = process.env.VUE_APP_GATEWAY_STICKER_PUBLIC_KEY;

function cleanToken(token) {
  // in case the token wasn't url encoded
  return token.replaceAll(' ', '+');
}

export default {
  name: 'GatewayStatus',
  components: {
    Layout,
    StatusPill,
    RefreshMessage,
  },
  mixins: [dateTimeHelper],
  props: {
    id: { type: String, required: true },
    t: { type: String, default: '' },
  },
  data() {
    return {
      summaryIsVisible: true,
    };
  },
  computed: {
    isSignedIn() { return this.$store.state.auth.signedIn; },
    errorMessage() { return this.$store.state.gateway.errorMessage; },
    isLoading() { return this.$store.state.gateway.isLoading; },
    gatewayStatusData() { return this.$store.state.gateway.data || {}; },
    modelName() { return this.gatewayStatusData.model || ''; },
    fullModelName() { return `${this.gatewayStatusData.manufacturer} ${this.modelName}`; },
    images() {
      return {
        X2000: {
          main: X2000,
          greenLeds: X2000GreenLeds,
          details: X2000Details,
        },
      };
    },
    gotDataAt() { return this.$store.state.gateway.lastGotDataAt; },
    requiresRefresh() { return this.$store.state.gateway.exceededMaxPollingDuration; },
  },
  async created() {
    const token = cleanToken(this.t);
    const shortId = this.id;
    if (!shortId) {
      this.routeHome();
    }
    if (!this.isSignedIn) {
      if (!token) {
        this.routeHome();
      }
      if (await verifySignature(token, `shortId:${shortId}`, PUBLIC_KEY)) {
        this.onCreated();
      } else {
        this.routeHome();
      }
    } else {
      // if signed in, then mark token as 'valid'
      this.onCreated();
    }
  },
  beforeDestroy() {
    this.stopPolling();
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  },
  methods: {
    onCreated() {
      this.getDataAndStartPolling();
      document.addEventListener('visibilitychange', this.onVisibilityChange);
    },
    routeHome() {
      this.$router.replace({ name: routeNames.home });
    },
    getDataAndStartPolling() {
      if (!this.requiresRefresh
      && (!this.gotDataAt
        || (Date.now() > this.gotDataAt + pollingGatewayStatusDataInterval))) {
        this.getData();
      }
      this.startPolling();
    },
    getData() {
      this.$store.dispatch(
        'gateway/getGatewayStatus',
        { token: cleanToken(this.t), shortId: this.id },
      );
    },
    toggleSummaryVisibility(isVisible) {
      this.summaryIsVisible = isVisible;
    },
    startPolling() {
      this.$store.dispatch(
        'gateway/startPolling',
        { token: cleanToken(this.t), shortId: this.id },
      );
    },
    stopPolling() {
      // stop data polling
      this.$store.dispatch('gateway/stopPolling');
    },
    onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.getDataAndStartPolling();
      } else {
        this.stopPolling();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_updated-variables.scss';

.gateway-status-container {
  .summary {
    margin-top: -20px;
    background-color: $gray4;
    padding: 30px 20px 10px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    .summary-item {
      margin-bottom: 20px;
    }
    .item-title-row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
    .item-title {
      color: $gray2;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 5px;
      margin-right: 10px;
    }
    .item-value {
      font-size: 1.375rem;
      color: $gray1;
      &.small {
        font-size: 1.125rem;
      }
    }
  }
  .installation-instructions {
    padding: 30px 20px;
    color: $gray1;
  }
  .device-info-container {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .device-info {
    margin: 5px 10px;
    font-size: 0.875rem;
    line-height: 1.125rem;
    .subtitle {
      font-weight: bold;
    }
    p {
      margin-bottom: 5px;
    }
  }
  .title {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: bold;
  }
  .step {
    margin: 20px 0 40px;
    .step-title {
      text-transform: uppercase;
      color: $gray2;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .subtitle {
      font-weight: bold;
      margin-bottom: 10px;
    }
    p {
      line-height: 1.25rem;
      margin-bottom: 20px;
    }
    ul {
      margin-left: 20px;
    }
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
