import Facilities from '../views/facilities/Facilities';
import FacilityDetail from '../views/facilities/FacilityDetail';
import FacilityGatewayDetail from '../views/facilities/FacilityGatewayDetail';
import FacilityPrinterDetail from '../views/facilities/FacilityPrinterDetail';
import AssetDetail from '../views/assets/AssetDetail';
import { routeNames } from '../constants';

export default [
  {
    path: 'facilities',
    name: routeNames.facilities.index,
    component: Facilities,
    meta: {
      noTopNav: true,
    },
    children: [
      {
        path: ':id',
        name: routeNames.facilities.detail,
        component: FacilityDetail,
        props: true,
        meta: {
          noTopNav: true,
        },
        children: [
          {
            path: 'spaces/:spaceId',
            name: routeNames.facilities.gatewayDetail,
            component: FacilityGatewayDetail,
            props: (route) => ({
              facilityId: route.params.id, // Pull `id` from route params
              spaceId: route.params.spaceId,
            }),
            meta: {
              noTopNav: true,
            },
          },
          {
            path: 'printers/:printerId',
            name: routeNames.facilities.printerDetail,
            component: FacilityPrinterDetail,
            props: (route) => ({
              facilityId: route.params.id, // Pull `id` from route params
              printerId: route.params.printerId,
            }),
            meta: {
              noTopNav: true,
            },
            children: [
              {
                path: 'print-jobs/:printJobId/asset/:assetId',
                name: routeNames.facilities.printJobAssetDetail,
                component: AssetDetail,
                props: (route) => ({
                  id: route.params.assetId,
                  isPrintJobAssetDetail: true,
                }),
                meta: {
                  noTopNav: true,
                },
              },
            ],
          },
          {
            path: 'assets/:assetId',
            name: routeNames.facilities.assetDetail,
            component: AssetDetail,
            props: (route) => ({
              id: route.params.assetId,
              isFacilityAssetDetail: true,
            }),
            meta: {
              noTopNav: true,
            },
          },
        ],
      },
    ],
  },
];
