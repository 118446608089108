<template>
  <Layout
    :error-message="isAssetNotFoundError ? '' : errorMessage"
    :is-loading="isLoading"
  >
    <fragment>
      <v-form
        v-if="!isLoading && hasNfcIdParam"
        ref="form"
        class="form-wrapper"
        @submit.prevent="startTracking"
      >
        <div class="inputs-container">
          <div class="input-title">
            Tracking Reference
          </div>
          <v-text-field
            v-model="form.name"
            name="name"
            placeholder="Enter a tracking reference"
            hint="Enter a name or tracking number"
            :rules="[required('Tracking Reference')]"
            :disabled="isLinkingLabel"
            required
            clearable
            autocomplete="off"
          />
        </div>
        <div class="button-container">
          <v-btn
            large
            :loading="isLinkingLabel"
            depressed
            dark
            @click="startTracking"
          >
            Start tracking
          </v-btn>
        </div>
      </v-form>
      <div
        v-if="!isLoading && !hasNfcIdParam"
        class="use-nfc-message"
      >
        LABEL NOT YET ACTIVATED
        <br><br>
        TO ACTIVATE: TAP LABEL WITH NFC-ENABLED SMARTPHONE
      </div>
    </fragment>
  </Layout>
</template>
<script>
import { routeNames } from '../../constants';
import Layout from '../../components/organisms/layout/nb-iot-layout';
import rules from '../../mixins/input-validation-rules';

export default {
  name: 'NbIotLink',
  components: {
    Layout,
  },
  mixins: [rules],
  props: {
    b: { type: String, required: true },
    t: { type: String, default: '' },
    n: { type: String, default: '' },
  },
  data() {
    return {
      displayForm: false,
      isLoading: true,
      canLinkLabel: false,
      isLinkingLabel: false,
      form: {
        name: '',
      },
    };
  },
  computed: {
    bleId() { return this.b; },
    nfcId() { return this.n; },
    token() { return this.t; },
    errorMessage() { return this.$store.state.nbIot.errorMessage; },
    isLoadingData() { return this.$store.state.nbIot.isLoading; },
    nbIotData() { return this.$store.state.nbIot.data; },
    hasData() { return !this.errorMessage && !this.isLoadingData && !!this.nbIotData; },
    isAssetNotFoundError() { return this.errorMessage === `No asset found with bleId: ${this.bleId}`; },
    activationData() { return this.$store.state.nbIot.activationData; },
    hasActivationData() {
      return !this.errorMessage
        && !this.isLinkingLabel && !!this.activationData;
    },
    hasNfcIdParam() { return !!this.nfcId; },
  },
  watch: {
    hasData() {
      if (this.hasData) {
        // 1) On created, use bleId and token to get nbIot data
        //      - if data - then route to tracking screen
        this.$router.replace({
          name: routeNames.nbIot.detail,
          query: { t: this.token, bleId: this.bleId, assetId: this.nbIotData.id },
        });
      }
    },
    isLoadingData() {
      if (!this.isLoadingData && this.isAssetNotFoundError) {
        // 2) If no data - then label isn't linked
        //    - if nfcId param is not set then display NFC Tap prompt
        //    - display form and ask user to input Tracking Reference
        this.isLoading = false;
      }
    },
    hasActivationData() {
      if (this.hasActivationData) {
        // Route user to tracking screen on successful activation
        this.$router.replace({
          name: routeNames.nbIot.detail,
          query: { t: this.activationData.token, assetId: this.activationData.assetId },
        });
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(
        'nbIot/getNbIotAsset',
        { token: this.token, bleId: this.bleId },
      );
    },
    async startTracking() {
      if (this.$refs.form.validate()) {
        this.isLinkingLabel = true;

        if (this.$root.featureToggles.useUserLocation() && navigator.geolocation) {
          // With location...
          navigator.geolocation.getCurrentPosition(async (position) => {
            await this.$store.dispatch(
              'nbIot/activateNbIotLabel',
              {
                token: this.token,
                name: this.form.name,
                bleId: this.bleId,
                location: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  error: position.coords.accuracy,
                  timestamp: new Date(position.timestamp).toISOString(),
                },
              },
            );
            this.isLinkingLabel = false;
          }, () => {
            // error
            // console.log('** ERROR GETTING LOCATION:', error.message);
            this.isLinkingLabel = false;
          });
        } else {
          // No location...
          await this.$store.dispatch(
            'nbIot/activateNbIotLabel',
            { token: this.token, name: this.form.name, bleId: this.bleId },
          );
          this.isLinkingLabel = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_updated-variables.scss';

.form-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.inputs-container {
  margin-bottom: 20px;
  color: $gray1;
  font-size: 1.25rem;
  word-wrap: break-word;
}
.input-title {
  font-size: 1rem;
  text-transform: uppercase;
  color: $gray3;
  font-weight: bold;
  margin-bottom: 8px;
}
.button-container {
  display: flex;
  flex-direction: column;
}
.use-nfc-message {
  padding: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 28px;
}
</style>
<style lang="scss">
.inputs-container .error--text .v-messages__message {
  color: #ff3e3e;
}
</style>
