<template>
  <div
    class="status-pill"
    :class="{
      'is-online': statusKey.toLowerCase() === 'online',
      'is-offline': statusKey.toLowerCase() === 'offline',
    }"
  >
    {{ statusKey || 'Loading' }}
  </div>
</template>
<script>
export default {
  name: 'StatusPill',
  props: {
    status: { type: String, default: '' },
  },
  computed: {
    statusKey() {
      return this.status === 'Unknown'
        ? 'Offline'
        : this.status;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_updated-variables.scss';
.status-pill {
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 26px;
  border-radius: 13px;
  padding: 8px;
  min-width: 80px;
  width: fit-content;
  font-size: 0.875rem;
  background-color: $yellowDark;
  &.is-online { background-color: $tealBright; }
  &.is-offline { background-color: $redDark; }
}
</style>
