<template>
  <FormContainer
    :title="title"
    :on-keydown="resetValidation"
    :error-message="authErrorMessage"
    @submit="signUp"
  >
    <fragment v-if="isStep1">
      <TextInput
        ref="givenName"
        v-model="givenName"
        autocomplete="given-name"
        label="Given Name"
        :error-message="givenNameError"
      />
      <TextInput
        ref="familyName"
        v-model="familyName"
        autocomplete="family-name"
        label="Family Name"
        :error-message="familyNameError"
      />
      <TextInput
        ref="email"
        v-model="email"
        label="Email"
        :error-message="emailError"
      />
      <PasswordInput
        ref="password"
        v-model="password"
        is-new
        :error-message="passwordError"
      />
    </fragment>
    <fragment v-else>
      <AuthMessage>
        We have sent a verification link to <Email>{{ email }}</Email>.
        Please use the link we have sent you to verify your account.
      </AuthMessage>
    </fragment>
    <template #buttons>
      <Button
        v-if="isStep1"
        :is-loading="isLoading"
        text="Sign up"
        :on-click="signUp"
      />
      <Button
        text="Return to login"
        :on-click="() => $emit('cancel')"
        is-text
      />
    </template>
  </FormContainer>
</template>
<script>
import TextInput from '../../atoms/input/text-input';
import PasswordInput from '../input/password-input';
import Button from '../../atoms/button/button';
import FormContainer from './form-container';
import rules from '../../../mixins/input-validation-rules';
import Email from './email-address';
import AuthMessage from './auth-message';

export default {
  name: 'SignUpForm',
  components: {
    Button,
    TextInput,
    PasswordInput,
    FormContainer,
    Email,
    AuthMessage,
  },
  mixins: [rules],
  data() {
    return {
      givenNameError: '',
      givenName: '',
      familyNameError: '',
      familyName: '',
      emailError: '',
      email: '',
      password: '',
      passwordError: '',
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
    authErrorMessage() {
      return this.$store.state.auth.errorMessage;
    },
    title() {
      return this.isStep1 ? null : 'Verification link sent';
    },
    isStep1() {
      return this.$store.state.auth.signUpStep === 1;
    },
  },
  methods: {
    resetValidation(event) {
      this.$store.commit('auth/CLEAR_ERROR_MESSAGE');
      if (this.$refs.givenName && event.target.name === this.$refs.givenName.name) {
        this.givenNameError = '';
      }
      if (this.$refs.familyName && event.target.name === this.$refs.familyName.name) {
        this.familyNameError = '';
      }
      if (this.$refs.email && event.target.name === this.$refs.email.name) {
        this.emailError = '';
      }
      if (this.$refs.password && event.target.name === this.$refs.password.name) {
        this.passwordError = '';
      }
    },
    validateForm() {
      let isValid = true;
      if (typeof this.validateName(this.givenName) === 'string') {
        this.givenNameError = this.validateName(this.givenName);
        isValid = false;
      }
      if (typeof this.validateName(this.familyName) === 'string') {
        this.familyNameError = this.validateName(this.familyName);
        isValid = false;
      }
      if (!this.validateEmail(this.email)) {
        this.emailError = 'Please enter a valid email address';
        isValid = false;
      }
      if (typeof this.validatePassword(this.password) === 'string') {
        this.passwordError = `Password must contain ${this.validatePassword(this.password)}`;
        isValid = false;
      }
      return isValid;
    },
    signUp() {
      if (this.validateForm() && !this.isLoading) {
        this.$store.dispatch('auth/signUp', {
          givenName: this.givenName,
          familyName: this.familyName,
          email: this.email.toLowerCase(),
          password: this.password,
        }).then(() => {
          this.$root.trackEvent.registeredAsAUser(this.email.toLowerCase());
        });
      }
    },
  },
};
</script>
