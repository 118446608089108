import wrapper from '../analytics-wrapper';

export default {
  addedTeam({ name, id }) {
    wrapper.track('Added team', {
      'New Team Name': name,
      'New Team ID': id,
    });
  },
  switchedTeam(oldTeam, newTeam) {
    wrapper.track('Switched team', {
      'Old Team Name': oldTeam.name,
      'Old Team ID': oldTeam.id,
      'New Team Name': newTeam.name,
      'New Team ID': newTeam.id,
    });
  },
  removedTeam({
    name, id, members, assets,
  }) {
    wrapper.track('Removed team', {
      'Removed Team Name': name,
      'Removed Team ID': id,
      'Members Count': members?.items.length,
      'Assets Count': assets?.items.length,
    });
  },
  setSelectedTeamSuperProperty(teamId) {
    wrapper.register({ 'Team ID': teamId });
  },
  updatedTeamName(teamId, newName, oldName) {
    wrapper.track('Updated team name', {
      'Team ID': teamId,
      'New Team Name': newName,
      'Old Team Name': oldName,
    });
  },
};
