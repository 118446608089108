import api from '../api';
import { logger } from '../mixins/logging/logger';

const getDefaultState = () => ({
  subscriptions: {},
  notifications: [],
  totalNofications: 0,
});

const unSubscribeToAllSubscriptions = (state) => {
  Object.keys(state.subscriptions).forEach((key) => {
    state.subscriptions[key].unsubscribe();
  });
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      unSubscribeToAllSubscriptions(state);
      Object.assign(state, getDefaultState());
    },
    ADD_SUBSCRIPTION(state, { key, subscription }) {
      state.subscriptions[key] = subscription;
    },
    REMOVE_SUBSCRIPTION(state, key) {
      if (state.subscriptions[key]) {
        state.subscriptions[key].unsubscribe();
      }
    },
    ADD_NOTIFICATION(state, notification) {
      state.totalNofications += 1;
      state.notifications.push({
        ...notification,
        id: state.totalNofications,
      });
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notifications = state.notifications
        .filter((notification) => notification.id !== id);
    },
  },
  actions: {
    subscribeToUserNotifications(context) {
      context.commit('REMOVE_SUBSCRIPTION', 'user-notifications');
      const subscription = api.subscribeToUserNotifications(
        context.rootState.user.user.id,
        (notification) => context.commit('ADD_NOTIFICATION', notification),
        (error) => {
          if (error?.error?.errors?.length && error?.error?.errors[0].message === 'Timeout disconnect') {
            logger.error('User notification subscription timeout error', {
              selectedTeam: context.rootState.teams.selectedTeam,
              user: context.rootState.user.user,
            });
            // For now, commenting out the restart - which is causing the memory leak
            // context.dispatch('subscribeToUserNotifications');
          }
        },
      );
      context.commit('ADD_SUBSCRIPTION', { key: 'user-notifications', subscription });
    },
  },
  getters: {},
};
