<template>
  <div
    v-if="hasMessage"
    class="error-message"
    :class="{
      'is-large': isLarge
    }"
  >
    {{ errorMessage }}
  </div>
</template>
<script>
export default {
  name: 'ErrorMessage',
  props: {
    errorMessage: { type: String, default: '' },
    isLarge: { type: Boolean, default: false },
  },
  computed: {
    hasMessage() {
      return this.errorMessage && this.errorMessage !== '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.error-message {
  text-align: center;
  color: $error-red;
  font-size: 12px;
  line-height: 12px;
  &.is-large {
    font-size: 1rem;
    line-height: initial;
  }
}
</style>
