<template>
  <ItemsTable
    :title="asset ? asset.name : ''"
    :headers="headers"
    :rows="rows"
    :get-data="() => {}"
    :is-loading="isLoading"
    larger-gap-bottom
  >
    <slot
      slot="buttons"
      name="buttons"
    />
  </ItemsTable>
</template>
<script>
import ItemsTable from '../table/items-table';

export default {
  name: 'AssetTemperatureTable',
  components: {
    ItemsTable,
  },
  props: {
    asset: { type: Object, required: true },
  },
  computed: {
    headers() {
      return [
        { name: 'Time', key: 'timestamp' },
        { name: 'Temperature', key: 'temperature' },
      ];
    },
    rows() {
      return this.asset.temperatures.items
        .map(({ timestamp, degreesCelcius }) => ({
          timestamp: new Date(timestamp).toLocaleString(),
          temperature: degreesCelcius.toFixed(1),
        }));
    },
    isLoading() {
      return this.$store.state.assets.isLoadingAssets;
    },
  },
};
</script>
