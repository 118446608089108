var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"asset-temperature-chart-conatiner"},[_c('LineChart',{attrs:{"chartdata":_vm.chartData,"options":{
      scales: {
        xAxes: {
          type: 'time',
          time: {
            unit: 'day',
          }
        }
      },
      legend: {
        onClick: null
      },
      responsive: true,
      maintainAspectRatio: false
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }