<template>
  <RouteWithChildren :parent-level="2">
    <DashboardsTable />
  </RouteWithChildren>
</template>
<script>
import RouteWithChildren from '../../components/organisms/layout/route-with-children';
import DashboardsTable from '../../components/organisms/dashboards/dashboards-table';

export default {
  name: 'Dashboards',
  components: {
    RouteWithChildren,
    DashboardsTable,
  },
  computed: {
    hasDashboards() {
      return this.$store.getters['dashboards/hasDashboards'];
    },
  },
  created() {
    this.getDashboards();
  },
  methods: {
    getDashboards() {
      if (!this.hasDashboards) this.$store.dispatch('dashboards/getDashboards');
    },
  },
};
</script>
