import api from '../api';

const getDefaultState = () => ({
  errorMessage: '',
  isLoadingDashboard: false,
  isLoadingDashboards: false,
  dashboards: [],
  nextToken: null,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    START_LOADING_DASHBOARD(state) {
      state.isLoadingDashboard = true;
    },
    STOP_LOADING_DASHBOARD(state) {
      state.isLoadingDashboard = false;
    },
    START_LOADING_DASHBOARDS(state) {
      state.isLoadingDashboards = true;
    },
    STOP_LOADING_DASHBOARDS(state) {
      state.isLoadingDashboards = false;
    },
    SET_DASHBOARDS(state, dashboards) {
      state.dashboards = [
        ...state.dashboards,
        ...dashboards.filter((d1) => !state.dashboards.find((d2) => d1.id === d2.id)),
      ];
    },
    ADD_DASHBOARD(state, dashboard) {
      // retain the original order
      state.dashboards = state.dashboards.map((d) => (d.id === dashboard.id ? dashboard : d));
      if (!state.dashboards.find(({ id }) => dashboard.id === id)) {
        state.dashboards.push(dashboard);
      }
    },
    SET_NEXT_TOKEN(state, nextToken) {
      state.nextToken = nextToken;
    },
  },
  actions: {
    async getDashboards(context) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_DASHBOARDS');
      try {
        const dashboardsResponse = await api.getDashboards(
          context.rootState.teams.selectedTeam.id,
          context.state.nextToken,
        );
        context.commit('SET_NEXT_TOKEN', dashboardsResponse.nextToken);
        context.commit('SET_DASHBOARDS', dashboardsResponse.items);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_DASHBOARDS');
      }
    },
    async getDashboard(context, dashboardId) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_DASHBOARD');
      try {
        const dashboardResponse = await api.getDashboard(
          context.rootState.teams.selectedTeam.id,
          dashboardId,
        );
        context.commit('ADD_DASHBOARD', dashboardResponse);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_DASHBOARD');
      }
    },
  },
  getters: {
    hasMoreDashboards(state) {
      return state.nextToken !== null;
    },
    hasDashboards(state) {
      return state.dashboards.length > 0;
    },
  },
};
