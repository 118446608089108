<template>
  <Map
    :items="locations"
    :includes-first-point="!hasMoreLocations"
    @clickedClusteredItems="(items) => locationClicked(items[0])"
    @clickedUnclusteredItem="locationClicked"
  >
    <div slot="popup-content">
      <LastSeenMapPopup
        :last-seen-time="lastSeenTime"
        :timer-id="timer"
      />
    </div>
  </Map>
</template>
<script>
import Map from '../../atoms/map/map-box';
import LastSeenMapPopup from '../../atoms/map/last-seen-map-popup';

export default {
  components: {
    Map,
    LastSeenMapPopup,
  },
  props: {
    locations: { type: Array, default: () => [] },
    timer: { type: Number, default: 0 },
    hasMoreLocations: { type: Boolean, default: false },
  },
  data() {
    return {
      lastSeenTime: null,
    };
  },
  methods: {
    locationClicked(locationProperties) {
      this.lastSeenTime = locationProperties.timestamp;
    },
  },
};
</script>
