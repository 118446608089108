/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import api from '../api';
import router from '../router/index';
import { routeNames } from '../constants';
import { logger } from '../mixins/logging/logger';

const getDefaultState = () => ({
  authUser: null,
  signedIn: false,
  isLoading: false,
  isLoadingSso: false,
  isSso: false,
  isGetUserLoading: false,
  errorMessage: '',
  passwordResetStep: 1,
  signUpStep: 1,
  identityProvider: undefined,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    START_LOADING(state) { state.isLoading = true; },
    STOP_LOADING(state) { state.isLoading = false; },
    START_LOADING_SSO(state) { state.isLoadingSso = true; },
    STOP_LOADING_SSO(state) { state.isLoadingSso = false; },
    IS_SSO(state) { state.isSso = true; },
    START_LOADING_GET_USER(state) {
      state.isGetUserLoading = true;
    },
    STOP_LOADING_GET_USER(state) {
      state.isGetUserLoading = false;
    },
    LOGIN(state) {
      state.signedIn = true;
    },
    LOG_OUT(state) {
      state.signedIn = false;
    },
    SET_AUTH_USER(state, user) {
      state.authUser = user;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    SET_PASSWORD_RESET_STEP(state, step) {
      state.passwordResetStep = step;
    },
    RESET_PASSWORD_RESET_STEP(state) {
      state.passwordResetStep = 1;
    },
    SET_SIGN_UP_STEP(state, step) {
      state.signUpStep = step;
    },
    RESET_SIGN_UP_STEP(state) {
      state.signUpStep = 1;
    },
    SET_IDENTITY_PROVIDER(state, identityProvider) {
      state.identityProvider = identityProvider;
    },
    RESET_IDENTITY_PROVIDER(state) {
      state.identityProvider = undefined;
    },
  },
  actions: {
    async _login(context, user) {
      context.commit('SET_AUTH_USER', user);
      context.commit('LOGIN', user);

      // const credentials = await Auth.currentUserCredentials();
      // const userAttributes = user.attributes || user.signInUserSession?.idToken?.payload;
    },
    _logout(context) {
      if (context.state.signedIn) {
        context.dispatch('resetAllState', null, { root: true });
        context.commit('SET_AUTH_USER', null);
        context.commit('LOG_OUT');
        router.push({ name: routeNames.home }).catch(() => {});
      }
    },
    async signUp(context, credentials) {
      context.commit('START_LOADING');
      try {
        await Auth.signUp({
          username: credentials.email,
          password: credentials.password,
          attributes: {
            email: credentials.email,
            given_name: credentials.givenName,
            family_name: credentials.familyName,
            name: `${credentials.givenName} ${credentials.familyName}`,
            'custom:client_app': 'got-it-web-app',
          },
        });
        context.commit('SET_SIGN_UP_STEP', 2);
      } catch (error) {
        logger.error('Error signing up', { error });
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async getUser(context) {
      context.commit('START_LOADING_GET_USER');
      try {
        const user = await Auth.currentAuthenticatedUser();
        context.dispatch('_login', user);
      } catch (error) {
        if (error !== 'not authenticated') {
          logger.error('Error getting user', { error });
        }
        context.dispatch('_logout');
      } finally {
        context.commit('STOP_LOADING_GET_USER');
      }
    },
    async login(context, credentials) {
      context.commit('START_LOADING');
      try {
        const user = await Auth.signIn(credentials.email, credentials.password);
        context.dispatch('_login', user);
      } catch (error) {
        logger.error('Error logging in', { error });
        context.commit('SET_ERROR_MESSAGE', error.message);
        context.dispatch('_logout');
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async logOut(context) {
      context.commit('START_LOADING');
      try {
        await Auth.signOut();
        context.dispatch('_logout');
      } catch (error) {
        logger.error('Error logging out', { error });
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async getResetPasswordCode(context, email) {
      context.commit('START_LOADING');
      try {
        await Auth.forgotPassword(email);
        context.commit('SET_PASSWORD_RESET_STEP', 2);
      } catch (error) {
        logger.error('Error getting password reset code', { error });
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async resetPassword(context, data) {
      context.commit('START_LOADING');
      try {
        await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
        context.commit('SET_PASSWORD_RESET_STEP', 3);
      } catch (error) {
        logger.error('Error resetting password', { error });
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    // SSO
    async getIdentityProviderForDomain(context, domain) {
      context.commit('START_LOADING');
      try {
        const identityProvider = await api.getDomainIdentityProvider(domain);
        context.commit('SET_IDENTITY_PROVIDER', identityProvider);
        return identityProvider;
      } catch (error) {
        if (error.message.includes('No Identity Provider found for domain')) {
          context.commit('SET_IDENTITY_PROVIDER', null);
        } else {
          logger.error('Error getting identity provider', { error });
          context.commit('SET_ERROR_MESSAGE', error.message);
        }
      } finally {
        context.commit('STOP_LOADING');
      }

      return null;
    },
    async athenticateIdpResponse(context, idpResponse) {
      context.commit('IS_SSO');
      context.commit('START_LOADING_SSO');
      try {
        const IDP_USER_POOL_ID = process.env.VUE_APP_SSO_USER_POOL_ID;

        let formBody = [];
        const data = {
          grant_type: 'authorization_code',
          client_id: idpResponse.clientId,
          code: idpResponse.authCode,
          redirect_uri: window.location.origin, // 'http://localhost:8080',
          scope: 'openid',
        };
        Object.entries(data).forEach(([key, value]) => {
          const encodedKey = encodeURIComponent(key);
          const encodedValue = encodeURIComponent(value);
          formBody.push(`${encodedKey}=${encodedValue}`);
        });
        formBody = formBody.join('&');

        const domain = process.env.VUE_APP_SSO_DOMAIN;
        const region = process.env.VUE_APP_AWS_COGNITO_REGION;

        const tokenResponse = await fetch(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: formBody,
        }).then((response) => response.json());

        const idTokenPayload = jwtDecode(tokenResponse.id_token);

        Auth.federatedSignIn(
          // logging in against user pool
          `cognito-idp.${region}.amazonaws.com/${IDP_USER_POOL_ID}`,
          {
            token: tokenResponse.id_token, // The id token from Auth0
            expires_at: idTokenPayload.exp * 1000, // the expiration timestamp
          },
          {
            email: idTokenPayload.email,
            given_name: idTokenPayload.given_name,
            family_name: idTokenPayload.family_name,
          },
        );
      } catch (error) {
        logger.error('Validating Auth Code', { error });
        const errorMessage = 'Something went wrong. Please try again or contact support if the problem persists.';
        context.commit('SET_ERROR_MESSAGE', errorMessage);
        context.dispatch('logOut');
      } finally {
        context.commit('STOP_LOADING_SSO');
      }
    },
  },
  getters: {},
};
