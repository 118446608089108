export const namespace = 'gotItWebApp';
export const usingAndroidApp = 'usingAndroidApp';
export const usingIosApp = 'usingIosApp';
export const nfcScanSuccess = 'nfcScanSuccess';
export const login = 'login';
export const logout = 'logout';
export const startNfcScan = 'startNfcScan';
export const stopNfcScan = 'stopNfcScan';
export const storeCookie = 'storeCookie';
export const startLabelTest = 'startLabelTest';
export const refresh = 'refresh';

const tLabelPath = 'tLabel/';
export const tLabel = {
  startDiscovery: `${tLabelPath}startDiscovery`,
  stopDiscovery: `${tLabelPath}stopDiscovery`,
  startDownload: `${tLabelPath}startDownload`,
  stopDownload: `${tLabelPath}stopDownload`,
};

export const goToBluetoothSettings = 'goToBluetoothSettings';
export const getDeviceUUID = 'getDeviceUUID';
export const startMobileGateway = 'startMobileGateway';
export const stopMobileGateway = 'stopMobileGateway';
export const log = 'log';
export const enterEvent = 'enterEvent';
export const enterEvents = 'enterEvents';
export const exitEvent = 'exitEvent';
export const exitEvents = 'exitEvents';
export const snapshotEvent = 'snapshotEvent';
export const temperatureEvent = 'temperatureEvent';
export const locationEvent = 'locationEvent';
export const deviceUUID = 'deviceUUID';
export const registerForPushNotifications = 'registerForPushNotifications';
