<template>
  <VerticalContainer is-editing>
    <Table
      :headers="headers"
      first-column-width="30%"
      :rows="rows"
      :is-editing="true"
      @onChange="onChange"
    />
    <ErrorMessage :error-message="errorToDisplay" />
    <template #buttons>
      <Button
        text="Save"
        :on-click="save"
        :is-loading="isLoading"
      />
      <Button
        text="Cancel"
        :on-click="cancelClick"
        :disabled="isLoading"
      />
    </template>
    <YesNoDialogue
      :is-visible="isCancelDialogueVisible"
      message="Any unsaved data will be lost"
      @no="() => isCancelDialogueVisible = false"
      @yes="goToTeamHome"
    />
  </VerticalContainer>
</template>

<script>
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import Table from '../../components/atoms/table/table';
import ErrorMessage from '../../components/atoms/message/error-message';
import Button from '../../components/atoms/button/button';
import YesNoDialogue from '../../components/molecules/dialogues/yes-no-dialogue';
import rules from '../../mixins/input-validation-rules';
import { routeNames, inputDataTypes } from '../../constants';

const roleToggles = {
  viewer: 'isViewer',
  admin: 'isAdmin',
};

export default {
  name: 'AddMember',
  components: {
    VerticalContainer,
    Button,
    ErrorMessage,
    Table,
    YesNoDialogue,
  },
  mixins: [rules],
  data() {
    return {
      isViewer: false,
      isAdmin: false,
      email: '',
      emailErrorMessage: '',
      isCancelDialogueVisible: false,
    };
  },
  computed: {
    headers() {
      return [{ key: 'name' }, { key: 'value' }];
    },
    isLoading() {
      return this.$store.state.teamMembers.isLoadingTeamMember;
    },
    serverErrorMessage() {
      return this.$store.state.teamMembers.errorMessage;
    },
    errorToDisplay() {
      return [this.emailErrorMessage, this.serverErrorMessage]
        .filter((message) => message && message !== '')
        .join(', ');
    },
    hasError() {
      return this.errorToDisplay !== '';
    },
    rows() {
      return [
        {
          name: 'Email',
          value: '',
          placeholder: 'Insert email',
          isEditable: true,
          dataType: inputDataTypes.text,
        },
        {
          name: 'Is an admin',
          id: roleToggles.admin,
          value: this.isAdmin,
          isEditable: true,
          dataType: inputDataTypes.boolean,
        },
        {
          name: 'Is a viewer',
          id: roleToggles.viewer,
          value: this.isViewer,
          isEditable: true,
          dataType: inputDataTypes.boolean,
        },
      ];
    },
  },
  destroyed() {
    this.$store.commit('teamMembers/CLEAR_ERROR_MESSAGE');
  },
  methods: {
    onChange({ name, id, value }) {
      if (typeof value === 'boolean') {
        if (id === roleToggles.viewer) {
          this.isViewer = value;
          this.isAdmin = false;
        } else if (id === roleToggles.admin) {
          this.isAdmin = value;
          this.isViewer = false;
        }
      } else {
        this.email = value;
      }
      this.rows.find((row) => row.name === name).value = value;
      this.resetNameError();
    },
    resetNameError() {
      this.emailErrorMessage = '';
    },
    async save() {
      const isEmailValid = this.validateEmail(this.email);
      if (isEmailValid === true) {
        const newMember = await this.$store.dispatch('teamMembers/addTeamMember', {
          email: this.email.toLowerCase(),
          isAdmin: this.isAdmin,
          isViewer: this.isViewer,
        });
        if (!this.hasError) {
          this.$root.trackEvent.addedMember(newMember);
          this.goToTeamHome();
        }
      } else {
        this.emailErrorMessage = 'Please enter a valid email address';
      }
    },
    cancelClick() {
      if (this.email !== '') {
        // open modal
        this.isCancelDialogueVisible = true;
      } else {
        this.goToTeamHome();
      }
    },
    goToTeamHome() {
      this.$router.replace({ name: routeNames.team.list });
    },
  },
};
</script>
