<template>
  <RouteWithChildren :parent-level="3">
    <ItemDetail
      ref="itemDetail"
      :item-title="facility.name || 'Loading...'"
      :item="facility"
      :explorer-toggle-options="toggleOptions"
      :rows="rowsFacilityDetail"
      :save-item="empty"
      :delete-item="empty"
      :delete-tracker="empty"
      :delete-item-dialogue-title="''"
      :delete-tracker-dialogue-title="''"
      :display-edit="false"
      :error="errorMessage"
      @close="close"
      @toggleIndex="setToggleIndex"
    >
      <template
        #explore-item-1
      >
        <AssetsTable
          smaller-gap-bottom
          :selected-id="facility.workspaceId"
          :display-has-label="false"
          :with-title="false"
          :no-items-text="'No assets currently in this facility'"
          :display-asset-detail="true"
          :select-item-route="(row) => ({
            name: facilityAssetDetailRouteName,
            params: { assetId: (row || {}).id },
          })"
          :sort-order="assetsSortOrder"
        />
      </template>
      <template
        #explore-item-2
      >
        <ItemsTable
          :headers="gatewayTableHeaders"
          :rows="rowsGatewayList"
          :no-items-text="noGatewaysDataText"
          :is-loading="isLoadingFacilitySpaces"
          :with-title="false"
          :get-data="() => {}"
          smaller-gap-bottom
          @clickRow="displayGatewayDetail"
        />
      </template>
      <template
        #explore-item-3
      >
        <ItemsTable
          :headers="printerTableHeaders"
          :rows="rowsPrinterList"
          :no-items-text="noPrintersDataText"
          :is-loading="isLoadingFacilityPrinters"
          :with-title="false"
          :get-data="() => {}"
          smaller-gap-bottom
          @clickRow="displayPrinterDetail"
        />
      </template>
      <template
        #explore-item-4
      >
        <Map
          v-if="hasLocation"
          :items="location"
        />
        <div
          v-else
        >
          No location
        </div>
      </template>
      <template
        #explore-item-5
      >
        <FloorPlanTable
          :id="id"
          :items="(facility.floorPlans || {}).items"
          :is-loading="isLoadingFacility"
        />
      </template>
    </Itemdetail>
  </routewithchildren>
</template>

<script>
import { routeNames, facilityTabSlugs, sortOrder } from '../../constants';
import RouteWithChildren from '../../components/organisms/layout/route-with-children';
import ItemDetail from '../../components/organisms/items/item-detail';
import ItemsTable from '../../components/organisms/table/items-table';
import AssetsTable from '../../components/organisms/assets/assets-table';
import FloorPlanTable from '../../components/atoms/floor-plan-table/floor-plan-table';
import Map from '../../components/atoms/map/map-box';

const floorPlansDisplayName = 'Floor Plans';
const gatewaysDisplayName = 'Gateways';
const printersDisplayName = 'Printers';
const assetsDisplayName = 'Assets';

export default {
  name: 'Facility',
  components: {
    RouteWithChildren,
    ItemDetail,
    ItemsTable,
    AssetsTable,
    FloorPlanTable,
    Map,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      toggleIndex: 0,
      facilityAssetDetailRouteName: routeNames.facilities.assetDetail,
    };
  },
  computed: {
    assetsSortOrder() { return [sortOrder.firstSeenAsc]; },
    facility() {
      return this.$store.state.facilities.facilities.find(({ id }) => id === this.id) || {};
    },
    hasLocation() {
      if (this.facility.location
       && this.facility.location.latitude && this.facility.location.longitude) {
        return !!Number(this.facility.location.latitude)
       && !!Number(this.facility.location.latitude);
      }
      return false;
    },
    location() {
      return [{
        latitude: Number(this.facility.location.latitude),
        longitude: Number(this.facility.location.longitude),
      }];
    },
    printerTableHeaders() {
      return [{ name: 'Name', key: 'name' }, { name: 'Status', key: 'status' }];
    },
    gatewayTableHeaders() {
      return [{ name: 'Name', key: 'name' }, { name: 'Short ID', key: 'shortId' }, { name: 'Status', key: 'status' }];
    },
    noGatewaysDataText() {
      return 'No gateways';
    },
    noPrintersDataText() {
      return 'No printers';
    },
    itemAlreadyLoaded() {
      return typeof this.facility !== 'undefined';
    },
    rowsFacilityDetail() {
      if (!this.itemAlreadyLoaded) return [];
      const rows = [{
        name: 'Name',
        value: this.facility.name,
      }];

      if (this.facility.userFacilityId) {
        rows.push({
          name: 'User Facility ID',
          value: this.facility.userFacilityId,
        });
      }

      rows.push(...[
        {
          name: '# Assets',
          value: this.facility.currentAssetsCount,
        },
        {
          name: '# Gateways',
          value: this.facility.numberOfGateways,
        },
        {
          name: 'Status',
          value: this.facility.status,
        },
      ]);

      if (this.facility.timeZone) {
        rows.push({
          name: 'Time zone',
          value: this.facility.timeZone,
        });
      }

      return rows;
    },
    rowsGatewayList() {
      return this.facility.spaces || [];
    },
    rowsPrinterList() {
      return this.facility.printers || [];
    },
    isLoadingFacility() {
      return this.$store.state.facilities.isLoadingFacility;
    },
    isLoadingFacilitySpaces() {
      return this.$store.state.facilities.isLoadingFacilitySpaces;
    },
    isLoadingFacilityPrinters() {
      return this.$store.state.facilities.isLoadingFacilityPrinters;
    },
    errorMessage() {
      return this.$store.state.assets.errorMessage;
    },
    toggleOptions() {
      return [
        { displayName: assetsDisplayName, slug: facilityTabSlugs.assets },
        { displayName: gatewaysDisplayName, slug: facilityTabSlugs.gateways },
        { displayName: printersDisplayName, slug: facilityTabSlugs.printers },
        { displayName: 'Location', slug: 'location' },
        { displayName: floorPlansDisplayName, slug: 'floorplans' },
      ];
    },
  },
  watch: {
    id() {
      this.toggleIndex = 0;
      this.conditionallyCallingBackend();
    },
  },
  created() {
    const { tab } = this.$route.query;
    if (tab) {
      this.toggleIndex = this.toggleOptions.findIndex((to) => to.slug === tab);
    } else if (this.$route.name === routeNames.facilities.printerDetail
    || this.$route.name === routeNames.facilities.printJobAssetDetail) {
      this.toggleIndex = this.toggleOptions
        .findIndex((to) => to.slug === facilityTabSlugs.printers);
    }
    this.conditionallyCallingBackend();
  },
  destroyed() {
    this.$store.commit('assets/RESET_FILTERS');
  },
  methods: {
    conditionallyCallingBackend() {
      switch (this.toggleOptions[this.toggleIndex].displayName) {
        case assetsDisplayName:
          this.getAssetsCurrentlyInFacility();
          break;
        case gatewaysDisplayName:
          this.getFacilitySpaces();
          break;
        case printersDisplayName:
          this.getFacilityPrinters();
          break;
        default:
          // do nothing
      }
    },
    getAssetsCurrentlyInFacility() {
      this.$store.commit('assets/SET_FILTERS', [{ key: 'selectedFacilityId', value: this.id }]);
      this.$store.dispatch('assets/refreshGetAssets', { sortOrder: this.assetsSortOrder });
    },
    setToggleIndex(index) {
      this.toggleIndex = index;
      if (this.toggleOptions[this.toggleIndex].displayName === floorPlansDisplayName) {
        // Get facility data (again) in case the S3 URL has expired
        this.getFacility(this.facility.id);
      } else {
        this.conditionallyCallingBackend();
      }
    },
    displayGatewayDetail(row) {
      this.$router.push({
        name: routeNames.facilities.gatewayDetail,
        params: {
          spaceId: row.spaceId,
        },
      });
    },
    displayPrinterDetail(row) {
      this.$router.push({
        name: routeNames.facilities.printerDetail,
        params: {
          printerId: row.printerId,
        },
      });
    },
    locationClicked(locationProperties) {
      this.lastSeenTime = locationProperties.timestamp;
    },
    empty() {},
    close() {
      this.$router.push({ name: routeNames.facilities.index });
    },
    getFacilitySpaces() {
      this.$store.dispatch('facilities/getFacilitySpaces', this.id);
    },
    getFacilityPrinters() {
      this.$store.dispatch('facilities/getFacilityPrinters', this.id);
    },
    getFacility() {
      this.$store.dispatch('facilities/getFacility', this.id);
    },
  },
};
</script>
