import Vue from 'vue';
import { Amplify, Analytics } from 'aws-amplify';
import Fragment from 'vue-fragment';
import VueResize from 'vue-resize';
import { ObserveVisibility } from 'vue-observe-visibility';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import awsconfig from './aws-exports';
import initAppInterface from './mobileApp/appInterface';
import analyticsEvents from './mixins/event-tracking/event-tracking';
import featureToggles from './mixins/feature-toggles';
import workspaceFeatures from './mixins/feature-toggles/workspace-features';
import userRoleHelper from './mixins/user-role-helper';

import 'vue-resize/dist/vue-resize.css';
import log from './mixins/logging/logger';

initAppInterface(store);

Amplify.configure({
  ...awsconfig,
  // Auth: {
  //   region: process.env.VUE_APP_AWS_PROJECT_REGION,
  //   identityPoolRegion: process.env.VUE_APP_AWS_PROJECT_REGION,
  //   //   region: process.env.VUE_APP_AWS_PROJECT_REGION,
  //   //   userPoolId: process.env.VUE_APP_AWS_USER_POOLS_ID,
  //   //   identityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  //   //   userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  //   //   mandatorySignIn: false,

  //   //   //   // hosted UI:
  //   //   //   oauth: {
  //   //   //     domain: 'reelables-sso-demo.auth.us-east-1.amazoncognito.com',
  //   //   //     scope: ['email', 'openid', 'phone'],
  //   //   //     redirectSignIn: 'http://localhost:8080',
  //   //   //     redirectSignOut: 'http"//localhost:8080',
  //   //   //     responseType: 'code',
  //   //   //   },

  // //   // IDP
  // //   oauth: {
  // //     domain: 'user-pool-pr5.auth.us-east-1.amazoncognito.com',
  // //     redirectSignIn: 'http://localhost:8080/',
  // //     redirectSignOut: 'http"//localhost:8080',
  // //     responseType: 'code',
  // //     scope: ['openid'],
  // //   },
  // },
});

Vue.use(Amplify);

// to disable Analytics
Analytics.disable();

Vue.config.productionTip = false;

Vue.use(Fragment.Plugin);

Vue.use(VueResize);

Vue.directive('observe-visibility', ObserveVisibility);

const mixpanel = require('mixpanel-browser');

mixpanel.init(
  process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN,
  { api_host: 'https://api-eu.mixpanel.com', batch_requests: true },
);

new Vue({
  router,
  mixins: [analyticsEvents, log, featureToggles, workspaceFeatures, userRoleHelper],
  mixpanel,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// Checking if analytics should be tracked
// Prod env always has analytics tracking enabled
// Dev env as default has analytics tracking disabled so events for e2e tests are not logged
// to enable analaytcis tracking in Dev run `npm run serve:with:analytics`
if (process.env.VUE_APP_SHOULD_TRACK_ANALYTICS === 'true') {
  mixpanel.opt_in_tracking();
} else {
  mixpanel.opt_out_tracking();
}
