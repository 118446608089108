<template>
  <MainWithDetailLayout
    :is-loading="isLoading"
    :main-route-name="mainRootName"
    :hide-detail="isLoading"
  >
    <VerticalContainer v-if="hasTeamMembers">
      <TeamMembersTable>
        <ButtonsContainer
          slot="buttons"
          has-small-gap
        >
          <Button
            v-if="isOwnerOrAdmin"
            :is-small="true"
            text="Edit"
            :to="teamDetailRoute"
          />
          <Button
            :is-small="true"
            text="Switch Team"
            :to="teamsListRoute"
          />
        </ButtonsContainer>
      </TeamMembersTable>
      <template #buttons>
        <Button
          v-if="isOwnerOrAdmin"
          :to="addTeamMemberRoute"
          text="Add Member"
        />
      </template>
    </VerticalContainer>
  </MainWithDetailLayout>
</template>
<script>
import MainWithDetailLayout from '../../components/organisms/layout/main-with-detail-layout';
import { routeNames } from '../../constants';
import TeamMembersTable from '../../components/organisms/team-members/team-members-table';
import Button from '../../components/atoms/button/button';
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import ButtonsContainer from '../../components/molecules/layout/buttons-container';

export default {
  name: 'TeamMemberList',
  components: {
    MainWithDetailLayout,
    Button,
    TeamMembersTable,
    VerticalContainer,
    ButtonsContainer,
  },
  computed: {
    mainRootName() {
      return routeNames.team.list;
    },
    addTeamMemberRoute() {
      return { name: routeNames.team.addMember };
    },
    teamsListRoute() {
      return { name: routeNames.team.teamsListRoute };
    },
    teamDetailRoute() {
      return { name: routeNames.team.detail };
    },
    isLoading() {
      return this.$store.state.teamMembers.isLoadingTeamMembers;
    },
    hasTeamMembers() {
      return this.$store.getters['teamMembers/hasTeamMembers'];
    },
    isOwnerOrAdmin() {
      return this.$store.getters['user/isAdmin'] || this.$store.getters['user/isOwner'];
    },
  },
  created() {
    if (!this.hasTeamMembers) {
      this.$store.dispatch('teamMembers/getTeamMembers');
    }
  },
};
</script>
