<template>
  <VerticalContainer>
    <div class="filters-container">
      <div class="is-bold text-center section-header">
        Filters
      </div>
      <ItemsDropDownFilter
        ref="consignmentDropdown"
        title="Consignment"
        :value="$store.state.assetsFilters.consignments.selectedId"
        :items="$store.state.assetsFilters.consignments.items"
        :all-loaded="$store.state.assetsFilters.consignments.allLoaded"
        :get-data="getConsignments"
        :get-item="getConsignment"
        @change="(value) => onChange(filterKeys.consignments, value)"
      />
      <ItemsDropDownFilter
        ref="facilityDropdown"
        title="Facility"
        :value="$store.state.assetsFilters.facilities.selectedId"
        :items="$store.state.assetsFilters.facilities.items"
        :all-loaded="$store.state.assetsFilters.facilities.allLoaded"
        :get-data="getFacilities"
        :get-item="getFacility"
        @change="(value) => onChange(filterKeys.facilities, value)"
      />
      <ItemsDropDownFilter
        ref="zoneDropdown"
        title="Zone"
        :value="$store.state.assetsFilters.zones.selectedId"
        :items="$store.state.assetsFilters.zones.items"
        :all-loaded="$store.state.assetsFilters.zones.allLoaded"
        :get-data="getZones"
        :get-item="getZone"
        @change="(value) => onChange(filterKeys.zones, value)"
      />
    </div>
    <template #buttons>
      <Button
        text="Reset"
        :disabled="!isResetEnabled"
        :on-click="reset"
      />
      <Button
        text="Apply"
        :disabled="!isApplyEnabled"
        :on-click="apply"
      />
      <Button
        :text="$vuetify.breakpoint.mdAndUp ? 'Close' : 'Cancel'"
        :on-click="goBack"
      />
    </template>
  </VerticalContainer>
</template>
<script>
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import ItemsDropDownFilter from '../../components/organisms/assets-filters/items-dropdown-filter';
import Button from '../../components/atoms/button/button';
import {
  routeNames, assetFilterKeys, assetFilterSelectedKeys, filterKeyToQueryStringParam,
} from '../../constants';

const defaultSelectedId = '';

export default {
  name: 'AssetsFilters',
  components: {
    VerticalContainer,
    ItemsDropDownFilter,
    Button,
  },
  data() {
    return {
      timeoutId: null,
    };
  },
  computed: {
    filterKeys() { return assetFilterKeys; },
    filtersToApply() {
      const filtersToApply = [];
      Object.keys(this.$store.state.assets.filters)
        .forEach((key) => {
          const existingValue = this.$store.state.assets.filters[key];
          const newInSpaceValue = this.$store.state.assetsFilters.zones.selectedId;
          const newInFacilitiesValue = this.$store.state.assetsFilters.facilities.selectedId;
          const newInConsignmentValue = this.$store.state.assetsFilters.consignments.selectedId;
          if (key === assetFilterSelectedKeys.zones
           && existingValue !== newInSpaceValue) {
            filtersToApply.push({
              key,
              filterKey: assetFilterKeys.zones,
              value: newInSpaceValue,
            });
          } else if (key === assetFilterSelectedKeys.facilities
           && existingValue !== newInFacilitiesValue) {
            filtersToApply.push({
              key,
              filterKey: assetFilterKeys.facilities,
              value: newInFacilitiesValue,
            });
          } else if (key === assetFilterSelectedKeys.consignments
           && existingValue !== newInConsignmentValue) {
            filtersToApply.push({
              key,
              filterKey: assetFilterKeys.consignments,
              value: newInConsignmentValue,
            });
          }
        });
      return filtersToApply;
    },
    isApplyEnabled() {
      return this.filtersToApply.length > 0;
    },
    isResetEnabled() {
      return this.$store.getters['assets/anyFiltersApplied'];
    },
  },
  created() {
    // set filters to match what is already applied
    const filters = [];
    this.$store.getters['assets/filtersApplied']
      .forEach(({ key, value }) => {
        if (key === assetFilterSelectedKeys.zones) {
          filters.push({
            key: assetFilterKeys.zones,
            value,
          });
        } else if (key === assetFilterSelectedKeys.facilities) {
          filters.push({
            key: assetFilterKeys.facilities,
            value,
          });
        } else if (key === assetFilterSelectedKeys.consignments) {
          filters.push({
            key: assetFilterKeys.consignments,
            value,
          });
        }
      });
    this.$store.commit(
      'assetsFilters/SET_ALL_SELECTED_ITEMS',
      filters,
    );
  },
  methods: {
    getConsignments(requestParams) {
      return this.$store.dispatch('assetsFilters/getConsignments', requestParams);
    },
    getConsignment(id) {
      return this.$store.dispatch('assetsFilters/getConsignment', id);
    },

    getFacilities(requestParams) {
      return this.$store.dispatch('assetsFilters/getFacilities', requestParams);
    },
    getFacility(id) {
      return this.$store.dispatch('assetsFilters/getFacility', id);
    },

    getZones(requestParams) {
      return this.$store.dispatch('assetsFilters/getZones', requestParams);
    },
    getZone(id) {
      return this.$store.dispatch('assetsFilters/getZone', id);
    },

    goBack() {
      this.$router
        .push({
          name: routeNames.assets.list,
          params: { teamId: this.$route.params.teamId },
          query: this.$route.query,
        });
      const actualFilterApplied = (this.filtersToApply.find((f) => f.value !== '') || {}).key;
      switch (actualFilterApplied) {
        case assetFilterSelectedKeys.zones:
          this.$store.commit('assetsFilters/RESET_SELECTED_ITEM', 'zones');
          break;
        case assetFilterSelectedKeys.facilities:
          this.$store.commit('assetsFilters/RESET_SELECTED_ITEM', 'facilities');
          break;
        case assetFilterSelectedKeys.consignments:
          this.$store.commit('assetsFilters/RESET_SELECTED_ITEM', 'consignments');
          break;
        default:
          // do nothing
      }
    },
    async updateAppliedFilters() {
      await this.$store.dispatch('assets/refreshGetAssets');
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.goBack();
      }
    },
    async apply() {
      // applying filter query to URL.
      const activeFilters = this.filtersToApply.filter((f) => f.value !== defaultSelectedId);
      this.$router.push({
        query: activeFilters.reduce((accumulator, currentValue) => {
          // eslint-disable-next-line no-param-reassign
          accumulator[filterKeyToQueryStringParam[currentValue.filterKey]] = currentValue.value;
          return accumulator;
        }, {}),
      }).catch(() => {
        // duplicate navigation - do nothing
      });
      this.$store.commit('assets/SET_FILTERS', this.filtersToApply);
      await this.updateAppliedFilters();

      this.$root.trackEvent.appliedFilters(
        this.filtersToApply,
        this.$store.state.assets.assets.length,
      );
    },
    reset() {
      this.$root.trackEvent.clearedFilters(
        this.filtersToApply,
        this.$store.state.assets.assets.length,
      );

      this.$store.commit('assetsFilters/RESET_ALL_SELECTED_ITEMS');
      this.$store.commit('assets/RESET_FILTERS');
      this.updateAppliedFilters();
      this.$router.push({ name: routeNames.assets.filters }).catch(() => {
        // duplicate navigation - do nothing
      });
    },
    onChange(key, value) {
      if (value) {
        this.$store.commit(
          'assetsFilters/SET_SELECTED_ITEM',
          { key, id: value },
        );
        if (key !== assetFilterKeys.consignments) {
          this.$refs.consignmentDropdown.reset();
        }
        if (key !== assetFilterKeys.facilities) {
          this.$refs.facilityDropdown.reset();
        }
        if (key !== assetFilterKeys.zones) {
          this.$refs.zoneDropdown.reset();
        }
      } else {
        this.$store.commit('assetsFilters/RESET_SELECTED_ITEM', key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';
.filters-container {
  padding: 20px;
  box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
  height: 100%;
  margin-bottom: 20px;
  @include mq($from: md) {
    box-shadow: initial;
    padding: 0;
  }
}
.section-header {
  color: $dark-grey;
  font-size: 1.125rem;
  margin-bottom: 20px;
}
</style>
