/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUser = /* GraphQL */ `
  query GetCurrentUser($requestId: String) {
    getCurrentUser(requestId: $requestId) {
      user {
        id
        givenName
        familyName
        avatarUrl
        email
      }
      organisationRoles {
        organisationId
        role
      }
    }
  }
`;
export const getOrganisations = /* GraphQL */ `
  query GetOrganisations($requestId: String, $limit: Int, $nextToken: String) {
    getOrganisations(
      requestId: $requestId
      limit: $limit
      nextToken: $nextToken
    ) {
      organisations {
        nextToken
        items {
          id
          name
          url
          allowedDomains
          members {
            nextToken
            items {
              user {
                id
                givenName
                familyName
                avatarUrl
                email
              }
              role
              joinedDate
            }
          }
          assets {
            nextToken
            items {
              id
              name
              organisationId
              createdOn
              lastSeen
              assetType {
                id
                name
                organisationId
                fields {
                  key
                  name
                  value
                  values
                  dataType
                  options
                  isMultiSelect
                  isRequired
                }
                iconUrl
              }
              photo
              labelAddedOn
              nfcId
              bleId
              custody {
                nextToken
                items {
                  timestamp
                  users {
                    id
                    givenName
                    familyName
                    avatarUrl
                    email
                  }
                }
              }
              locations {
                nextToken
                items {
                  timestamp
                  longitude
                  latitude
                  error
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                  updatedAt
                }
              }
              positions {
                nextToken
                items {
                  timestamp
                  name
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  floor {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
              }
              temperatures {
                nextToken
                items {
                  timestamp
                  degreesCelcius
                  numberReadings
                }
              }
              isSpace
              numberAssetsInSpace
              inSpaces {
                assetId
                spaceId
                spaceName
                facilityId
                facilityTimeZone
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
              inFacilities {
                assetId
                facilityId
                facilityName
                facilityTimeZone
                enteredOn
                exitedOn
                hasExited
              }
              gateways {
                gatewayId
                shortId
                deviceId
                manufacturer
                productName
                addedOn
                removedOn
                status
                lastUpdated
                hasPendingCommands
                isStatic
                longitude
                latitude
                rssiFilter
              }
            }
            total
          }
          features
          buildings {
            floors {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
          printers {
            nextToken
            items {
              id
              name
              addedOn
              isOnline
              workspaceId
              facilityId
              printerSettings {
                labelSize
                printMethod
                template {
                  selectedId
                }
                consignment {
                  createConsignment
                  autoPopulatePieceNumber
                }
                barcode {
                  sliceStart
                  sliceEnd
                }
              }
            }
          }
          dashboards {
            nextToken
            items {
              id
              name
              url
            }
          }
          facilities {
            nextToken
            items {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
          }
          consignments {
            nextToken
            items {
              id
              trackingRef
              workspace {
                id
                name
                url
                allowedDomains
                members {
                  nextToken
                  items {
                    user {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                    role
                    joinedDate
                  }
                }
                assets {
                  nextToken
                  items {
                    id
                    name
                    organisationId
                    createdOn
                    lastSeen
                    assetType {
                      id
                      name
                      organisationId
                      fields {
                        key
                        name
                        value
                        values
                        dataType
                        options
                        isMultiSelect
                        isRequired
                      }
                      iconUrl
                    }
                    photo
                    labelAddedOn
                    nfcId
                    bleId
                    custody {
                      nextToken
                      items {
                        timestamp
                        users {
                          id
                          givenName
                          familyName
                          avatarUrl
                          email
                        }
                      }
                    }
                    locations {
                      nextToken
                      items {
                        timestamp
                        longitude
                        latitude
                        error
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                        updatedAt
                      }
                    }
                    positions {
                      nextToken
                      items {
                        timestamp
                        name
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        floor {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                    }
                    temperatures {
                      nextToken
                      items {
                        timestamp
                        degreesCelcius
                        numberReadings
                      }
                    }
                    isSpace
                    numberAssetsInSpace
                    inSpaces {
                      assetId
                      spaceId
                      spaceName
                      facilityId
                      facilityTimeZone
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    inFacilities {
                      assetId
                      facilityId
                      facilityName
                      facilityTimeZone
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    gateways {
                      gatewayId
                      shortId
                      deviceId
                      manufacturer
                      productName
                      addedOn
                      removedOn
                      status
                      lastUpdated
                      hasPendingCommands
                      isStatic
                      longitude
                      latitude
                      rssiFilter
                    }
                  }
                  total
                }
                features
                buildings {
                  floors {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
                printers {
                  nextToken
                  items {
                    id
                    name
                    addedOn
                    isOnline
                    workspaceId
                    facilityId
                    printerSettings {
                      labelSize
                      printMethod
                      template {
                        selectedId
                      }
                      consignment {
                        createConsignment
                        autoPopulatePieceNumber
                      }
                      barcode {
                        sliceStart
                        sliceEnd
                      }
                    }
                  }
                }
                dashboards {
                  nextToken
                  items {
                    id
                    name
                    url
                  }
                }
                facilities {
                  nextToken
                  items {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                }
                consignments {
                  nextToken
                  items {
                    id
                    trackingRef
                    workspace {
                      id
                      name
                      url
                      allowedDomains
                      members {
                        nextToken
                        items {
                          user {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                          role
                          joinedDate
                        }
                      }
                      assets {
                        nextToken
                        items {
                          id
                          name
                          organisationId
                          createdOn
                          lastSeen
                          assetType {
                            id
                            name
                            organisationId
                            iconUrl
                          }
                          photo
                          labelAddedOn
                          nfcId
                          bleId
                          custody {
                            nextToken
                          }
                          locations {
                            nextToken
                          }
                          positions {
                            nextToken
                          }
                          temperatures {
                            nextToken
                          }
                          isSpace
                          numberAssetsInSpace
                          inSpaces {
                            assetId
                            spaceId
                            spaceName
                            facilityId
                            facilityTimeZone
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          inFacilities {
                            assetId
                            facilityId
                            facilityName
                            facilityTimeZone
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          gateways {
                            gatewayId
                            shortId
                            deviceId
                            manufacturer
                            productName
                            addedOn
                            removedOn
                            status
                            lastUpdated
                            hasPendingCommands
                            isStatic
                            longitude
                            latitude
                            rssiFilter
                          }
                        }
                        total
                      }
                      features
                      buildings {
                        floors {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                      printers {
                        nextToken
                        items {
                          id
                          name
                          addedOn
                          isOnline
                          workspaceId
                          facilityId
                          printerSettings {
                            labelSize
                            printMethod
                          }
                        }
                      }
                      dashboards {
                        nextToken
                        items {
                          id
                          name
                          url
                        }
                      }
                      facilities {
                        nextToken
                        items {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                      }
                      consignments {
                        nextToken
                        items {
                          id
                          trackingRef
                          workspace {
                            id
                            name
                            url
                            allowedDomains
                            features
                          }
                          pieces {
                            nextToken
                            total
                          }
                          gateways {
                            nextToken
                          }
                          activated {
                            when
                          }
                          deactivated {
                            when
                          }
                          destination {
                            name
                          }
                        }
                      }
                    }
                    pieces {
                      nextToken
                      items {
                        id
                        trackingRef
                        consignmentId
                        workspaceId
                        label {
                          nfcId
                          bleId
                          addedOn
                        }
                        inFacilities {
                          facilityId
                          userFacilityId
                          facilityName
                          enteredOn
                          exitedOn
                          hasExited
                        }
                      }
                      total
                    }
                    gateways {
                      nextToken
                      items {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    activated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    deactivated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    destination {
                      name
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                  }
                }
              }
              pieces {
                nextToken
                items {
                  id
                  trackingRef
                  consignmentId
                  workspaceId
                  label {
                    nfcId
                    bleId
                    addedOn
                  }
                  inFacilities {
                    facilityId
                    userFacilityId
                    facilityName
                    enteredOn
                    exitedOn
                    hasExited
                  }
                }
                total
              }
              gateways {
                nextToken
                items {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              activated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              deactivated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              destination {
                name
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($requestId: String, $id: String!) {
    getOrganisation(requestId: $requestId, id: $id) {
      id
      name
      url
      allowedDomains
      members {
        nextToken
        items {
          user {
            id
            givenName
            familyName
            avatarUrl
            email
          }
          role
          joinedDate
        }
      }
      assets {
        nextToken
        items {
          id
          name
          organisationId
          createdOn
          lastSeen
          assetType {
            id
            name
            organisationId
            fields {
              key
              name
              value
              values
              dataType
              options
              isMultiSelect
              isRequired
            }
            iconUrl
          }
          photo
          labelAddedOn
          nfcId
          bleId
          custody {
            nextToken
            items {
              timestamp
              users {
                id
                givenName
                familyName
                avatarUrl
                email
              }
            }
          }
          locations {
            nextToken
            items {
              timestamp
              longitude
              latitude
              error
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
              updatedAt
            }
          }
          positions {
            nextToken
            items {
              timestamp
              name
              coordinate {
                latitude
                longitude
                accuracy
              }
              floor {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
          }
          temperatures {
            nextToken
            items {
              timestamp
              degreesCelcius
              numberReadings
            }
          }
          isSpace
          numberAssetsInSpace
          inSpaces {
            assetId
            spaceId
            spaceName
            facilityId
            facilityTimeZone
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
          inFacilities {
            assetId
            facilityId
            facilityName
            facilityTimeZone
            enteredOn
            exitedOn
            hasExited
          }
          gateways {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        total
      }
      features
      buildings {
        floors {
          url
          level
          name
          topLeft {
            latitude
            longitude
            accuracy
          }
          topRight {
            latitude
            longitude
            accuracy
          }
          bottomLeft {
            latitude
            longitude
            accuracy
          }
          rotationDegrees
        }
      }
      printers {
        nextToken
        items {
          id
          name
          addedOn
          isOnline
          workspaceId
          facilityId
          printerSettings {
            labelSize
            printMethod
            template {
              selectedId
            }
            consignment {
              createConsignment
              autoPopulatePieceNumber
            }
            barcode {
              sliceStart
              sliceEnd
            }
          }
        }
      }
      dashboards {
        nextToken
        items {
          id
          name
          url
        }
      }
      facilities {
        nextToken
        items {
          id
          userFacilityId
          name
          workspaceId
          createdAt
          status
          numberOfGateways
          currentAssetsCount
          location {
            latitude
            longitude
            accuracy
          }
          floorPlans {
            items {
              id
              name
              imageKey
              uploadUrl
              imageUrl
            }
          }
          timeZone
        }
      }
      consignments {
        nextToken
        items {
          id
          trackingRef
          workspace {
            id
            name
            url
            allowedDomains
            members {
              nextToken
              items {
                user {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
                role
                joinedDate
              }
            }
            assets {
              nextToken
              items {
                id
                name
                organisationId
                createdOn
                lastSeen
                assetType {
                  id
                  name
                  organisationId
                  fields {
                    key
                    name
                    value
                    values
                    dataType
                    options
                    isMultiSelect
                    isRequired
                  }
                  iconUrl
                }
                photo
                labelAddedOn
                nfcId
                bleId
                custody {
                  nextToken
                  items {
                    timestamp
                    users {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                  }
                }
                locations {
                  nextToken
                  items {
                    timestamp
                    longitude
                    latitude
                    error
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                    updatedAt
                  }
                }
                positions {
                  nextToken
                  items {
                    timestamp
                    name
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    floor {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                }
                temperatures {
                  nextToken
                  items {
                    timestamp
                    degreesCelcius
                    numberReadings
                  }
                }
                isSpace
                numberAssetsInSpace
                inSpaces {
                  assetId
                  spaceId
                  spaceName
                  facilityId
                  facilityTimeZone
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
                inFacilities {
                  assetId
                  facilityId
                  facilityName
                  facilityTimeZone
                  enteredOn
                  exitedOn
                  hasExited
                }
                gateways {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              total
            }
            features
            buildings {
              floors {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
            printers {
              nextToken
              items {
                id
                name
                addedOn
                isOnline
                workspaceId
                facilityId
                printerSettings {
                  labelSize
                  printMethod
                  template {
                    selectedId
                  }
                  consignment {
                    createConsignment
                    autoPopulatePieceNumber
                  }
                  barcode {
                    sliceStart
                    sliceEnd
                  }
                }
              }
            }
            dashboards {
              nextToken
              items {
                id
                name
                url
              }
            }
            facilities {
              nextToken
              items {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
            }
            consignments {
              nextToken
              items {
                id
                trackingRef
                workspace {
                  id
                  name
                  url
                  allowedDomains
                  members {
                    nextToken
                    items {
                      user {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                      role
                      joinedDate
                    }
                  }
                  assets {
                    nextToken
                    items {
                      id
                      name
                      organisationId
                      createdOn
                      lastSeen
                      assetType {
                        id
                        name
                        organisationId
                        fields {
                          key
                          name
                          value
                          values
                          dataType
                          options
                          isMultiSelect
                          isRequired
                        }
                        iconUrl
                      }
                      photo
                      labelAddedOn
                      nfcId
                      bleId
                      custody {
                        nextToken
                        items {
                          timestamp
                          users {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                        }
                      }
                      locations {
                        nextToken
                        items {
                          timestamp
                          longitude
                          latitude
                          error
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                          updatedAt
                        }
                      }
                      positions {
                        nextToken
                        items {
                          timestamp
                          name
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          floor {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                      }
                      temperatures {
                        nextToken
                        items {
                          timestamp
                          degreesCelcius
                          numberReadings
                        }
                      }
                      isSpace
                      numberAssetsInSpace
                      inSpaces {
                        assetId
                        spaceId
                        spaceName
                        facilityId
                        facilityTimeZone
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      inFacilities {
                        assetId
                        facilityId
                        facilityName
                        facilityTimeZone
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      gateways {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    total
                  }
                  features
                  buildings {
                    floors {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                  printers {
                    nextToken
                    items {
                      id
                      name
                      addedOn
                      isOnline
                      workspaceId
                      facilityId
                      printerSettings {
                        labelSize
                        printMethod
                        template {
                          selectedId
                        }
                        consignment {
                          createConsignment
                          autoPopulatePieceNumber
                        }
                        barcode {
                          sliceStart
                          sliceEnd
                        }
                      }
                    }
                  }
                  dashboards {
                    nextToken
                    items {
                      id
                      name
                      url
                    }
                  }
                  facilities {
                    nextToken
                    items {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                  }
                  consignments {
                    nextToken
                    items {
                      id
                      trackingRef
                      workspace {
                        id
                        name
                        url
                        allowedDomains
                        members {
                          nextToken
                          items {
                            role
                            joinedDate
                          }
                        }
                        assets {
                          nextToken
                          items {
                            id
                            name
                            organisationId
                            createdOn
                            lastSeen
                            photo
                            labelAddedOn
                            nfcId
                            bleId
                            isSpace
                            numberAssetsInSpace
                          }
                          total
                        }
                        features
                        buildings {
                          floors {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                        printers {
                          nextToken
                          items {
                            id
                            name
                            addedOn
                            isOnline
                            workspaceId
                            facilityId
                          }
                        }
                        dashboards {
                          nextToken
                          items {
                            id
                            name
                            url
                          }
                        }
                        facilities {
                          nextToken
                          items {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                        }
                        consignments {
                          nextToken
                          items {
                            id
                            trackingRef
                          }
                        }
                      }
                      pieces {
                        nextToken
                        items {
                          id
                          trackingRef
                          consignmentId
                          workspaceId
                          label {
                            nfcId
                            bleId
                            addedOn
                          }
                          inFacilities {
                            facilityId
                            userFacilityId
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                        }
                        total
                      }
                      gateways {
                        nextToken
                        items {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      activated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      deactivated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      destination {
                        name
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                    }
                  }
                }
                pieces {
                  nextToken
                  items {
                    id
                    trackingRef
                    consignmentId
                    workspaceId
                    label {
                      nfcId
                      bleId
                      addedOn
                    }
                    inFacilities {
                      facilityId
                      userFacilityId
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                  }
                  total
                }
                gateways {
                  nextToken
                  items {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                activated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                deactivated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                destination {
                  name
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
              }
            }
          }
          pieces {
            nextToken
            items {
              id
              trackingRef
              consignmentId
              workspaceId
              label {
                nfcId
                bleId
                addedOn
              }
              inFacilities {
                facilityId
                userFacilityId
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
            }
            total
          }
          gateways {
            nextToken
            items {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          activated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          deactivated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          destination {
            name
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
        }
      }
    }
  }
`;
export const getOrganisationMembers = /* GraphQL */ `
  query GetOrganisationMembers(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getOrganisationMembers(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        user {
          id
          givenName
          familyName
          avatarUrl
          email
        }
        role
        joinedDate
      }
    }
  }
`;
export const getOrganisationMember = /* GraphQL */ `
  query GetOrganisationMember(
    $requestId: String
    $id: String!
    $userId: String!
  ) {
    getOrganisationMember(requestId: $requestId, id: $id, userId: $userId) {
      user {
        id
        givenName
        familyName
        avatarUrl
        email
      }
      role
      joinedDate
    }
  }
`;
export const getAssetLocations = /* GraphQL */ `
  query GetAssetLocations(
    $requestId: String
    $nfcId: String!
    $limit: Int
    $nextToken: String
  ) {
    getAssetLocations(
      requestId: $requestId
      nfcId: $nfcId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        timestamp
        longitude
        latitude
        error
        address {
          road
          city
          district
          county
          state
          country
          postalCode
        }
        updatedAt
      }
    }
  }
`;
export const getAssetCustody = /* GraphQL */ `
  query GetAssetCustody(
    $requestId: String
    $nfcId: String!
    $limit: Int
    $nextToken: String
  ) {
    getAssetCustody(
      requestId: $requestId
      nfcId: $nfcId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        timestamp
        users {
          id
          givenName
          familyName
          avatarUrl
          email
        }
      }
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset(
    $requestId: String
    $id: String
    $nfcId: String
    $gatewayId: String
  ) {
    getAsset(
      requestId: $requestId
      id: $id
      nfcId: $nfcId
      gatewayId: $gatewayId
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const getAssetSpaceHistory = /* GraphQL */ `
  query GetAssetSpaceHistory(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getAssetSpaceHistory(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
    }
  }
`;
export const getAssetFacilityHistory = /* GraphQL */ `
  query GetAssetFacilityHistory(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getAssetFacilityHistory(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
    }
  }
`;
export const getAssetTemperatures = /* GraphQL */ `
  query GetAssetTemperatures(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getAssetTemperatures(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        timestamp
        degreesCelcius
        numberReadings
      }
    }
  }
`;
export const searchOrganisationAssets = /* GraphQL */ `
  query SearchOrganisationAssets(
    $requestId: String
    $id: String!
    $searchTerm: String!
    $filter: AssetFilterInput
    $filters: String
    $limit: Int
    $nextToken: String
    $includeExitedSpaces: Boolean
    $includeInSpacesHistory: Boolean
    $sortOrder: [AssetSort]
  ) {
    searchOrganisationAssets(
      requestId: $requestId
      id: $id
      searchTerm: $searchTerm
      filter: $filter
      filters: $filters
      limit: $limit
      nextToken: $nextToken
      includeExitedSpaces: $includeExitedSpaces
      includeInSpacesHistory: $includeInSpacesHistory
      sortOrder: $sortOrder
    ) {
      nextToken
      items {
        id
        name
        organisationId
        createdOn
        lastSeen
        assetType {
          id
          name
          organisationId
          fields {
            key
            name
            value
            values
            dataType
            options
            isMultiSelect
            isRequired
          }
          iconUrl
        }
        photo
        labelAddedOn
        nfcId
        bleId
        custody {
          nextToken
          items {
            timestamp
            users {
              id
              givenName
              familyName
              avatarUrl
              email
            }
          }
        }
        locations {
          nextToken
          items {
            timestamp
            longitude
            latitude
            error
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
            updatedAt
          }
        }
        positions {
          nextToken
          items {
            timestamp
            name
            coordinate {
              latitude
              longitude
              accuracy
            }
            floor {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
        }
        temperatures {
          nextToken
          items {
            timestamp
            degreesCelcius
            numberReadings
          }
        }
        isSpace
        numberAssetsInSpace
        inSpaces {
          assetId
          spaceId
          spaceName
          facilityId
          facilityTimeZone
          facilityName
          enteredOn
          exitedOn
          hasExited
        }
        inFacilities {
          assetId
          facilityId
          facilityName
          facilityTimeZone
          enteredOn
          exitedOn
          hasExited
        }
        gateways {
          gatewayId
          shortId
          deviceId
          manufacturer
          productName
          addedOn
          removedOn
          status
          lastUpdated
          hasPendingCommands
          isStatic
          longitude
          latitude
          rssiFilter
        }
      }
      total
    }
  }
`;
export const getOrganisationAssetTypes = /* GraphQL */ `
  query GetOrganisationAssetTypes($requestId: String, $id: String!) {
    getOrganisationAssetTypes(requestId: $requestId, id: $id) {
      id
      name
      organisationId
      fields {
        key
        name
        value
        values
        dataType
        options
        isMultiSelect
        isRequired
      }
      iconUrl
    }
  }
`;
export const getSpaceStatuses = /* GraphQL */ `
  query GetSpaceStatuses(
    $requestId: String
    $spaceId: String!
    $limit: Int
    $nextToken: String
  ) {
    getSpaceStatuses(
      requestId: $requestId
      spaceId: $spaceId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        spaceId
        gatewayId
        status
        from
        to
      }
    }
  }
`;
export const getOrganisationPrinters = /* GraphQL */ `
  query GetOrganisationPrinters(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getOrganisationPrinters(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        name
        addedOn
        isOnline
        workspaceId
        facilityId
        printerSettings {
          labelSize
          printMethod
          template {
            selectedId
          }
          consignment {
            createConsignment
            autoPopulatePieceNumber
          }
          barcode {
            sliceStart
            sliceEnd
          }
        }
      }
    }
  }
`;
export const getOrganisationPrinter = /* GraphQL */ `
  query GetOrganisationPrinter(
    $requestId: String
    $id: String!
    $printerId: String!
  ) {
    getOrganisationPrinter(
      requestId: $requestId
      id: $id
      printerId: $printerId
    ) {
      id
      name
      addedOn
      isOnline
      workspaceId
      facilityId
      printerSettings {
        labelSize
        printMethod
        template {
          selectedId
        }
        consignment {
          createConsignment
          autoPopulatePieceNumber
        }
        barcode {
          sliceStart
          sliceEnd
        }
      }
    }
  }
`;
export const getOrganisationPrintTemplates = /* GraphQL */ `
  query GetOrganisationPrintTemplates(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getOrganisationPrintTemplates(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        templateId
        requiredParams
        name
        size
        templateVersion
        templateSbpl
      }
      nextToken
    }
  }
`;
export const getPrinterPrintJobs = /* GraphQL */ `
  query GetPrinterPrintJobs(
    $requestId: String
    $printerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getPrinterPrintJobs(
      requestId: $requestId
      printerId: $printerId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        printerId
        workspaceId
        assetId
        createdAt
        updatedAt
        status
        origin
        replaceExistingLabel
        templateId
        includeCutLine
        isComplete
        isSuccessful
        failureReason
      }
    }
  }
`;
export const getPrintJob = /* GraphQL */ `
  query GetPrintJob($requestId: String, $printJobId: String!) {
    getPrintJob(requestId: $requestId, printJobId: $printJobId) {
      id
      printerId
      workspaceId
      assetId
      createdAt
      updatedAt
      status
      origin
      replaceExistingLabel
      templateId
      includeCutLine
      isComplete
      isSuccessful
      failureReason
    }
  }
`;
export const getPrintJobStatuses = /* GraphQL */ `
  query GetPrintJobStatuses(
    $requestId: String
    $printJobId: String!
    $limit: Int
    $nextToken: String
  ) {
    getPrintJobStatuses(
      requestId: $requestId
      printJobId: $printJobId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        status
        createdAt
      }
    }
  }
`;
export const getOrganisationDashboards = /* GraphQL */ `
  query GetOrganisationDashboards(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getOrganisationDashboards(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        name
        url
      }
    }
  }
`;
export const getOrganisationDashboard = /* GraphQL */ `
  query GetOrganisationDashboard(
    $requestId: String
    $id: String!
    $dashboardId: String!
  ) {
    getOrganisationDashboard(
      requestId: $requestId
      id: $id
      dashboardId: $dashboardId
    ) {
      id
      name
      url
    }
  }
`;
export const getOrganisationFacilities = /* GraphQL */ `
  query GetOrganisationFacilities(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
    $searchTerm: String
  ) {
    getOrganisationFacilities(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
      searchTerm: $searchTerm
    ) {
      nextToken
      items {
        id
        userFacilityId
        name
        workspaceId
        createdAt
        status
        numberOfGateways
        currentAssetsCount
        location {
          latitude
          longitude
          accuracy
        }
        floorPlans {
          items {
            id
            name
            imageKey
            uploadUrl
            imageUrl
          }
        }
        timeZone
      }
    }
  }
`;
export const getFacility = /* GraphQL */ `
  query GetFacility($requestId: String, $id: String!) {
    getFacility(requestId: $requestId, id: $id) {
      id
      userFacilityId
      name
      workspaceId
      createdAt
      status
      numberOfGateways
      currentAssetsCount
      location {
        latitude
        longitude
        accuracy
      }
      floorPlans {
        items {
          id
          name
          imageKey
          uploadUrl
          imageUrl
        }
      }
      timeZone
    }
  }
`;
export const getFacilityAssetGroups = /* GraphQL */ `
  query GetFacilityAssetGroups(
    $requestId: String
    $facilityId: String!
    $limit: Int
    $nextToken: String
  ) {
    getFacilityAssetGroups(
      requestId: $requestId
      facilityId: $facilityId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        name
        workspaceId
        facilityId
        available
        availabilityThreshold
        count
      }
    }
  }
`;
export const getFacilitySpaces = /* GraphQL */ `
  query GetFacilitySpaces(
    $requestId: String
    $facilityId: String!
    $limit: Int
    $nextToken: String
  ) {
    getFacilitySpaces(
      requestId: $requestId
      facilityId: $facilityId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        name
        organisationId
        createdOn
        lastSeen
        assetType {
          id
          name
          organisationId
          fields {
            key
            name
            value
            values
            dataType
            options
            isMultiSelect
            isRequired
          }
          iconUrl
        }
        photo
        labelAddedOn
        nfcId
        bleId
        custody {
          nextToken
          items {
            timestamp
            users {
              id
              givenName
              familyName
              avatarUrl
              email
            }
          }
        }
        locations {
          nextToken
          items {
            timestamp
            longitude
            latitude
            error
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
            updatedAt
          }
        }
        positions {
          nextToken
          items {
            timestamp
            name
            coordinate {
              latitude
              longitude
              accuracy
            }
            floor {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
        }
        temperatures {
          nextToken
          items {
            timestamp
            degreesCelcius
            numberReadings
          }
        }
        isSpace
        numberAssetsInSpace
        inSpaces {
          assetId
          spaceId
          spaceName
          facilityId
          facilityTimeZone
          facilityName
          enteredOn
          exitedOn
          hasExited
        }
        inFacilities {
          assetId
          facilityId
          facilityName
          facilityTimeZone
          enteredOn
          exitedOn
          hasExited
        }
        gateways {
          gatewayId
          shortId
          deviceId
          manufacturer
          productName
          addedOn
          removedOn
          status
          lastUpdated
          hasPendingCommands
          isStatic
          longitude
          latitude
          rssiFilter
        }
      }
    }
  }
`;
export const getFacilityFloorPlan = /* GraphQL */ `
  query GetFacilityFloorPlan(
    $requestId: String
    $facilityId: String!
    $floorPlanId: String!
  ) {
    getFacilityFloorPlan(
      requestId: $requestId
      facilityId: $facilityId
      floorPlanId: $floorPlanId
    ) {
      id
      name
      imageKey
      uploadUrl
      imageUrl
    }
  }
`;
export const getFacilityPrinters = /* GraphQL */ `
  query GetFacilityPrinters(
    $requestId: String
    $facilityId: String!
    $limit: Int
    $nextToken: String
  ) {
    getFacilityPrinters(
      requestId: $requestId
      facilityId: $facilityId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        name
        addedOn
        isOnline
        workspaceId
        facilityId
        printerSettings {
          labelSize
          printMethod
          template {
            selectedId
          }
          consignment {
            createConsignment
            autoPopulatePieceNumber
          }
          barcode {
            sliceStart
            sliceEnd
          }
        }
      }
    }
  }
`;
export const getConsignment = /* GraphQL */ `
  query GetConsignment(
    $requestId: String
    $id: String
    $workspaceId: String
    $trackingRef: String
  ) {
    getConsignment(
      requestId: $requestId
      id: $id
      workspaceId: $workspaceId
      trackingRef: $trackingRef
    ) {
      id
      trackingRef
      workspace {
        id
        name
        url
        allowedDomains
        members {
          nextToken
          items {
            user {
              id
              givenName
              familyName
              avatarUrl
              email
            }
            role
            joinedDate
          }
        }
        assets {
          nextToken
          items {
            id
            name
            organisationId
            createdOn
            lastSeen
            assetType {
              id
              name
              organisationId
              fields {
                key
                name
                value
                values
                dataType
                options
                isMultiSelect
                isRequired
              }
              iconUrl
            }
            photo
            labelAddedOn
            nfcId
            bleId
            custody {
              nextToken
              items {
                timestamp
                users {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
              }
            }
            locations {
              nextToken
              items {
                timestamp
                longitude
                latitude
                error
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
                updatedAt
              }
            }
            positions {
              nextToken
              items {
                timestamp
                name
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                floor {
                  url
                  level
                  name
                  topLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  topRight {
                    latitude
                    longitude
                    accuracy
                  }
                  bottomLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  rotationDegrees
                }
              }
            }
            temperatures {
              nextToken
              items {
                timestamp
                degreesCelcius
                numberReadings
              }
            }
            isSpace
            numberAssetsInSpace
            inSpaces {
              assetId
              spaceId
              spaceName
              facilityId
              facilityTimeZone
              facilityName
              enteredOn
              exitedOn
              hasExited
            }
            inFacilities {
              assetId
              facilityId
              facilityName
              facilityTimeZone
              enteredOn
              exitedOn
              hasExited
            }
            gateways {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          total
        }
        features
        buildings {
          floors {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
        printers {
          nextToken
          items {
            id
            name
            addedOn
            isOnline
            workspaceId
            facilityId
            printerSettings {
              labelSize
              printMethod
              template {
                selectedId
              }
              consignment {
                createConsignment
                autoPopulatePieceNumber
              }
              barcode {
                sliceStart
                sliceEnd
              }
            }
          }
        }
        dashboards {
          nextToken
          items {
            id
            name
            url
          }
        }
        facilities {
          nextToken
          items {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
        }
        consignments {
          nextToken
          items {
            id
            trackingRef
            workspace {
              id
              name
              url
              allowedDomains
              members {
                nextToken
                items {
                  user {
                    id
                    givenName
                    familyName
                    avatarUrl
                    email
                  }
                  role
                  joinedDate
                }
              }
              assets {
                nextToken
                items {
                  id
                  name
                  organisationId
                  createdOn
                  lastSeen
                  assetType {
                    id
                    name
                    organisationId
                    fields {
                      key
                      name
                      value
                      values
                      dataType
                      options
                      isMultiSelect
                      isRequired
                    }
                    iconUrl
                  }
                  photo
                  labelAddedOn
                  nfcId
                  bleId
                  custody {
                    nextToken
                    items {
                      timestamp
                      users {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                    }
                  }
                  locations {
                    nextToken
                    items {
                      timestamp
                      longitude
                      latitude
                      error
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                      updatedAt
                    }
                  }
                  positions {
                    nextToken
                    items {
                      timestamp
                      name
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      floor {
                        url
                        level
                        name
                        topLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        topRight {
                          latitude
                          longitude
                          accuracy
                        }
                        bottomLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        rotationDegrees
                      }
                    }
                  }
                  temperatures {
                    nextToken
                    items {
                      timestamp
                      degreesCelcius
                      numberReadings
                    }
                  }
                  isSpace
                  numberAssetsInSpace
                  inSpaces {
                    assetId
                    spaceId
                    spaceName
                    facilityId
                    facilityTimeZone
                    facilityName
                    enteredOn
                    exitedOn
                    hasExited
                  }
                  inFacilities {
                    assetId
                    facilityId
                    facilityName
                    facilityTimeZone
                    enteredOn
                    exitedOn
                    hasExited
                  }
                  gateways {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                total
              }
              features
              buildings {
                floors {
                  url
                  level
                  name
                  topLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  topRight {
                    latitude
                    longitude
                    accuracy
                  }
                  bottomLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  rotationDegrees
                }
              }
              printers {
                nextToken
                items {
                  id
                  name
                  addedOn
                  isOnline
                  workspaceId
                  facilityId
                  printerSettings {
                    labelSize
                    printMethod
                    template {
                      selectedId
                    }
                    consignment {
                      createConsignment
                      autoPopulatePieceNumber
                    }
                    barcode {
                      sliceStart
                      sliceEnd
                    }
                  }
                }
              }
              dashboards {
                nextToken
                items {
                  id
                  name
                  url
                }
              }
              facilities {
                nextToken
                items {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
              }
              consignments {
                nextToken
                items {
                  id
                  trackingRef
                  workspace {
                    id
                    name
                    url
                    allowedDomains
                    members {
                      nextToken
                      items {
                        user {
                          id
                          givenName
                          familyName
                          avatarUrl
                          email
                        }
                        role
                        joinedDate
                      }
                    }
                    assets {
                      nextToken
                      items {
                        id
                        name
                        organisationId
                        createdOn
                        lastSeen
                        assetType {
                          id
                          name
                          organisationId
                          fields {
                            key
                            name
                            value
                            values
                            dataType
                            options
                            isMultiSelect
                            isRequired
                          }
                          iconUrl
                        }
                        photo
                        labelAddedOn
                        nfcId
                        bleId
                        custody {
                          nextToken
                          items {
                            timestamp
                          }
                        }
                        locations {
                          nextToken
                          items {
                            timestamp
                            longitude
                            latitude
                            error
                            updatedAt
                          }
                        }
                        positions {
                          nextToken
                          items {
                            timestamp
                            name
                          }
                        }
                        temperatures {
                          nextToken
                          items {
                            timestamp
                            degreesCelcius
                            numberReadings
                          }
                        }
                        isSpace
                        numberAssetsInSpace
                        inSpaces {
                          assetId
                          spaceId
                          spaceName
                          facilityId
                          facilityTimeZone
                          facilityName
                          enteredOn
                          exitedOn
                          hasExited
                        }
                        inFacilities {
                          assetId
                          facilityId
                          facilityName
                          facilityTimeZone
                          enteredOn
                          exitedOn
                          hasExited
                        }
                        gateways {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      total
                    }
                    features
                    buildings {
                      floors {
                        url
                        level
                        name
                        topLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        topRight {
                          latitude
                          longitude
                          accuracy
                        }
                        bottomLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        rotationDegrees
                      }
                    }
                    printers {
                      nextToken
                      items {
                        id
                        name
                        addedOn
                        isOnline
                        workspaceId
                        facilityId
                        printerSettings {
                          labelSize
                          printMethod
                          template {
                            selectedId
                          }
                          consignment {
                            createConsignment
                            autoPopulatePieceNumber
                          }
                          barcode {
                            sliceStart
                            sliceEnd
                          }
                        }
                      }
                    }
                    dashboards {
                      nextToken
                      items {
                        id
                        name
                        url
                      }
                    }
                    facilities {
                      nextToken
                      items {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                    }
                    consignments {
                      nextToken
                      items {
                        id
                        trackingRef
                        workspace {
                          id
                          name
                          url
                          allowedDomains
                          members {
                            nextToken
                          }
                          assets {
                            nextToken
                            total
                          }
                          features
                          printers {
                            nextToken
                          }
                          dashboards {
                            nextToken
                          }
                          facilities {
                            nextToken
                          }
                          consignments {
                            nextToken
                          }
                        }
                        pieces {
                          nextToken
                          items {
                            id
                            trackingRef
                            consignmentId
                            workspaceId
                          }
                          total
                        }
                        gateways {
                          nextToken
                          items {
                            gatewayId
                            shortId
                            deviceId
                            manufacturer
                            productName
                            addedOn
                            removedOn
                            status
                            lastUpdated
                            hasPendingCommands
                            isStatic
                            longitude
                            latitude
                            rssiFilter
                          }
                        }
                        activated {
                          when
                        }
                        deactivated {
                          when
                        }
                        destination {
                          name
                        }
                      }
                    }
                  }
                  pieces {
                    nextToken
                    items {
                      id
                      trackingRef
                      consignmentId
                      workspaceId
                      label {
                        nfcId
                        bleId
                        addedOn
                      }
                      inFacilities {
                        facilityId
                        userFacilityId
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                    }
                    total
                  }
                  gateways {
                    nextToken
                    items {
                      gatewayId
                      shortId
                      deviceId
                      manufacturer
                      productName
                      addedOn
                      removedOn
                      status
                      lastUpdated
                      hasPendingCommands
                      isStatic
                      longitude
                      latitude
                      rssiFilter
                    }
                  }
                  activated {
                    when
                    where {
                      facility {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                    }
                  }
                  deactivated {
                    when
                    where {
                      facility {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                    }
                  }
                  destination {
                    name
                    where {
                      facility {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                    }
                  }
                }
              }
            }
            pieces {
              nextToken
              items {
                id
                trackingRef
                consignmentId
                workspaceId
                label {
                  nfcId
                  bleId
                  addedOn
                }
                inFacilities {
                  facilityId
                  userFacilityId
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
              }
              total
            }
            gateways {
              nextToken
              items {
                gatewayId
                shortId
                deviceId
                manufacturer
                productName
                addedOn
                removedOn
                status
                lastUpdated
                hasPendingCommands
                isStatic
                longitude
                latitude
                rssiFilter
              }
            }
            activated {
              when
              where {
                facility {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
              }
            }
            deactivated {
              when
              where {
                facility {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
              }
            }
            destination {
              name
              where {
                facility {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
              }
            }
          }
        }
      }
      pieces {
        nextToken
        items {
          id
          trackingRef
          consignmentId
          workspaceId
          label {
            nfcId
            bleId
            addedOn
          }
          inFacilities {
            facilityId
            userFacilityId
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
        }
        total
      }
      gateways {
        nextToken
        items {
          gatewayId
          shortId
          deviceId
          manufacturer
          productName
          addedOn
          removedOn
          status
          lastUpdated
          hasPendingCommands
          isStatic
          longitude
          latitude
          rssiFilter
        }
      }
      activated {
        when
        where {
          facility {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
          coordinate {
            latitude
            longitude
            accuracy
          }
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
        }
      }
      deactivated {
        when
        where {
          facility {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
          coordinate {
            latitude
            longitude
            accuracy
          }
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
        }
      }
      destination {
        name
        where {
          facility {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
          coordinate {
            latitude
            longitude
            accuracy
          }
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
        }
      }
    }
  }
`;
export const getOrganisationConsignments = /* GraphQL */ `
  query GetOrganisationConsignments(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
    $searchTerm: String
  ) {
    getOrganisationConsignments(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
      searchTerm: $searchTerm
    ) {
      nextToken
      items {
        id
        trackingRef
        workspace {
          id
          name
          url
          allowedDomains
          members {
            nextToken
            items {
              user {
                id
                givenName
                familyName
                avatarUrl
                email
              }
              role
              joinedDate
            }
          }
          assets {
            nextToken
            items {
              id
              name
              organisationId
              createdOn
              lastSeen
              assetType {
                id
                name
                organisationId
                fields {
                  key
                  name
                  value
                  values
                  dataType
                  options
                  isMultiSelect
                  isRequired
                }
                iconUrl
              }
              photo
              labelAddedOn
              nfcId
              bleId
              custody {
                nextToken
                items {
                  timestamp
                  users {
                    id
                    givenName
                    familyName
                    avatarUrl
                    email
                  }
                }
              }
              locations {
                nextToken
                items {
                  timestamp
                  longitude
                  latitude
                  error
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                  updatedAt
                }
              }
              positions {
                nextToken
                items {
                  timestamp
                  name
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  floor {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
              }
              temperatures {
                nextToken
                items {
                  timestamp
                  degreesCelcius
                  numberReadings
                }
              }
              isSpace
              numberAssetsInSpace
              inSpaces {
                assetId
                spaceId
                spaceName
                facilityId
                facilityTimeZone
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
              inFacilities {
                assetId
                facilityId
                facilityName
                facilityTimeZone
                enteredOn
                exitedOn
                hasExited
              }
              gateways {
                gatewayId
                shortId
                deviceId
                manufacturer
                productName
                addedOn
                removedOn
                status
                lastUpdated
                hasPendingCommands
                isStatic
                longitude
                latitude
                rssiFilter
              }
            }
            total
          }
          features
          buildings {
            floors {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
          printers {
            nextToken
            items {
              id
              name
              addedOn
              isOnline
              workspaceId
              facilityId
              printerSettings {
                labelSize
                printMethod
                template {
                  selectedId
                }
                consignment {
                  createConsignment
                  autoPopulatePieceNumber
                }
                barcode {
                  sliceStart
                  sliceEnd
                }
              }
            }
          }
          dashboards {
            nextToken
            items {
              id
              name
              url
            }
          }
          facilities {
            nextToken
            items {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
          }
          consignments {
            nextToken
            items {
              id
              trackingRef
              workspace {
                id
                name
                url
                allowedDomains
                members {
                  nextToken
                  items {
                    user {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                    role
                    joinedDate
                  }
                }
                assets {
                  nextToken
                  items {
                    id
                    name
                    organisationId
                    createdOn
                    lastSeen
                    assetType {
                      id
                      name
                      organisationId
                      fields {
                        key
                        name
                        value
                        values
                        dataType
                        options
                        isMultiSelect
                        isRequired
                      }
                      iconUrl
                    }
                    photo
                    labelAddedOn
                    nfcId
                    bleId
                    custody {
                      nextToken
                      items {
                        timestamp
                        users {
                          id
                          givenName
                          familyName
                          avatarUrl
                          email
                        }
                      }
                    }
                    locations {
                      nextToken
                      items {
                        timestamp
                        longitude
                        latitude
                        error
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                        updatedAt
                      }
                    }
                    positions {
                      nextToken
                      items {
                        timestamp
                        name
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        floor {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                    }
                    temperatures {
                      nextToken
                      items {
                        timestamp
                        degreesCelcius
                        numberReadings
                      }
                    }
                    isSpace
                    numberAssetsInSpace
                    inSpaces {
                      assetId
                      spaceId
                      spaceName
                      facilityId
                      facilityTimeZone
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    inFacilities {
                      assetId
                      facilityId
                      facilityName
                      facilityTimeZone
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    gateways {
                      gatewayId
                      shortId
                      deviceId
                      manufacturer
                      productName
                      addedOn
                      removedOn
                      status
                      lastUpdated
                      hasPendingCommands
                      isStatic
                      longitude
                      latitude
                      rssiFilter
                    }
                  }
                  total
                }
                features
                buildings {
                  floors {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
                printers {
                  nextToken
                  items {
                    id
                    name
                    addedOn
                    isOnline
                    workspaceId
                    facilityId
                    printerSettings {
                      labelSize
                      printMethod
                      template {
                        selectedId
                      }
                      consignment {
                        createConsignment
                        autoPopulatePieceNumber
                      }
                      barcode {
                        sliceStart
                        sliceEnd
                      }
                    }
                  }
                }
                dashboards {
                  nextToken
                  items {
                    id
                    name
                    url
                  }
                }
                facilities {
                  nextToken
                  items {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                }
                consignments {
                  nextToken
                  items {
                    id
                    trackingRef
                    workspace {
                      id
                      name
                      url
                      allowedDomains
                      members {
                        nextToken
                        items {
                          user {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                          role
                          joinedDate
                        }
                      }
                      assets {
                        nextToken
                        items {
                          id
                          name
                          organisationId
                          createdOn
                          lastSeen
                          assetType {
                            id
                            name
                            organisationId
                            iconUrl
                          }
                          photo
                          labelAddedOn
                          nfcId
                          bleId
                          custody {
                            nextToken
                          }
                          locations {
                            nextToken
                          }
                          positions {
                            nextToken
                          }
                          temperatures {
                            nextToken
                          }
                          isSpace
                          numberAssetsInSpace
                          inSpaces {
                            assetId
                            spaceId
                            spaceName
                            facilityId
                            facilityTimeZone
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          inFacilities {
                            assetId
                            facilityId
                            facilityName
                            facilityTimeZone
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          gateways {
                            gatewayId
                            shortId
                            deviceId
                            manufacturer
                            productName
                            addedOn
                            removedOn
                            status
                            lastUpdated
                            hasPendingCommands
                            isStatic
                            longitude
                            latitude
                            rssiFilter
                          }
                        }
                        total
                      }
                      features
                      buildings {
                        floors {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                      printers {
                        nextToken
                        items {
                          id
                          name
                          addedOn
                          isOnline
                          workspaceId
                          facilityId
                          printerSettings {
                            labelSize
                            printMethod
                          }
                        }
                      }
                      dashboards {
                        nextToken
                        items {
                          id
                          name
                          url
                        }
                      }
                      facilities {
                        nextToken
                        items {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                      }
                      consignments {
                        nextToken
                        items {
                          id
                          trackingRef
                          workspace {
                            id
                            name
                            url
                            allowedDomains
                            features
                          }
                          pieces {
                            nextToken
                            total
                          }
                          gateways {
                            nextToken
                          }
                          activated {
                            when
                          }
                          deactivated {
                            when
                          }
                          destination {
                            name
                          }
                        }
                      }
                    }
                    pieces {
                      nextToken
                      items {
                        id
                        trackingRef
                        consignmentId
                        workspaceId
                        label {
                          nfcId
                          bleId
                          addedOn
                        }
                        inFacilities {
                          facilityId
                          userFacilityId
                          facilityName
                          enteredOn
                          exitedOn
                          hasExited
                        }
                      }
                      total
                    }
                    gateways {
                      nextToken
                      items {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    activated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    deactivated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    destination {
                      name
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                  }
                }
              }
              pieces {
                nextToken
                items {
                  id
                  trackingRef
                  consignmentId
                  workspaceId
                  label {
                    nfcId
                    bleId
                    addedOn
                  }
                  inFacilities {
                    facilityId
                    userFacilityId
                    facilityName
                    enteredOn
                    exitedOn
                    hasExited
                  }
                }
                total
              }
              gateways {
                nextToken
                items {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              activated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              deactivated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              destination {
                name
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
            }
          }
        }
        pieces {
          nextToken
          items {
            id
            trackingRef
            consignmentId
            workspaceId
            label {
              nfcId
              bleId
              addedOn
            }
            inFacilities {
              facilityId
              userFacilityId
              facilityName
              enteredOn
              exitedOn
              hasExited
            }
          }
          total
        }
        gateways {
          nextToken
          items {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        activated {
          when
          where {
            facility {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
            coordinate {
              latitude
              longitude
              accuracy
            }
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
          }
        }
        deactivated {
          when
          where {
            facility {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
            coordinate {
              latitude
              longitude
              accuracy
            }
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
          }
        }
        destination {
          name
          where {
            facility {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
            coordinate {
              latitude
              longitude
              accuracy
            }
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
          }
        }
      }
    }
  }
`;
export const getConsignmentPieces = /* GraphQL */ `
  query GetConsignmentPieces(
    $requestId: String
    $consignmentId: String!
    $limit: Int
    $nextToken: String
  ) {
    getConsignmentPieces(
      requestId: $requestId
      consignmentId: $consignmentId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        trackingRef
        consignmentId
        workspaceId
        label {
          nfcId
          bleId
          addedOn
        }
        inFacilities {
          facilityId
          userFacilityId
          facilityName
          enteredOn
          exitedOn
          hasExited
        }
      }
      total
    }
  }
`;
export const getConsignmentGateways = /* GraphQL */ `
  query GetConsignmentGateways(
    $requestId: String
    $consignmentId: String!
    $limit: Int
    $nextToken: String
  ) {
    getConsignmentGateways(
      requestId: $requestId
      consignmentId: $consignmentId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const getLabel = /* GraphQL */ `
  query GetLabel($requestId: String, $nfcId: String!) {
    getLabel(requestId: $requestId, nfcId: $nfcId) {
      bleId
      bleIdHex
      nfcId
      lotId
      isGoodLabel
      reelId
      bank
      index
    }
  }
`;
export const getReelLabels = /* GraphQL */ `
  query GetReelLabels(
    $requestId: String
    $nfcId: String!
    $limit: Int
    $nextToken: String
  ) {
    getReelLabels(
      requestId: $requestId
      nfcId: $nfcId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        bleId
        bleIdHex
        nfcId
        lotId
        isGoodLabel
        reelId
        bank
        index
      }
    }
  }
`;
export const getGatewayTest = /* GraphQL */ `
  query GetGatewayTest($requestId: String, $testId: String!) {
    getGatewayTest(requestId: $requestId, testId: $testId) {
      testId
      gatewayId
      spaceId
      testBleId
      startedAt
      completedAt
      deviceUuid
      passed
    }
  }
`;
export const getGatewayTests = /* GraphQL */ `
  query GetGatewayTests(
    $requestId: String
    $gatewayId: String!
    $limit: Int
    $nextToken: String
  ) {
    getGatewayTests(
      requestId: $requestId
      gatewayId: $gatewayId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        testId
        gatewayId
        spaceId
        testBleId
        startedAt
        completedAt
        deviceUuid
        passed
      }
    }
  }
`;
export const getDomainIdentityProvider = /* GraphQL */ `
  query GetDomainIdentityProvider($requestId: String, $domain: String!) {
    getDomainIdentityProvider(requestId: $requestId, domain: $domain) {
      clientId
      idpName
    }
  }
`;
export const getNbIotAsset = /* GraphQL */ `
  query GetNbIotAsset(
    $requestId: String
    $token: String
    $assetId: String
    $bleId: Int
  ) {
    getNbIotAsset(
      requestId: $requestId
      token: $token
      assetId: $assetId
      bleId: $bleId
    ) {
      id
      name
      status
      lastSeen
      activatedAt
      origin {
        road
        city
        district
        county
        state
        country
        postalCode
      }
      locations {
        nextToken
        items {
          coordinate {
            latitude
            longitude
            accuracy
          }
          timestamp
          accuracy
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          state
          lastUpdatedAt
        }
      }
    }
  }
`;
export const getGatewayStatus = /* GraphQL */ `
  query GetGatewayStatus(
    $requestId: String
    $token: String
    $shortId: String!
  ) {
    getGatewayStatus(requestId: $requestId, token: $token, shortId: $shortId) {
      shortId
      name
      status
      lastSeen
      connectivity
      manufacturer
      model
      facilityName
    }
  }
`;
export const getOrganisationAssets = /* GraphQL */ `
  query GetOrganisationAssets(
    $requestId: String
    $id: String!
    $limit: Int
    $nextToken: String
  ) {
    getOrganisationAssets(
      requestId: $requestId
      id: $id
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        name
        organisationId
        createdOn
        lastSeen
        assetType {
          id
          name
          organisationId
          fields {
            key
            name
            value
            values
            dataType
            options
            isMultiSelect
            isRequired
          }
          iconUrl
        }
        photo
        labelAddedOn
        nfcId
        bleId
        custody {
          nextToken
          items {
            timestamp
            users {
              id
              givenName
              familyName
              avatarUrl
              email
            }
          }
        }
        locations {
          nextToken
          items {
            timestamp
            longitude
            latitude
            error
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
            updatedAt
          }
        }
        positions {
          nextToken
          items {
            timestamp
            name
            coordinate {
              latitude
              longitude
              accuracy
            }
            floor {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
        }
        temperatures {
          nextToken
          items {
            timestamp
            degreesCelcius
            numberReadings
          }
        }
        isSpace
        numberAssetsInSpace
        inSpaces {
          assetId
          spaceId
          spaceName
          facilityId
          facilityTimeZone
          facilityName
          enteredOn
          exitedOn
          hasExited
        }
        inFacilities {
          assetId
          facilityId
          facilityName
          facilityTimeZone
          enteredOn
          exitedOn
          hasExited
        }
        gateways {
          gatewayId
          shortId
          deviceId
          manufacturer
          productName
          addedOn
          removedOn
          status
          lastUpdated
          hasPendingCommands
          isStatic
          longitude
          latitude
          rssiFilter
        }
      }
      total
    }
  }
`;
