<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    :no-items-text="noDataText"
    smaller-gap-bottom
    title="Dashboards"
    @clickRow="clickRow"
  />
</template>
<script>
import ItemsTable from '../table/items-table';
import { routeNames } from '../../../constants';

export default {
  name: 'DashboardsTable',
  components: {
    ItemsTable,
  },
  props: {
  },
  computed: {
    noDataText() {
      return 'This team does not have any dashboards. Contact team@reelables.com to set up a dashboard.';
    },
    headers() {
      return [{ name: 'Name', key: 'name' }, { name: '', key: 'action' }];
    },
    rows() {
      return this.$store.state.dashboards.dashboards
        .map((d) => ({
          ...d,
          name: d.name || '(Blank name)',
          action: 'View dashboard >',
        }));
    },
    hasMoreData() {
      return this.$store.getters['dashboards/hasMoreDashboards'];
    },
    isLoading() {
      return this.$store.state.dashboards.isLoadingDashboards;
    },
  },
  methods: {
    getData() {
      if (this.hasMoreData) {
        return this.$store.dispatch('dashboards/getDashboards');
      }

      return null;
    },
    clickRow(row) {
      this.$router.push({
        name: routeNames.dashboards.detail,
        params: { id: row.id },
      });
    },
  },
};
</script>
