<template>
  <VerticalContainer>
    <MapClusterListDialogue
      :title="dialogueTitle"
      :is-visible="isDialogueVisible"
      @close="() => $emit('close')"
    >
      <div slot="map-dialogue-content">
        <slot name="map-dialogue-content" />
      </div>
    </MapClusterListDialogue>
    <div class="map-container">
      <Map
        :items="locations"
        :should-cluster-locations="true"
        @clickedClusteredItems="(i) => clickedClusteredItems(i)"
        @clickedUnclusteredItem="(i) => clickedUnclusteredItem(i)"
      >
        <div
          slot="popup-content"
          class="text-center"
        >
          <div class="is-bold popup-title">
            {{ popupTitle }}
          </div>
          <Button
            :text="popupButtonTitle"
            :on-click="clickedPopupButton"
            is-small
          />
        </div>
      </Map>
    </div>
    <ButtonsContainer>
      <Button
        text="Close"
        :on-click="backToItemsList"
      />
    </ButtonsContainer>
  </VerticalContainer>
</template>

<script>
import Map from '../../components/atoms/map/map-box';
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import ButtonsContainer from '../../components/molecules/layout/buttons-container';
import Button from '../../components/atoms/button/button';
import MapClusterListDialogue from '../../components/molecules/dialogues/map-cluster-list-dialogue';

export default {
  name: 'ItemsMap',
  components: {
    Map,
    ButtonsContainer,
    Button,
    VerticalContainer,
    MapClusterListDialogue,
  },
  props: {
    isDialogueVisible: { type: Boolean, default: false },
    dialogueTitle: { type: String, default: null },
    locations: { type: Array, default: () => [] },
    popupTitle: { type: String, default: null },
    popupButtonTitle: { type: String, default: null },
  },
  methods: {
    clickedPopupButton() {
      this.$emit('clickedPopupButton');
    },
    backToItemsList() {
      this.$emit('backToList');
    },
    clickedClusteredItems(clusteredItems) {
      this.$emit('clickedClusteredItems', clusteredItems);
    },
    clickedUnclusteredItem(unclusteredItem) {
      this.$emit('clickedUnclusteredItem', unclusteredItem);
    },
  },
};

</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.map-container {
  height: 100%;
  margin-bottom: 40px;
}
.popup-title {
  margin-bottom: 6px;
}
</style>
