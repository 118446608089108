import AWS from 'aws-sdk';
import { v1 as uuidv1 } from 'uuid';
import md5 from 'md5';

const logLevels = {
  console: 0,
  off: 1,
  always: 2,
  error: 3,
  warn: 4,
  info: 5,
  debug: 6,
};

const logLevel = logLevels[process.env.VUE_APP_LOG_LEVEL?.toLocaleLowerCase()] || 0;
const requestId = `Reelables_Web_App_${uuidv1()}`;
const logs = [];
let sequenceNum = 0;

function extractData(data) {
  return Buffer.from(data, 'base64').toString('utf8');
}

const firehose = new AWS.Firehose({
  apiVersion: '2015-08-04',
  region: process.env.VUE_APP_AWS_PROJECT_REGION,
  credentials: new AWS.Credentials(
    extractData(process.env.VUE_APP_LOG_KEY),
    extractData(process.env.VUE_APP_LOG_SECRET),
  ),
});

async function sendLogsToFirehose(logsToSend) {
  return firehose.putRecordBatch({
    DeliveryStreamName: process.env.VUE_APP_LOG_STREAM_NAME,
    Records: logsToSend.map((log) => ({ Data: JSON.stringify(log) })),
  }).promise();
}

function purgeLogs(start, number) {
  logs.splice(start, number);
}

let errorCount = 0;
(function logSender() {
  const intervalId = window.setInterval(async () => {
    if (logs.length > 0) {
      const logsToSend = JSON.parse(JSON.stringify(logs));
      try {
        await sendLogsToFirehose(logsToSend);
        purgeLogs(0, logsToSend.length);
      } catch (error) {
        // unable to send data to firehose
        errorCount += 1;
        if (errorCount >= 5) {
          clearInterval(intervalId);
        }
      }
    }
  }, 2000);
}());

function addLog(level, message, payload, isEntry = false) {
  if (level > logLevel && logLevel !== logLevels.console) {
    return;
  }

  const log = {
    sequenceNum: ++sequenceNum,
    timestamp: Date.now(),
    requestId,
    message,
    payload,
    stage: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
    service: 'reelables-web-app',
    isError: level === logLevels.error,
    level: Object.keys(logLevels)[level],
  };
  if (isEntry) {
    log.entry = true;
  }
  if (level <= logLevel) {
    logs.push({
      ...log,
      hash: md5(JSON.stringify(log)),
    });
  }
  if (logLevel === logLevels.console) {
    // eslint-disable-next-line no-console
    console.log(log);
  }
}

export const logger = {
  entry(message, payload) {
    const data = payload ? JSON.parse(JSON.stringify(payload)) : {};
    if (window.navigator) {
      data.device = {
        platform: window.navigator.platform,
        language: window.navigator.language,
        userAgent: window.navigator.userAgent,
        vendor: window.navigator.vendor,
      };
    }
    addLog(logLevels.always, message, data, true);
  },
  error(message, payload) {
    addLog(logLevels.error, message, payload);
  },
  warn(message, payload) {
    addLog(logLevels.warn, message, payload);
  },
  info(message, payload) {
    addLog(logLevels.info, message, payload);
  },
  debug(message, payload) {
    addLog(logLevels.debug, message, payload);
  },
};

export default {
  computed: {
    logger() {
      return logger;
    },
  },
};
