<template>
  <div
    :style="{
      height: wrapperHeight,
    }"
  >
    <div
      v-if="isLoading"
      class="loading-message"
    >
      Loading...
    </div>
    <Map
      v-if="includeMap"
      :items="items"
      :should-cluster-locations="shouldClusterLocations"
      :includes-first-point="includesFirstPoint"
      :is-grey-scale="isGreyScale"
      :latest-point-is-uncertain="latestPointIsUncertain"
      @loaded="isLoading = false"
    />
  </div>
</template>
<script>
import Map from '../../atoms/map/map-box';

export default {
  components: {
    Map,
  },
  props: {
    height: { type: [Number, String], default: 500 },
    items: { type: Array, default: () => [] },
    shouldClusterLocations: { type: Boolean, default: false },
    includesFirstPoint: { type: Boolean, default: true },
    isGreyScale: { type: Boolean, default: false },
    latestPointIsUncertain: { type: Boolean, default: false },
  },
  data() {
    return {
      includeMap: false,
      isLoading: true,
    };
  },
  computed: {
    wrapperHeight() {
      if (typeof this.height === 'string') {
        return this.height;
      }

      return `${this.height}px`;
    },
  },
  mounted() {
    // so that content will display - and not wait for map to load first
    setTimeout(() => { this.includeMap = true; }, 10);
  },
};
</script>
<style lang="scss" scoped>
.loading-message {
  padding: 10px;
  text-align: center;
}
</style>
