<template>
  <div
    class="vertical-container"
    :class="{
      'is-editing': isEditing
    }"
  >
    <slot />
    <ButtonsContainer>
      <slot name="buttons" />
    </ButtonsContainer>
  </div>
</template>
<script>
import ButtonsContainer from '../../molecules/layout/buttons-container';

export default {
  components: {
    ButtonsContainer,
  },
  props: {
    isEditing: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';
.vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @include mq($from: md) {
    padding: 30px 30px 30px;
    box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
  }
  &.is-editing {
    background-color: $white-3;
  }
}
</style>
