import { query } from './api-consumer';

export default {
  async getFacility(facilityId) {
    return query('getFacility', { id: facilityId });
  },
  async getFacilitySpaces(facilityId) {
    return query('getFacilitySpaces', { facilityId });
  },
  async getFacilityPrinters(facilityId) {
    return query('getFacilityPrinters', { facilityId });
  },
  async getFacilityFloorPlan(facilityId, floorPlanId) {
    return query('getFacilityFloorPlan', { facilityId, floorPlanId });
  },
  async getPrinterPrintJobs(printerId, nextToken) {
    return query('getPrinterPrintJobs', {
      printerId,
      nextToken,
      limit: 20,
    });
  },
  async getFacilities(organisationId, nextToken, searchTerm) {
    return query('getOrganisationFacilities', {
      id: organisationId, nextToken, searchTerm, limit: 20,
    });
  },
  async getSpaceStatuses(spaceId, nextToken) {
    return query('getSpaceStatuses', {
      spaceId,
      nextToken,
      limit: 20,
    });
  },
};
