<template>
  <VerticalContainer is-editing>
    <Table
      :headers="headers"
      first-column-width="30%"
      :rows="rows"
      :is-editing="true"
      @onChange="onChange"
    />
    <ErrorMessage :error-message="errorToDisplay" />
    <template #buttons>
      <Button
        text="Save"
        :on-click="save"
        :is-loading="isLoading"
      />
      <Button
        text="Cancel"
        :on-click="cancelClick"
        :disabled="isLoading"
      />
    </template>
    <YesNoDialogue
      :is-visible="isCancelDialogueVisible"
      message="Any unsaved data will be lost"
      @no="() => isCancelDialogueVisible = false"
      @yes="goToAssetsHome"
    />
  </VerticalContainer>
</template>
<script>
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import Table from '../../components/atoms/table/table';
import ErrorMessage from '../../components/atoms/message/error-message';
import Button from '../../components/atoms/button/button';
import YesNoDialogue from '../../components/molecules/dialogues/yes-no-dialogue';
import rules from '../../mixins/input-validation-rules';
import { routeNames } from '../../constants';

export default {
  name: 'AddAsset',
  components: {
    VerticalContainer,
    Button,
    ErrorMessage,
    Table,
    YesNoDialogue,
  },
  mixins: [rules],
  props: {
    label: { type: Object, default: null },
  },
  data() {
    const rows = [{
      name: 'Name',
      value: '',
      placeholder: 'Insert name',
      isEditable: true,
    }];
    if (this.label !== null) {
      rows.push({ name: 'Has label', value: true });
    }
    return {
      assetName: '',
      nameErrorMessage: '',
      rows,
      isCancelDialogueVisible: false,
    };
  },
  computed: {
    headers() {
      return [{ key: 'name' }, { key: 'value' }];
    },
    isLoading() {
      return this.$store.state.assets.isLoadingAsset;
    },
    serverErrorMessage() {
      return this.$store.state.assets.errorMessage;
    },
    errorToDisplay() {
      return [this.nameErrorMessage, this.serverErrorMessage]
        .filter((message) => message && message !== '')
        .join(', ');
    },
    hasError() {
      return this.errorToDisplay !== '';
    },
  },
  destroyed() {
    this.$store.commit('assets/CLEAR_ERROR_MESSAGE');
  },
  methods: {
    onChange({ value }) {
      this.assetName = value;
      this.rows.find(({ name }) => name === 'Name').value = value;
      this.resetNameError();
    },
    resetNameError() {
      this.nameErrorMessage = '';
    },
    async save() {
      const isNameValid = this.validateName(this.assetName);
      if (isNameValid === true) {
        const newAsset = await this.$store.dispatch('assets/addAsset', {
          name: this.assetName,
          label: this.label,
        });
        if (!this.hasError) {
          this.goToAssetsHome();
          this.$root.trackEvent.addedAsset(newAsset);
          if (this.label) {
            this.$root.trackEvent.linkedLabel(newAsset, true);
          }
        }
      } else {
        this.nameErrorMessage = isNameValid;
      }
    },
    cancelClick() {
      if (this.assetName !== '' || this.label !== null) {
        // open modal
        this.isCancelDialogueVisible = true;
      } else {
        this.goToAssetsHome();
      }
    },
    goToAssetsHome() {
      this.$router.replace({ name: routeNames.assets.list });
    },
  },
};
</script>
