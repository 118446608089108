<template>
  <div class="page-container">
    <div>
      <div class="sticky-header">
        <div class="header">
          <img
            class="reelables-logo"
            :src="logo"
            :height="40"
            alt="Reelables logo"
          >
          <div class="title-and-status">
            <div class="title">
              GATEWAY: {{ shortId }}
            </div>
            <StatusPill
              v-if="showStatus"
              :status="status"
            />
          </div>
        </div>
        <div
          v-if="showStatus"
          class="last-seen"
        >
          Last seen {{ lastSeen ? getTimeAgo(lastSeen) : '(no data)' }}
        </div>
      </div>
      <div class="content">
        <div
          v-if="topContentIsVisible"
          class="top-content"
        >
          <ErrorMessage :error-message="errorMessage" />
          <Loader
            :is-loading="isLoading"
            color="#45BEC1"
          />
        </div>
        <slot />
      </div>
    </div>
    <div class="footer-container">
      <div class="support-info">
        <div class="support-title">
          Support
        </div>
        <div class="support-details">
          Contact <EmailLink
            email="device@reelables.com"
            :subject="`Device Support: Gateway ${shortId}`"
          /> and quote ID: <strong>{{ shortId }}</strong>
        </div>
      </div>
      <div class="footer">
        <img
          class="reelables-logo"
          :src="logoTeal"
          height="40"
          alt="Reelables logo"
        >
      </div>
    </div>
  </div>
</template>
<script>
import LogoImage from '../../../assets/images/reelables-r-logo-white.svg';
import LogoImageTeal from '../../../assets/images/reelables-r-logo-teal.svg';
import ErrorMessage from '../../atoms/message/error-message';
import Loader from '../../atoms/loader/loader';
import StatusPill from '../../atoms/pill/status-pill';
import EmailLink from '../../atoms/links/email-link';
import dateTimeHelper from '../../../mixins/date-time-helper';

export default {
  name: 'GatewayStatusLayout',
  components: {
    ErrorMessage,
    Loader,
    StatusPill,
    EmailLink,
  },
  mixins: [dateTimeHelper],
  props: {
    errorMessage: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    shortId: { type: String, default: '' },
    status: { type: String, default: '' },
    lastSeen: { type: String, default: '' },
    includeStatus: { type: Boolean, default: false },
  },
  data() {
    return {
      tab: 0,
      timelineHeight: 0,
      backgroundLeft: 0,
      backgroundWidth: 0,
      backgroundResizeCounter: 0,
      hasLoadedData: false,
    };
  },
  computed: {
    logo() { return LogoImage; },
    logoTeal() { return LogoImageTeal; },
    topContentIsVisible() { return this.errorMessage || this.isLoading; },
    showStatus() { return !this.isLoading && this.includeStatus; },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@import '@/styles/_updated-variables.scss';

.page-container {
  font-family: Helvetica-Neue;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: space-between;
}
.top-content {
  margin-top: 20px;
  padding: 20px;
}
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%
}
.header, .footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #000000;
  padding: 8px 8px 8px 0px;
}
.header {
  color: $white;
  .title {
    font-size: 1.25rem;
  }
}
.footer-container {
  background-color: $gray4;
  border-top: 1px solid $gray3;
}
.support-info {
  padding: 20px;
  .support-title {
    text-transform: uppercase;
    color: $gray2;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .support-details {
    color: $gray1;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 10px;
    a {
      color: $gray2;
    }
  }
}
.footer {
  background-color: $tealLight;
}
.title-and-status {
  margin-left: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.last-seen {
  text-align: right;
  color: $gray1;
  font-size: 0.875rem;
  background-color: $gray4;
  padding: 4px 8px;
  height: 25px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
}
.content {
  margin-top: 70px;
}
</style>
