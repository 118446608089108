<template>
  <v-app
    id="web-app"
    :class="{
      'has-background-image': !isSignedIn,
      'is-vertically-centered': isVerticallyCentered,
      'is-signed-in': isSignedIn
    }"
  >
    <div class="left-side-container">
      <AppHeader />
      <v-main class="main-container">
        <v-container
          fluid
          class="fluid-container"
          :class="{
            'no-top-padding': noNavigationTabs
          }"
        >
          <slot />
        </v-container>
      </v-main>
    </div>
    <div class="right-side-container">
      <v-img
        :src="backgroundImage"
        position="center center"
        aspect-ratio="2.2"
        alt="background image"
      />
    </div>
    <ScanModal />
    <ScanSuccessDialogue />
    <Notifications />
  </v-app>
</template>
<script>

import BackgroundImage from '../../../assets/images/background-image.svg';
import AppHeader from '../../molecules/layout/app-header';
import ScanModal from '../../atoms/nfc/scan-modal';
import ScanSuccessDialogue from '../../molecules/dialogues/nfc-scan-success-dialogue';
import Notifications from '../notifications/notifications';

export default {
  components: {
    AppHeader,
    ScanModal,
    ScanSuccessDialogue,
    Notifications,
  },
  computed: {
    backgroundImage() {
      return BackgroundImage;
    },
    isSignedIn() {
      return this.$store.state.auth.signedIn;
    },
    isVerticallyCentered() {
      return this.$route.meta.isVerticallyCentered === true
        || !this.isSignedIn;
    },
    noNavigationTabs() {
      return this.$route.meta.noTopNav;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';

#web-app {
  background-color: $grey-1;
  &.is-signed-in {
    background-color: $white-2;
  }
  .main-container {
    height: 100vh;
    .fluid-container {
      height: 100%;
      padding: 14px;
      &.no-top-padding {
        padding-top: 4px;
      }
      @include mq($from: md) {
        padding: 20px 36px 36px;
        &.no-top-padding {
          padding-top: 16px;
        }
      }
    }
  }
}

.has-background-image .left-side-container {
  display: flex;

  @include mq($from: lg) {
    width: 600px;
  }
}

.right-side-container {
  display: none;
}
.has-background-image .right-side-container {
  background-color: $white;
  width: calc(100% - 600px);
  z-index: 10;
  align-items: center;

  @include mq($from: lg) {
    display: flex;
  }
}

.is-vertically-centered .main-container {
  @include mq($from: lg) {
    align-items: center;
    margin-top: -140px;
  }
}

</style>
<style lang="scss">
@import '@/styles/_breakpoints.scss';

#web-app.has-background-image .v-application--wrap {
  @include mq($from: lg) {
    flex-direction: row;
  }
}
</style>
