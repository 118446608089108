<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    @clickRow="goToMemberDetail"
  >
    <slot
      slot="buttons"
      name="buttons"
    />
  </ItemsTable>
</template>
<script>
import ItemsTable from '../table/items-table';
import { routeNames } from '../../../constants';

export default {
  name: 'MembersTable',
  components: {
    ItemsTable,
  },
  computed: {
    headers() {
      return [
        { name: 'Role', key: 'role' },
        { name: 'Name', key: 'name' },
      ];
    },
    rows() {
      return this.$store.state.teamMembers.teamMembers.map((teamMember) => (
        {
          id: teamMember.user.id,
          role: teamMember.role,
          name: `${teamMember.user.givenName} ${teamMember.user.familyName}`,
          isSelected: this.selectedId && this.selectedId === teamMember.user.id,
        }
      ));
    },
    hasMoreData() {
      return this.$store.getters['teamMembers/hasMoreTeamMembers'];
    },
    isLoading() {
      return this.$store.state.teamMembers.isLoadingTeamMembers;
    },
    selectedId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getData() {
      if (this.hasMoreData) {
        return this.$store.dispatch('teamMembers/getTeamMembers');
      }
      return null;
    },
    goToMemberDetail(row) {
      this.$router.push({
        name: routeNames.team.memberDetail,
        params: {
          id: row.id,
        },
      }).catch(() => {
        // duplicate navigation to same zone - do nothing
      });
    },
  },
};
</script>
