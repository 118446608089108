<template>
  <div class="search-input-container">
    <v-combobox
      class="search-input"
      :value="value"
      :search-input.sync="dataValue"
      prepend-inner-icon="$vuetify.icons.searchIcon"
      :placeholder="placeholder"
      :height="$vuetify.breakpoint.mdAndUp ? 50 : 36"
      filled
      rounded
      hide-details
      autocomplete="off"
      @update:search-input="onChange"
      @click:clear="onChange"
      @click:append="openFilters"
      @focusout="focusout"
    >
      <template #append>
        <div
          v-if="hasValue"
          class="icon-button"
          data-automation="clear-button"
          @keydown="clear"
          @click="clear"
        >
          <v-icon>$vuetify.icons.clearIcon</v-icon>
        </div>
        <div
          class="icon-button"
          data-automation="filters-button"
          @keydown="openFilters"
          @click="openFilters"
        >
          <div
            v-if="filtersApplied"
            class="filters-applied"
          >
            <span>Filters applied</span>
            <v-icon v-if="filtersApplied">
              $vuetify.icons.filterAppliedIcon
            </v-icon>
          </div>
          <v-icon v-else>
            $vuetify.icons.filterIcon
          </v-icon>
        </div>
      </template>
    </v-combobox>
  </div>
</template>
<script>
export default {
  name: 'SearchInput',
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: 'Search' },
    filtersApplied: { type: Boolean, default: false },
  },
  data() {
    return {
      dataValue: this.value,
    };
  },
  computed: {
    hasValue() {
      return this.dataValue && this.dataValue !== '';
    },
  },
  methods: {
    clear() {
      this.$root.trackEvent.clearedAssetsSearch(
        this.value,
        this.$store.state.assets.assets.length,
      );
      this.dataValue = '';
      this.onChange();
      this.$emit('clear');
    },
    onChange() {
      this.$emit('changeValue', this.dataValue || '');
    },
    focusout() {
      // to catch mobile phones closing keyboard, e.g. 'done' (iOS)
      this.onChange();
    },
    openFilters() {
      this.$emit('openFilters');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';
.search-input-container {
  .search-input {
    color: $warm-grey;
    background-color: $white-4;
    border-radius: 13px;
    font-size: 0.875rem;
    line-height: 16px;
    width: 100%;
    @include mq($from: md) {
      border-radius: 14px;
      font-size: 1rem;
    }
    &:focus  {
      outline: solid 2px $reelables-light-grey;
    }
    .icon-button {
      cursor: pointer;
      margin-right: 4px;
      &:last-of-type {
        margin-left: 8px;
      }
    }
    .filters-applied span {
      font-size: 0.75rem;
      color: $reelables-light-grey;
      display: inline-block;
      margin-right: 10px;
      @include mq($from: md) {
        font-size: 0.875rem;
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/styles/_breakpoints.scss';
.search-input-container {
  .v-input__slot {
    padding: 0 10px !important;
    min-height: 36px !important;
    background-color: initial !important;
    @include mq($from: md) {
      padding: 0 14px !important;
      min-height: 50px !important;
    }
    .v-input__prepend-inner,
    .v-input__append-inner {
      margin-top: 7px;
      @include mq($from: md) {
        margin-top: 12px;
      }
    }
    .v-input__prepend-inner {
      padding-right: 10px !important;
      @include mq($from: md) {
        padding-right: 14px !important;
      }
      svg {
        height: 17px;
        width: 17px;
        @include mq($from: md) {
          height: 24px;
          width: 24px;
        }
      }
    }
    .v-input__append-inner {
      padding-left: 12px !important;
      @include mq($from: md) {
        padding-left: 16px !important;
      }
      svg {
        height: 24px;
        width: 24px;
        @include mq($from: md) {
          height: 26px;
          width: 26px;
        }
      }
      .v-input__icon--clear {
        svg {
          height: 20px;
          width: 20px;
          @include mq($from: md) {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
  // prevent rotation of append icon
  .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    -webkit-transform: none;
    transform: none;
  }
}
</style>
