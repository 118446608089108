export default {
  data() {
    return {
      timer: 0,
      timerIntervalId: null,
    };
  },
  mounted() {
    this.timerIntervalId = setInterval(() => { this.timer += 1; }, 10000);
  },
  beforeDestroy() {
    window.clearInterval(this.timerIntervalId);
  },
};
