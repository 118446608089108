<template>
  <div class="password-input-container">
    <div
      class="show-passord-container"
      @keydown="showHidePassword"
      @click="showHidePassword"
    >
      <EyeIcon />
      <div class="label">
        {{ showHideLabel }}
      </div>
    </div>
    <TextInput
      ref="passwordInput"
      v-model="inputVal"
      :label="isNew ? 'New password' : 'Password'"
      :autocomplete="isNew ? 'new-password' : 'current-password'"
      :type="inputType"
      :error-message="errorMessage"
    />
  </div>
</template>
<script>
import TextInput from '../../atoms/input/text-input';
import EyeIcon from '../../atoms/icon/eye-icon';

export default {
  name: 'PasswordInput',
  components: { TextInput, EyeIcon },
  props: {
    value: { type: String, default: '' },
    errorMessage: { type: String, default: '' },
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    inputType() {
      return this.showPassword ? 'text' : 'password';
    },
    showHideLabel() {
      return this.showPassword ? 'Hide' : 'Show';
    },
    name() {
      return this.$refs.passwordInput.name;
    },
    inputVal: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); },
    },
  },
  methods: {
    showHidePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
<style lang="scss" scoped>
.password-input-container {
  position: relative;
  .show-passord-container {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 4px;
    font-size: 14px;
    line-height: 17px;
    .label {
      display: inline-block;
      margin-left: 6px;
      width: 40px;
    }
  }
}
</style>
