export const routeNames = {
  home: 'home',
  notFound: 'notFound',
  teamHome: 'teamHome',
  assets: {
    list: 'assets.list',
    detail: 'assets.detail',
    linkLabel: 'assets.linkLabel',
    linkAssetLabel: 'assets.linkAssetLabel',
    filters: 'assets.filters',
    add: 'assets.add',
    selectAsset: 'assets.selectAsset',
    map: 'assets.map',
    downloadTemperature: 'assets.downloadTemperature',
    temperature: 'assets.temperature',
    downloadTemperatureRequireApp: 'downloadTemperatureRequireApp',
  },
  zones: {
    list: 'zones.list',
    detail: 'zones.detail',
    linkGateway: 'zones.linkGateway',
    linkZoneGateway: 'zones.linkZoneGateway',
    add: 'zones.add',
    selectZone: 'zones.selectZone',
    map: 'zones.map',
  },
  team: {
    addMember: 'team.addMember',
    list: 'team.list',
    detail: 'team.detail',
    memberDetail: 'team.memberDetail',
    teamsListRoute: 'team.teamsListRoute',
  },
  dashboards: {
    index: 'dashboards.index',
    detail: 'dashboards.detail',
  },
  facilities: {
    index: 'facilities.index',
    detail: 'facilities.detail',
    gatewayDetail: 'facilities.gatewayDetail',
    printerDetail: 'facilities.printerDetail',
    assetDetail: 'facilities.assetDetail',
    printJobAssetDetail: 'facilities.printJobAssetDetail',
  },
  nbIot: {
    detail: 'nbIot.detail',
    link: 'nbIot.link',
  },
  gateway: {
    status: 'gateway.status',
  },
  settings: 'settings',
};

export const table = {
  selectColumnKey: 'selectColumnKey',
};

export const userRoles = {
  viewer: 'Viewer',
  member: 'Member',
  admin: 'Admin',
  owner: 'Owner',
};

export const inputDataTypes = {
  number: 'Number',
  text: 'Text',
  date: 'Date',
  boolean: 'Boolean',
  select: 'Select',
};

export const cookieKeys = {
  teamId: 'teamId',
};

export const linkTrackerStatus = {
  noAccess: 'noAccess',
  alreadyLinked: 'alreadyLinked',
  availableForLinking: 'availableForLinking',
  unknownIssue: 'unknownIssue',
};

export const pollingDataInterval = 10000; // 10 secs

export const tLabelDiscoveryEnum = {
  bluetoothOff: 'BLUETOOTH_OFF',
  bluetoothUnsupported: 'BLUETOOTH_UNSUPPORTED',
  bluetoothUnauthorized: 'BLUETOOTH_UNAUTHORIZED',
  error: 'ERROR',
};

export const tLabelDataDownloadEnum = {
  error: 'ERROR',
  timeout: 'TIMEOUT',
  discovering: 'DISCOVERING',
  connecting: 'CONNECTING',
  discoveringServices: 'DISCOVERING_SERVICES',
  discoveringCharacteristics: 'DISCOVERING_CHARACTERISTICS',
  downloading: 'DOWNLOADING',
  complete: 'COMPLETE',
};

export const workspaceFeatures = {
  assetSpaceHistoryTimeline: 'AssetSpaceHistoryTimeline',
  assetLocationTimeline: 'AssetLocationTimeline',
  spaceGroupingOnName: 'SpaceGroupingOnName',
  coalesceConsecutiveTimelineEvents: 'CoalesceConsecutiveTimelineEvents',
  gatewayTestEnabled: 'GatewayTestEnabled',
  assetFacilityEpisodes: 'AssetFacilityEpisodes',
  demoMode: 'DemoMode',
};

export const errorMessages = {
  noPermission: 'User does not have necessary permission to carry-out action',
};

export const expoBackOffTimes = [200, 500, 1000, 3000];

export const pollingNbIotDataInterval = Number(process.env.VUE_APP_NB_IOT_POLLING_MS);
export const pollingNbIotDataMaxDuration = Number(process.env.VUE_APP_NB_IOT_MAX_POLL_DURATION);

export const pollingGatewayStatusDataInterval = Number(
  process.env.VUE_APP_GATEAY_STATUS_POLLING_MS,
);
export const pollingGatewayStatusMaxDuration = Number(
  process.env.VUE_APP_GATEAY_STATUS_MAX_POLL_DURATION,
);

export const facilityTabSlugs = {
  printers: 'printers',
  gateways: 'gateways',
  assets: 'assets',
};

export const assetDetailTabSlugs = {
  timeline: 'timeline',
  map: 'map',
  temperature: 'temperature',
};

export const labelProvisioned = 'Label activated';

export const nbIotNoLocationDisplayName = 'Unknown';

export const locationStates = {
  inMotion: 'IN_MOTION',
  inMotionStationaryOverlap: 'IN_MOTION_STATIONARY_OVERLAP', // in motion, overlaps with stationary
  stationary: 'STATIONARY',
};

/** ASSET FILTERS */
export const assetFilterKeys = {
  consignments: 'consignments',
  facilities: 'facilities',
  zones: 'zones',
};

export const assetFilterSelectedKeys = {
  consignments: 'selectedConsignmentId',
  facilities: 'selectedFacilityId',
  zones: 'selectedZoneId',
};

export const filterKeyToQueryStringParam = {
  [assetFilterKeys.consignments]: 'inConsignments',
  [assetFilterKeys.facilities]: 'inFacilities',
  [assetFilterKeys.zones]: 'inZones',
};

export const sortOrder = {
  firstSeenAsc: 'FirstSeenAsc',
};

export const justNow = 'Just now';
