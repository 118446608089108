import api from '../api';
import { pollingDataInterval } from '../constants';
import { constructGatewayStatus, noGatewayStatus } from '../helpers/zones-helper';

const getDefaultState = () => ({
  isLoadingZones: false,
  isLoadingZone: false,
  isPollingZones: false,
  zonesPollingId: null,
  zones: [],
  totalZones: 0,
  nextToken: null,
  errorMessage: '',
  getZonesCallCount: 0, // to ensure we only use the latest query results
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    ADD_ZONE(state, zone) {
      const z = zone;
      // remove blank locations
      if (zone.locations && Array.isArray(zone.locations.items)) {
        // eslint-disable-next-line no-param-reassign
        z.locations.items = zone.locations.items.filter((i) => i.latitude && i.longitude);
      }
      // add status
      z.status = constructGatewayStatus(zone);
      z.hasGateway = z.status !== noGatewayStatus;

      if (!state.zones.find(({ id }) => zone.id === id)) {
        state.zones.push(z);
      } else {
        // retain the original order
        state.zones = state.zones.map((t) => (t.id === zone.id ? z : t));
      }
    },
    REMOVE_ZONE(state, removeZoneId) {
      const zones = state.zones.filter(({ id }) => id !== removeZoneId);
      state.zones = [...zones];
    },
    UPDATE_ZONE(state, editedZone) {
      state.zones = state.zones
        .map((zone) => {
          if (zone.id === editedZone.id) {
            const status = constructGatewayStatus(editedZone);
            return {
              ...editedZone,
              status,
              hasGateway: status !== noGatewayStatus,
            };
          }
          return zone;
        });
    },
    SET_ZONES(state, zones) {
      state.zones = [
        ...state.zones
          .filter((zoneInState) => typeof zones
            .find((newZone) => zoneInState.id === newZone.id) === 'undefined'),
        ...zones.map((zone) => {
          const z = zone;
          // remove blank locations
          if (z.locations && Array.isArray(z.locations.items)) {
            // eslint-disable-next-line no-param-reassign
            z.locations.items = z.locations.items.filter((i) => i.latitude && i.longitude);
          }
          // add status
          z.status = constructGatewayStatus(z);
          z.hasGateway = z.status !== noGatewayStatus;

          return z;
        }),
      ];
    },
    SET_TOTAL_ZONES(state, total) {
      state.totalZones = total;
    },
    SET_NEXT_TOKEN(state, nextToken) {
      state.nextToken = nextToken;
    },
    START_LOADING_ZONE(state) {
      state.isLoadingZone = true;
    },
    STOP_LOADING_ZONE(state) {
      state.isLoadingZone = false;
    },
    START_LOADING_ZONES(state) {
      state.isLoadingZones = true;
    },
    STOP_LOADING_ZONES(state) {
      state.isLoadingZones = false;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    INCREMENT_GET_ZONES_CALL_COUNT(state) {
      state.getZonesCallCount += 1;
    },
    START_POLLING_ZONES(state) { state.isPollingZones = true; },
    STOP_POLLING_ZONES(state) { state.isPollingZones = false; },
    SET_ZONES_POLLING_INTERVAL_ID(state, pollingIntervalId) {
      state.zonesPollingId = pollingIntervalId;
    },
    RESET_ZONES_POLLING_INTERVAL_ID(state) {
      window.clearInterval(state.zonesPollingId);
      state.zonesPollingId = null;
    },
  },
  actions: {
    async getZoneByGatewayId(context, { gatewayId, skipRedirect }) {
      context.commit('START_LOADING_ZONE');
      try {
        return await api.getZoneByGatewayId(gatewayId, !skipRedirect);
      } finally {
        context.commit('STOP_LOADING_ZONE');
      }
    },
    async getZone(context, id) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ZONE');
      try {
        const zone = await api.getZone(id);
        context.commit('ADD_ZONE', zone);
        return zone;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_ZONE');
      }
    },
    startPollingZones(context, data = {}) {
      const { condition } = data;
      context.commit('RESET_ZONES_POLLING_INTERVAL_ID');
      const pollingIntervalId = window.setInterval(async () => {
        try {
          if (!context.state.isLoadingZones
            && !context.state.isPollingZones
            && (typeof condition !== 'function' || condition())) {
            context.commit('START_POLLING_ZONES');
            const zonesResponse = await api.getZones(
              context.rootState.teams.selectedTeam.id,
              context.state.nextToken,
            );
            context.commit('SET_ZONES', zonesResponse.items);
          }
        } catch (error) {
          // error
        } finally {
          context.commit('STOP_POLLING_ZONES');
        }
      }, pollingDataInterval);
      context.commit('SET_ZONES_POLLING_INTERVAL_ID', pollingIntervalId);
    },
    stopPollingZones(context) {
      context.commit('RESET_ZONES_POLLING_INTERVAL_ID');
    },
    async getZones(context) {
      context.commit('INCREMENT_GET_ZONES_CALL_COUNT');
      const getZonesQueryId = context.state.getZonesCallCount;
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ZONES');
      try {
        const zonesResponse = await api.getZones(
          context.rootState.teams.selectedTeam.id,
          context.state.nextToken,
        );
        if (getZonesQueryId === context.state.getZonesCallCount) {
          if (!context.getters.hasZones) {
            context.commit('SET_TOTAL_ZONES', zonesResponse.total);
          }
          context.commit('SET_NEXT_TOKEN', zonesResponse.nextToken);
          context.commit('SET_ZONES', zonesResponse.items);
        }
      } catch (error) {
        if (getZonesQueryId === context.state.getZonesCallCount) {
          context.commit('SET_ERROR_MESSAGE', error.message);
        }
      } finally {
        if (getZonesQueryId === context.state.getZonesCallCount) {
          context.commit('STOP_LOADING_ZONES');
        }
      }
    },
    async addZone(context, zone) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ZONE');
      try {
        const newZone = await api.addZone(
          context.rootState.teams.selectedTeam.id,
          zone.name,
          zone.gateway ? zone.gateway.gatewayId : null,
          zone.gateway ? zone.gateway.location : null,
        );
        context.commit('SET_TOTAL_ZONES', context.state.totalZones + 1);
        context.commit('ADD_ZONE', newZone);
        context.commit('assetsFilters/RESET_ZONES_STATE', null, { root: true });
        return newZone;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_ZONE');
      }
    },
    async editZone(context, { zoneId, name }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ZONE');
      try {
        const editedZone = await api.editZone(zoneId, name);
        context.commit('UPDATE_ZONE', editedZone);
        context.commit('assetsFilters/RESET_ZONES_STATE', null, { root: true });
        return editedZone;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_ZONE');
      }
    },
    async removeZone(context, zoneId) {
      context.commit('START_LOADING_ZONE');
      try {
        await api.removeZone(context.rootState.teams.selectedTeam.id, zoneId);
        context.commit('SET_TOTAL_ZONES', context.state.totalZones - 1);
        context.commit('REMOVE_ZONE', zoneId);
        context.commit('assetsFilters/RESET_ZONES_STATE', null, { root: true });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message || 'Unknown error');
      } finally {
        context.commit('STOP_LOADING_ZONE');
      }
    },
    async addGatewayToZone(context, zone) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ZONE');
      try {
        const editedZone = await api.addGatewayToZone(
          zone.id,
          zone.gateway ? zone.gateway.gatewayId : null,
          zone.gateway ? zone.gateway.location : null,
        );
        context.commit('UPDATE_ZONE', editedZone);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_ZONE');
      }
    },
    async removeGatewayFromZone(context, { zoneId, gatewayId }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ZONE');
      try {
        const editedZone = await api.removeGatewayFromZone(zoneId, gatewayId);
        context.commit('UPDATE_ZONE', editedZone);
        context.commit('assetsFilters/RESET_ZONES_STATE', null, { root: true });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_ZONE');
      }
    },
    async startGatewayTest(context, { deviceUuid, gatewayId }) {
      context.commit('CLEAR_ERROR_MESSAGE');

      try {
        const response = await api.startGatewayTest(deviceUuid, gatewayId);
        return response;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      }
      return null;
    },
    async getGatewayTest(context, testId) {
      context.commit('CLEAR_ERROR_MESSAGE');
      try {
        const response = await api.getGatewayTest(testId);
        return response;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      }

      return null;
    },
    async getGatewayTests(context, { gatewayId, nextToken, limit }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      try {
        const response = await api.getGatewayTests(gatewayId, nextToken, limit);
        return response;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      }

      return null;
    },
  },
  getters: {
    hasCalledGetZones(state) {
      return state.getZonesCallCount > 0;
    },
    hasZones(state) {
      return state.totalZones > 0;
    },
    hasMoreZones(state) {
      return state.nextToken !== null;
    },
    hasLocations(state) {
      return Boolean(state.zones.find((zone) => zone.locations?.items?.length));
    },
  },
};
