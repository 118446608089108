<template>
  <ItemsMap
    :is-dialogue-visible="isDialogueVisible"
    :dialogue-title="dialogueTitle"
    :locations="locations"
    :popup-title="popupTitle"
    :popup-button-title="popupButtonTitle"
    @close="() => isDialogueVisible = false"
    @backToList="backToZonesList"
    @clickedPopupButton="clickedPopupButton"
    @clickedClusteredItems="clickedClusteredItems"
    @clickedUnclusteredItem="clickedUnclusteredItem"
  >
    <ZonesTable
      slot="map-dialogue-content"
      class="zones-table"
      :item-ids="clusteredZoneIds"
    />
  </ItemsMap>
</template>

<script>
import ItemsMap from '../map/ItemsMap';
import ZonesTable from '../../components/organisms/zones/zones-table';
import { routeNames } from '../../constants';

export default {
  name: 'ZonesMap',
  components: {
    ZonesTable,
    ItemsMap,
  },
  data() {
    return {
      isDialogueVisible: false,
      clusteredZoneIds: [],
      unclusteredZone: null,
      popupTitle: null,
      popupButtonTitle: null,
      clickedOnClusteredItems: false,
    };
  },
  computed: {
    dialogueTitle() {
      return `${this.clusteredZoneIds.length} zones in cluster`;
    },
    locations() {
      return this.$store.state.zones.zones
        .filter((zone) => zone.locations?.items.length > 0)
        .map((zone) => ({
          latitude: zone.locations.items[0].latitude,
          longitude: zone.locations.items[0].longitude,
          properties: {
            id: zone.id,
            name: zone.name,
            timestamp: zone.timestamp,
          },
        }));
    },
  },
  methods: {
    clickedPopupButton() {
      if (this.clickedOnClusteredItems) {
        this.isDialogueVisible = true;
      } else {
        this.$router.push({
          name: routeNames.zones.detail,
          params: { id: this.unclusteredZone.id },
        }).catch(() => { });
      }
    },
    clickedClusteredItems(clusteredZones) {
      this.popupTitle = `${clusteredZones.length} zones in cluster`;
      this.popupButtonTitle = 'Show zones';
      this.clusteredZoneIds = clusteredZones.map((zone) => zone.id);
      this.clickedOnClusteredItems = true;
    },
    clickedUnclusteredItem(unclusteredZone) {
      const selectedZone = this.$store.state.zones.zones.find(
        (zone) => zone.id === unclusteredZone.id,
      );
      this.popupTitle = `${selectedZone.name}`;
      this.popupButtonTitle = 'Go to zone';
      this.unclusteredZone = selectedZone;
      this.clickedOnClusteredItems = false;
    },
    backToZonesList() {
      this.$router.push({ name: routeNames.zones.list });
    },
  },
};
</script>
<style lang="scss" scoped>
.zones-table {
  max-height: 300px;
}
</style>
