<template>
  <div class="home-container">
    <Loader :is-loading="isLoading" />
    <NoTeamMessage v-if="!isLoading" />
  </div>
</template>
<script>
import VueCookies from 'vue-cookies';
import Loader from '../components/atoms/loader/loader';
import NoTeamMessage from '../components/molecules/message/no-team-message';
import { cookieKeys, routeNames } from '../constants';

export default {
  components: {
    Loader,
    NoTeamMessage,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {
    if (!this.$route.params.teamId) {
      let teamId = null;
      // check whether a pervious team has been viewed (teamId in cookie)
      if (VueCookies.isKey(cookieKeys.teamId)) {
        teamId = VueCookies.get(cookieKeys.teamId);
        // check whether user has access to teamId (either in already loaded teams or in database)
        if (!this.$store.state.teams.teams.find((team) => team.id === teamId)) {
          const team = await this.$store.dispatch('teams/getTeam', { teamId, skipRedirect: true });
          if (!team) {
            // user does not have access
            teamId = null;
          }
        }
      }

      // if no teams...
      if (this.$store.state.teams.teams.length === 0) {
        // display no team holding screen
        this.isLoading = false;
      } else {
        // add default team id to URL
        await this.$router.replace({
          name: routeNames.teamHome,
          params: { teamId: teamId || this.$store.state.teams.teams[0].id },
        }).catch(() => {});
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>
