export function constructDate(date, showYearAlways = false, timeZone = null, useCsvFormat = false) {
  const now = new Date();
  const params = {
    day: '2-digit',
    month: useCsvFormat ? '2-digit' : 'short',
  };
  if (date.getYear() < now.getYear() || showYearAlways || useCsvFormat) {
    params.year = useCsvFormat ? 'numeric' : '2-digit';
  }
  if (timeZone) {
    params.timeZone = timeZone;
  }

  const dateString = date.toLocaleDateString(useCsvFormat ? 'en-GB' : navigator.language, params);

  if (useCsvFormat) {
    // yyyy-mm-dd
    return dateString.split('/').reverse().join('-');
  }

  return dateString;
}

export function constructTime(date, timeZone = null, showSeconds = false) {
  const params = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  if (timeZone) {
    params.timeZone = timeZone;
  }
  if (showSeconds) {
    params.second = '2-digit';
  }
  return date.toLocaleTimeString(navigator.language, params);
}

export function constructCsvDateTime(isoTimestamp, facilityTimeZone = null) {
  const dateTime = new Date(isoTimestamp);
  const datePart = constructDate(dateTime, true, facilityTimeZone, true);
  const timePart = constructTime(dateTime, facilityTimeZone, true);
  return `${datePart} ${timePart}`;
}
