<template>
  <component :is="layoutComponent">
    <Login>
      <router-view />
    </Login>
  </component>
</template>

<script>
import Login from './components/organisms/auth/login';
import Layout from './components/organisms/layout/layout';
import NoLayout from './components/organisms/layout/no-layout';
import { decodeBase64 } from './helpers/base64-helper';

const BRANCH_PARAMS = [
  '$web_only',
  '_branch_match_id',
  '_branch_referrer',
];

export default {
  name: 'App',

  components: {
    Layout,
    NoLayout,
    Login,
  },
  computed: {
    isMobileApp() {
      return this.$store.getters.isMobileApp;
    },
    hasSelectedTeam() {
      // must be logged in to select a team
      return this.$store.getters['teams/hasSelectedTeam'];
    },
    isSignedIn() {
      return this.$store.state.auth.signedIn;
    },
    tokenAuth() {
      return !!this.$route.meta.allowTokenAuth && !!this.$route.query.t;
    },
    layoutComponent() {
      if (!this.isSignedIn && !this.tokenAuth) {
        // for login screen, use default Layout
        return Layout;
      }

      return this.$route.meta.noLayout
        ? NoLayout
        : Layout;
    },
  },
  watch: {
    isMobileApp() {
      this.conditionallyStartMobile();
    },
    hasSelectedTeam() {
      this.conditionallyStartMobile();
    },
  },
  async created() {
    // Strip out any un-wanted branch params
    if (Object.keys(this.$route.query).some((param) => BRANCH_PARAMS.includes(param))) {
      const { query } = this.$route;
      const updatedQuery = {};

      Object.keys(query).forEach((key) => {
        if (!BRANCH_PARAMS.includes(key)) {
          updatedQuery[key] = query[key];
        }
      });

      this.$router.replace({
        ...this.$route,
        query: updatedQuery,
      });
    }

    this.$root.logger.entry('Web app loaded');
    this.conditionallyStartMobile();

    // App loaded as redirect from IDP auth
    const authCode = this.$route.query.code;
    if (authCode) {
      let clientId = null;
      try {
        clientId = JSON.parse(decodeBase64(this.$route.query.state)).clientId;
      } catch (error) {
        this.$root.logger.error('SSO Auth: error getting client ID', { error: error.message });
      }
      // redirect from URL?code=<auth_code>&state=<state>
      this.$router.replace({ query: null });
      this.$store.dispatch('auth/athenticateIdpResponse', { authCode, clientId });
    }
  },
  methods: {
    conditionallyStartMobile() {
      if (this.isMobileApp && this.hasSelectedTeam) {
        this.startMobileFunctionality();
      }
    },
    startMobileFunctionality() {
      if (!this.$root.isViewer) {
        // only start mobile gateway if user is not a viewer

        // start mobile gateway
        this.$store.dispatch('mobileApp/getDeviceUUID');

        // start T-Label discovery
        if (this.$root.featureToggles.includeTemperatureGetData(this.$store.state.mobileApp)) {
          this.$store.dispatch('mobileApp/tLabels/startDiscovery');
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import './styles/app.scss';
</style>
