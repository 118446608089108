import wrapper from '../analytics-wrapper';

export default {
  addedMember({ user, role }) {
    wrapper.track('Added member', {
      'New Member Name': `${user.givenName} ${user.familyName}`,
      'New Member Email': user.email,
      'New Member ID': user.id,
      'New Member Role': role,
    });
  },
  editedMember({ role, user }, oldRole) {
    wrapper.track('Edited member', {
      'New Member Role': role,
      'Old Member Role': oldRole,
      'Member ID': user.id,
    });
  },
  removedMember({ user, role }) {
    wrapper.track('Removed member', {
      'Member Name': `${user.givenName} ${user.familyName}`,
      'Member Email': user.email,
      'Member ID': user.id,
      'Member Role': role,
    });
  },
};
