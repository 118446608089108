<template>
  <ItemsMap
    :is-dialogue-visible="isDialogueVisible"
    :dialogue-title="dialogueTitle"
    :locations="locations"
    :popup-title="popupTitle"
    :popup-button-title="popupButtonTitle"
    @close="() => isDialogueVisible = false"
    @backToList="backToAssetsList"
    @clickedPopupButton="clickedPopupButton"
    @clickedClusteredItems="clickedClusteredItems"
    @clickedUnclusteredItem="clickedUnclusteredItem"
  >
    <AssetsTable
      slot="map-dialogue-content"
      class="assets-table"
      :item-ids="clusteredAssetIds"
    />
  </ItemsMap>
</template>

<script>
import ItemsMap from '../map/ItemsMap';
import AssetsTable from '../../components/organisms/assets/assets-table';
import { routeNames } from '../../constants';

export default {
  name: 'AssetsMap',
  components: {
    AssetsTable,
    ItemsMap,
  },
  data() {
    return {
      isDialogueVisible: false,
      clusteredAssetIds: [],
      unclusteredAsset: null,
      popupTitle: null,
      popupButtonTitle: null,
    };
  },
  computed: {
    dialogueTitle() {
      return `${this.clusteredAssetIds.length} assets in cluster`;
    },
    locations() {
      return this.$store.state.assets.assets
        .filter((asset) => asset.locations?.items.length > 0)
        .map((asset) => ({
          latitude: asset.locations.items[0].latitude,
          longitude: asset.locations.items[0].longitude,
          properties: {
            id: asset.id,
            name: asset.name,
            timestamp: asset.timestamp,
          },
        }));
    },
  },
  methods: {
    clickedPopupButton() {
      if (this.popupButtonTitle === 'Show assets') {
        this.isDialogueVisible = true;
      } else {
        this.$router.push({
          name: routeNames.assets.detail,
          params: { id: this.unclusteredAsset.id },
        }).catch(() => { });
      }
    },
    clickedClusteredItems(clusteredAssets) {
      this.popupTitle = `${clusteredAssets.length} assets in cluster`;
      this.popupButtonTitle = 'Show assets';
      this.clusteredAssetIds = clusteredAssets.map((asset) => asset.id);
    },
    clickedUnclusteredItem(unclusteredAsset) {
      const selectedAsset = this.$store.state.assets.assets.find(
        (asset) => asset.id === unclusteredAsset.id,
      );
      this.popupTitle = `${selectedAsset.name}`;
      this.popupButtonTitle = 'Go to asset';
      this.unclusteredAsset = selectedAsset;
    },
    backToAssetsList() {
      this.$router.push({ name: routeNames.assets.list });
    },
  },
};
</script>
<style lang="scss" scoped>
.assets-table {
  max-height: 300px;
}
</style>
