<template>
  <Table
    :key="height"
    :title="title"
    :with-title="withTitle"
    :headers="headers"
    :rows="rows"
    :height="height"
    :is-loading="isLoading"
    :is-selecting="isSelecting"
    :is-disabled="isDisabled"
    :first-column-width="firstColumnWidth"
    :no-items-text="noItemsText"
    row-is-clickable
    @clickRow="clickRow"
  >
    <slot
      slot="buttons"
      name="buttons"
    />
    <infinite-loading
      v-if="hasData"
      :distance="100"
      :identifier="infiniteId"
      @infinite="infiniteHandler"
    >
      <div slot="spinner" />
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
  </Table>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import Table from '../../atoms/table/table';

export default {
  components: {
    InfiniteLoading,
    Table,
  },
  props: {
    headers: { type: Array, required: true },
    rows: { type: Array, default: () => ([]) },
    firstColumnWidth: { type: String, default: null },
    getData: { type: Function, default: () => {} },
    hasMoreData: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    height: { type: Number, default: 500 },
    isSelecting: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    title: { type: String, default: '' },
    withTitle: { type: Boolean, default: true },
    noItemsText: { type: String, default: 'No matching results found' },
  },
  data() {
    return {
      infiniteId: +new Date(),
    };
  },
  computed: {
    hasData() {
      return this.rows.length > 0;
    },
  },
  methods: {
    async infiniteHandler($state) {
      await this.getData();
      $state.loaded();
      if (!this.hasMoreData) {
        $state.complete();
      }
    },
    clickRow(data) {
      this.$emit('clickRow', data);
    },
  },
};
</script>
