<template>
  <div class="asset-temperature-chart-conatiner">
    <LineChart
      :chartdata="chartData"
      :options="{
        scales: {
          xAxes: {
            type: 'time',
            time: {
              unit: 'day',
            }
          }
        },
        legend: {
          onClick: null
        },
        responsive: true,
        maintainAspectRatio: false
      }"
    />
  </div>
</template>
<script>
import LineChart from '../../atoms/charts/line-chart';
import variables from '../../../styles/_variables.scss';
import 'chartjs-adapter-date-fns';

export default {
  name: 'AssetTemperatureChart',
  components: {
    LineChart,
  },
  props: {
    asset: { type: Object, required: true },
  },
  computed: {
    temperatures() {
      return this.asset.temperatures?.items
        .map(({ timestamp, degreesCelcius }) => ({
          x: new Date(timestamp),
          y: degreesCelcius,
        }));
    },
    chartData() {
      return {
        labels: this.temperatures.map(({ x }) => x),
        datasets: [
          {
            label: this.asset.name,
            data: this.temperatures,
            borderColor: variables.warmGrey,
            backgroundColor: 'transparent',
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.asset-temperature-chart-conatiner {
  margin-bottom: 20px;
}
</style>
