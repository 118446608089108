import { query, mutate, subscribe } from './api-consumer';

export default {
  async getAssetByNfcId(nfcId, redirectOn403Error) {
    return query('getAsset', { nfcId }, redirectOn403Error);
  },
  async getAsset(assetId) {
    return query('getAsset', { id: assetId });
  },
  async getAssetLocations(nfcId, nextToken) {
    return query('getAssetLocations', { nfcId, limit: 200, nextToken });
  },
  async getAssetTemperatures(id, nextToken) {
    return query('getAssetTemperatures', { id, limit: 1000, nextToken });
  },
  async getAssets(
    organisationId,
    nextToken,
    searchTerm = '*',
    filters = null,
    sortOrder = null,
  ) {
    const filter = { quickFilters: ['IsNotSpace'] };
    if (filters !== null) {
      if (filters.selectedZoneId !== '') {
        filter.quickFilters.push('InSpaces');
        filter.spaceIds = filters.selectedZoneId;
      }
      if (filters.selectedFacilityId !== '') {
        filter.quickFilters.push('InFacilities');
        filter.facilityIds = filters.selectedFacilityId;
      }
      if (filters.selectedConsignmentId !== '') {
        filter.quickFilters.push('InConsignments');
        filter.consignmentIds = filters.selectedConsignmentId;
      }
    }
    return query('searchOrganisationAssets', {
      id: organisationId,
      searchTerm,
      nextToken,
      limit: 20,
      filter,
      sortOrder,
    });
  },
  async addAsset(organisationId, name, nfcId, location) {
    return mutate('addAssetToOrganisation', {
      organisationId,
      name,
      nfcId,
      location,
    });
  },
  async editAsset(assetId, name) {
    return mutate('editAsset', { id: assetId, name });
  },
  async removeAsset(organisationId, assetId) {
    return mutate('removeAssetFromOrganisation', { assetId, organisationId });
  },
  async addLabelToAsset(assetId, nfcId, location) {
    return mutate('addLabelToAsset', {
      assetId,
      nfcId,
      location,
      replaceExistingLabel: true,
    });
  },
  async addLabelTemperatureReadings(nfcId, readings) {
    return mutate('addLabelTemperatureReadings', {
      nfcId,
      readings,
    });
  },
  async removeLabelFromAsset(assetId, nfcId) {
    return mutate('removeLabelFromAsset', {
      assetId,
      nfcId,
    });
  },
  subscribeToAssetUpdates(assetId, callback, errorCallback) {
    return subscribe('subscribeToAssetInSpaces', { assetId }, callback, errorCallback);
  },
  async getAssetSpaceHistory(assetId, nextToken) {
    return query('getAssetSpaceHistory', { id: assetId, limit: 100, nextToken });
  },
  async getAssetFacilityHistory(assetId, nextToken) {
    return query('getAssetFacilityHistory', { id: assetId, limit: 100, nextToken });
  },
};
