<template>
  <Button
    text="Sign out"
    :is-loading="isLoading"
    is-text
    :on-click="logOut"
  />
</template>
<script>
import Button from '../../atoms/button/button';

export default {
  name: 'LogOut',
  components: { Button },
  computed: {
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
    signedIn() {
      return this.$store.state.auth.signedIn;
    },
  },
  methods: {
    async logOut() {
      const { user } = this.$store.state.user;
      await this.$store.dispatch('auth/logOut');
      if (user) {
        this.$root.trackEvent.signedOut(user);
      }
    },
  },
};
</script>
