<template>
  <div
    ref="infiniteTableContainer"
    class="items-table-container"
    :class="{
      'larger-bottom-gap': largerGapBottom,
      'smaller-bottom-gap': smallerGapBottom
    }"
  >
    <InfiniteTable
      v-if="tableIsVisible"
      :title="title"
      :with-title="withTitle"
      :headers="headers"
      :rows="rows"
      :first-column-width="firstColumnWidth"
      :get-data="getData"
      :has-more-data="hasMoreData"
      :is-loading="isLoading"
      :height="wrapperHeight"
      :is-selecting="isSelecting"
      :is-disabled="isDisabled"
      :no-items-text="noItemsText"
      @clickRow="clickRow"
    >
      <slot
        slot="buttons"
        name="buttons"
      />
    </InfiniteTable>
    <resize-observer @notify="setWrapperHeight" />
  </div>
</template>
<script>
import InfiniteTable from '../../molecules/table/infinite-table';

export default {
  components: {
    InfiniteTable,
  },
  props: {
    headers: { type: Array, required: true },
    rows: { type: Array, default: () => ([]) },
    firstColumnWidth: { type: String, default: null },
    getData: { type: Function, required: true },
    hasMoreData: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isSelecting: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    largerGapBottom: { type: Boolean, default: false },
    smallerGapBottom: { type: Boolean, default: false },
    title: { type: String, default: '' },
    withTitle: { type: Boolean, default: true },
    noItemsText: { type: String, default: 'No matching results found' },
  },
  data() {
    return {
      wrapperHeight: 0,
      tableIsVisible: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setWrapperHeight();
      this.tableIsVisible = true;
    });
  },
  methods: {
    setWrapperHeight() {
      this.wrapperHeight = this.$refs.infiniteTableContainer.clientHeight;
    },
    clickRow(row) {
      this.$emit('clickRow', row);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
.items-table-container {
  position: relative;
  width: 100%;
  height: calc(100% - 70px);
  @include mq($from: md) {
    height: calc(100% - 90px);
  }
  &.larger-bottom-gap {
    height: calc(100% - 79px);
    @include mq($from: md) {
      height: calc(100% - 105px);
    }
  }
  &.smaller-bottom-gap {
    height: calc(100% - 30px);
    @include mq($from: md) {
      height: calc(100% - 40px);
    }
  }
}
</style>
