<template>
  <Dialogue
    :is-visible="isVisible"
    :is-fullscreen="$vuetify.breakpoint.xs"
  >
    <div
      v-if="isVisible"
      class="scan-success-dialogue-content"
    >
      <Loader :is-loading="isLoading" />
      <ErrorMessage :error-message="errorMessage" />
      <fragment v-if="!isLoading && !hasError">
        <p class="is-bold text-center">
          You've scanned a {{ isGateway ? 'Gateway' : 'Label' }}
        </p>
        <p class="text-center">
          {{ message }}
        </p>
        <ButtonsContainer
          v-if="isGateway"
          vertically-stack-on-x-s
        >
          <Button
            v-if="!zoneExists"
            text="New zone"
            :on-click="newZoneClick"
          />
          <Button
            v-if="!zoneExists && hasZones"
            text="Existing zone"
            :on-click="existingZoneClick"
          />
          <Button
            v-if="zoneExists && userHasAccess"
            text="View zone"
            :on-click="viewZoneClick"
          />
        </ButtonsContainer>
        <ButtonsContainer v-else>
          <Button
            v-if="!assetExists"
            text="New asset"
            :on-click="newAssetClick"
          />
          <Button
            v-if="!assetExists && hasAssets"
            text="Existing asset"
            :on-click="existingAssetClick"
          />
          <Button
            v-if="assetExists && userHasAccess"
            text="View asset"
            :on-click="viewAssetClick"
          />
        </ButtonsContainer>
      </fragment>
      <div class="cancel-container">
        <Button
          text="Cancel"
          :is-text="!cancelButtonIsHighlighted"
          :on-click="cancelClick"
        />
      </div>
    </div>
  </dialogue>
</template>
<script>
import Dialogue from '../../atoms/dialogue/dialogue';
import Button from '../../atoms/button/button';
import ErrorMessage from '../../atoms/message/error-message';
import Loader from '../../atoms/loader/loader';
import ButtonsContainer from '../layout/buttons-container';
import linkLabelHelper from '../../../mixins/link-label-helper';
import linkGatewayHelper from '../../../mixins/link-gateway-helper';
import { routeNames, linkTrackerStatus } from '../../../constants';

export default {
  name: 'ScanSuccessDialogue',
  components: {
    Dialogue,
    Button,
    ButtonsContainer,
    ErrorMessage,
    Loader,
  },
  mixins: [linkLabelHelper, linkGatewayHelper],
  data() {
    return {
      errorMessage: '',
      assetExists: false,
      zoneExists: false,
      userHasAccess: false,
      assetId: null,
      zoneId: null,
      teamId: null,
    };
  },
  computed: {
    isGateway() {
      return this.gateway !== null;
    },
    isVisible() {
      return this.$route.name !== routeNames.assets.detail
        && this.$route.name !== routeNames.zones.detail
        && this.hasLabelOrGateway;
    },
    hasLabelOrGateway() {
      return this.label !== null || this.gateway !== null;
    },
    label() {
      return this.$store.state.mobileApp.label;
    },
    hasAssets() {
      return this.$store.getters['assets/hasAssets'];
    },
    gateway() {
      return this.$store.state.mobileApp.gateway;
    },
    hasZones() {
      return this.$store.getters['zones/hasZones'];
    },
    isLoading() {
      if (!this.isGateway) {
        return this.$store.state.assets.isLoadingAsset
        || this.$store.state.assets.isLoadingAssets;
      }

      return this.$store.state.zones.isLoadingZone
       || this.$store.state.zones.isLoadingZones;
    },
    hasError() {
      return this.errorMessage !== '';
    },
    message() {
      if (!this.isGateway) {
        if (!this.assetExists) {
          return 'Link label to...';
        }

        return !this.userHasAccess
          ? 'This label is linked to an asset you do not have access to'
          : 'This label is already linked to an asset';
      }
      if (!this.zoneExists) {
        return 'Link gateway to...';
      }

      return !this.userHasAccess
        ? 'This gateway is linked to a zone you do not have access to'
        : 'This gateway is already linked to a zone';
    },
    cancelButtonIsHighlighted() {
      if (this.hasError) return true;
      if ((this.assetExists || this.zoneExists) && !this.userHasAccess) return true;
      return false;
    },
  },
  watch: {
    async isVisible() {
      if (this.isVisible) {
        if (!this.isGateway) {
          const labelStatus = await this.checkLabel(this.label);
          switch (labelStatus.status) {
            case linkTrackerStatus.availableForLinking:
              this.assetExists = false;
              break;
            case linkTrackerStatus.noAccess:
              this.assetExists = true;
              this.userHasAccess = false;
              break;
            case linkTrackerStatus.alreadyLinked:
              this.assetId = labelStatus.assetId;
              this.teamId = labelStatus.teamId;
              this.assetExists = true;
              this.userHasAccess = true;
              break;
            default:
              this.errorMessage = 'Something is wrong with this label';
          }
        } else {
          const gatewayStatus = await this.checkGateway(this.gateway);
          switch (gatewayStatus.status) {
            case linkTrackerStatus.availableForLinking:
              this.zoneExists = false;
              break;
            case linkTrackerStatus.noAccess:
              this.zoneExists = true;
              this.userHasAccess = false;
              break;
            case linkTrackerStatus.alreadyLinked:
              this.zoneId = gatewayStatus.zoneId;
              this.teamId = gatewayStatus.organisationId;
              this.zoneExists = true;
              this.userHasAccess = true;
              break;
            default:
              this.errorMessage = 'Something is wrong with this gateway';
          }
        }
      } else {
        // need to delay the reset until the dialogue has had time to be hidden
        // otherwise we get a flash of the un-error state dialogue if we have error
        // and user clicks cancel
        setTimeout(this.resetDialogue, 500);
      }
    },
  },
  methods: {
    goToRoute(routeName, params) {
      this.$router.push({
        name: routeName,
        params,
      }).catch(() => {
        // duplicate navigation to same asset - do nothing
      });
      this.resetLableOrGateway();
    },
    newAssetClick() {
      this.goToRoute(routeNames.assets.add, {
        label: { ...this.$store.state.mobileApp.label },
      });
    },
    existingAssetClick() {
      this.goToRoute(routeNames.assets.selectAsset, {
        label: { ...this.$store.state.mobileApp.label },
      });
    },
    viewAssetClick() {
      this.goToRoute(routeNames.assets.detail, {
        id: this.assetId,
        teamId: this.teamId,
      });
    },
    newZoneClick() {
      this.goToRoute(routeNames.zones.add, {
        gateway: { ...this.$store.state.mobileApp.gateway },
      });
    },
    existingZoneClick() {
      this.goToRoute(routeNames.zones.selectZone, {
        gateway: { ...this.$store.state.mobileApp.gateway },
      });
    },
    viewZoneClick() {
      this.goToRoute(routeNames.zones.detail, {
        id: this.zoneId,
        teamId: this.teamId,
      });
    },
    cancelClick() {
      this.resetLableOrGateway();
    },
    resetDialogue() {
      this.errorMessage = '';
      this.assetExists = false;
      this.zoneExists = false;
      this.userHasAccess = false;
      this.assetId = null;
      this.zoneId = null;
      this.teamId = null;
    },
    resetLableOrGateway() {
      if (this.isGateway) {
        this.$store.commit('mobileApp/RESET_GATEWAY');
      } else {
        this.$store.commit('mobileApp/RESET_LABEL');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';
.scan-success-dialogue-content {
  .cancel-container {
    text-align: center;
    margin-top: 30px;
    .button {
      padding: 0 10px !important;
    }
  }
}
</style>
