import { mutate } from './api-consumer';

export default {
  async startMobileGateway(gatewayData) {
    return mutate('startMobileGateway', gatewayData);
  },
  async mobileGatewayEnterEvent(enterEventData) {
    return mutate('mobileGatewayEnterEvent', enterEventData);
  },
  async mobileGatewayEnterEvents(enterEventsData) {
    return mutate('mobileGatewayEnterEvents', enterEventsData);
  },
  async mobileGatewayExitEvent(exitEventData) {
    return mutate('mobileGatewayExitEvent', exitEventData);
  },
  async mobileGatewayExitEvents(exitEventsData) {
    return mutate('mobileGatewayExitEvents', exitEventsData);
  },
  async mobileGatewaySnapshotEvent(snapshotEventData) {
    return mutate('mobileGatewaySnapshotEvent', snapshotEventData);
  },
  async mobileGatewayLocationEvent(locationsData) {
    return mutate('mobileGatewayLocationEvent', locationsData);
  },
  async mobileGatewayLabelTemperatureReadings(readingsData) {
    return mutate('mobileGatewayLabelTemperatureReadings', readingsData);
  },
};
