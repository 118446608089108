/* eslint-disable no-underscore-dangle */
import { API } from 'aws-amplify';
import { v1 as uuidv1 } from 'uuid';
import awsConfig from '../aws-exports';
import { logger } from '../mixins/logging/logger';
import {
  isBasicAppSync, queries, mutations, subscriptions,
} from './graphql-endpoints';
import router from '../router';
import { routeNames } from '../constants';

const requestIdPrefix = 'Reelables_Web_App_';

const callApi = async (api, requestName, payload, redirectOn403Error = true) => {
  try {
    // eslint-disable-next-line no-param-reassign
    payload.variables.requestId = `${requestIdPrefix}${uuidv1()}`;
    const response = await api.graphql(payload);
    return response.data[requestName];
  } catch (error) {
    if (error.errors && error.errors.length > 0 && error.errors[0].message) {
      if (error.errors[0].errorInfo === 403 && redirectOn403Error) {
        router.replace({ name: routeNames.notFound }).catch(() => {});
      }
      logger.error('Error requesting api', { requestName, requestPayload: payload, error });
      throw new Error(error.errors[0].message);
    }
    logger.error('Unknown error requesting api', { requestName, requestPayload: payload, error });
    throw new Error('Unknown error');
  }
};

let basicApi = null;
const getBasicApi = () => {
  if (basicApi === null) {
    basicApi = API;
    basicApi._graphqlApi._options.aws_appsync_graphqlEndpoint = awsConfig
      .aws_appsync_graphqlEndpoint;
    basicApi._options.aws_appsync_graphqlEndpoint = awsConfig.aws_appsync_graphqlEndpoint;
  }

  return basicApi;
};

const makeRequest = (
  requestName,
  query,
  variables,
  subscriptionCallback,
  errorCallback,
  redirectOn403Error,
) => {
  if (isBasicAppSync(requestName)) {
    const api = getBasicApi();
    if (typeof subscriptionCallback === 'function') {
      return api.graphql({ query, variables }).subscribe({
        next: (data) => subscriptionCallback(data.value.data[requestName]),
        error: errorCallback,
      });
    }
    return callApi(api, requestName, { query, variables }, redirectOn403Error);
  }

  return Promise.reject(new Error(`Unknown query, mutation or subscription: ${requestName}`));
};

export const query = async (
  queryName,
  variables = {},
  redirectOn403Error = null,
) => makeRequest(queryName, queries[queryName], variables, null, null, redirectOn403Error);

export const mutate = async (
  mutationName,
  variables = {},
) => makeRequest(mutationName, mutations[mutationName], variables);

export const subscribe = (
  subscriptionName,
  variables = {},
  callback = null,
  errorCallback = null,
) => makeRequest(
  subscriptionName,
  subscriptions[subscriptionName],

  variables,

  callback,

  errorCallback,
);
