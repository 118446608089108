<template>
  <v-timeline
    class="timeline-container"
  >
    <HeaderRow
      v-if="displayHeader"
      :left-header="leftHeader"
      :right-header="rightHeader"
    />
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.color"
      small
      right
    >
      <v-row
        justify="center"
      >
        <v-col>
          <strong>{{ item.title }}</strong>
          <div class="text-caption">
            {{ item.text }}
          </div>
        </v-col>
      </v-row>
      <template #opposite>
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="5"
            class="text-center"
          >
            <strong>{{ item.startDate }}</strong>
            <div>{{ item.startTime }}</div>
            <div v-if="item.timeZone">
              {{ item.timeZone }}
            </div>
          </v-col>
          <v-col
            class="text-center"
            cols="1"
          >
            <span v-if="displayRightSide(item) && item.endDate">-</span>
          </v-col>
          <v-col
            v-if="displayRightSide(item)"
            cols="5"
            class="text-center"
          >
            <strong>{{ item.endDate }}</strong>
            <div>{{ item.endTime }}</div>
            <div>&nbsp;</div>
          </v-col>
        </v-row>
      </template>
      <template
        v-if="i>0"
        #icon
      >
        <v-icon small>
          mdi-arrow-up
        </v-icon>
      </template>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
import HeaderRow from './header-row';

export default {
  name: 'Timeline',
  components: { HeaderRow },
  props: {
    items: { type: Array, required: true },
    displayHeader: { type: Boolean, default: false },
    leftHeader: { type: String, default: 'Arrived' },
    rightHeader: { type: String, default: 'Departed' },
    hideRightIfSame: { type: Boolean, default: false },
  },
  methods: {
    displayRightSide(item) {
      return !this.hideRightIfSame
        || (item.endDate && (item.endDate !== item.startDate || item.endTime !== item.startTime));
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
.timeline-container .v-timeline-item__inner-dot .v-icon {
  position: absolute;
  top: -16px;
  color: $ground;
}
.v-timeline::before {
  display: none !important;
}
</style>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
.timeline-container {
  font-size: 0.75rem;
  padding-right: 5px;
  padding-left: 5px;
  @include mq($from: md) {
    font-size: 0.875rem;
  }
}
</style>
