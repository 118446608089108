<template>
  <div
    class="buttons-container"
    :class="{
      'display-on-empty-state': displayOnEmptyState,
      'has-small-gap': hasSmallGap
    }"
  >
    <slot />
  </div>
</template>
<script>

export default {
  name: 'ButtonsContainer',
  props: {
    displayOnEmptyState: { type: Boolean, default: false },
    hasSmallGap: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';

.buttons-container {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: space-between;
  @include mq($from: md) {
    justify-content: center;
  }
  > * {
    flex-grow: 1;
    @include mq($from: md) {
      flex-grow: 0;
    }
    &:not(:first-child) {
      margin-left: 7px;
    }
    &:not(:last-child) {
      margin-right: 7px;
    }
  }
  &.has-small-gap {
    > * {
      &:not(:first-child) {
        margin-left: 3px;
      }
      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }
}
.display-on-empty-state {
  @include mq($from: md) {
    position: absolute;
    right: 33%;
    transform: translateX(50%);
    bottom: 80px;
    z-index: 2;
  }
}
</style>
