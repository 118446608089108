import Dashboards from '../views/dashboards/Dashboards';
import Dashboard from '../views/dashboards/Dashboard';
import { routeNames } from '../constants';

export default [
  {
    path: 'dashboards',
    name: routeNames.dashboards.index,
    component: Dashboards,
    meta: {
      noTopNav: true,
    },
    children: [
      {
        path: ':id',
        name: routeNames.dashboards.detail,
        component: Dashboard,
        props: true,
        meta: {
          noTopNav: true,
        },
      },
    ],
  },
];
