import wrapper from '../analytics-wrapper';

export default {
  linkedGateway({ id, name }, gatewayId, addedNewZone) {
    wrapper.track('Linked gateway', {
      'Gateway ID': gatewayId,
      'Zone ID': id,
      'Zone Name': name,
      'Was New Zone Added': addedNewZone,
    });
  },
  removedGateway({ id, name }, gatewayId) {
    wrapper.track('Removed gateway', {
      'Gateway ID': gatewayId,
      'Zone ID': id,
      'Zone Name': name,
    });
  },
};
