import { mutate, query } from './api-consumer';

export default {
  async getNbIotAsset(token, assetId, bleId) {
    return query('getNbIotAsset', { token, assetId, bleId });
  },
  async activateNbIotLabel(token, name, bleId, location) {
    return mutate('activateNbIotLabel', {
      token, name, bleId, location,
    });
  },
};
