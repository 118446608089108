import wrapper from '../analytics-wrapper';

export default {
  addedAsset({ name, id, assetType }) {
    wrapper.track('Added asset', {
      'Asset Name': name,
      'Asset ID': id,
      'Asset Type': assetType?.name,
    });
  },
  editedAsset({ name, id }, oldName) {
    wrapper.track('Edited asset', {
      'New Asset Name': name,
      'Old Asset Name': oldName,
      'Asset ID': id,
    });
  },
  removedAsset({
    name, id, assetType, bleId, inSpaces,
  }) {
    wrapper.track('Removed asset', {
      'Asset Name': name,
      'Asset ID': id,
      'Asset Type': assetType?.name,
      'Had Linked Label': typeof bleId !== 'undefined' && bleId !== null,
      'Was In Zone': inSpaces && inSpaces.length > 0
        ? inSpaces.map(({ spaceName }) => spaceName).join(' and ')
        : 'Was not in a zone',
    });
  },
  searchedAssets(searchQuery, resultsCount) {
    wrapper.track('Searched assets', {
      'Search Query': searchQuery,
      'Results Count': resultsCount,
    });
  },
  clearedAssetsSearch(searchQuery, resultsCount) {
    wrapper.track('Cleared assets search', {
      'Search Query': searchQuery,
      'Results Count': resultsCount,
    });
  },
  appliedFilters(filtersApplied, resultsCount) {
    wrapper.track('Applied filters', {
      'Filters Applied': filtersApplied,
      'Results Count': resultsCount,
    });
  },
  clearedFilters(filtersCleared, resultsCount) {
    wrapper.track('Cleared filters', {
      'Filters Cleared': filtersCleared,
      'Results Count': resultsCount,
    });
  },
};
