<template>
  <div class="asset-temperature-zones-chart-container">
    <Loader
      :is-loading="isLoading"
      with-padding-top
    />
    <ErrorMessage :error-message="errorMessage" />
    <TemperatureChart
      v-if="!isLoading && !hasError && hasData"
      :asset-name="assetName"
      :ble-id="bleId"
      :temperature-data="temperatureData"
      :asset-space-data="spaceData"
      @openFullScreen="fullScreen = true"
    />
    <div v-if="!isLoading && !hasError && !hasData">
      No data
    </div>
    <v-dialog
      v-model="fullScreen"
      fullscreen
    >
      <v-card>
        <v-card-title class="no-padding">
          <v-card-text class="no-padding">
            <TemperatureChart
              v-if="!isLoading && !hasError && hasData"
              :asset-name="assetName"
              :ble-id="bleId"
              :temperature-data="temperatureData"
              :asset-space-data="spaceData"
              :is-full-screen="true"
              :full-screen-window-height="fullScreenWindowHeight"
              @closeFullScreen="fullScreen = false"
            />
            <div v-if="!isLoading && !hasError && !hasData">
              No data
            </div>
          </v-card-text>
        </v-card-title>
      </v-card>
      <resize-observer @notify="setFullScreenHeight" />
    </v-dialog>
  </div>
</template>
<script>
import TemperatureChart from '../../atoms/charts/temperature-chart';
import Loader from '../../atoms/loader/loader';
import ErrorMessage from '../../atoms/message/error-message';

export default {
  components: {
    TemperatureChart,
    Loader,
    ErrorMessage,
  },
  props: {
    assetId: { type: String, required: true },
    isAssetDataLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      fullScreen: false,
      fullScreenWindowHeight: 0,
      gettingData: false,
    };
  },
  computed: {
    asset() {
      return this.$store.state.assets.assets.find(({ id }) => id === this.assetId);
    },
    assetName() { return (this.asset || {}).name; },
    bleId() { return (this.asset || {}).bleId; },

    assetInSpaces() {
      return this.$store.state.assets.assetInSpaces.assetsData
        .find((d) => d.assetId === this.assetId);
    },
    spaceData() { return (this.assetInSpaces || {}).items || []; },

    temperatureData() {
      return ((this.asset || {}).temperatures || {}).items || [];
    },
    hasData() { return this.temperatureData.length > 0; },

    errorMessage() {
      return this.$store.state.assets.assetInSpaces.errorMessage
      || this.$store.state.assets.errorMessage;
    },
    hasError() { return !!this.errorMessage; },
  },
  watch: {
    async isAssetDataLoading() {
      console.log('AssetTemperatureZonesChart watch, this.isAssetDataLoading:', this.isAssetDataLoading);
      if (!this.isAssetDataLoading) {
        await this.getData();
      }
    },
    fullScreen(val) {
      if (val) {
        this.fullScreenWindowHeight = window.innerHeight;
      }
    },
  },
  async created() {
    console.log('AssetTemperatureZonesChart created, this.isAssetDataLoading:', this.isAssetDataLoading);
    if (!this.isAssetDataLoading) {
      await this.getData();
    }
  },
  methods: {
    setFullScreenHeight() {
      this.fullScreenWindowHeight = window.innerHeight;
    },
    async getData() {
      // only get data once
      if (!this.gettingData) {
        this.gettingData = true;
        await Promise.all([
          this.getAllAssetTemperatureData(),
          this.getAllAssetSpaceData(),
        ]);

        this.isLoading = false;
      }
    },
    async getAllAssetTemperatureData() {
      await this.$store.dispatch('assets/getAllAssetTemperatures', this.assetId);
    },
    async getAllAssetSpaceData() {
      await this.$store.dispatch('assets/assetInSpaces/getAllAssetInSpaces', this.assetId);
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-temperature-zones-chart-container {
  height: calc(100% - 10px);
}
.no-padding {
  padding: 0 !important;
}
</style>
