<template>
  <fragment>
    <div
      v-if="!renderSlot && !$store.state.auth.isGetUserLoading && !signedIn"
      class="login-container"
    >
      <div
        v-if="!(isSso && !hasError) && !shouldHideImage"
        class="image-container"
      >
        <img
          :src="loginImage"
          :height="loginImageHeight"
          alt="Login image"
        >
      </div>
      <Loader
        v-if="isSso && !hasError"
        is-loading
      />
      <ForgotPasswordForm
        v-else-if="isForgotPasswordVisible"
        @cancel="isForgotPasswordVisible = false"
      />
      <SignUpForm
        v-else-if="isSignUpVisible"
        @cancel="isSignUpVisible = false"
      />
      <LoginForm
        v-else
        @signUp="signUpClick"
        @forgotPassword="forgotPasswordClick"
      />
    </div>
    <Loader
      v-else-if="!renderSlot"
      :is-loading="!hasError"
    />
    <ErrorMessage
      v-if="signedIn"
      :error-message="errorMessage"
    />
    <slot v-if="renderSlot" />
  </fragment>
</template>
<script>
import { Hub } from 'aws-amplify';
import LoginImage from '../../../assets/images/people-and-laptop.svg';
import LoginImageSmall from '../../../assets/images/people-and-laptop_small.svg';
import LoginForm from '../../molecules/auth/login-form';
import SignUpForm from '../../molecules/auth/sign-up-form';
import ForgotPasswordForm from '../../molecules/auth/forgot-password-form';
import ErrorMessage from '../../atoms/message/error-message';
import Loader from '../../atoms/loader/loader';

export default {
  name: 'Login',
  components: {
    LoginForm,
    SignUpForm,
    ForgotPasswordForm,
    ErrorMessage,
    Loader,
  },
  data() {
    return {
      isSignUpVisible: false,
      isForgotPasswordVisible: false,
      renderSlot: false,
    };
  },
  computed: {
    loginImage() {
      return this.$vuetify.breakpoint.mdAndDown
        ? LoginImageSmall
        : LoginImage;
    },
    loginImageHeight() {
      if (this.isSignUpVisible) {
        return this.$vuetify.breakpoint.smAndDown ? 120 : 160;
      }
      if (this.$vuetify.breakpoint.xs) {
        return 80;
      }
      return this.$vuetify.breakpoint.mdAndDown ? 120 : 160;
    },
    shouldHideImage() {
      return (this.$vuetify.breakpoint.xs && this.isSignUpVisible) || this.isForgotPasswordVisible;
    },
    signedIn() {
      return this.$store.state.auth.signedIn;
    },
    errorMessage() {
      if (this.$store.state.auth.errorMessage !== '') {
        return this.$store.state.auth.errorMessage;
      }

      if (this.$store.state.user.errorMessage !== '') {
        return this.$store.state.user.errorMessage;
      }

      return this.$store.state.teams.getTeamsErrorMessage;
    },
    hasUserError() {
      return this.$store.state.user.errorMessage !== '';
    },
    hasError() {
      return this.errorMessage !== '';
    },
    tokenAuth() {
      return !!this.$route.meta.allowTokenAuth && !!this.$route.query.t;
    },
    isSso() { return this.$store.state.auth.isSso; },
  },
  watch: {
    async signedIn() {
      this.onSignedIn();
    },
  },
  created() {
    if (this.tokenAuth) {
      this.renderSlot = true;
    } else {
      this.$store.dispatch('auth/getUser');

      // listen to events from Amplify...
      Hub.listen('auth', ({ payload: { event, data } }) => {
        // eslint-disable-next-line default-case
        switch (event) {
          case 'signIn':
          case 'cognitoHostedUI':
            this.$store.dispatch('auth/_login', data);
            break;
          case 'signIn_failure':
          case 'cognitoHostedUI_failure':
            this.$store.dispatch('auth/logOut');
            // console.log('Error', data);
            break;
        }
      });

      this.onSignedIn();
    }
  },
  methods: {
    signUpClick() {
      this.$store.commit('auth/RESET_SIGN_UP_STEP');
      this.isSignUpVisible = true;
    },
    forgotPasswordClick() {
      this.$store.commit('auth/RESET_PASSWORD_RESET_STEP');
      this.isForgotPasswordVisible = true;
    },
    async onSignedIn() {
      if (this.signedIn) {
        this.$root.logger.info('User is logged in');
        // scroll to top once signed in
        window.scroll(0, 0);

        if (this.$store.getters.isMobileApp) {
          this.$store.dispatch('mobileApp/onLogin');
        }

        // getCurrentUser
        await this.$store.dispatch('user/getUser');

        // user could have signed out while getUser is called
        if (this.signedIn && !this.hasUserError) {
          this.$root.trackEvent.identifyUser(this.$store.state.user.user);
          await this.$store.dispatch('teams/getTeams');

          if (this.signedIn) this.renderSlot = true;
        }
      } else {
        this.renderSlot = false;
        if (this.$store.getters.isMobileApp) {
          this.$store.dispatch('mobileApp/onLogout');
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
.login-container {
  margin-top: -20px;
  @include mq($from: md) {
    margin-top: -60px;
  }
  @include mq($from: lg) {
    margin-top: 205px;
  }
}
.image-container {
  text-align: center;

  @include mq($from: md) {
    margin: 30px auto;
  }
}
</style>
