<template>
  <div class="dashboard-container">
    <Loader :is-loading="isLoading" />
    <h3 v-if="!hasError">
      {{ title }}
    </h3>
    <div
      v-if="!isLoading && hasError"
      class="error-container text-center"
    >
      <ErrorMessage
        v-if="!isLoading && hasError"
        error-message="This dashboard cannot be found"
      />
      <Button
        text="Return home"
        :on-click="goHome"
      />
    </div>
    <iframe
      v-if="dashboardIsValid"
      :title="title"
      :src="dashboard.url"
    />
    <div class="white-block" />
  </div>
</template>
<script>
import Loader from '../../components/atoms/loader/loader';
import ErrorMessage from '../../components/atoms/message/error-message';
import Button from '../../components/atoms/button/button';
import { routeNames } from '../../constants';

export default {
  name: 'Dashboard',
  components: {
    Loader,
    ErrorMessage,
    Button,
  },
  props: {
    id: { type: String, required: true },
  },
  computed: {
    isLoading() {
      return this.$store.state.dashboards.isLoadingDashboard;
    },
    dashboard() {
      return this.$store.state.dashboards.dashboards.find((d) => d.id === this.id);
    },
    title() {
      return this.dashboard?.name || '';
    },
    hasError() {
      return this.$store.state.dashboards.errorMessage !== ''
        || !this.dashboardIsValid;
    },
    dashboardIsValid() {
      return this.dashboard && this.dashboard.url;
    },
  },
  created() {
    this.getDashboard();
  },
  methods: {
    getDashboard() {
      if (!this.dashboardIsValid) {
        this.$store.dispatch('dashboards/getDashboard', this.id);
      }
    },
    goHome() {
      this.$router.replace({ name: routeNames.home });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';

.dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  h3 {
    margin-bottom: 10px;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  .error-container {
    > * {
      margin: 10px 0;
    }
  }
  .white-block {
    background: $white-2;
    position: absolute;
    bottom: 0;
    height: 46px;
    width: calc( 100% - 15px );
    left: 0;
    @include mq($from: 1032px) {
      height: 66px;
      // width: 100%;
    }
  }
}
</style>
