import { query, mutate } from './api-consumer';

export default {
  async getZoneByGatewayId(gatewayId, redirectOn403Error) {
    return query('getAsset', { gatewayId }, redirectOn403Error);
  },
  async getZone(zoneId) {
    return query('getAsset', { id: zoneId });
  },
  async getZones(organisationId, nextToken, searchTerm = '*') {
    return query('searchOrganisationAssets', {
      id: organisationId,
      searchTerm,
      nextToken,
      limit: 20,
      filter: {
        quickFilters: ['IsSpace'],
      },
    });
  },
  async addZone(organisationId, name, gatewayId, location) {
    return mutate('addAssetToOrganisation', {
      organisationId,
      name,
      isSpace: true,
      gatewayId,
      location,
    });
  },
  async editZone(zoneId, name) {
    return mutate('editAsset', { id: zoneId, name });
  },
  async removeZone(organisationId, assetId) {
    return mutate('removeAssetFromOrganisation', { assetId, organisationId });
  },
  async addGatewayToZone(zoneId, gatewayId, location) {
    return mutate('addGatewayToAsset', {
      assetId: zoneId,
      gatewayId,
      location,
      replaceExistingGateway: true,
    });
  },
  async removeGatewayFromZone(zoneId, gatewayId) {
    return mutate('removeGatewayFromAsset', {
      assetId: zoneId,
      gatewayId,
    });
  },

  async startGatewayTest(deviceUuid, gatewayId) {
    return mutate('startGatewayTest', { deviceUuid, gatewayId });
  },

  async getGatewayTest(testId) {
    return query('getGatewayTest', { testId });
  },
  async getGatewayTests(gatewayId, nextToken, limit = 20) {
    return query('getGatewayTests', {
      gatewayId,
      nextToken,
      limit,
    });
  },
};
