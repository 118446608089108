<template>
  <div class="message">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AuthMessage',
};
</script>

<style lang="scss" scoped>
  .message {
    font-size: 14px;
    line-height: 17px;
    margin: 20px 0 30px;
  }
</style>
