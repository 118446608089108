<template>
  <VerticalContainer>
    <Table
      v-if="!isLoading"
      :headers="headers"
      first-column-width="35%"
      :rows="rows"
      :is-loading="isLoading"
      :is-editing="true"
      @onChange="onChange"
    />
    <Loader
      v-else
      :is-loading="true"
    />
    <ErrorMessage :error-message="errorMessage" />
    <template #buttons>
      <Button
        :is-loading="isLoading"
        text="Save"
        :on-click="save"
        :disabled="isLoading"
      />
      <Button
        text="Close"
        :on-click="close"
        :disabled="isLoading"
      />
    </template>
  </VerticalContainer>
</template>

<script>
import { routeNames } from '../../constants';
import Table from '../../components/atoms/table/table';
import ErrorMessage from '../../components/atoms/message/error-message';
import Loader from '../../components/atoms/loader/loader';
import Button from '../../components/atoms/button/button';
import dateTimeHelper from '../../mixins/date-time-helper';
import updateDateTimes from '../../mixins/update-date-times';
import rules from '../../mixins/input-validation-rules';
import VerticalContainer from '../../components/organisms/layout/vertical-container';

export default {
  name: 'TeamDetail',
  components: {
    Table,
    Loader,
    Button,
    ErrorMessage,
    VerticalContainer,
  },
  mixins: [dateTimeHelper, updateDateTimes, rules],
  data() {
    return {
      newName: '',
      nameErrorMessage: '',
    };
  },
  computed: {
    headers() {
      return [{ key: 'name' }, { key: 'value' }];
    },
    rows() {
      return [{ name: 'Team name', value: this.$store.state.teams.selectedTeam.name, isEditable: true }];
    },
    isLoading() {
      return this.$store.state.teams.isLoading || this.$store.state.teams.isLoadingGetTeams;
    },
    errorMessage() {
      return [
        this.nameErrorMessage,
        this.$store.state.teams.errorMessage,
        this.$store.state.teams.getTeamsErrorMessage,
      ]
        .filter((message) => message && message !== '')
        .join(', ');
    },
    hasError() {
      return this.errorMessage !== '';
    },
  },
  methods: {
    onChange({ value }) {
      this.newName = value;
      this.nameErrorMessage = '';
    },
    close() {
      this.$router.push({ name: routeNames.team.list });
    },
    async save() {
      const isNameValid = this.validateName(this.newName);
      if (isNameValid === true) {
        const oldTeamName = this.$store.state.teams.selectedTeam.name;
        await this.$store.dispatch('teams/setTeamName', this.newName);

        if (!this.hasError) {
          this.$root.trackEvent.updatedTeamName(
            this.$store.state.teams.selectedTeam.id,
            this.$store.state.teams.selectedTeam.name,
            oldTeamName,
          );
          this.close();
        }
      } else {
        this.nameErrorMessage = isNameValid;
      }
    },
  },
};
</script>
