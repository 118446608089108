import api from '../../api';

const getDefaultState = () => ({
  isLoading: false,
  errorMessage: '',
  assetsData: [], // [{ assetId: assetId1 items: [...], nextToken}, assetId: assetId2: ... }]
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    START_LOADING(state) {
      state.isLoading = true;
    },
    STOP_LOADING(state) {
      state.isLoading = false;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    SET_ASSETS_DATA(state, {
      assetId, items, nextToken, useAssetSpaceData, reset,
    }) {
      if (reset) {
        state.assetsData = state.assetsData.filter((d) => d.assetId !== assetId);
      }

      const existingData = state.assetsData.find((d) => d.assetId === assetId);
      if (existingData) {
        existingData.items = [...(existingData.items || []), ...items];
        existingData.nextToken = nextToken;
      } else {
        state.assetsData.push({
          assetId, items, nextToken, useAssetSpaceData,
        });
      }
    },
  },
  actions: {
    async getAssetInFacilities(context, { assetId, reset }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING');
      try {
        const existingData = context.state.assetsData.find((d) => d.assetId === assetId);
        if (reset || !existingData || existingData.nextToken !== null) {
          let useAssetSpaceData = !reset && existingData && existingData.useAssetSpaceData;
          let data = useAssetSpaceData
            ? await api.getAssetSpaceHistory(
              assetId,
              reset ? null : (existingData || {}).nextToken,
            )
            : await api.getAssetFacilityHistory(
              assetId,
              reset ? null : (existingData || {}).nextToken,
            );

          // fallback to getAssetSpaceHistory when no asset-facility data
          if ((reset || !existingData) && data.items.length === 0) {
            useAssetSpaceData = true;
            data = await api.getAssetSpaceHistory(assetId);
          }
          context.commit('SET_ASSETS_DATA', {
            ...data, assetId, useAssetSpaceData, reset,
          });
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
  },
};
