<template>
  <div>
    <router-view v-if="isSignedIn || tokenIsValid" />
  </div>
</template>
<script>
import { routeNames } from '../../constants';
import { verifySignature } from '../../helpers/token-verification-helper';

const PUBLIC_KEY = process.env.VUE_APP_NB_IOT_PUBLIC_KEY;

export default {
  data() {
    return {
      tokenIsValid: false,
    };
  },
  computed: {
    isSignedIn() { return this.$store.state.auth.signedIn; },
  },
  async created() {
    const token = this.$route.query.t;
    const bleId = this.$route.query.b || this.$route.query.bleId;
    if (!this.isSignedIn) {
      if (!token) {
        this.routeHome();
      }
      if (bleId) {
        if (await verifySignature(token, `bleId:${bleId}`, PUBLIC_KEY)) {
          this.tokenIsValid = true;
        } else {
          this.$root.logger.error('Link cellular label: Invalid signature', { token, bleId });
          this.routeHome();
        }
      } else {
        this.tokenIsValid = true;
      }
    }
  },
  methods: {
    routeHome() {
      this.$router.replace({ name: routeNames.home });
    },
  },
};
</script>
