let includeAll = process.env.VUE_APP_INCLUDE_ALL_FEATURES === 'true';

function getIncludeAll() {
  return includeAll;
}

export const features = {
  includeNavigationBar() { return getIncludeAll() || false; },
  includeAssetsSearch() { return getIncludeAll() || true; },
  includeTemperatureGetData(mobileAppState) {
    return mobileAppState.isIosApp || mobileAppState.isAndroidApp;
  },
  includeAppRefreshButton() { return getIncludeAll() || false; },
  includeAssetDataPolling(mobileAppState, ctx) {
    return ctx.$root.workspaceFeatures.demoMode
     || mobileAppState.isIosApp
     || mobileAppState.isAndroidApp;
  },
  includeSettingsScreen() { return getIncludeAll() || false; },
  useUserLocation() { return process.env.VUE_APP_USE_USER_LOCATION === 'true'; },
};

export default {
  computed: {
    featureToggles() {
      return features;
    },
  },
  methods: {
    switchOnAllFeatures() {
      includeAll = true;
    },
  },
};
