<template>
  <v-btn-toggle
    class="button-group"
    active-class="is-active"
    tile
    group
    mandatory
    :dense="isSmall"
    :value="selectedIndex"
    @change="onChange"
  >
    <v-btn
      v-for="(item, i) in items"
      :key="i"
    >
      {{ item }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ReelablesWebButtonGroup',
  props: {
    items: { type: Array, default: () => [] },
    selectedIndex: { type: Number, required: true },
    isSmall: { type: Boolean, default: false },
  },
  methods: {
    onChange(index) {
      this.$emit('change', index);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';
  .button-group {
  .v-btn {
    text-transform: capitalize !important;
    min-width: 120px !important;
    color: $black !important;
    &::before {
      color: $white;
    }
    &.is-active{
      color: $white !important;
      &::before {
        opacity: 1 !important;
        color: $reelables-light-grey;
      }
    }
    .v-btn__content {
      font-family: 'Bold-Font';
      font-size: 0.875rem;
      @include mq($from: md) {
        font-size: 1rem;
      }
    }
  }
}
</style>
