import api from '../api';
import { constructDate, constructTime } from '../helpers/date-formatting-helper';

const getDefaultState = () => ({
  errorMessage: '',
  isLoadingFacility: false,
  isLoadingFacilitySpaces: false,
  isLoadingFacilityPrinters: false,
  isLoadingFacilities: false,
  isLoadingSpaceStatuses: false,
  isLoadingPrintJobs: false,
  facilities: [],
  nextToken: null,
});

function addBlankFacility(state, facilityId) {
  const facility = {
    id: facilityId,
    name: 'Loading...',
    printers: [],
    isSkeletonRecord: true,
  };
  state.facilities.push(facility);
  return facility;
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    START_LOADING_FACILITY(state) {
      state.isLoadingFacility = true;
    },
    START_LOADING_FACILITY_SPACES(state) {
      state.isLoadingFacilitySpaces = true;
    },
    START_LOADING_FACILITY_PRINTERS(state) {
      state.isLoadingFacilityPrinters = true;
    },
    STOP_LOADING_FACILITY(state) {
      state.isLoadingFacility = false;
    },
    START_LOADING_FACILITIES(state) {
      state.isLoadingFacilities = true;
    },
    STOP_LOADING_FACILITIES(state) {
      state.isLoadingFacilities = false;
    },
    STOP_LOADING_FACILITY_SPACES(state) {
      state.isLoadingFacilitySpaces = false;
    },
    STOP_LOADING_FACILITY_PRINTERS(state) {
      state.isLoadingFacilityPrinters = false;
    },
    START_LOADING_SPACE_STATUSES(state) {
      state.isLoadingSpaceStatuses = true;
    },
    STOP_LOADING_SPACE_STATUSES(state) {
      state.isLoadingSpaceStatuses = false;
    },
    START_LOADING_PRINTER_PRINT_JOBS(state) {
      state.isLoadingPrintJobs = true;
    },
    STOP_LOADING_PRINTER_PRINT_JOBS(state) {
      state.isLoadingPrintJobs = false;
    },
    SET_FACILITIES(state, facilities) {
      state.facilities = [
        ...state.facilities,
        ...facilities.filter((d1) => !state.facilities.find((d2) => d1.id === d2.id)),
      ];
    },
    ADD_FACILITY(state, facility) {
      // ensuring that if previous data of spaces existed then it is not removed.
      state.facilities = state.facilities
        .map((d) => (d.id === facility.id
          ? {
            ...facility,
            spaces: d.spaces,
            printers: d.printers,
            isSkeletonRecord: false,
          }
          : d));
      if (!state.facilities.find(({ id }) => facility.id === id)) {
        state.facilities.push(facility);
      }
    },
    ADD_FACILITY_SPACES(state, { facilityId, spaces }) {
      let facility = state.facilities.find((f) => f.id === facilityId);

      // if facility not already loaded, then add facility to state...
      if (!facility) {
        facility = addBlankFacility(state, facilityId);
      }

      facility.spaces = spaces
        .filter((s) => Array.isArray(s.gateways) && s.gateways.find((g) => !g.removedOn))
        .map((s) => {
          const gateway = s.gateways.find((g) => !g.removedOn);
          return {
            name: s.name,
            gatewayId: gateway.gatewayId,
            shortId: gateway.shortId,
            status: gateway.status,
            spaceId: s.id,
          };
        });

      state.facilities = state.facilities
        .map((d) => (d.id === facilityId ? facility : d));
    },
    ADD_FACILITY_PRINTERS(state, { facilityId, printers }) {
      let facility = state.facilities.find((f) => f.id === facilityId);

      // if facility not already loaded, then add facility to state...
      if (!facility) {
        facility = addBlankFacility(state, facilityId);
      }

      facility.printers = [
        // any printer data that were in state, but not in printers response
        ...(facility.printers || []).filter((fp) => !printers
          .find((p) => p.id === fp.printerId)),
        // printer data in response
        ...printers.map(({ id, ...rest }) => {
          const facilityPrinter = (facility.printers || [])
            .find((fp) => fp.printerId === id);
          return {
            ...rest,
            ...(facilityPrinter || {}),
            printerId: id,
            status: rest.isOnline ? 'Online' : 'Offline',
          };
        }),
      ];

      state.facilities = state.facilities
        .map((d) => (d.id === facilityId ? facility : d));
    },
    ADD_PRINTER_PRINT_JOBS(state, { printerId, facilityId, newPrintJobs }) {
      let facility = state.facilities.find((f) => f.id === facilityId);

      if (!facility) {
        facility = addBlankFacility(state, facilityId);
      }
      // retain the original order
      let printer = (facility.printers || []).find((p) => p.printerId === printerId);

      if (!printer) {
        printer = {
          printerId,
        };
        (facility.printers || []).push(printer);
      }

      if (printer.printJobs) {
        printer.printJobs.items = printer.printJobs.items.concat(newPrintJobs.items);
        printer.printJobs.nextToken = newPrintJobs.nextToken;
      } else {
        printer.printJobs = newPrintJobs;
      }

      state.facilities = state.facilities
        .map((d) => (d.id === facilityId ? facility : d));
    },
    ADD_FACILITY_FLOOR_PLAN(state, { facilityId, floorPlan }) {
      // retain the original order
      const facility = state.facilities.find((f) => f.id === facilityId);
      if (facility) {
        if (facility.floorPlans) {
          facility.floorPlans = facility.floorPlans
            .map((d) => (d.id === floorPlan.id ? floorPlan : d));
        } else {
          facility.floorPlans = [floorPlan];
        }
      }
      state.facilities = state.facilities.map((d) => (d.id === facilityId ? facility : d));
    },
    ADD_SPACE_STATUSES(state, { spaceId, facilityId, statuses }) {
      // retain the original order
      const space = state.facilities.find(({ id }) => id === facilityId)
        .spaces.find((s) => s.spaceId === spaceId);
      if (space.statusHistory) {
        space.statusHistory.items = space.statusHistory.items.concat(statuses.items);
        space.statusHistory.nextToken = statuses.nextToken;
      } else {
        space.statusHistory = statuses;
      }
      state.facilities = state.facilities.map((d) => (d.id === facilityId ? { ...d, space } : d));
    },
    SET_NEXT_TOKEN(state, nextToken) {
      state.nextToken = nextToken;
    },
  },
  actions: {
    async getFacilities(context) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_FACILITIES');
      try {
        const facilitiesResponse = await api.getFacilities(
          context.rootState.teams.selectedTeam.id,
          context.state.nextToken,
        );
        context.commit('SET_NEXT_TOKEN', facilitiesResponse.nextToken);
        context.commit('SET_FACILITIES', facilitiesResponse.items);

        // for each item, call api.getFacility and store data
        await Promise.all(facilitiesResponse.items
          .map((facility) => context.dispatch('getFacility', facility.id)));
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_FACILITIES');
      }
    },
    async getFacility(context, facilityId) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_FACILITY');
      try {
        const facilityResponse = await api.getFacility(
          facilityId,
        );
        context.commit('ADD_FACILITY', facilityResponse);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_FACILITY');
      }
    },
    async getFacilitySpaces(context, facilityId) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_FACILITY_SPACES');
      try {
        const facilitySpacesResponse = await api.getFacilitySpaces(
          facilityId,
        );
        context.commit('ADD_FACILITY_SPACES', { facilityId, spaces: facilitySpacesResponse.items });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_FACILITY_SPACES');
      }
    },
    async getFacilityPrinters(context, facilityId) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_FACILITY_PRINTERS');
      try {
        const facilityPrintersResponse = await api.getFacilityPrinters(
          facilityId,
        );
        context.commit('ADD_FACILITY_PRINTERS', { facilityId, printers: facilityPrintersResponse.items });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_FACILITY_PRINTERS');
      }
    },
    async getFacilityFloorPlan(context, { facilityId, floorPlanId }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      try {
        const facilityFloorPlanResponse = await api.getFacilityFloorPlan(
          facilityId,
          floorPlanId,
        );
        context.commit('ADD_FACILITY_FLOOR_PLAN', { facilityId, floorPlan: facilityFloorPlanResponse });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      }
    },
    async getSpaceStatuses(context, {
      spaceId, facilityId,
    }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_SPACE_STATUSES');
      try {
        const existingData = (((context.state.facilities
          .find(({ id }) => id === facilityId) || {}).spaces || [])
          .find((s) => s.spaceId === spaceId) || {}).statusHistory;
        if (!existingData || existingData.nextToken !== null) {
          const data = await api.getSpaceStatuses(spaceId, (existingData || {}).nextToken);
          context.commit('ADD_SPACE_STATUSES', { spaceId, facilityId, statuses: data });
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_SPACE_STATUSES');
      }
    },
    async getPrinterPrintJobs(context, {
      printerId, facilityId, timeZone,
    }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_PRINTER_PRINT_JOBS');
      try {
        const existingData = (((context.state.facilities
          .find(({ id }) => id === facilityId) || {}).printers || [])
          .find((s) => s.printerId === printerId) || {}).printJobs;
        if (!existingData || existingData.nextToken !== null) {
          const data = await api
            .getPrinterPrintJobs(printerId, (existingData || {}).nextToken);
          data.items = data.items.map((printJob) => {
            const { createdAt, ...rest } = printJob;
            const createdAtDate = new Date(createdAt);
            const createdAtRestructured = `${constructDate(createdAtDate, false, timeZone)} ${constructTime(createdAtDate, timeZone)}`;
            return { date: createdAtRestructured, ...rest };
          });
          context.commit('ADD_PRINTER_PRINT_JOBS', { printerId, facilityId, newPrintJobs: data });
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_PRINTER_PRINT_JOBS');
      }
    },
  },
  getters: {
    hasMoreFacilities(state) {
      return state.nextToken !== null;
    },
    hasFacilities(state) {
      return state.facilities.length > 0;
    },
    anyFacilitiesHaveUserFacilityId(state) {
      return state.facilities.some((f) => !!f.userFacilityId);
    },
  },
};
