<template>
  <div>
    <v-menu
      v-model="isMenuOpen"
      close-on-click
      bottom
      offset-y
      min-width="100%"
      :nudge-bottom="20"
      eager
    >
      <template #activator="{ on }">
        <img
          :src="menuIcon"
          :height="imageHeight"
          alt="Menu icon"
          v-on="on"
        >
      </template>
      <v-list light>
        <v-list-item
          :to="facilitiesRoute"
          exact
          :disabled="!hasSelectedTeam"
          @click="isMenuOpen = false"
        >
          <v-list-item-action>
            <v-icon>mdi-warehouse</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Facilities</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="dashbaordsRoute"
          exact
          :disabled="!hasSelectedTeam || !hasDashboards"
          @click="isMenuOpen = false"
        >
          <v-list-item-action>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboards</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isSettingsEnabled"
          :to="settingsRoute"
          exact
          @click="isMenuOpen = false"
        >
          <v-list-item-action>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <LogOut />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import MenuIcon from '../../../assets/icons/menu-icon.svg';
import { routeNames } from '../../../constants';
import LogOut from '../auth/log-out';

export default {
  name: 'Menu',
  components: {
    LogOut,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    selectedTeam() {
      return this.$store.state.teams.selectedTeam;
    },
    hasSelectedTeam() {
      return this.$store.getters['teams/hasSelectedTeam'];
    },

    hasDashboards() {
      return this.$store.getters['dashboards/hasDashboards'];
    },
    menuIcon() {
      return MenuIcon;
    },
    imageHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 35 : 45;
    },
    dashbaordsRoute() {
      return { name: routeNames.dashboards.index };
    },
    facilitiesRoute() {
      return { name: routeNames.facilities.index };
    },
    isSettingsEnabled() {
      return this.$root.featureToggles.includeSettingsScreen();
    },
    settingsRoute() {
      return { name: routeNames.settings };
    },
  },
  watch: {
    selectedTeam() {
      // So that we get dashboards for new team, when team is switched
      this.conditionallyGetDashboards();
    },
    isMenuOpen() {
      if (this.isMenuOpen) {
        // this is in the case where we've not yet got dashbaords
        this.conditionallyGetDashboards();
      }
    },
  },
  created() {
    this.conditionallyGetDashboards();
  },
  methods: {
    conditionallyGetDashboards() {
      if (this.$store.getters['teams/hasSelectedTeam'] && !this.hasDashboards) {
        this.getDashboards();
      }
    },
    getDashboards() {
      this.$store.dispatch('dashboards/getDashboards');
    },

  },
};
</script>
