import { linkTrackerStatus } from '../constants';

export default {
  methods: {
    async checkLabel(label) {
      if (!label.nfcId || label.nfcId === '') {
        return { status: linkTrackerStatus.unknownIssue };
      }
      // get label and see whether user has access
      try {
        const asset = await this.$store
          .dispatch('assets/getAssetByNfcId', { nfcId: label.nfcId, skipRedirect: true });
        if (asset && asset.id) {
          // asset has been found and user has access to it
          return {
            status: linkTrackerStatus.alreadyLinked,
            assetId: asset.id,
            teamId: asset.organisationId,
          };
        }
      } catch (error) {
        if (error.message.indexOf('No asset found with nfcId') !== -1) {
          // this means there is no asset associated with this label yet :)
          return { status: linkTrackerStatus.availableForLinking };
        } if (error.message.indexOf('User does not have necessary permission') !== -1) {
          this.$root.logger.info(
            'User scanned label they do not have access to',
            { nfcId: label.nfcId, userId: this.$store.state.user.user.id },
          );
          return { status: linkTrackerStatus.noAccess };
        }
      }
      return { status: null };
    },
    async addLabelToAsset(label) {
      const selectedAssetId = this.$route.params.id;
      await this.$store.dispatch('assets/addLabelToAsset', { id: selectedAssetId, label });

      if (!this.$store.state.assets.errorMessage) {
        const asset = this.$store.state.assets.assets.filter(
          ({ id }) => selectedAssetId === id,
        )[0];
        this.$root.trackEvent.linkedLabel(asset, false);
      }
    },
  },
};
