<template>
  <div class="settings-container">
    <h3 class="text-center">
      Settings
    </h3>
    <v-checkbox
      prepend-icon="mdi-cellphone-basic "
      :input-value="includeMobileGatewayInDashboard"
      label="Include mobile gateways in dashboard"
      @change="toggleIncludeMobileGatewaysInDashboard"
    />
  </div>
</template>
<script>
export default {
  computed: {
    includeMobileGatewayInDashboard() {
      return this.$store.state.settings.includeMobileGatewayInDashboard;
    },
  },
  methods: {
    toggleIncludeMobileGatewaysInDashboard(val) {
      this.$store.commit('settings/SET_INCLUDE_MOBILE_GATEWAYS_IN_DASHBOARD', val || false);
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  max-width: 400px;
  margin: auto;
}
</style>
