import wrapper from '../analytics-wrapper';

export default {
  addedZone({ name, id }) {
    wrapper.track('Added zone', {
      'Zone Name': name,
      'Zone ID': id,
    });
  },
  editedZone({ name, id }, oldName) {
    wrapper.track('Edited zone', {
      'New Zone Name': name,
      'Old Zone Name': oldName,
      'Zone ID': id,
    });
  },
  removedZone({
    name, id, numberAssetsInSpace, gateways,
  }) {
    wrapper.track('Removed zone', {
      'Zone Name': name,
      'Zone ID': id,
      'Assets Count': (numberAssetsInSpace !== null
        && numberAssetsInSpace > 0) ? numberAssetsInSpace : 'No data',
      'Last Seen': 'No data',
      'Had Active Gateway': Array.isArray(gateways)
        && gateways.filter(({ removedOn }) => !removedOn).length > 0,
      isSelected: this.selectedId && this.selectedId === id,
    });
  },
};
