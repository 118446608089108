<template>
  <ButtonsDialogue
    :is-visible="isVisible"
    :title="title"
    :message="message"
  >
    <Button
      text="No"
      :on-click="() => $emit('no')"
    />
    <Button
      text="Yes"
      :on-click="() => $emit('yes')"
      is-warning
    />
  </ButtonsDialogue>
</template>
<script>
import ButtonsDialogue from './dialogue-with-buttons';
import Button from '../../atoms/button/button';

export default {
  name: 'YesNoDialogue',
  components: {
    ButtonsDialogue,
    Button,
  },
  props: {
    isVisible: { type: Boolean, default: false },
    title: { type: String, default: 'Are you sure?' },
    message: { type: String, default: '' },
  },
};
</script>
