import mixpanel from 'mixpanel-browser';
import { logger } from '../logging/logger';

function handleError(error, requestPayload) {
  logger.error('Error logging usage data in Mix Panel', { error, requestPayload });
}

export default {
  track(eventName, eventProperties) {
    try {
      mixpanel.track(eventName, eventProperties);
    } catch (error) {
      handleError(error, { request: 'track', eventProperties });
    }
  },
  identify(id) {
    try {
      // Identifying user in Mixpanel
      mixpanel.identify(id);
    } catch (error) {
      handleError(error, { request: 'identify', id });
    }
  },
  register(superProperties) {
    try {
      // Registering the super property
      mixpanel.register(superProperties);
    } catch (error) {
      handleError(error, { request: 'register', superProperties });
    }
  },
  setPeopleProperties(peopleProperties) {
    try {
      // Setting people property
      mixpanel.people.set(peopleProperties);
    } catch (error) {
      handleError(error, { request: 'setPeopleProperties', peopleProperties });
    }
  },
  setPeoplePropertiesOnce(peopleProperties) {
    try {
      // Setting people property just once
      mixpanel.people.set_once(peopleProperties);
    } catch (error) {
      handleError(error, { request: 'setPeoplePropertiesOnce', peopleProperties });
    }
  },
  reset() {
    // To reset mixpanel and remove the identifier
    try {
      mixpanel.reset();
    } catch (error) {
      handleError(error, { request: 'reset' });
    }
  },
};
