<template>
  <VerticalContainer :is-editing="isEditing">
    <Loader :is-loading="isLoading && !isEditing" />
    <div
      v-if="selectedTeamMember && (!isLoading || isEditing)"
      class="team-member-detail"
    >
      <TableTitle
        v-if="!isLoading"
        :title="memberName"
      />
      <img
        :src="profileImage"
        :height="imageHeight"
        class="profile-image"
        :class="{ 'profile-image-for-editing': isEditing }"
        alt="Member image"
      >
      <div v-if="isEditing">
        <ButtonGroup
          :items="roles"
          :selected-index="savedRoleIndex"
          @change="(index) => newRoleIndex = index"
        />
      </div>
      <div
        v-else
        style="font-weight: bold;"
      >
        {{ selectedTeamMember.role }}
      </div>
      <div>  {{ selectedTeamMember.user.email }} </div>
    </div>
    <ErrorMessage :error-message="errorToDisplay" />
    <template
      v-if="isEditing"
      #buttons
    >
      <Button
        is-warning
        text="Delete"
        :is-loading="isDeleteDialogueVisible && isLoading"
        :disabled="isLoading && !isSaveDisabled"
        :on-click="() => isDeleteDialogueVisible = true"
      />
      <Button
        text="Save"
        :on-click="saveNewMemberDetails"
        :disabled="isSaveDisabled"
        :is-loading="!isDeleteDialogueVisible && isLoading"
      />
      <Button
        text="Cancel"
        :on-click="cancelClicked"
        :disabled="isLoading"
      />
    </template>
    <template
      v-else
      #buttons
    >
      <Button
        v-if="!isLoading && shouldAllowEditing"
        text="Edit"
        :on-click="beginEditing"
        :disabled="isLoading"
      />
      <Button
        :on-click="closeClicked"
        text="Close"
        :disabled="isLoading"
      />
    </template>
    <YesNoDialogue
      :is-visible="isDeleteDialogueVisible"
      title="Are you sure you want to delete this member?"
      @no="() => isDeleteDialogueVisible = false"
      @yes="removeMember"
    />
    <YesNoDialogue
      :is-visible="isCancelDialogueVisible"
      message="Any unsaved data will be lost"
      @no="() => isCancelDialogueVisible = false"
      @yes="cancelEditing"
    />
  </VerticalContainer>
</template>

<script>
import { routeNames, userRoles } from '../../constants';
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import AvatarIcon from '../../assets/icons/man-icon.svg';
import Loader from '../../components/atoms/loader/loader';
import Button from '../../components/atoms/button/button';
import ButtonGroup from '../../components/atoms/button-group/button-group';
import YesNoDialogue from '../../components/molecules/dialogues/yes-no-dialogue';
import ErrorMessage from '../../components/atoms/message/error-message';
import TableTitle from '../../components/atoms/table-title/table-title';

export default {
  name: 'TeamMemberDetail',
  components: {
    VerticalContainer,
    Loader,
    Button,
    ButtonGroup,
    YesNoDialogue,
    ErrorMessage,
    TableTitle,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      isDeleteDialogueVisible: false,
      isEditing: false,
      savedRoleIndex: null,
      newRoleIndex: null,
      roles: [
        userRoles.viewer,
        userRoles.member,
        userRoles.admin,
      ],
      isCancelDialogueVisible: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.teamMembers.isLoadingTeamMember;
    },
    profileImage() {
      if (this.$store.state.teamMembers.selectedTeamMember.user.avatarUrl) {
        return this.$store.state.teamMembers.selectedTeamMember.user.avatarUrl;
      }
      return AvatarIcon;
    },
    imageHeight() {
      return this.$vuetify.breakpoint.smAndDown
        ? 106
        : 150;
    },
    selectedTeamMember() {
      return this.$store.state.teamMembers.selectedTeamMember;
    },
    memberName() {
      return this.$store.getters['teamMembers/selectedTeamMemberName'];
    },
    shouldAllowEditing() {
      const allowUserEditing = this.$store.getters['user/isOwner'] || this.$store.getters['user/isAdmin'];
      const isOwnerSelected = this.$store.getters['teamMembers/isSelectedTeamMemberOwner'];
      return allowUserEditing && !isOwnerSelected;
    },
    isSaveDisabled() {
      if (this.isDeleteDialogueVisible) return true;

      return this.newRoleIndex === null;
    },
    errorToDisplay() {
      return this.$store.state.teamMembers.errorMessage;
    },
    hasError() {
      return this.errorToDisplay && this.errorToDisplay !== '';
    },
  },
  watch: {
    id() {
      this.getTeamMember();
    },
    isLoading() {
      if (!this.isLoading && this.selectedTeamMember !== null) {
        this.savedRoleIndex = this.roles.indexOf(this.selectedTeamMember.role);
        this.isEditing = false;
      }
    },
  },
  created() {
    this.getTeamMember();
  },
  beforeDestroy() {
    this.$store.commit('teamMembers/RESET_SELECTED_TEAM_MEMBER');
  },
  methods: {
    getTeamMember() {
      this.$store.dispatch('teamMembers/getTeamMember', this.id);
    },
    async removeMember() {
      const removedTeamMember = this.selectedTeamMember;
      await this.$store.dispatch('teamMembers/removeTeamMember', this.id);

      this.isDeleteDialogueVisible = false;

      if (!this.hasError) {
        this.$root.trackEvent.removedMember(removedTeamMember);
        this.$router.push({
          name: routeNames.team.list,
        });
      }
    },
    async saveNewMemberDetails() {
      const oldTeamMemberRole = this.selectedTeamMember.role;
      const newTeamMemberRole = this.roles[this.newRoleIndex];
      const updatedTeamMember = await this.$store.dispatch(
        'teamMembers/updateTeamMemberRole',
        newTeamMemberRole,
      );
      if (updatedTeamMember) {
        // null if error;
        this.$root.trackEvent.editedMember(updatedTeamMember, oldTeamMemberRole);
      }
    },
    beginEditing() {
      this.newRoleIndex = null;
      this.isEditing = true;
    },
    cancelClicked() {
      if (this.newRoleIndex !== null) {
        this.isCancelDialogueVisible = true;
      } else {
        this.cancelEditing();
      }
    },
    cancelEditing() {
      this.isCancelDialogueVisible = false;
      this.isEditing = false;
    },
    closeClicked() {
      this.$router.push({ name: routeNames.team.list });
    },
  },
};

</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.team-member-detail {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-color: $white;
  text-align: center;
  height: calc(100% - 85px);
  box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
  padding-bottom: 55px;
}

.profile-image {
  margin-top: 45px;
}

.profile-image-for-editing {
  margin-top: 10px;
}
</style>
