<template>
  <div class="popup">
    <div class="is-bold">
      Last seen
    </div>
    {{ relativeLastSeenTime.lastSeen }}
  </div>
</template>
<script>
import dateTimeHelper from '../../../mixins/date-time-helper';

export default {
  name: 'LastSeenMapPopup',
  mixins: [dateTimeHelper],
  props: {
    lastSeenTime: { type: String, required: true },
    timerId: { type: Number, default: 0 },
  },
  computed: {
    relativeLastSeenTime() {
      return {
        timer: this.timerId,
        lastSeen: this.getLastSeen(this.lastSeenTime) || 'no data',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.popup {
  color: $black;
  text-align: center;
  padding: 0px 10px;
}
</style>
