export const noGatewayStatus = 'No gateway';

export function constructGatewayStatus(zone) {
  if (!zone.gateways
    || !Array.isArray(zone.gateways)
    || !zone.gateways.some(({ removedOn }) => !removedOn)) {
    return noGatewayStatus;
  }

  return zone.gateways.find(({ removedOn }) => !removedOn).status || 'Unknown';
}
