function constructSpacesString(spaces) {
  const spaceNames = spaces.map(({ spaceName }) => spaceName);
  const uniqueSpaceNames = Array.from(new Set(spaceNames)); // Remove duplicates.
  return uniqueSpaceNames.join(', ');
}

function constructFacilitySpacesString(spaces, facilityName) {
  return `${facilityName} (${constructSpacesString(spaces)})`;
}

function constructSpacesNotInfacility(
  spacesNotInFacility,
  spacesNotInAnyFacility,
  ignoreSpacesInFacility,
) {
  if (ignoreSpacesInFacility) {
    return (spacesNotInAnyFacility.length > 0 ? `, ${constructSpacesString(spacesNotInAnyFacility)}` : '');
  }

  return (spacesNotInFacility.length > 0 ? `, ${constructSpacesString(spacesNotInFacility)}` : '');
}

export default function constructAssetCurrentlyIn(asset, notCurrentlyInText = '-', ignoreSpacesInFacility = false) {
  if ((asset.inSpaces || []).length === 0
    && (asset.inFacilities || []).length === 0) {
    return notCurrentlyInText;
  }

  const inSpaces = [...(asset.inSpaces || [])]
    .sort((a, b) => a.enteredOn.localeCompare(b.enteredOn));

  const spacesNotInAnyFacility = inSpaces.filter(({ facilityId }) => !facilityId);

  const inFacilities = [...(asset.inFacilities || [])]
    .sort((a, b) => a.enteredOn.localeCompare(b.enteredOn))
    // remove duplicate facilities based on name
    .filter((facility, index, self) => self
      .findIndex((f) => f.facilityName === facility.facilityName) === index);

  if (inFacilities.length > 0) {
    const spacesNotInFacility = inSpaces
      .filter(({ facilityId }) => !inFacilities.some(({ facilityId: fId }) => fId === facilityId));

    return inFacilities
      .map(({ facilityId, facilityName }) => {
        const spacesInFacility = inSpaces.filter(({ facilityId: fId }) => fId === facilityId);

        if (spacesInFacility.length > 0) {
          return constructFacilitySpacesString(spacesInFacility, facilityName, facilityId);
        }

        return facilityName;
      }).join(', ')
      + constructSpacesNotInfacility(
        spacesNotInFacility,
        spacesNotInAnyFacility,
        ignoreSpacesInFacility,
      );
  }

  // OPTIONALLY only display spaces where facilityId is not set
  return constructSpacesString(ignoreSpacesInFacility ? spacesNotInAnyFacility : inSpaces);
}
