var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:{
    'has-background-image': !_vm.isSignedIn,
    'is-vertically-centered': _vm.isVerticallyCentered,
    'is-signed-in': _vm.isSignedIn
  },attrs:{"id":"web-app"}},[_c('div',{staticClass:"left-side-container"},[_c('AppHeader'),_c('v-main',{staticClass:"main-container"},[_c('v-container',{staticClass:"fluid-container",class:{
          'no-top-padding': _vm.noNavigationTabs
        },attrs:{"fluid":""}},[_vm._t("default")],2)],1)],1),_c('div',{staticClass:"right-side-container"},[_c('v-img',{attrs:{"src":_vm.backgroundImage,"position":"center center","aspect-ratio":"2.2","alt":"background image"}})],1),_c('ScanModal'),_c('ScanSuccessDialogue'),_c('Notifications')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }