import NbIotHome from '../views/nb-iot/NbIotHome';
import NbIotDetail from '../views/nb-iot/NbIotDetail';
import NbIotLink from '../views/nb-iot/NbIotLink';
import { routeNames } from '../constants';

export default [
  {
    path: '/label',
    redirect: { name: routeNames.home },
    component: NbIotHome,
    children: [
      {
        path: 'link',
        name: routeNames.nbIot.link,
        component: NbIotLink,
        props: (route) => ({ ...route.query, ...route.params }),
        meta: {
          allowTokenAuth: true, // allow route to be viewed with token in the URL
          noLayout: true,
        },
      },
      {
        path: 'track',
        name: routeNames.nbIot.detail,
        component: NbIotDetail,
        props: (route) => ({ ...route.query, ...route.params }),
        meta: {
          allowTokenAuth: true, // allow route to be viewed with token in the URL
          noLayout: true,
        },
      },
    ],
  },
];
