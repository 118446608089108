<template>
  <MainWithDetailLayout
    :is-loading="!hasZones && isLoading"
    :main-route-name="mainRouteName"
    :hide-detail="!hasZones && isLoading"
    :is-main-full-width="!hasZones && isLinkGatewayRoute"
    :is-detail-full-width="!hasZones && isAddZoneRoute"
  >
    <EmptyState
      v-if="!isLoading && !hasZones && !isAddZoneRoute"
      :background-source="backgroundSource"
      :items="contents"
      :left-alignment-percentage="85"
    >
      <template
        v-if="!isLinkGatewayRoute"
        #buttons
      >
        <Button
          :to="addZoneRoute"
          text="Add Zone"
        />
        <Button
          :on-click="clickLinkGateway"
          text="Link Gateway"
        />
      </template>
    </EmptyState>
    <VerticalContainer v-else-if="hasZones">
      <ButtonsDialogue
        title="Could not start mobile gateway"
        :is-visible="isDialogueVisible"
        :message="mobileGatewayErrorMessage"
      >
        <Button
          text="OK"
          :on-click="clickDialogueButton"
          is-warning
        />
      </ButtonsDialogue>
      <ZonesTable :selected-id="$route.params.id">
        <Button
          v-if="isMobileApp"
          slot="buttons"
          is-small
          :text="gatewayButtonTitle"
          :is-loading="isLoadingMobileGateway"
          :on-click="clickMobileGateway"
          :disabled="$root.isViewer"
        />
      </ZonesTable>
      <template #buttons>
        <Button
          :to="addZoneRoute"
          text="Add Zone"
          :disabled="$root.isViewer"
        />
        <Button
          :on-click="clickLinkGateway"
          text="Link Gateway"
          :disabled="$root.isViewer"
        />
        <Button
          v-if="hasLocations"
          :on-click="displayZonesMap"
          text="Map"
        />
      </template>
    </VerticalContainer>
  </MainWithDetailLayout>
</template>
<script>
import MainWithDetailLayout from '../../components/organisms/layout/main-with-detail-layout';
import EmptyState from '../../components/organisms/assets/empty-state';
import ZonesTable from '../../components/organisms/zones/zones-table';
import Button from '../../components/atoms/button/button';
import { routeNames } from '../../constants';
import BackgroundImage from '../../assets/images/empty-zones-background.svg';
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import ButtonsDialogue from '../../components/molecules/dialogues/dialogue-with-buttons';

export default {
  name: 'ZonesList',
  components: {
    MainWithDetailLayout,
    EmptyState,
    ZonesTable,
    Button,
    VerticalContainer,
    ButtonsDialogue,
  },
  computed: {
    hasLocations() {
      return this.$store.getters['zones/hasLocations'];
    },
    mainRouteName() {
      return routeNames.zones.list;
    },
    isLoading() {
      return this.$store.state.zones.isLoadingZones;
    },
    hasZones() {
      return this.$store.getters['zones/hasZones'];
    },
    backgroundSource() {
      return BackgroundImage;
    },
    isMobileApp() {
      return this.$store.getters.isMobileApp;
    },
    isDialogueVisible() {
      return !!this.mobileGatewayErrorMessage;
    },
    mobileGatewayErrorMessage() {
      return this.$store.state.mobileApp.mobileGatewayErrorMessage;
    },
    contents() {
      return [
        'Zones are locations where your assets are continuously monitored',
        'To make a zone Reelables enabled you need to install a gateway in the zone',
        'You will need a Reelables Gateway then click Link Gateway on this page',
      ];
    },
    addZoneRoute() {
      return {
        name: routeNames.zones.add,
      };
    },
    isAddZoneRoute() {
      return this.$route.name === routeNames.zones.add;
    },
    isLinkGatewayRoute() {
      return this.$route.name === routeNames.zones.linkGateway;
    },
    selectedTeamId() {
      return this.$route.params.teamId;
    },
    isLoadingMobileGateway() {
      return this.$store.state.mobileApp.isMobileGatewayLoading;
    },
    isMobileGatewayActive() {
      return this.$store.state.mobileApp.isMobileGatewayActive;
    },
    gatewayButtonTitle() {
      return this.isMobileGatewayActive ? 'Stop Mobile Gateway' : 'Start Mobile Gateway';
    },
  },
  watch: {
    selectedTeamId() {
      if (!this.hasZones) {
        this.$store.dispatch('zones/getZones');
      }
    },
  },
  created() {
    if (!this.hasZones) {
      this.$store.dispatch('zones/getZones');
    }
  },
  methods: {
    clickMobileGateway() {
      if (this.isMobileGatewayActive) {
        this.$store.dispatch('mobileApp/stopMobileGateway');
      } else {
        // Need to fetch mobile device UUID before starting mobile gateway
        this.$store.dispatch('mobileApp/getDeviceUUID');
      }
    },
    clickDialogueButton() {
      this.$store.commit('mobileApp/RESET_MOBILE_GATEWAY_ERROR_MESSAGE');
    },
    clickLinkGateway() {
      if (this.$store.getters.isMobileApp) {
        this.$store.dispatch('mobileApp/startNfcScan');
      } else {
        this.$router.push({
          name: routeNames.zones.linkGateway,
        });
      }
    },
    displayZonesMap() {
      this.$router.push({ name: routeNames.zones.map }).catch(() => {});
    },
  },
};
</script>
