import ZonesMap from '../views/zones/ZonesMap';
import ZoneDetail from '../views/zones/ZoneDetail';
import ZonesAndLocationsHome from '../views/zones/ZonesList';
import LinkGateway from '../views/zones/LinkGateway';
import AddZone from '../views/zones/AddZone';
import SelectZone from '../views/zones/SelectZone';
import { routeNames } from '../constants';

export default [
  {
    path: 'zones',
    name: routeNames.zones.list,
    component: ZonesAndLocationsHome,
    children: [
      {
        path: 'link-gateway',
        name: routeNames.zones.linkGateway,
        component: LinkGateway,
      },
      {
        path: 'add',
        name: routeNames.zones.add,
        component: AddZone,
        props: true,
      },
      {
        path: 'map',
        name: routeNames.zones.map,
        component: ZonesMap,
      },
      {
        path: ':id',
        name: routeNames.zones.detail,
        component: ZoneDetail,
        props: true,
        children: [
          {
            path: 'link-gateway',
            name: routeNames.zones.linkZoneGateway,
            component: LinkGateway,
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: 'selectZone',
    name: routeNames.zones.selectZone,
    component: SelectZone,
    props: true,
  },
];
