import VueCookies from 'vue-cookies';
import api from '../api';
import { cookieKeys, errorMessages, expoBackOffTimes } from '../constants';
import { sleep } from '../helpers/async-helpers';

const getDefaultState = () => ({
  isLoading: false,
  isLoadingGetTeams: false,
  selectedTeam: null,
  teams: [],
  nextToken: null,
  errorMessage: '',
  getTeamsErrorMessage: '',
});

function resetOtherStores(context) {
  context.commit('teamMembers/RESET_STATE', null, { root: true });
  context.commit('assetsFilters/RESET_STATE', null, { root: true });
  context.commit('assets/RESET_STATE', null, { root: true });
  context.commit('zones/RESET_STATE', null, { root: true });
  context.commit('dashboards/RESET_STATE', null, { root: true });
  context.commit('facilities/RESET_STATE', null, { root: true });
}

function setCookieInMobileApp(context) {
  if (context.rootGetters.isMobileApp) {
    context.dispatch('mobileApp/storeCookie', null, { root: true });
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    ADD_TEAM(state, team) {
      state.teams = [team, ...state.teams];
    },
    SET_SELECTED_TEAM(state, team) {
      state.selectedTeam = team;
    },
    DELETE_TEAM(state, teamId) {
      state.teams = [...state.teams.filter(({ id }) => id !== teamId)];
    },
    UPDATE_TEAM(state, editedTeam) {
      state.teams = state.teams.map((team) => (team.id === editedTeam.id ? editedTeam : team));
      if (state.selectedTeam.id === editedTeam.id) { state.selectedTeam = editedTeam; }
    },
    SET_TEAMS(state, teams) {
      state.teams = [...state.teams, ...teams];
    },
    RESET_TEAMS(state) {
      state.teams = [];
    },
    SET_NEXT_TOKEN(state, nextToken) {
      state.nextToken = nextToken;
    },
    RESET_NEXT_TOKEN(state) {
      state.nextToken = null;
    },
    START_LOADING(state) {
      state.isLoading = true;
    },
    STOP_LOADING(state) {
      state.isLoading = false;
    },
    START_LOADING_GET_TEAMS(state) {
      state.isLoadingGetTeams = true;
    },
    STOP_LOADING_GET_TEAMS(state) {
      state.isLoadingGetTeams = false;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    SET_GET_TEAMS_ERROR_MESSAGE(state, errorMessage) {
      state.getTeamsErrorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    CLEAR_GET_TEAMS_ERROR_MESSAGE(state) {
      state.getTeamsErrorMessage = '';
    },
  },
  actions: {
    async refreshGetTeams(context) {
      context.commit('RESET_TEAMS');
      context.commit('RESET_NEXT_TOKEN');
      await context.dispatch('getTeams');
    },
    async getTeam(context, { teamId, skipRedirect }) {
      context.commit('START_LOADING');
      try {
        const teamsResponse = await api.getTeam(teamId, !skipRedirect);
        context.commit('ADD_TEAM', teamsResponse);
        return teamsResponse;
      } catch (error) {
        return null;
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async getTeams(context, retry = {}) {
      context.commit('CLEAR_GET_TEAMS_ERROR_MESSAGE');
      context.commit('START_LOADING_GET_TEAMS');
      try {
        const teamsResponse = await api.getTeams(context.state.nextToken);
        context.commit('SET_NEXT_TOKEN', teamsResponse.organisations.nextToken);
        context.commit('SET_TEAMS', teamsResponse.organisations.items);
      } catch (error) {
        if (retry.retryOnFailPermissions && error.includes(errorMessages.noPermission)) {
          // e.g. use this when logging in - as there's sometimes a delay
          // in adding permissions to new user
          const attempt = retry.attempt || 0;
          const sleepTime = expoBackOffTimes[attempt];
          if (sleepTime) {
            await sleep(expoBackOffTimes);
            await context.dispatch('getTeams', { ...retry, attempt: attempt + 1 });
            // don't go any further...
            return;
          }
        }
        context.commit('SET_GET_TEAMS_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_GET_TEAMS');
      }
    },
    async setTeamName(context, newName) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING');
      try {
        const teamsResponse = await api.setTeamName(context.state.selectedTeam.id, newName);
        context.commit('UPDATE_TEAM', teamsResponse);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async setSelectedTeam(context, teamId) {
      const alreadyLoadedTeam = context.state.teams.find((team) => team.id === teamId);
      const teamToSelect = alreadyLoadedTeam || await context.dispatch('getTeam', { teamId });

      if (!teamToSelect) {
        return false;
      }

      // store teamId in cookie
      VueCookies.set(cookieKeys.teamId, teamId, Infinity);
      setCookieInMobileApp(context);
      resetOtherStores(context);

      await context.dispatch('user/setRoleInTeam', teamId, { root: true });
      context.commit('SET_SELECTED_TEAM', teamToSelect);

      return false;
    },
    resetSelectedTeam(context) {
      // store teamId in cookie
      VueCookies.remove(cookieKeys.teamId);
      setCookieInMobileApp(context);
      resetOtherStores(context);
      context.commit('SET_SELECTED_TEAM', null);
    },
  },
  getters: {
    hasTeams(state) {
      return state.teams.length > 0;
    },
    hasSelectedTeam(state) {
      return state.selectedTeam !== null;
    },
    hasMoreTeams(state) {
      return state.nextToken !== null;
    },
    enabledFeatures(state) {
      return state.selectedTeam?.features || [];
    },
  },
};
