const { crypto } = window;

// from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
export function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

async function importKey(key) {
  // base64 decode the string to get the binary data
  const binaryDerString = Buffer.from(key, 'base64').toString('binary');
  // convert from a binary string to an ArrayBuffer
  const binaryDer = str2ab(binaryDerString);

  return crypto.subtle.importKey(
    'spki',
    binaryDer,
    {
      name: 'ECDSA',
      namedCurve: 'P-256',
    },
    false,
    ['verify'],
  );
}

export async function verifySignature(signature, data, keyAsString) {
  let publicKey = null;
  try {
    publicKey = await importKey(keyAsString);
  } catch (error) {
    return false;
  }

  try {
    const isValid = await crypto.subtle.verify({
      name: 'ECDSA',
      namedCurve: 'P-256',
      hash: { name: 'SHA-384' },
    }, publicKey, str2ab(Buffer.from(signature, 'base64').toString('binary')), str2ab(data));

    return isValid;
  } catch (_) {
    return false;
  }
}
