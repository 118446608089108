<template>
  <div class="asset-timeline-container">
    <InfiniteTimeline
      :items="items"
      :display-header="showHeader"
      :is-loading="isLoading"
      :has-more-data="hasMoreData"
      @moreData="getMoreData"
    />
  </div>
</template>
<script>
import InfiniteTimeline from '../../molecules/timeline/infinite-timeline';
import { constructTimelineItemsForAsset, constructLocationTimelineItemsForAsset } from '../../../helpers/asset-timeline-helper';
import { labelProvisioned } from '../../../constants';

export default {
  name: 'AssetTimeline',
  components: {
    InfiniteTimeline,
  },
  props: {
    assetId: { type: String, required: true },
    displayLocationTimeline: { type: Boolean, defaults: false },
  },
  computed: {
    hasMoreData() {
      return this.displayLocationTimeline
        ? this.hasMoreLocationData : this.hasMoreAssetInSpacesOrFacilitiesData;
    },
    useAssetSpaceData() {
      return !!(this.$store.state.assets.assetInFacilities
        .assetsData.find((d) => d.assetId === this.assetId) || {}).useAssetSpaceData;
    },
    assetFacilityEpisodesAndTimeline() {
      return this.$root.workspaceFeatures.assetSpaceHistoryTimeline
      && this.$root.workspaceFeatures.assetFacilityEpisodes;
    },
    asset() {
      return this.$store.state.assets.assets.find(({ id }) => id === this.assetId);
    },
    assetInSpacesOrFacilitiesForAsset() {
      return this.$store.state.assets[
        this.assetFacilityEpisodesAndTimeline ? 'assetInFacilities' : 'assetInSpaces'
      ].assetsData.find((d) => d.assetId === this.assetId) || {};
    },
    items() {
      if (this.displayLocationTimeline) {
        return constructLocationTimelineItemsForAsset(
          this.asset,
          !this.isLoading && !this.hasMoreLocationData,
        );
      }
      return constructTimelineItemsForAsset(
        this.asset,
        (this.assetInSpacesOrFacilitiesForAsset.items || []),
        !this.isLoading && !this.hasMoreAssetInSpacesOrFacilitiesData,
        this.$root.workspaceFeatures.spaceGroupingOnName
          && (!this.$root.workspaceFeatures.assetFacilityEpisodes || this.useAssetSpaceData),
        this.$root.workspaceFeatures.coalesceConsecutiveTimelineEvents,
        this.$root.workspaceFeatures.assetFacilityEpisodes && !this.useAssetSpaceData,
      );
    },
    showHeader() {
      return this.items.filter((d) => d.title !== labelProvisioned).length > 0;
    },
    isLoading() {
      return this.$store.state.assets.assetInSpaces.isLoading
      || this.$store.state.assets.assetInFacilities.isLoading
      || this.$store.state.assets.isLoadingAsset
      || this.$store.state.assets.isLoadingAssetLocations;
    },
    hasMoreAssetInSpacesOrFacilitiesData() {
      return !!this.assetInSpacesOrFacilitiesForAsset.nextToken;
    },
    hasMoreLocationData() {
      return this.asset.locations.nextToken !== null;
    },
  },
  watch: {
    assetId() {
      this.getInitialData(true);
    },
  },
  created() {
    this.getInitialData(true);
  },
  methods: {
    getInitialData(reset = false) {
      if (this.assetFacilityEpisodesAndTimeline) {
        this.getAssetInFacilitiesData(reset);
      } else if (this.$root.workspaceFeatures.assetSpaceHistoryTimeline) {
        this.getAssetInSpacesData(reset);
      }
    },
    getAssetLocationData() {
      this.$store.dispatch('assets/getAssetLocations', { id: this.assetId, nfcId: this.asset.nfcId, nextToken: this.asset.locations.nextToken });
    },
    getAssetInSpacesData(reset = false) {
      this.$store.dispatch('assets/assetInSpaces/getAssetInSpaces', { assetId: this.assetId, reset });
    },
    getAssetInFacilitiesData(reset = false) {
      this.$store.dispatch('assets/assetInFacilities/getAssetInFacilities', { assetId: this.assetId, reset });
    },
    getMoreData() {
      if (this.displayLocationTimeline) {
        this.getAssetLocationData();
      } else if (this.assetFacilityEpisodesAndTimeline) {
        this.getAssetInFacilitiesData();
      } else if (this.$root.workspaceFeatures.assetSpaceHistoryTimeline) {
        this.getAssetInSpacesData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.asset-timeline-container {
  height: calc(100% - 35px);
}
</style>
