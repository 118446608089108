<template>
  <MainWithDetailLayout
    :main-route-name="mainRouteName"
    :is-main-full-width="!isDetailScreen"
  >
    <VerticalContainer>
      <FacilitiesTable
        :selected-id="$route.params.id"
      />
    </VerticalContainer>
  </MainWithDetailLayout>
</template>
<script>
import MainWithDetailLayout from '../../components/organisms/layout/main-with-detail-layout';
import FacilitiesTable from '../../components/organisms/facilities/facilities-table';
import { routeNames } from '../../constants';
import VerticalContainer from '../../components/organisms/layout/vertical-container';

export default {
  name: 'Facilities',
  components: {
    VerticalContainer,
    FacilitiesTable,
    MainWithDetailLayout,
  },
  computed: {
    mainRouteName() {
      return routeNames.facilities.index;
    },
    hasFacilities() {
      return this.$store.getters['facilities/hasFacilities'];
    },
    isDetailScreen() {
      return this.$route.name !== routeNames.facilities.index;
    },
  },
  created() {
    this.getFacilities();
  },
  methods: {
    getFacilities() {
      if (!this.hasFacilities) this.$store.dispatch('facilities/getFacilities');
    },
  },
};
</script>
