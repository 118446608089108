import AssetsHome from '../views/assets/AssetsList';
import AssetDetail from '../views/assets/AssetDetail';
import AssetsMap from '../views/assets/AssetsMap';
import LinkLabel from '../views/assets/LinkLabel';
import AssetsFilters from '../views/assets/AssetsFilters';
import AddAsset from '../views/assets/AddAsset';
import SelectAsset from '../views/assets/SelectAsset';
import DownloadTemperature from '../views/assets/DownloadTemperature';
import DownloadTemperatureRequireApp from '../views/assets/DownloadTemperatureRequireApp';
import AssetTemperature from '../views/assets/AssetTemperature';
import { routeNames } from '../constants';

export default [
  {
    path: 'assets',
    name: routeNames.assets.list,
    component: AssetsHome,
    children: [
      {
        path: 'link-label',
        name: routeNames.assets.linkLabel,
        component: LinkLabel,
      },
      {
        path: 'add',
        name: routeNames.assets.add,
        component: AddAsset,
        props: true,
      },
      {
        path: 'filters',
        name: routeNames.assets.filters,
        component: AssetsFilters,
      },
      {
        path: 'map',
        name: routeNames.assets.map,
        component: AssetsMap,
      },
      {
        path: ':id',
        name: routeNames.assets.detail,
        component: AssetDetail,
        props: true,
        children: [
          {
            path: 'download-temperature',
            name: routeNames.assets.downloadTemperature,
            component: DownloadTemperature,
            props: true,
          },
          {
            path: 'temperature',
            name: routeNames.assets.temperature,
            component: AssetTemperature,
            props: true,
          },
          {
            path: 'link-label',
            name: routeNames.assets.linkAssetLabel,
            component: LinkLabel,
            props: true,
          },
          {
            path: 'download-temperature-require-app',
            name: routeNames.assets.downloadTemperatureRequireApp,
            component: DownloadTemperatureRequireApp,
          },
        ],
      },
    ],
  },
  {
    path: 'selectAsset',
    name: routeNames.assets.selectAsset,
    component: SelectAsset,
    props: true,
  },
];
