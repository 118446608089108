import authEndpoints from './auth';
import userEndpoints from './user';
import assetEndpoints from './assets';
import consignmentEndpoints from './consignments';
import zoneEndpoints from './zones';
import dashboardEndpoints from './dashboards';
import facilityEndpoints from './facilities';
import teamMemberEndpoints from './team-members';
import teamEndpoints from './teams';
import mobileGatewayEndpoints from './mobile-gateway';
import mobileEndpoints from './mobile';

export default {
  ...authEndpoints,
  ...userEndpoints,
  ...assetEndpoints,
  ...consignmentEndpoints,
  ...zoneEndpoints,
  ...dashboardEndpoints,
  ...facilityEndpoints,
  ...teamMemberEndpoints,
  ...teamEndpoints,
  ...mobileGatewayEndpoints,
  ...mobileEndpoints,
};
