import { query, mutate } from './api-consumer';

export default {
  async getTeam(id, redirectOn403Error) {
    return query('getOrganisation', { id }, redirectOn403Error);
  },
  async getTeams(nextToken) {
    return query('getOrganisations', { nextToken, limit: 20 });
  },
  async setTeamName(id, name) {
    return mutate('setOrganisationName', { id, name });
  },
};
