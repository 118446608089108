<template>
  <VerticalContainer>
    <Loader :is-loading="isLoading" />
    <TeamsTable
      v-if="!isLoading"
      :selected-team-id="selectedTeamId"
      @onClickRow="onChangeRow"
    />
    <ErrorMessage :error-message="errorMessage" />
    <template #buttons>
      <Button
        text="Select Team"
        :on-click="select"
        :disabled="isSelecButtonDissabled"
      />
      <Button
        text="Cancel"
        :on-click="goBack"
      />
    </template>
  </VerticalContainer>
</template>
<script>
import Button from '../../components/atoms/button/button';
import TeamsTable from '../../components/organisms/teams/teams-table';
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import ErrorMessage from '../../components/atoms/message/error-message';
import Loader from '../../components/atoms/loader/loader';
import { routeNames } from '../../constants';

export default {
  name: 'TeamsList',
  components: {
    Button,
    ErrorMessage,
    Loader,
    TeamsTable,
    VerticalContainer,
  },
  data() {
    return {
      selectedTeamId: '',
    };
  },
  computed: {
    isSelecButtonDissabled() {
      return this.currentlySelectedTeamId === this.selectedTeamId || this.isLoading;
    },
    currentlySelectedTeamId() {
      return this.$store.state.teams.selectedTeam.id;
    },
    isLoading() {
      return this.$store.state.teams.isLoading;
    },
    errorMessage() {
      return this.$store.state.teams.errorMessage;
    },
  },
  watch: {
    currentlySelectedTeamId() {
      this.$store.dispatch('teamMembers/refreshGetTeamMembers');
    },
  },
  mounted() {
    this.selectedTeamId = this.$store.state.teams.selectedTeam.id;
  },
  methods: {
    onChangeRow(id) {
      this.selectedTeamId = id;
    },
    goBack() {
      this.goToTeamMembersList(this.$route.params.teamId);
    },
    teamWithId(teamId) {
      return this.$store.state.teams.teams.find(({ id }) => id === teamId);
    },
    select() {
      this.$root.trackEvent.switchedTeam(
        this.$store.state.teams.selectedTeam,
        this.teamWithId(this.selectedTeamId),
      );
      this.goToTeamMembersList(this.selectedTeamId);
    },
    goToTeamMembersList(teamId) {
      this.$router
        .push({
          name: routeNames.team.list,
          params: { teamId },
        })
        .catch(() => {});
    },
  },
};
</script>
