<template>
  <div
    v-if="isLoading"
    class="loader-container"
    :class="{ 'padding-top': withPaddingTop }"
  >
    <v-progress-circular
      :color="color"
      indeterminate
      :size="$vuetify.breakpoint.mdAndUp ? 40 : 30"
    />
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    isLoading: { type: Boolean, default: false },
    color: { type: String, default: 'primary' },
    withPaddingTop: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.loader-container {
  width: 100%;
  text-align: center;
  &.padding-top {
    padding-top: 20px;
  }
}
</style>
