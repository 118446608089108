<template>
  <v-row
    dense
    class="main-with-detail-container"
    :class="{
      'two-columns': isDetailVisible && isMainVisible,
      'detail-is-on-top': isMainFullWidth
    }"
  >
    <Loader :is-loading="isLoading" />
    <v-col
      v-if="isMainVisible"
      class="column-container"
      cols="12"
      :md="mainCols"
    >
      <slot />
    </v-col>
    <v-col
      v-if="isDetailVisible"
      class="column-container"
      cols="12"
      :md="detailCols"
    >
      <router-view v-if="!hideDetail" />
    </v-col>
  </v-row>
</template>
<script>
import Loader from '../../atoms/loader/loader';

export default {
  components: { Loader },
  props: {
    isLoading: { type: Boolean, default: false },
    mainRouteName: { type: String, required: true },
    hideDetail: { type: Boolean, default: false },
    isMainFullWidth: { type: Boolean, default: false },
    isDetailFullWidth: { type: Boolean, default: false },
  },
  computed: {
    isDetailVisible() {
      return this.$route.name !== this.mainRouteName;
    },
    mainCols() {
      if (this.isMainFullWidth) return 12;
      if (this.isDetailFullWidth) return 0;
      return this.isDetailVisible ? 6 : 12;
    },
    detailCols() {
      return this.isDetailFullWidth ? 12 : 6;
    },
    isMainVisible() {
      return (!this.isDetailVisible || this.$vuetify.breakpoint.mdAndUp) && !this.isDetailFullWidth;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';

.main-with-detail-container {
  height: 100%;
  margin: 0;
  position: relative;
  .column-container {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 0;
  }
  &.two-columns {
    .column-container:last-of-type {
      @include mq($from: md) {
        padding-left: 15px;
      }
    }
    &:not(.detail-is-on-top) {
      .column-container:first-of-type {
        @include mq($from: md) {
          padding-right: 15px;
        }
      }
    }
    &.detail-is-on-top {
      .column-container {
        @include mq($from: md) {
          position: absolute;
          right: 0px;
          height: 100%;
          z-index: 2;
        }
      }
    }
  }
}
</style>
