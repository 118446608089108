import { query, subscribe } from './api-consumer';

export default {
  async getUser() {
    return query('getCurrentUser');
  },
  subscribeToUserNotifications(userId, callback, errorCallback) {
    return subscribe('subscribeToUserNotifications', { userId }, callback, errorCallback);
  },
};
