<template>
  <fragment>
    <router-view v-if="isChildRoute" />
    <slot v-else />
  </fragment>
</template>
<script>
export default {
  props: {
    parentLevel: { type: Number, required: true },
  },
  computed: {
    isChildRoute() {
      return this.$route.matched.length > this.parentLevel;
    },
  },
};
</script>
