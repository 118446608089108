import { query } from './api-consumer';

export default {
  async getDashboard(organisationId, dashboardId) {
    return query('getOrganisationDashboard', { id: organisationId, dashboardId });
  },
  async getDashboards(organisationId, nextToken) {
    return query('getOrganisationDashboards', { id: organisationId, nextToken, limit: 20 });
  },
};
