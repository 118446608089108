var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.withTitle)?_c('TableTitle',{attrs:{"height":_vm.headerHeight,"title":_vm.title}},[_vm._t("buttons",null,{"slot":"buttons"})],2):_vm._e(),_c('v-simple-table',{key:_vm.isEditing,staticClass:"table-container",attrs:{"dense":"","fixed-header":_vm.height !== null,"height":_vm.tableHeight},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.hasHeaders)?_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,i){return _c('th',{key:i,staticClass:"is-bold header-cell",style:(_vm.columnWidth(i))},[_vm._v(" "+_vm._s(header.name)+" ")])}),0)]):_vm._e(),_c('tbody',{staticClass:"table-body",class:{
          'table-body-without-header': !_vm.hasHeaders,
        }},[_vm._l((_vm.rows),function(row,i){return _c('tr',{key:i,staticClass:"table-row",class:{
            'is-clickable': _vm.rowIsClickable || row.isClickable,
            'is-selected': row.isSelected
          },on:{"click":() => _vm.clickRow(row)}},_vm._l((_vm.headers),function(col,j){return _c('td',{key:j,staticClass:"table-cell",class:{
              'is-bold': j === 0,
              'is-editing': j > 0 && _vm.isEditing && row.isEditable,
              'is-editing-cell': j > 0 && _vm.isEditing
                && _vm.editingRow === i
                && _vm.isBoolean(row.dataType),
              'is-non-editable': j > 0 && _vm.isEditing && !row.isEditable,
              'is-select': col.key === _vm.selectColumnKey,
              'is-removable': row.isDeletable
            },style:(_vm.columnWidth(j)),on:{"keydown":() => {},"click":() => _vm.isEditing &&
              row.isEditable &&
              _vm.editingRow !== i &&
              !_vm.isBoolean(row.dataType)
              ? _vm.setEditingRow(i) : false}},[_c('div',{staticClass:"table-cell-content-wrapper"},[(_vm.isEditing && j > 0 && _vm.isBoolean(row.dataType))?_c('ToggleSwitch',{attrs:{"value":row[col.key]},on:{"onChange":(v) => _vm.onEdit(v, row.name, row.id)}}):(col.key === _vm.selectColumnKey)?_c('ToggleSwitch',{attrs:{"readonly":_vm.isDisabled,"value":!_vm.selectingRow && row.isSelected},on:{"onChange":() => _vm.selectRow(row)}}):(row.isEditable && j > 0 && i === _vm.editingRow)?_c('input',{ref:`edit-input-${i}`,refInFor:true,staticClass:"edit-input",attrs:{"type":"text","autocomplete":"off"},domProps:{"value":row[col.key]},on:{"keyup":(e) => _vm.onEdit(e.target.value, row.name)}}):(_vm.isEditing && row[col.key] === '' && row.placeholder)?_c('span',[_vm._v(" "+_vm._s(row.placeholder)+" ")]):_c('span',{class:{
                  'no-wrap-content': (j === 0 && _vm.hasHeaders)
                    || (i === 0 && !_vm.hasHeaders && j > 0),
                }},[_vm._v(" "+_vm._s(_vm.renderValue(row[col.key]))+" ")]),(row.buttons && row.buttons.length && (j === 1 || row.isDeletable))?_c('ButtonContainer',{staticClass:"buttons-container"},_vm._l((row.buttons),function(button,index){return _c('Button',_vm._b({key:index,staticClass:"row-button"},'Button',button.props,false))}),1):_vm._e()],1)])}),0)}),(_vm.isLoading)?_c('tr',[(_vm.hasHeaders)?_c('td',{staticClass:"loader-cell",attrs:{"colspan":_vm.headers.length}},[_c('Loader',{attrs:{"is-loading":true}})],1):_vm._e()]):_vm._e()],2),_vm._t("default"),(_vm.noMatchingSearchResult && !_vm.isLoading)?_c('div',{staticClass:"no-results-label"},[_vm._v(" "+_vm._s(_vm.noItemsText)+" ")]):_vm._e()]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }