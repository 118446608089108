<template>
  <v-autocomplete
    class="autocomplete"
    :value="value"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :loading="isLoading"
    :disabled="hasValue"
    cache-items
    open-on-clear
    :no-data-text="noDataText"
    :search-input.sync="search"
    append-icon="$vuetify.icons.downArrowIcon"
    solo
    placeholder="Enter or select a value"
    :menu-props="{ contentClass: 'autocomplete-items' }"
    @change="onChange"
  >
    <template #append>
      <div
        v-if="hasSearchText"
        @keydown="clear"
        @click="clear"
      >
        <v-icon>$vuetify.icons.clearIcon</v-icon>
      </div>
    </template>
    <template #append-item>
      <div
        v-intersect="onIntersect"
        class="loader-container"
      >
        &nbsp;
        <slot name="loader" />
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: 'Autocomplete',
  props: {
    value: { type: String, required: true },
    itemText: { type: String, default: 'name' },
    itemValue: { type: String, default: 'id' },
    items: { type: Array, required: true },
    isLoading: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    noDataText: { type: String, default: '' },
  },
  data() {
    return {
      search: null,
      dataItems: this.items,
    };
  },
  computed: {
    hasValue() {
      return typeof this.value !== 'undefined' && this.value !== null && this.value !== '';
    },
    hasSearchText() {
      return this.search !== null && this.search !== '';
    },
  },
  watch: {
    search(value) {
      if (value && !this.hasValue) {
        this.$emit('search', value);
      } else if (value === '' || value === null) {
        this.$emit('clear');
      }
    },
  },
  methods: {
    clear() {
      this.search = null;
    },
    onChange(value) {
      if (value) {
        this.$emit('select', value);
      } else {
        // this.$emit('clear');
      }
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.$emit('lastInView');
      } else {
        this.$emit('lastNotInView');
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
.autocomplete {
  .v-input__slot {
    border-radius: 0;
    box-shadow: 0px 0px 3px 0px rgba($black, 0.2) !important;
    input {
      color: $dark-grey !important;
      font-family: 'Bold-Font' !important;
      font-size: 0.9375rem;
    }
    .v-input__append-inner {
      cursor: pointer;
      margin-right: 4px;
      margin-left: 4px;
      &:last-of-type {
        margin-left: 8px;
      }
    }
  }
}
.autocomplete-items {
  .v-select-list {
    .v-list-item:not(.v-list-item--link):nth-last-of-type(2) {
      min-height: 0 !important;
      .v-list-item__content {
        margin: 0;
        padding: 0;
      }
    }
    .v-list-item__title {
      color: $dark-grey !important;
      font-size: 0.9375rem;
    }
  }
  .loader-container {
    margin: 4px 0;
  }
}
</style>
