import { linkTrackerStatus } from '../constants';

export default {
  methods: {
    async checkGateway(gateway) {
      if (!gateway.gatewayId || gateway.gatewayId === '') {
        return { status: linkTrackerStatus.unknownIssue };
      }
      // get gateway and see whether user has access
      try {
        const zone = await this.$store
          .dispatch('zones/getZoneByGatewayId', { gatewayId: gateway.gatewayId, skipRedirect: true });
        if (zone && zone.id) {
          // zone has been found and user has access to it
          return {
            status: linkTrackerStatus.alreadyLinked,
            zoneId: zone.id,
            teamId: zone.organisationId,
          };
        }
      } catch (error) {
        if (error.message.indexOf('No space found with gatewayId') !== -1) {
          // this means there is no zone associated with this gateway yet :)
          return { status: linkTrackerStatus.availableForLinking };
        } if (error.message.indexOf('User does not have necessary permission') !== -1) {
          this.$root.logger.info(
            'User scanned gateway they do not have access to',
            { gatewayId: gateway.gatewayId, userId: this.$store.state.user.user.id },
          );
          return { status: linkTrackerStatus.noAccess };
        }
      }
      return { status: null };
    },
    async addGatewayToZone(gateway) {
      const selectedZoneId = this.$route.params.id;
      await this.$store.dispatch('zones/addGatewayToZone', { id: selectedZoneId, gateway });

      if (!this.$store.state.zones.errorMessage) {
        const zone = this.$store.state.zones.zones.filter(
          ({ id }) => selectedZoneId === id,
        )[0];
        const { gatewayId } = zone.gateways.filter(
          ({ removedOn }) => removedOn === null,
        )[0];
        this.$root.trackEvent.linkedGateway(zone, gatewayId, false);
      }
    },
  },
};
