<template>
  <Autocomplete
    ref="autocomplete"
    :value="value"
    :items="dataItems"
    :item-text="itemText"
    :item-value="itemValue"
    :is-loading="isLoading || isSearching"
    :error-message="errorMessage"
    :no-data-text="noDataText"
    @search="setSearchTerm"
    @select="selectItem"
    @clear="onClear"
    @lastInView="() => lastInView(true)"
    @lastNotInView="() => lastInView(false)"
  >
    <template #loader>
      <Loader :is-loading="isLoading || isSearching" />
    </template>
  </Autocomplete>
</template>
<script>
import Autocomplete from '../../atoms/input/autocomplete';
import Loader from '../../atoms/loader/loader';

export default {
  components: {
    Autocomplete,
    Loader,
  },
  props: {
    value: { type: String, required: true },
    itemText: { type: String, default: 'name' },
    itemValue: { type: String, default: 'id' },
    items: { type: Array, default: () => ([]) },
    getData: { type: Function, required: true },
    allLoaded: { type: Boolean, default: false },
  },
  data() {
    return {
      dataItems: [...this.items],
      searchTerm: '',
      nextToken: undefined, // intentionally undefined to allow for !== null
      emptySearchNextToken: null, // to enable efficient getting of data when search term is empty
      isLoading: false,
      errorMessage: '',
      isSearching: false,

      firstLoad: true,
      shouldLoadData: true,
      debounceTimeoutId: null,
    };
  },
  computed: {
    hasMoreData() { return this.nextToken !== null; },
    noDataText() {
      if (this.isLoading || this.isSearching) return '';
      return 'No matches';
    },
  },
  methods: {
    clear() { this.$refs.autocomplete.clear(); },
    selectItem(value) { this.$emit('select', value); },
    setSearchTerm(value) {
      this.searchTerm = value;
      this.debounceGetData();
    },
    onClear() {
      this.selectItem(null);
      this.setSearchTerm('');
    },
    resetNextToken() { this.nextToken = undefined; },
    resetSearchTerm() { this.searchTerm = ''; },

    debounceGetData() {
      this.isSearching = true;
      clearTimeout(this.debounceTimeoutId);

      // to fix race condition where first search is not always triggered
      if (this.firstLoad) {
        this.shouldLoadData = true;
      }

      this.debounceTimeoutId = setTimeout(() => {
        this.resetNextToken();
        this.getDataRecursive(true);
        this.isSearching = false;
      }, 800);
    },

    lastInView(shouldLoadData) {
      this.shouldLoadData = shouldLoadData;
      this.getDataRecursive();
    },

    async getDataRecursive(forceGetData = false) {
      if ((!this.isLoading || forceGetData)
        && !this.allLoaded && this.hasMoreData
        && (this.firstLoad || this.shouldLoadData)) {
        const { searchTerm } = this;
        const isEmptySearch = searchTerm === '';
        this.isLoading = true;
        try {
          const { items, nextToken } = await this.getData({
            nextToken: isEmptySearch ? this.emptySearchNextToken : this.nextToken,
            searchTerm,
          });
          this.nextToken = nextToken;
          this.dataItems = [
            ...this.dataItems.filter((x) => !items.find((y) => y.id === x.id)),
            ...items];
          if (isEmptySearch) {
            this.emptySearchNextToken = nextToken;
          }
          // await this.getDataRecursive();
        } catch (error) {
          this.errorMessage = error.message;
        }
        this.firstLoad = false;
        this.isLoading = false;
      }
    },
  },
};
</script>
