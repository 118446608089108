import * as basicQueries from '../graphql/basic/queries';
import * as basicMutations from '../graphql/basic/mutations';
import * as basicSubscriptions from '../graphql/basic/subscriptions';

const allQueries = {};
const allMutations = {};
const allSubscriptions = {};

const basicQueryNames = Object.keys(basicQueries);
const basicMutationNames = Object.keys(basicMutations);
const basicSubscriptionNames = Object.keys(basicSubscriptions);

basicQueryNames.forEach((name) => {
  allQueries[name] = basicQueries[name];
});

basicMutationNames.forEach((name) => {
  allMutations[name] = basicMutations[name];
});

basicSubscriptionNames.forEach((name) => {
  allSubscriptions[name] = basicSubscriptions[name];
});

export const queries = allQueries;
export const mutations = allMutations;
export const subscriptions = allSubscriptions;

export const isBasicAppSync = (name) => basicQueryNames
  .indexOf(name) !== -1
  || basicMutationNames.indexOf(name) !== -1
  || basicSubscriptionNames.indexOf(name) !== -1;
