<template>
  <div class="empty-state-container">
    <div class="messages-container">
      <v-col
        v-for="(content, i) in infoContents"
        :key="i"
        class="message-container"
        :class="{
          'message-wrapper': i !== 0
        }"
        md="4"
      >
        <div
          v-if="i === 0 && $vuetify.breakpoint.smAndDown"
          class="header-background-container"
        >
          <v-img
            class="header-background-image"
            :src="backgroundImage"
            contain
            alt="background image"
          />
          <div class="sky" />
          <div class="ground" />
        </div>
        <div
          v-else
          class="message"
          :class="{
            'heading-message': i === 0 && $vuetify.breakpoint.mdAndUp,
          }"
          tile
        >
          {{ (i !== 0 || $vuetify.breakpoint.mdAndUp) ? content : '' }}
        </div>
      </v-col>
      <ButtonsContainer :display-on-empty-state="true">
        <slot name="buttons" />
      </ButtonsContainer>
    </div>
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="background-container"
    >
      <div class="sky" />
      <div class="ground" />
      <v-img
        class="background-image"
        :src="backgroundImage"
        aspect-ratio="3.5"
        contain
        width="100%"
        height="65%"
        :position="position"
        alt="background image"
      />
    </div>
  </div>
</template>

<script>
import ButtonsContainer from '../../molecules/layout/buttons-container';

export default {
  name: 'EmptyState',
  components: {
    ButtonsContainer,
  },
  props: {
    leftAlignmentPercentage: { type: Number, default: 90 },
    backgroundSource: { type: String, required: true },
    items: { type: Array, default: () => ([]) },
  },
  computed: {
    welcomeMessage() {
      return this.contents[0];
    },
    backgroundImage() {
      return this.backgroundSource;
    },
    infoContents() {
      return ['Welcome to Reelables', ...this.items];
    },
    position() {
      return `left ${this.leftAlignmentPercentage}% center`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';

.empty-state-container {
  position: relative;
  height: 100%;
  margin-bottom: 14px;
  @include mq($from: md) {
    margin-bottom: 0px;
  }
  .messages-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include mq($from: md) {
      padding: 30px;
    }
    .message-container {
      position: relative;
      padding: 0;
      flex: 1 1 0;
      margin: 6px 0;
      @include mq($from: sm) {
        margin: 10px 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
        @include mq($from: sm, $until: md) {
          margin-bottom: 20px;
        }
      }
    }
    .message-wrapper {
      text-align: center;
      background-color: $black;
      padding: 4px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .message {
      font-family: 'Bold-Font';
      font-size: 1rem;
      color: $white;

      @include mq($from: md) {
        font-size: 1.2rem;
      }
    }
    .heading-message {
      font-size: 1.6rem;
      text-align: center;
      background-color: $reelables-dark-grey;
      height: 100%;
      padding: 4px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .background-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    .background-image {
      z-index: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .header-background-container {
    top: 0;
    width: 100%;
    height: 100%;

    .header-background-image {
      z-index: 1;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  .sky {
    position: absolute;
    top: 0;
    background-color: $grey-sky;
    width: 100%;
    height: 30%;
  }
  .ground {
    position: absolute;
    top: 30%;
    background-color: $ground;
    width: 100%;
    height: 70%;
  }
}
</style>
