<template>
  <div class="filter-container">
    <div class="heading is-bold">
      {{ title }}
    </div>
    <div
      v-if="isLoading"
      class="loading-container"
    >
      <v-skeleton-loader
        :width="loaderWidth"
        type="list-item, divider"
      />
    </div>
    <InfiniteAutocomplete
      v-else
      ref="infiniteAutocomplete"
      :value="value"
      :items="items"
      :all-loaded="allLoaded"
      :get-data="getData"
      @select="setSelectedItem"
    />
    <ErrorMessage :error-message="errorMessage" />
  </div>
</template>
<script>
import InfiniteAutocomplete from '../../molecules/autocomplete/infinite-autocomplete';
import ErrorMessage from '../../atoms/message/error-message';

export default {
  name: 'ItemsDropDownFilter',
  components: {
    InfiniteAutocomplete,
    ErrorMessage,
  },
  props: {
    title: { type: String, default: '' },
    value: { type: String, default: '' },
    items: { type: Array, default: () => ([]) },
    allLoaded: { type: Boolean, default: false },
    getData: { type: Function, required: true },
    getItem: { type: Function, required: true },
  },
  data() {
    return {
      isLoading: true,
      errorMessage: '',
    };
  },
  computed: {
    loaderWidth() {
      if (this.$vuetify.breakpoint.xs) return 250;
      if (this.$vuetify.breakpoint.smAndDown) return 280;
      return 225;
    },
  },
  async created() {
    // if there is already an item selected, and this item hasn't been loaded
    // - then get the item
    if (this.value !== '') {
      if (!this.items.some((item) => item.id === this.value)) {
        try {
          await this.getItem(this.value);
        } catch (error) {
          this.errorMessage = error.message;
        }
      }
    }
    this.isLoading = false;
  },
  methods: {
    setSelectedItem(value) {
      this.$emit('change', value);
    },
    reset() {
      this.$refs.infiniteAutocomplete.clear();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';
.filter-container {
  display: flex;
  align-items: baseline;
  @include mq($from: md) {
    flex-direction: column;
    align-items: center;
  }
  .heading {
    color: $dark-grey;
    font-size: 0.9375rem;
    margin-right: 10px;
    @include mq($from: md) {
      margin-bottom: 10px;
    }
  }
  .loading-container {
    padding-bottom: 40px;
  }
}
</style>
