<template>
  <div style="height: 100%">
    <router-view v-if="hasSelectedTeam" />
    <Loader
      v-else
      is-loading
    />
  </div>
</template>
<script>
import Loader from '../components/atoms/loader/loader';

export default {
  components: {
    Loader,
  },
  async beforeRouteUpdate(to, from, next) {
    // handles all changes for the selected team
    // team is selected by changing the URL param
    if (to.params.teamId !== from.params.teamId) {
      await this.$store.dispatch('teams/setSelectedTeam', to.params.teamId);
      this.$root.trackEvent.setSelectedTeamSuperProperty(to.params.teamId);
    }
    next();
  },
  computed: {
    hasSelectedTeam() {
      return this.$store.getters['teams/hasSelectedTeam'];
    },
  },
  async created() {
    await this.$store.dispatch('teams/setSelectedTeam', this.$route.params.teamId);
    this.$root.trackEvent.setSelectedTeamSuperProperty(this.$route.params.teamId);
  },
};
</script>
