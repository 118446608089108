<template>
  <v-btn
    class="button"
    :class="{
      'is-small': isSmall,
    }"
    :color="isWarning ? 'warning' : 'primary'"
    :loading="isLoading"
    :disabled="disabled"
    :to="to"
    depressed
    :text="isText"
    :height="height"
    @click="clickCallback"
  >
    {{ text }}
  </v-btn>
</template>
<script>
export default {
  name: 'ReelablesWebButton',
  props: {
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    text: { type: String, required: true },
    onClick: { type: Function, default: () => {} },
    isText: { type: Boolean, default: false },
    isWarning: { type: Boolean, default: false },
    to: { type: Object, default: () => null },
    isSmall: { type: Boolean, default: false },
  },
  computed: {
    height() {
      if (this.isSmall) return 25;
      return this.$vuetify.breakpoint.mdAndUp ? 50 : 42;
    },
  },
  methods: {
    clickCallback() {
      this.$emit('click');
      if (typeof this.onClick === 'function'
        && !this.isLoading) this.onClick();
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';

.button {
  text-transform: initial !important;
  border-radius: 0 !important;
  padding: 0 10px !important;

  @include mq($from: md) {
    min-width: 120px !important;
  }
  @include mq($from: lg) {
    padding: 0 30px !important;
  }
  span.v-btn__content {
    font-family: 'Bold-Font';
    font-size: 0.875rem;
    @include mq($from: md) {
      font-size: 1rem;
    }
  }
  &.is-small {
    span.v-btn__content {
      font-size: 0.6875rem !important;
      @include mq($from: md) {
        font-size: 0.875rem !important;
      }
    }
  }
}
</style>
