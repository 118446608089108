/* eslint-disable import/prefer-default-export */

export const notificationTypes = {
  bluetoothOff: 'bluetoothOff',
  bluetoothUnsupported: 'bluetoothUnsupported',
  bluetoothUnauthorized: 'bluetoothUnauthorized',
  error: 'error',
};

export function createNotification(message, type, params, isError = false, persistent = false) {
  return {
    message, action: { type, params }, isError, persistent,
  };
}
