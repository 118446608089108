<template>
  <div class="no-team-container">
    You have not been added to a team.
    <br>
    Please ask your administrator to add you.
    <br>
    <br>
    Alternatively, contact
    <EmailLink subject="Account Support: No Team" /> for help.
  </div>
</template>
<script>
import EmailLink from '../../atoms/links/email-link';

export default {
  name: 'NoTeamMessage',
  components: {
    EmailLink,
  },
};
</script>
<style lang="scss" scoped>
.no-team-container {
  margin-top: 20px;
  text-align: center;
  font-size: 1.125rem;
}
</style>
