<template>
  <LineChart
    :chart-options="options"
    :chart-data="chartdata"
  />
</template>
<script>
import { Line as LineChart } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
);

export default {
  components: { LineChart },
  props: {
    chartdata: { type: Object, default: null },
    options: { type: Object, default: null },
  },
};
</script>
