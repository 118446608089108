<template>
  <div class="scan-modal-wrapper">
    <div
      v-if="isBackgroundVisible"
      class="background"
    />
    <transition name="slide-up">
      <div
        v-if="isVisible"
        class="scan-modal-container"
      >
        <div
          class="scan-modal"
        >
          <div>
            Ready to Scan
          </div>
          <img
            class="eye-icon"
            :src="iconImage"
            alt="Scan icon"
          >
          <button
            class="cancel-button is-bold"
            @click="cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import IconImage from '../../../assets/icons/scan-icon.svg';

export default {
  name: 'ScanModal',
  data() {
    return {
      isBackgroundVisible: false,
    };
  },
  computed: {
    isVisible() {
      return this.$store.state.mobileApp.isNfcScanning && this.$store.state.mobileApp.isAndroidApp;
    },
    iconImage() {
      return IconImage;
    },
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        this.isBackgroundVisible = true;
      } else {
        setTimeout(() => {
          this.isBackgroundVisible = false;
        }, 500);
      }
    },
  },
  methods: {
    cancel() {
      this.$store.commit('mobileApp/STOP_NFC_SCAN');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(calc(100% + 10px));
}
.slide-up-enter-to,
.slide-up-leave {
  transform: translateY(0);
}

.scan-modal-wrapper {
  z-index: 100;
  .background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba($black, 0.5);
  }
  .scan-modal-container {
    height: 100%;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    .scan-modal {
      position: fixed;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      padding: 30px;
      color: $soft-blue;
      background-color: $white;
      border-radius: 24px;
      height: 312px;
      max-width: 360px;
      width: calc(100% - 16px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      .cancel-button {
        background-color: $reelables-light-grey;
        width: 80%;
        height: 46px;
        border-radius: 8px;
        color: $white;
        font-size: 1.25rem;
        max-width: 300px;
      }
    }
  }
}
</style>
