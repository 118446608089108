<template>
  <v-timeline-item
    small
    right
    hide-dot
    density="default"
  >
    <template #opposite>
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          cols="5"
          class="text-center"
        >
          <strong>{{ leftHeader }}</strong>
        </v-col>
        <v-col
          class="text-center"
          cols="1"
        />
        <v-col
          cols="5"
          class="text-center"
        >
          <strong>{{ rightHeader }}</strong>
        </v-col>
      </v-row>
    </template>
  </v-timeline-item>
</template>
<script>
export default {
  props: {
    leftHeader: { type: String, default: 'Arrived' },
    rightHeader: { type: String, default: 'Departed' },
  },
};
</script>
