import * as KEYS from './keys';
import eventTracking from '../mixins/event-tracking/event-tracking';
import { logger } from '../mixins/logging/logger';

let isE2eTest = false;

const parametersString = (params) => {
  if (!(params?.length > 0)) return '';
  let string = '?';
  params.forEach((param) => { string += `${param.key}=${param.value}&`; });
  return string.slice(0, -1); // remove trailing '&'
};

const callApp = (path, params = []) => {
  if (!isE2eTest) {
    if (window.webkit?.messageHandlers?.appInterface) {
      // iOS interface
      const payload = {};
      params.forEach((param) => {
        payload[param.key] = param.data;
      });
      window.webkit.messageHandlers.appInterface.postMessage({
        path, payload,
      });
    }
    const parameters = parametersString(params);
    window.location.href = `${KEYS.namespace}://${path}${parameters}`;
  }
};

export const webToApp = {
  login() { callApp(KEYS.login); },
  logout() { callApp(KEYS.logout); },
  startNfcScan() { callApp(KEYS.startNfcScan); },
  stopNfcScan() { callApp(KEYS.stopNfcScan); },
  storeCookie() { callApp(KEYS.storeCookie); },
  startLabelTest(bleId, intervalMs, numberBeacons) {
    const params = [
      { key: 'bleId', value: bleId, data: bleId },
      { key: 'intervalMs', value: intervalMs, data: intervalMs },
      { key: 'numberBeacons', value: numberBeacons, data: numberBeacons }];
    callApp(KEYS.startLabelTest, params);
  },
  refresh() { callApp(KEYS.refresh); },
  getDeviceUUID() { callApp(KEYS.getDeviceUUID); },
  startMobileGateway() { callApp(KEYS.startMobileGateway); },
  stopMobileGateway() { callApp(KEYS.stopMobileGateway); },
  goToBluetoothSettings() { callApp(KEYS.goToBluetoothSettings); },
  tLabel: {
    startDiscovery() { callApp(KEYS.tLabel.startDiscovery); },
    stopDiscovery() { callApp(KEYS.tLabel.stopDiscovery); },
    startDownload(bleIds, timeout = null) {
      const params = [{ key: 'bleIds', value: bleIds.join(','), data: bleIds }];
      if (timeout !== null) params.push({ key: 'timeout', value: timeout });
      callApp(KEYS.tLabel.startDownload, params);
    },
    stopDownload(bleIds) {
      callApp(
        KEYS.tLabel.stopDownload,
        [{ key: 'bleIds', value: bleIds.join(','), data: bleIds }],
      );
    },
  },
};

// setup to the app-to-web functions
export default (store) => {
  window[KEYS.namespace] = {};

  window[KEYS.namespace][KEYS.usingAndroidApp] = () => {
    eventTracking.computed.trackEvent().registerClientApp('Android Wrapper');
    store.commit('mobileApp/USING_ANDROID_APP');
  };

  window[KEYS.namespace][KEYS.usingIosApp] = () => {
    eventTracking.computed.trackEvent().registerClientApp('iOS Wrapper');
    store.commit('mobileApp/USING_IOS_APP');

    return `isLoggedIn=${store.state.auth.signedIn}`;
  };

  window[KEYS.namespace][KEYS.nfcScanSuccess] = (
    nfcId = null,
    isGateway = false,
    latitude = null,
    longitude = null,
    error = null,
  ) => {
    if (!store.state.mobileApp.isNfcScanning) return;

    const location = latitude !== null && longitude !== null
      ? {
        latitude, longitude, error, timestamp: new Date().toISOString(),
      }
      : null;

    if (isGateway === true) {
      const gateway = { gatewayId: nfcId };
      if (location !== null) {
        gateway.location = location;
      }

      store.dispatch('mobileApp/setGateway', gateway);
    } else {
      const label = { nfcId };
      if (location !== null) {
        label.location = location;
      }

      store.dispatch('mobileApp/setLabel', label);
    }
  };

  window[KEYS.namespace].isE2eTest = () => {
    isE2eTest = true;
  };

  window[KEYS.namespace][KEYS.log] = (deviceOS, message, logLevel) => {
    try {
      logger[logLevel](`${deviceOS}: ${message}`);
    } catch (err) {
      logger.error(`Error submitting log for ${deviceOS} app`, { err });
    }
  };

  window[KEYS.namespace][KEYS.registerForPushNotifications] = (registrationData) => {
    store.dispatch('mobileApp/registerForPushNotifications', registrationData);
  };

  window[KEYS.namespace][KEYS.enterEvent] = (enterEventData) => {
    store.dispatch('mobileApp/mobileGatewayEnterEvent', enterEventData);
  };

  window[KEYS.namespace][KEYS.enterEvents] = (enterEventsData) => {
    store.dispatch('mobileApp/mobileGatewayEnterEvents', enterEventsData);
  };

  window[KEYS.namespace][KEYS.exitEvent] = (exitEventData) => {
    store.dispatch('mobileApp/mobileGatewayExitEvent', exitEventData);
  };

  window[KEYS.namespace][KEYS.exitEvents] = (exitEventsData) => {
    store.dispatch('mobileApp/mobileGatewayExitEvents', exitEventsData);
  };

  window[KEYS.namespace][KEYS.snapshotEvent] = (snapshotEventData) => {
    store.dispatch('mobileApp/mobileGatewaySnapshotEvent', snapshotEventData);
  };

  window[KEYS.namespace][KEYS.locationEvent] = (locationData) => {
    store.dispatch('mobileApp/mobileGatewayLocationEvent', locationData);
  };

  window[KEYS.namespace][KEYS.temperatureEvent] = (readingsData) => {
    store.dispatch('mobileApp/mobileGatewayLabelTemperatureReadings', readingsData);
  };

  window[KEYS.namespace][KEYS.deviceUUID] = (deviceUuidData) => {
    store.dispatch('mobileApp/startMobileGateway', deviceUuidData);
  };

  // T-Label data
  window[KEYS.namespace].tLabel = {
    discoveryStatus(status) { store.dispatch('mobileApp/tLabels/setDiscoveryStatus', status); },
    downloadStatus(status) { store.dispatch('mobileApp/tLabels/setDownloadStatus', status); },
    data(data) { store.dispatch('mobileApp/tLabels/setData', data); },
  };
};
