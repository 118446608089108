<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.028"
    height="17.039"
    viewBox="0 0 17.028 17.039"
  >
    <path
      id="Path_679"
      data-name="Path 679"
      :d="d"
      transform="translate(-4174.983 -9010.053)"
      fill="#7c7c7c"
    />
  </svg>
</template>
<script>
export default {
  computed: {
    d() {
      return 'M4188.807,9017.13a6.671,6.671,0,0,1-.907,3.248.128.128,0,0,0,.027.187c1.171,1.167,2.337,2.339,3.511,3.5a1.771,1.771,0,0,1,.573,1.274,1.726,1.726,0,0,1-2.942,1.246q-1.794-1.789-3.582-3.583a.128.128,0,0,0-.187-.027,6.789,6.789,0,0,1-3.861.895,6.916,6.916,0,1,1,6.85-9.534A7.183,7.183,0,0,1,4188.807,9017.13Zm-6.912-5.344a5.184,5.184,0,1,0,5.179,5.19A5.187,5.187,0,0,0,4181.9,9011.786Z';
    },
  },
};
</script>
