import { webToApp } from '../mobileApp/appInterface';
import api from '../api';
import tLabels from './mobileApp/tLabels';
import { logger } from '../mixins/logging/logger';
import { sleep } from '../helpers/async-helpers';

const reformatTimestamp = (timestamp) => new Date(timestamp).toISOString();

export default {
  namespaced: true,
  state: {
    isAndroidApp: false,
    isIosApp: false,
    isNfcScanning: false,
    label: null,
    gateway: null,
    mobileGatewayErrorMessage: '',
    isMobileGatewayActive: false,
    isMobileGatewayLoading: false,
    deviceUuid: null,
  },
  mutations: {
    USING_ANDROID_APP(state) {
      state.isAndroidApp = true;
    },
    USING_IOS_APP(state) {
      state.isIosApp = true;
    },
    START_NFC_SCAN(state) {
      state.isNfcScanning = true;
      webToApp.startNfcScan();
    },
    STOP_NFC_SCAN(state) {
      state.isNfcScanning = false;
      webToApp.stopNfcScan();
    },
    SET_LABEL(state, label) {
      state.label = label;
    },
    RESET_LABEL(state) {
      state.label = null;
    },
    SET_GATEWAY(state, gateway) {
      state.gateway = gateway;
    },
    RESET_GATEWAY(state) {
      state.gateway = null;
    },
    SET_MOBILE_GATEWAY_ACTIVE(state) {
      state.isMobileGatewayActive = true;
    },
    SET_MOBILE_GATEWAY_INACTIVE(state) {
      state.isMobileGatewayActive = false;
    },
    START_LOADING_MOBILE_GATEWAY(state) {
      state.isMobileGatewayLoading = true;
    },
    STOP_LOADING_MOBILE_GATEWAY(state) {
      state.isMobileGatewayLoading = false;
    },
    SET_MOBILE_GATEWAY_ERROR_MESSAGE(state, errorMessage) {
      state.mobileGatewayErrorMessage = errorMessage;
    },
    RESET_MOBILE_GATEWAY_ERROR_MESSAGE(state) {
      state.mobileGatewayErrorMessage = '';
    },
    SET_DEVICE_UUID(state, deviceUuid) {
      state.deviceUuid = deviceUuid;
    },
  },
  actions: {
    onLogin() {
      webToApp.login();
    },
    onLogout() {
      webToApp.logout();
    },
    refresh() {
      webToApp.refresh();
    },
    getDeviceUUID() {
      webToApp.getDeviceUUID();
    },
    async startMobileGateway(context, { deviceUuid, deviceType }) {
      logger.debug('startMobileGateway', { deviceUuid, deviceType });
      context.commit('SET_DEVICE_UUID', deviceUuid);
      context.commit('START_LOADING_MOBILE_GATEWAY');
      try {
        const organisationId = context.rootState.teams.selectedTeam.id;
        const gatewayData = { organisationId, deviceUuid, deviceType };
        const newZone = await api.startMobileGateway(gatewayData);
        context.commit('SET_MOBILE_GATEWAY_ACTIVE');
        webToApp.startMobileGateway();
        return newZone;
      } catch (error) {
        logger.error('startMobileGateway', { error: error.message });
        context.commit('SET_MOBILE_GATEWAY_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_MOBILE_GATEWAY');
      }
    },
    async stopMobileGateway(context) {
      context.commit('SET_MOBILE_GATEWAY_INACTIVE');
      await webToApp.stopMobileGateway();
    },
    async registerForPushNotifications(_, registrationData) {
      await api.registerForPushNotifications(registrationData);
    },
    async mobileGatewayEnterEvent(_, enterEventData) {
      await api.mobileGatewayEnterEvent({
        ...enterEventData,
        timestamp: reformatTimestamp(enterEventData.timestamp),
      });
    },
    async mobileGatewayEnterEvents(_, enterEventsData) {
      await api.mobileGatewayEnterEvents({
        ...enterEventsData,
        labelEvents: enterEventsData.labelEvents.map((le) => ({
          ...le,
          timestamp: reformatTimestamp(le.timestamp),
        })),
      });
    },
    async mobileGatewayExitEvent(_, exitEventData) {
      await api.mobileGatewayExitEvent({
        ...exitEventData,
        timestamp: reformatTimestamp(exitEventData.timestamp),
        determinationTimestamp: reformatTimestamp(exitEventData.determinationTimestamp),
        enterTimestamp: reformatTimestamp(exitEventData.enterTimestamp),
      });
    },
    async mobileGatewayExitEvents(_, exitEventsData) {
      await api.mobileGatewayExitEvents({
        ...exitEventsData,
        labelEvents: exitEventsData.labelEvents.map((le) => ({
          ...le,
          timestamp: reformatTimestamp(le.timestamp),
          determinationTimestamp: reformatTimestamp(le.determinationTimestamp),
          enterTimestamp: reformatTimestamp(le.enterTimestamp),
        })),
      });
    },
    async mobileGatewaySnapshotEvent(_, snapshotEventData) {
      await api.mobileGatewaySnapshotEvent({
        ...snapshotEventData,
        timestamp: reformatTimestamp(snapshotEventData.timestamp),
        metadata: snapshotEventData.metadata.map((d) => ({
          ...d,
          enterTimestamp: reformatTimestamp(d.enterTimestamp),
        })),
      });
    },
    async mobileGatewayLocationEvent(_, locationData) {
      await api.mobileGatewayLocationEvent({
        ...locationData,
        locations: locationData.locations.map((l) => ({
          ...l,
          timestamp: reformatTimestamp(l.timestamp),
        })),
      });
    },
    async mobileGatewayLabelTemperatureReadings(_, readingsData) {
      await api.mobileGatewayLabelTemperatureReadings({
        ...readingsData,
        readings: readingsData.readings.map((r) => ({
          ...r,
          reading: {
            ...r.reading,
            timestamp: reformatTimestamp(r.reading.timestamp),
          },
        })),
      });
    },
    goToBluetoothSettings() {
      webToApp.goToBluetoothSettings();
    },
    async startNfcScan(context) {
      context.commit('START_NFC_SCAN');
      if (context.state.isAndroidApp) {
        const startTime = new Date();
        const timeout = 60000; // 60 secs
        // loop for 60 secs or until isNfcScanning stops
        // This is the same duration the iOS NFC session is valid
        while (context.state.isNfcScanning && new Date() - startTime < timeout) {
          // eslint-disable-next-line no-await-in-loop
          await sleep(1000);
        }
        context.commit('STOP_NFC_SCAN');
      }
    },
    setLabel(context, label) {
      context.commit('STOP_NFC_SCAN');
      context.commit('SET_LABEL', label);
    },
    setGateway(context, gateway) {
      context.commit('STOP_NFC_SCAN');
      context.commit('SET_GATEWAY', gateway);
    },
    storeCookie() {
      webToApp.storeCookie();
    },
    async startLabelTest(context, bleId) {
      await webToApp.startLabelTest(bleId, 125, 80);
    },
  },
  getters: {},
  modules: {
    tLabels,
  },
};
