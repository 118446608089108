<template>
  <div class="get-asset-temperature-container">
    <div class="heading is-bold">
      Get Asset Temperature Data
    </div>
    <div class="text-blocks">
      <div>
        To get temperature data you will need to use our smartphone app
      </div>
      <Button
        text="Use the app"
        :on-click="goToUseApp"
      />
    </div>
    <Button
      text="Close"
      :on-click="goBack"
      is-text
    />
  </div>
</template>

<script>
import Button from '../../components/atoms/button/button';
import { routeNames } from '../../constants';

export default {
  name: 'DownloadTemperatureRequireApp',
  components: {
    Button,
  },
  methods: {
    goBack() {
      this.$router.replace({ name: routeNames.assets.detail });
    },
    goToUseApp() {
      window.location.href = process.env.VUE_APP_USE_APP_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';
.get-asset-temperature-container {
  height: 100%;
  background-color: rgba($black, 0.85);
  color: $white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  .heading {
    font-size: 1.125rem;
  }
  .text-blocks {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-around;
    max-width: 200px;
  }
}
</style>
