import wrapper from '../analytics-wrapper';

export default {
  identifyUser({
    id, email, givenName, familyName,
  }) {
    wrapper.identify(id);
    wrapper.register({ Source: 'Client' });

    wrapper.setPeopleProperties({
      $email: email,
      $user_id: id,
      $name: `${givenName} ${familyName}`,
    });

    wrapper.setPeoplePropertiesOnce({
      'First Login Date': new Date().toISOString(),
    });
  },
  registerClientApp(app) {
    wrapper.register({ 'Client App': app });
  },
  registeredAsAUser(email) {
    wrapper.track('Registered as a user', { $email: email });
  },
  signedOut({ email, id }) {
    wrapper.track('Signed out', { $email: email, $user_id: id });
    wrapper.reset();
  },
  signedIn(email) {
    wrapper.track('Signed in', { $email: email });
  },
  requestedForgotPasswordCode(email) {
    wrapper.track('Requested forgot password code', { $email: email });
  },
  completedPasswordReset(email) {
    wrapper.track('Completed password reset', { $email: email });
  },
};
