<template>
  <VerticalContainer class="text-center">
    <div>
      <div class="not-found-title">
        Not found
      </div>
      <div> {{ message }} </div>
    </div>
    <template #buttons>
      <Button
        text="Return home"
        :on-click="goHome"
        :is-loading="isLoading"
      />
    </template>
  </VerticalContainer>
</template>
<script>
import Button from '../components/atoms/button/button';
import VerticalContainer from '../components/organisms/layout/vertical-container';
import { routeNames } from '../constants';

export default {
  name: 'NotFound',
  components: {
    VerticalContainer,
    Button,
  },
  computed: {
    message() {
      return 'You do not have access to the selected team or the team does not exist';
    },
    isLoading() {
      return this.$store.state.teams.isLoadingGetTeams;
    },
  },
  created() {
    this.$store.dispatch('teams/resetSelectedTeam');
  },
  methods: {
    async goHome() {
      await this.$store.dispatch('teams/refreshGetTeams');
      this.$router.replace({ name: routeNames.home });
    },
  },
};
</script>
<style lang="scss" scoped>
.not-found-title {
  font-family: 'Bold-Font';
  font-size: 1.2rem;
  margin: 40px 0;
}
</style>
