<template>
  <v-tabs
    class="navigation-tabs"
    :height="height"
    grow
    centered
  >
    <v-tab
      :to="assetRoute"
      class="navigation-tab is-bold"
      :ripple="false"
    >
      Assets
    </v-tab>
    <v-tab
      :to="zonesRoute"
      class="navigation-tab is-bold"
      :ripple="false"
    >
      Zones
    </v-tab>
    <v-tab
      v-if="includeTeam"
      :to="teamRoute"
      class="navigation-tab is-bold"
      :ripple="false"
    >
      Team
    </v-tab>
  </v-tabs>
</template>
<script>
import { routeNames } from '../../../constants';

export default {
  name: 'NavigationTabs',
  props: {
    includeTeam: { type: Boolean, default: false },
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.smAndDown
        ? 40
        : 69;
    },
    assetRoute() {
      return {
        name: routeNames.assets.list,
      };
    },
    zonesRoute() {
      return { name: routeNames.zones.list };
    },
    teamRoute() {
      return { name: routeNames.team.list };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';

.navigation-tabs {
  width: 100%;
  position: relative;
  padding: 0 4px;

  @include mq($from: md) {
    margin: 0px 20px;
  }

  &::after {
    display: block;
    content: "";
    background: $white;
    height: 10px;
    width: calc(100%);
    position: absolute;
    bottom: 2px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px -4px rgba(0,0,0,0.75);
    margin-left: -4px;

    @include mq($from: md) {
      bottom: 14px;
    }
  }

  .navigation-tab {
    color: $reelables-light-grey !important;
    text-transform: initial;
    font-size: 12px;
    min-width: 30px;
    padding: 2px 12px 4px;

    @include mq($from: md) {
      font-size: 1rem;
    }

    @include mq($from: lg) {
      font-size: 1.25rem;
    }

    &::before {
      background-color: initial;
    }
  }
}

</style>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';

.navigation-tabs {
  .v-item-group {
    z-index: 1;
    background-color: transparent !important;
  }
  .v-slide-group__wrapper {
    padding-bottom: 10px;
  }
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }
  .v-tabs-bar__content {
    .v-tabs-slider-wrapper {
      background-color: $reelables-light-grey;
      height: 4px !important;
      border-radius: 3px;
      border: solid 2px $reelables-light-grey;
      bottom: -5px;

      @include mq($from: md) {
        bottom: 6.5px;
      }
    }
  }
}
</style>
