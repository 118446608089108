<template>
  <VerticalContainer :is-editing="isEditing">
    <Table
      v-if="!isLoading || isEditing"
      :title="itemTitle"
      :headers="headers"
      first-column-width="30%"
      :rows="rows"
      :is-loading="!isEditing && isLoading"
      :is-editing="isEditing"
      @onChange="onChange"
    />
    <Loader
      v-else
      :is-loading="true"
    />
    <div
      v-if="!isEditing && !isLoading"
      class="explore-panel"
      :class="{
        'has-toggle': explorerToggleOptions.length > 1
      }"
    >
      <div
        v-if="explorerToggleOptions.length > 1"
      >
        <Tabs
          :items="explorerToggleOptions.map(x => typeof x === 'string' ? x : x.displayName)"
          :selected-index="explorerToggle"
          is-small
          @change="updateExplorerToggle"
        />
      </div>

      <div
        v-if="explorerToggleOptions.length > 0 && activeTab.component"
        class="container-with-drop-shadow"
      >
        <component
          :is="activeTab.component"
          v-bind="activeTab.componentProps"
        />
      </div>
      <slot
        v-if="explorerToggle === 0"
        name="explore-item-1"
      />
      <slot
        v-if="explorerToggle === 1"
        name="explore-item-2"
      />
      <slot
        v-if="explorerToggle === 2"
        name="explore-item-3"
      />
      <slot
        v-if="explorerToggle === 3"
        name="explore-item-4"
      />
      <slot
        v-if="explorerToggle === 4"
        name="explore-item-5"
      />
    </div>
    <div class="error-message-container">
      <ErrorMessage
        :error-message="errorMessage"
        is-large
      />
    </div>
    <template
      v-if="isEditing"
      #buttons
    >
      <Button
        v-if="showDeleteButton"
        :is-loading="isDialogueVisible && isLoading"
        is-warning
        text="Delete"
        :disabled="isLoading && !isSaveDisabled"
        :on-click="displayDeleteItemDialogue"
      />
      <Button
        :is-loading="!isDialogueVisible && isLoading"
        text="Save"
        :on-click="saveCurrentItem"
        :disabled="isSaveDisabled"
      />
      <Button
        text="Cancel"
        :on-click="endEditing"
        :disabled="isLoading"
      />
    </template>
    <template
      v-else
      #buttons
    >
      <Button
        v-if="displayEdit"
        text="Edit"
        :on-click="edit"
        :is-loading="isLoadingInBackground"
        :disabled="isLoading || isLoadingInBackground || $root.isViewer"
      />
      <Button
        text="Close"
        :on-click="close"
        :disabled="isLoading"
      />
    </template>
    <YesNoDialogue
      :is-visible="isDialogueVisible"
      :title="dialogueTitle"
      @no="() => isDialogueVisible = false"
      @yes="clickedYesOnDialogue"
    />
  </VerticalContainer>
</template>

<script>
import Table from '../../atoms/table/table';
import ErrorMessage from '../../atoms/message/error-message';
import Loader from '../../atoms/loader/loader';
import Button from '../../atoms/button/button';
import updateDateTimes from '../../../mixins/update-date-times';
import YesNoDialogue from '../../molecules/dialogues/yes-no-dialogue';
import rules from '../../../mixins/input-validation-rules';
import VerticalContainer from '../layout/vertical-container';
import Tabs from '../../atoms/tabs/tabs';

export default {
  name: 'ItemDetail',
  components: {
    Table,
    Loader,
    Button,
    ErrorMessage,
    YesNoDialogue,
    VerticalContainer,
    Tabs,
  },
  mixins: [updateDateTimes, rules],
  props: {
    itemTitle: { type: String, required: true },
    item: { type: Object, required: true },
    rows: { type: Array, default: () => [] },
    explorerToggleOptions: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    isLoadingInBackground: { type: Boolean, default: false },
    saveItem: { type: Function, required: true },
    deleteItem: { type: Function, required: true },
    deleteTracker: { type: Function, required: true },
    deleteItemDialogueTitle: { type: String, required: true },
    deleteTrackerDialogueTitle: { type: String, required: true },
    error: { type: String, default: '' },
    displayEdit: { type: Boolean, default: true },
  },
  data() {
    const { tab } = this.$route.query;
    const startingTabIndex = this.explorerToggleOptions.findIndex((d) => d.slug === tab);
    const data = {
      dialogueTitle: '',
      isEditing: false,
      newName: '',
      nameErrorMessage: '',
      isDialogueVisible: false,
      lastSeenTime: null,
      startingTabIndex: startingTabIndex > -1 ? startingTabIndex : 0,
    };
    data.explorerToggle = data.startingTabIndex;
    return data;
  },
  computed: {
    showDeleteButton() {
      return this.$store.getters['user/isAdmin'] || this.$store.getters['user/isOwner'];
    },
    headers() {
      return [{ key: 'name' }, { key: 'value' }];
    },
    errorMessage() {
      return [this.nameErrorMessage, this.error]
        .filter((message) => message && message !== '')
        .join(', ');
    },
    isSaveDisabled() {
      if (this.isDialogueVisible) return true;

      return this.isEditing
        && (this.errorMessage !== ''
            || (this.item && this.newName === this.item.name));
    },
    activeTab() {
      return this.explorerToggleOptions[this.explorerToggle];
    },
  },
  watch: {
    item(newItem, oldItem) {
      // reset state when viewing a different facility
      // and retaining state when page is refreshed
      const hasPageRefreshed = Object.keys(oldItem).length === 0;
      if (newItem.id !== oldItem.id && !hasPageRefreshed) {
        this.endEditing();
        this.reRouteToCorrectTeam();
        // reset map-timeline toggle
        this.explorerToggle = 0;
      }
    },
    newName() {
      this.$emit('newName', this.newName);
    },
  },
  methods: {
    updateExplorerToggle(index) {
      this.explorerToggle = index;
      this.$emit('toggleIndex', index);

      const explorerToggleOption = this.explorerToggleOptions[index];
      if (typeof explorerToggleOption !== 'string' && !!explorerToggleOption.slug) {
        // update URL query string
        this.$router.push({
          query: { tab: explorerToggleOption.slug },
        });
      }
    },
    onChange({ value }) {
      this.newName = value;
      this.nameErrorMessage = '';
    },
    edit() {
      this.$emit('startEditing');
      this.newName = this.item.name;
      this.isEditing = true;
    },
    endEditing() {
      this.$emit('stopEditing');
      this.nameErrorMessage = '';
      this.isEditing = false;
      this.newName = '';
    },
    close() {
      this.$emit('close');
    },
    displayDeleteItemDialogue() {
      this.dialogueTitle = this.deleteItemDialogueTitle;
      this.isDialogueVisible = true;
    },
    displayDeleteTrackerDialogue() {
      this.dialogueTitle = this.deleteTrackerDialogueTitle;
      this.isDialogueVisible = true;
    },
    async clickedYesOnDialogue() {
      switch (this.dialogueTitle) {
        case this.deleteItemDialogueTitle:
          await this.deleteItem();
          this.isDialogueVisible = false;
          break;
        case this.deleteTrackerDialogueTitle:
          await this.deleteTracker();
          this.isDialogueVisible = false;
          break;
        default:
          break;
      }
    },
    async saveCurrentItem() {
      const isNameValid = this.validateName(this.newName);
      if (isNameValid === true) {
        await this.saveItem(this.newName);
        this.endEditing();
      } else {
        this.nameErrorMessage = isNameValid;
      }
    },
    reRouteToCorrectTeam() {
      if (this.item && this.item.organisationId
        && this.item.organisationId !== this.$route.params.teamId) {
        // in the case where the teamId doesn't match that of the item
        // e.g. when a user scans a label that is already linked to an item
        // and they choose to 'view item'
        this.$router.replace({ params: { teamId: this.item.organisationId } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';

.explore-panel {
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;

}
.error-message-container {
  margin: 20px 0;
}

.container-with-drop-shadow{
  height: 100%;
  box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
}
</style>
