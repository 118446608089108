<template>
  <SearchInput
    placeholder="Search for assets"
    :value="searchTerm"
    :filters-applied="areFiltersApplied"
    @changeValue="setSearchTerm"
    @openFilters="openFilters"
    @clear="closeAssetDetail"
  />
</template>
<script>
import SearchInput from '../../atoms/input/search-input';
import { routeNames } from '../../../constants';

export default {
  name: 'AssetsSearch',
  components: {
    SearchInput,
  },
  data() {
    return {
      timeoutId: null,
    };
  },
  computed: {
    searchTerm() {
      return this.$store.state.assets.searchTerm;
    },
    areFiltersApplied() {
      return this.$store.getters['assets/anyFiltersApplied'];
    },
    isAssetDetailOpen() {
      return this.$route.name === routeNames.assets.detail;
    },
  },
  watch: {
    searchTerm() {
      this.searchAssetsDebounced();
    },
  },
  created() {
    this.$store.commit('assets/RESET_SEARCH_TERM');
  },
  methods: {
    setSearchTerm(value) {
      this.$store.commit('assets/SET_SEARCH_TERM', value);

      if (value) {
        this.closeAssetDetail();
      }
    },
    searchAssetsDebounced() {
      // cancel pending call
      clearTimeout(this.timeoutId);

      // delay new call 500ms
      this.timeoutId = setTimeout(() => {
        this.$store.dispatch('assets/refreshGetAssets').then(() => {
          this.$root.trackEvent.searchedAssets(
            this.searchTerm,
            this.$store.state.assets.assets.length,
          );
        });
      }, this.$store.getters['assets/isSearching'] ? 300 : 0); // makes clear search instant
    },
    openFilters() {
      this.$router.push(
        {
          name: routeNames.assets.filters,
          query: this.$route.query,
        },
      ).catch(() => {
        // duplicate navigation - filters already open
      });
    },
    closeAssetDetail() {
      if (this.isAssetDetailOpen) {
        this.$router.push({
          name: routeNames.assets.list,
          query: this.$route.query,
        }).catch(() => {
        // duplicate navigation - already on assets list
        });
      }
    },
  },
};
</script>
