<template>
  <form
    class="form-container"
    @keydown="onKeydown"
    @keyup.enter="$emit('submit')"
    @submit.prevent=""
  >
    <div
      v-if="title !== ''"
      class="is-bold form-title"
    >
      {{ title }}
    </div>
    <slot />
    <ErrorMessage :error-message="errorMessage" />
    <div
      class="button-container"
      :class="{
        'inline-buttons': inlineButtons
      }"
    >
      <slot name="buttons" />
    </div>
  </form>
</template>
<script>
import ErrorMessage from '../../atoms/message/error-message';

export default {
  components: { ErrorMessage },
  props: {
    /**
     * E.g. for clearing the error
     */
    onKeydown: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    errorMessage: { type: String, default: '' },
    inlineButtons: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss">
@import '@/styles/_breakpoints.scss';

.form-container {
  margin: 0 30px;

  @include mq($from: md) {
    max-width: 500px;
    margin: 0 auto;
  }

  .form-title {
    font-size: 16px;
    line-height: 17px;
  }
}
.button-container {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 20px;
  @include mq($from: md) {
    margin-top: 30px;
  }
  > * {
    margin-bottom: 20px;
  }
  &.inline-buttons {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    > * {
      margin-right: 10px;
      margin-left: 10px;
      @include mq($from: md) {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }
  .button {
    min-width: 140px !important;
  }
}
</style>
