<template>
  <FormContainer
    :on-keydown="resetValidation"
    :error-message="errorMessage"
    :title="title"
    @submit="submit"
  >
    <fragment v-if="isStep1">
      <AuthMessage>
        Don't worry. Resetting your password is easy,
        just tell us the email address your account
        is registered to.
      </AuthMessage>
      <TextInput
        key="email"
        ref="email"
        v-model="email"
        label="Email"
        autocomplete="email"
        :error-message="emailError"
      />
    </fragment>
    <fragment v-else-if="isStep2">
      <AuthMessage>
        We have sent a code to <Email>{{ email }}</Email>.
        Please enter the code into the box below and choose a new password.
      </AuthMessage>
      <TextInput
        key="code"
        ref="code"
        v-model="code"
        label="Code"
        :error-message="codeError"
      />
      <PasswordInput
        ref="password"
        v-model="password"
        is-new
        :error-message="passwordError"
      />
    </fragment>
    <fragment v-else>
      <AuthMessage>
        Your password has been reset. You can now login using your new password.
      </AuthMessage>
    </fragment>
    <template #buttons>
      <Button
        v-if="isStep1"
        :is-loading="isLoading"
        text="Send code"
        :on-click="sendCodeClick"
      />
      <Button
        v-if="isStep2"
        :is-loading="isLoading"
        text="Reset password"
        :on-click="resetPasswordClick"
      />
      <Button
        text="Return to login"
        :on-click="() => $emit('cancel')"
        is-text
      />
    </template>
  </FormContainer>
</template>
<script>
import TextInput from '../../atoms/input/text-input';
import PasswordInput from '../input/password-input';
import Button from '../../atoms/button/button';
import FormContainer from './form-container';
import rules from '../../../mixins/input-validation-rules';
import Email from './email-address';
import AuthMessage from './auth-message';

export default {
  name: 'ForgotPasswordForm',
  components: {
    Button,
    TextInput,
    PasswordInput,
    FormContainer,
    Email,
    AuthMessage,
  },
  mixins: [rules],
  data() {
    return {
      emailError: '',
      email: '',
      code: '',
      codeError: '',
      password: '',
      passwordError: '',
    };
  },
  computed: {
    errorMessage() {
      return this.$store.state.auth.errorMessage;
    },
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
    step() {
      return this.$store.state.auth.passwordResetStep;
    },
    isStep1() {
      return this.step === 1;
    },
    isStep2() {
      return this.step === 2;
    },
    title() {
      if (this.isStep1) return 'Forgot your password?';

      if (this.isStep2) return 'Reset your password';

      return 'Password reset success';
    },
    validCode() {
      return this.code !== '';
    },
  },
  methods: {
    resetValidation(event) {
      this.$store.commit('auth/CLEAR_ERROR_MESSAGE');
      if (this.$refs.email && event.target.name === this.$refs.email.name) {
        this.emailError = '';
      }
      if (this.$refs.code && event.target.name === this.$refs.code.name) {
        this.codeError = '';
      }
      if (this.$refs.password && event.target.name === this.$refs.password.name) {
        this.passwordError = '';
      }
    },
    validateForm() {
      let isValid = true;
      if (this.isStep1) {
        if (!this.validateEmail(this.email)) {
          this.emailError = 'Please enter a valid email address';
          isValid = false;
        }
      } else {
        if (!this.validCode) {
          this.codeError = 'Please enter the reset code';
          isValid = false;
        }
        if (typeof this.validatePassword(this.password) === 'string') {
          this.passwordError = `Password must contain ${this.validatePassword(this.password)}`;
          isValid = false;
        }
      }

      return isValid;
    },
    submit() {
      if (this.isStep1) {
        this.sendCodeClick();
      } else if (this.isStep2) {
        this.resetPasswordClick();
      }
    },
    async sendCodeClick() {
      if (this.validateForm() && !this.isLoading) {
        this.$store.dispatch('auth/getResetPasswordCode', this.email.toLowerCase()).then(() => {
          this.$root.trackEvent.requestedForgotPasswordCode(this.email.toLowerCase());
        });
      }
    },
    async resetPasswordClick() {
      if (this.validateForm() && !this.isLoading) {
        this.$store.dispatch('auth/resetPassword', {
          email: this.email.toLowerCase(),
          code: this.code,
          password: this.password,
        }).then(() => {
          this.$root.trackEvent.completedPasswordReset(this.email.toLowerCase());
        });
      }
    },
  },
};
</script>
