<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    :is-selecting="isSelectZone"
    :is-disabled="isDisabled"
    @clickRow="clickRow"
  >
    <slot
      slot="buttons"
      name="buttons"
    />
  </ItemsTable>
</template>
<script>
import ItemsTable from '../table/items-table';
import { routeNames, table } from '../../../constants';
import dateTimeHelper from '../../../mixins/date-time-helper';
import updateDateTimes from '../../../mixins/update-date-times';

export default {
  name: 'ZonesTable',
  components: {
    ItemsTable,
  },
  mixins: [dateTimeHelper, updateDateTimes],
  props: {
    isSelectZone: { type: Boolean, default: false },
    selectedId: { type: String, default: null },
    isDisabled: { type: Boolean, default: false },
    itemIds: { type: Array, default: () => [] },
  },
  computed: {
    headers() {
      const headers = [{ name: 'Name', key: 'name' }];

      if (this.isSelectZone) {
        headers.unshift({
          key: table.selectColumnKey,
        });
      } else {
        headers.push(...[
          { name: '# Assets', key: 'assets' },
          { name: 'Last seen', key: 'lastSeen' },
        ]);
      }

      headers.push({ name: 'Gateway status', key: 'status' });

      return headers;
    },
    rows() {
      return this.$store.state.zones.zones
        .filter((zone) => (this.itemIds.length === 0 || this.itemIds.includes(zone.id)))
        .map((zone) => (
          {
            // need to include the timer, so that the computed property is re-computed every 10 secs
            timer: this.timer,
            id: zone.id,
            name: zone.name,
            assets: this.numberAssetsInSpace(zone),
            lastSeen: this.getLastSeen(zone.lastSeen || zone, true) || 'No data',
            status: zone.status,
            hasGateway: zone.hasGateway, // required when linking gateway to existing zone
            isSelected: this.selectedId && this.selectedId === zone.id,
          }
        ));
    },
    hasMoreData() {
      return this.$store.getters['zones/hasMoreZones'];
    },
    isLoading() {
      return this.$store.state.zones.isLoadingZones;
    },
  },
  methods: {
    getData() {
      if (this.hasMoreData) {
        return this.$store.dispatch('zones/getZones');
      }
      return null;
    },
    clickRow(row) {
      if (!this.isSelectZone) {
        this.goToZoneDetail(row);
      } else {
        this.$emit('clickRow', row);
      }
    },
    goToZoneDetail(row) {
      this.$router.push({
        name: routeNames.zones.detail,
        params: {
          id: row.id,
        },
      }).catch(() => { });
    },
    numberAssetsInSpace(zone) {
      if (zone.numberAssetsInSpace !== null
          && zone.numberAssetsInSpace > 0) {
        return zone.numberAssetsInSpace;
      }
      return '0';
    },
  },
};
</script>
