<template>
  <RouteWithChildren :parent-level="parentLevel">
    <ItemDetail
      :key="explorerToggleOptions.length"
      ref="itemDetail"
      :item-title="asset ? asset.name : ''"
      :item="asset || {}"
      :rows="rows"
      :is-loading="isLoading"
      :is-loading-in-background="isLoadingInBackground"
      :save-item="save"
      :delete-item="deleteAsset"
      :delete-tracker="deleteLabel"
      :delete-item-dialogue-title="deleteAssetDialogueTitle"
      :delete-tracker-dialogue-title="deleteLabelDialogueTitle"
      :error="errorMessage"
      :display-edit="fromFacility? false: true"
      :has-more-locations="hasMoreLocations"
      :explorer-toggle-options="explorerToggleOptions"
      @close="close"
      @startEditing="() => isEditing = true"
      @stopEditing="() => isEditing = false"
    />
    <ButtonsDialogue
      :is-visible="isLinkingLabelDialogueVisible"
      :title="linkLabelDialogueTitle"
      :message="linkLabelDialogueMessage"
    >
      <Button
        v-if="isYesButtonVisible"
        :is-loading="isYesButtonLoading"
        text="Yes"
        is-warning
        :on-click="replaceCurrentLabel"
      />
      <Button
        v-if="isRedirectButtonVisible"
        :text="redirectButtonText"
        :on-click="redirectClicked"
      />
      <Button
        :text="dismissDialogueButtonText"
        :on-click="resetLinkingDialogue"
      />
    </ButtonsDialogue>
  </RouteWithChildren>
</template>

<script>
import {
  linkTrackerStatus, routeNames, facilityTabSlugs, assetDetailTabSlugs,
} from '../../constants';
import RouteWithChildren from '../../components/organisms/layout/route-with-children';
import ItemDetail from '../../components/organisms/items/item-detail';
import linkLabelHelper from '../../mixins/link-label-helper';
import dateTimeHelper from '../../mixins/date-time-helper';
import updateDateTimes from '../../mixins/update-date-times';
import ButtonsDialogue from '../../components/molecules/dialogues/dialogue-with-buttons';

import AssetTimeline from '../../components/organisms/assets/asset-timeline';
import MapWithPopup from '../../components/molecules/map/map-with-popup';
import AssetTemperatureZonesChart from '../../components/organisms/assets/asset-temperature-zones-chart';

import Button from '../../components/atoms/button/button';
import constructAssetCurrentlyIn from '../../helpers/currently-in-helper';

export default {
  name: 'AssetDetail',
  components: {
    RouteWithChildren,
    ItemDetail,
    ButtonsDialogue,
    Button,
  },
  mixins: [linkLabelHelper, dateTimeHelper, updateDateTimes],
  props: {
    id: { type: String, required: true },
    isFacilityAssetDetail: { type: Boolean, default: false },
    isPrintJobAssetDetail: { type: Boolean, default: false },
  },
  data() {
    return {
      isRedirectButtonVisible: false,
      redirectButtonText: 'View Asset',
      isYesButtonVisible: false,
      isYesButtonLoading: false,
      isLinkingLabelDialogueVisible: false,
      linkLabelDialogueTitle: '',
      linkLabelDialogueMessage: '',
      dismissDialogueButtonText: 'Cancel',
      deleteAssetDialogueTitle: 'Are you sure you want to delete this asset?',
      scannedLabelForAssetId: null,
      scannedLabelForTeamId: null,
      loadingDataForItemIds: [],
      isEditing: false,
    };
  },
  computed: {
    parentLevel() {
      if (this.isPrintJobAssetDetail) {
        return 5;
      }
      if (this.isFacilityAssetDetail) {
        return 4;
      }
      return 3;
    },
    fromFacility() { return this.isFacilityAssetDetail || this.isPrintJobAssetDetail; },
    explorerToggleOptions() {
      const options = [];
      if (this.includeTimeline) {
        options.push({
          displayName: 'Timeline',
          slug: assetDetailTabSlugs.timeline,
          component: AssetTimeline,
          componentProps: {
            assetId: this.id,
            displayLocationTimeline: this.displayLocationTimeline,
          },
        });
      }
      if (this.hasLocation) {
        options.push({
          displayName: 'Map',
          slug: assetDetailTabSlugs.map,
          component: MapWithPopup,
          componentProps: {
            locations: this.locations,
            timer: this.timer,
            hasMoreLocations: this.hasMoreLocations,
          },
        });
      }
      if (this.hasTemperatureData) {
        options.push({
          displayName: 'Temperature',
          slug: assetDetailTabSlugs.temperature,
          component: AssetTemperatureZonesChart,
          componentProps: {
            assetId: this.id,
            isAssetDataLoading: this.isLoading || this.isLoadingInBackground,
          },
        });
      }
      return options;
    },
    locations() {
      if (!this.item?.locations?.items) {
        return [];
      }
      return this.item.locations.items
        .map(({
          longitude, latitude, timestamp, error, updatedAt,
        }) => ({
          longitude,
          latitude,
          error,
          properties: {
            id: this.item.id,
            name: this.item.name,
            timestamp: updatedAt || timestamp,
          },
        }));
    },
    hasLocation() {
      return this.locations.length > 0;
    },
    asset() {
      return this.$store.state.assets.assets.find(({ id }) => id === this.id);
    },
    item() {
      return this.asset || {};
    },
    assetIsLoaded() {
      return !!this.asset;
    },
    itemAlreadyLoaded() {
      return typeof this.asset !== 'undefined';
    },
    assetHasLabel() {
      return this.asset && this.asset.bleId !== null;
    },
    isTemperatureLabel() {
      return !!this.asset?.temperatures;
    },
    hasTemperatureData() {
      return this.asset?.temperatures?.items?.length;
    },
    displayLocationTimeline() {
      return (!this.$root.workspaceFeatures.assetSpaceHistoryTimeline
        || ((this.$store.state.assets.assetInSpaces
          .assetsData[this.id] || {}).items || []).length === 0)
        && this.asset.locations.items.filter((l) => !!l.address?.city).length > 0;
    },
    includeTimeline() {
      return this.assetIsLoaded
        && !this.hasError
        && (this.$root.workspaceFeatures.assetSpaceHistoryTimeline
        || this.$root.workspaceFeatures.assetLocationTimeline);
    },
    hasMoreLocations() {
      return ((this.asset || {}).locations || {}).nextToken !== null;
    },
    scannedLabel() {
      return this.$store.state.mobileApp.label;
    },
    scannedGateway() {
      return this.$store.state.mobileApp.gateway;
    },
    deleteLabelDialogueTitle() {
      return this.itemAlreadyLoaded
        ? `Are you sure you would like to delete label from "${this.asset.name}"?`
        : '';
    },
    isLoading() {
      return !this.isLoadingInBackground && this.$store.state.assets.isLoadingAsset;
    },
    isLoadingInBackground() {
      return this.loadingDataForItemIds.length > 0;
    },
    rows() {
      if (!this.itemAlreadyLoaded) return [];
      const labelRowButtons = [{
        props: {
          text: 'Link Label',
          isText: true,
          isSmall: true,
          onClick: this.linkLabel,
          disabled: this.$root.isViewer,
        },
      }];

      if (this.assetHasLabel) {
        labelRowButtons.push({
          props: {
            text: 'Delete',
            isText: true,
            isWarning: true,
            isSmall: true,
            onClick: () => this.$refs.itemDetail.displayDeleteTrackerDialogue(),
            disabled: this.$root.isViewer,
          },
        });
      }

      const rows = [
        {
          // need to include the timer, so that the computed property is re-computed every 10 secs
          timer: this.isEditing ? null : this.timer,
          name: 'Name',
          value: this.asset.name,
          isEditable: true,
        },
        {
          name: 'Currently in',
          value: constructAssetCurrentlyIn(
            this.asset,
            'Not in a zone',
            this.$root.workspaceFeatures.assetFacilityEpisodes,
          ),
        },
      ];

      rows.push({ name: 'First seen', value: this.getFirstSeen(this.asset) || 'No data' });
      rows.push({ name: 'Last seen', value: this.getLastSeen(this.asset) || 'No data' });

      if (!this.fromFacility) {
        rows.push({
          name: 'Has label',
          value: this.assetHasLabel,
          buttons: labelRowButtons,
        });
      }

      if (this.isTemperatureLabel) {
        const buttons = [];
        if (this.hasTemperatureData) {
          buttons.push({
            props: {
              text: 'View Data',
              isText: true,
              isSmall: true,
              onClick: () => this.viewTemperatureData(),
            },
          });
        }
        if (this.$root.featureToggles.includeTemperatureGetData(this.$store.state.mobileApp)) {
          buttons.push({
            props: {
              text: 'Get data',
              isText: true,
              isSmall: true,
              onClick: this.getLabelTemperatureData,
              disabled: this.$root.isViewer,
            },
          });
        }
        rows.push({
          name: 'Last temperature',
          value: this.hasTemperatureData
            ? `${this.asset.temperatures.items[0].degreesCelcius.toFixed(1)}C`
            : 'No data',
          buttons,
        });
      }

      return rows;
    },
    showDeleteButton() {
      return this.assetHasLabel
        && (this.$store.getters['user/isAdmin'] || this.$store.getters['user/isOwner']);
    },
    errorMessage() {
      return this.$store.state.assets.errorMessage;
    },
    hasError() {
      return this.errorMessage !== '';
    },
    isMobileApp() {
      return this.$store.getters.isMobileApp;
    },
  },
  watch: {
    id() {
      this.getItemData();
    },
    isMobileApp() {
      this.startPolling();
    },
    async scannedLabel() {
      if (this.scannedLabel !== null) {
        this.resetLinkingDialogue(true);
        const labelStatus = await this.checkLabel(this.scannedLabel);
        switch (labelStatus.status) {
          case linkTrackerStatus.availableForLinking:
            if (this.assetHasLabel) {
              this.linkLabelDialogueTitle = 'Asset already has a label';
              this.linkLabelDialogueMessage = "Select 'yes' to replace the original label";
              this.isLinkingLabelDialogueVisible = true;
              this.isYesButtonVisible = true;
              this.dismissDialogueButtonText = 'No';
            } else {
              await this.addLabelToAsset(this.scannedLabel);
              this.resetLinkingDialogue();
            }
            break;
          case linkTrackerStatus.noAccess:
            this.linkLabelDialogueTitle = 'Error';
            this.linkLabelDialogueMessage = 'This label is linked to an asset you do not have access to';
            this.isLinkingLabelDialogueVisible = true;
            break;
          case linkTrackerStatus.alreadyLinked:
            this.linkLabelDialogueTitle = 'Label scanned';
            this.linkLabelDialogueMessage = 'This label is already linked to an asset';
            this.isRedirectButtonVisible = true;
            this.isLinkingLabelDialogueVisible = true;
            this.scannedLabelForAssetId = labelStatus.assetId;
            this.scannedLabelForTeamId = labelStatus.teamId;
            break;
          default:
            this.linkLabelDialogueTitle = 'Unknown Error';
            this.linkLabelDialogueMessage = 'Please try again later';
            this.isLinkingLabelDialogueVisible = true;
        }
      }
    },
    async scannedGateway() {
      if (this.scannedGateway !== null) {
        this.resetLinkingDialogue(true);
        this.linkLabelDialogueTitle = 'Gateway Scanned';
        this.linkLabelDialogueMessage = "You cannot link a gateway to an asset. Click on 'View Zones' if you would like to link a gateway";
        this.isLinkingLabelDialogueVisible = true;
        this.redirectButtonText = 'View Zones';
        this.isRedirectButtonVisible = true;
      }
    },
  },
  created() {
    this.getItemData();
    this.startPolling();
  },
  beforeDestroy() {
    // stop data polling
    this.$store.dispatch('assets/stopPollingAsset');
  },
  methods: {
    startPolling() {
      if (this.$root.featureToggles.includeAssetDataPolling(this.$store.state.mobileApp, this)) {
        this.$store.dispatch('assets/startPollingAsset', {
          id: this.id,
          condition: () => !this.isEditing,
        });
      }
    },
    async getItemData() {
      if (this.itemAlreadyLoaded) this.loadingDataForItemIds.push(this.id);
      const assetData = await this.$store.dispatch('assets/getAsset', this.id);
      this.loadingDataForItemIds = this.loadingDataForItemIds.filter((id) => id !== assetData?.id);
    },
    close() {
      if (this.isFacilityAssetDetail) {
        this.$router.push({
          name: routeNames.facilities.detail,
          query: { tab: facilityTabSlugs.assets },
        });
      } else if (this.isPrintJobAssetDetail) {
        this.$router.push({
          name: routeNames.facilities.printerDetail,
          params: { id: this.$route.params.id, printerId: this.$route.params.printerId },
        });
      } else {
        this.$router.push({ name: routeNames.assets.list, query: this.$route.query });
      }
    },
    redirectClicked() {
      let route = null;
      if (this.scannedGateway !== null) {
        route = { name: routeNames.zones.list };
      } else {
        route = {
          name: routeNames.assets.detail,
          params: {
            id: this.scannedLabelForAssetId,
            teamId: this.scannedLabelForTeamId,
          },
        };
      }
      this.$router.push(route).catch(() => {});
      this.resetLinkingDialogue();
    },
    resetLinkingDialogue(skipStoreReset = false) {
      this.isRedirectButtonVisible = false;
      this.redirectButtonText = 'View Asset';
      this.isYesButtonVisible = false;
      this.isYesButtonLoading = false;
      this.isLinkingLabelDialogueVisible = false;
      this.linkLabelDialogueTitle = '';
      this.linkLabelDialogueMessage = '';
      this.scannedLabelForAssetId = null;
      this.scannedLabelForTeamId = null;
      if (!skipStoreReset) {
        this.$store.commit('mobileApp/RESET_GATEWAY');
        this.$store.commit('mobileApp/RESET_LABEL');
      }
    },
    async replaceCurrentLabel() {
      this.isYesButtonLoading = true;
      await this.addLabelToAsset(this.scannedLabel);
      this.resetLinkingDialogue();
    },
    async save(newName) {
      const oldName = this.asset.name;
      const updatedAsset = await this.$store.dispatch(
        'assets/editAsset',
        { assetId: this.id, name: newName },
      );

      if (!this.hasError) {
        this.$root.trackEvent.editedAsset(updatedAsset, oldName);
      }
    },
    linkLabel() {
      if (this.$store.getters.isMobileApp) {
        this.$store.dispatch('mobileApp/startNfcScan');
      } else {
        this.$router.push({ name: routeNames.assets.linkAssetLabel });
      }
    },
    getLabelTemperatureData() {
      this.$router.push({ name: routeNames.assets.downloadTemperature });
    },
    async deleteAsset() {
      const deletedAsset = this.asset;
      await this.$store.dispatch('assets/removeAsset', this.id);

      if (!this.hasError) {
        this.$root.trackEvent.removedAsset(deletedAsset);
        this.$router.push({ name: routeNames.assets.list });
      }
    },
    async deleteLabel() {
      const labelDeletedFromAsset = this.asset;
      await this.$store.dispatch('assets/removeLabelFromAsset', this.asset);
      this.$root.trackEvent.removedLabel(labelDeletedFromAsset);
    },
    viewTemperatureData() {
      this.$router.push({
        name: routeNames.assets.temperature,
      });
    },
  },
};
</script>
