<template>
  <div
    class="refresh-message-container"
    :class="{
      'is-small': isSmall
    }"
  >
    <img
      class="refresh-button"
      alt="Refresh button"
      :src="refreshIcon"
      @keydown="refresh"
      @click="refresh"
    >
    <div class="message">
      {{ message }}
    </div>
  </div>
</template>
<script>
import RefreshIcon from '../../../assets/icons/refresh.svg';

export default {
  name: 'RefreshMessage',
  props: {
    message: { type: String, default: 'Please refresh to view new data' },
    preventUrlReload: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },
  computed: {
    refreshIcon() { return RefreshIcon; },
  },
  methods: {
    refresh() {
      this.$emit('refresh');
      if (!this.preventUrlReload) {
        window.location.reload(true);
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';

.refresh-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  &.is-small {
    margin-top: 0px;
    .message {
      margin-top: -6px;
      font-size: 0.625rem;
    }
    .refresh-button {
      height: 30px;
      width: 30px;
    }
  }
  .refresh-button {
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .message {
    color: $dark-grey;
  }
}
</style>
