import { query } from './api-consumer';

export default {
  async getConsignment(consignmentId) {
    return query('getConsignment', { id: consignmentId });
  },
  async getConsignments(workspaceId, nextToken, searchTerm) {
    return query('getOrganisationConsignments', {
      id: workspaceId, nextToken, searchTerm, limit: 20,
    });
  },
};
