<template>
  <v-snackbar
    :timeout="timeout"
    :top="true"
    :absolute="true"
    :color="color"
    :value="isVisible"
    :vertical="hasAction"
    content-class="notification"
  >
    {{ item.message }}
    <div
      v-if="hasAction"
      class="action-button"
    >
      <v-btn
        text
        outlined
        :light="item.isError"
        color="white"
        @click="() => {
          if (action.onClick) action.onClick(item.action);
          closeItem();
        }"
      >
        {{ action.text(item.action) }}
      </v-btn>
    </div>
    <template #action>
      <slot />
      <v-btn
        v-if="!hideCloseButton"
        color="white"
        text
        :icon="!hasAction"
        @click="closeItem"
      >
        <fragment v-if="hasAction">
          Close
        </fragment>
        <v-icon v-else>
          $vuetify.icons.clearIcon
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import notificationActionMap from '../../../mixins/notification-action-map';
import variables from '../../../styles/_variables.scss';

export default {
  name: 'Notification',
  mixins: [notificationActionMap],
  props: {
    item: { type: Object, required: true },
    timeout: { type: Number, default: 5000 },
    isVisible: { type: Boolean, default: false },
    hideCloseButton: { type: Boolean, default: false },
  },
  data() {
    return {
      timeoutId: null,
    };
  },
  computed: {
    color() {
      if (this.item.isError) return variables.errorRed;
      return '';
    },
    hasAction() {
      return this.action && !!this.action.onClick;
    },
    action() {
      if (this.item.action) {
        const action = this.notificationToActionMap[this.item.action.type];
        if (action) return action;
      }

      return null;
    },
  },
  methods: {
    closeItem() {
      this.$emit('remove', this.item.id);
    },
  },
};
</script>
<style lang="scss">
.notification {
  text-align: center;
  width: 100%;
  .action-button {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
