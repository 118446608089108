export default {
  methods: {
    required(fieldName, conjunction) {
      return (value) => {
        if (value == null || typeof value === 'undefined' || value === '' || value === 0 || value.length === 0) {
          return `${fieldName} ${conjunction || 'is'} required`;
        }

        return true;
      };
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    validatePassword(password) {
      const validationErrors = [];
      const hasEightCharacters = /^(?=.{8,})/;
      const includesLowerCaseCharacter = /^(?=.*[a-z])/;
      const includesUpperCaseCharacter = /^(?=.*[A-Z])/;
      const includesNumber = /^(?=.*[0-9])/;
      const includesSpecialCharacter = /^(?=.*[!@#$%^&*])/;

      if (!hasEightCharacters.test(String(password))) {
        validationErrors.push('8 characters');
      }
      if (!includesLowerCaseCharacter.test(String(password))) {
        validationErrors.push('a lower case character');
      }
      if (!includesUpperCaseCharacter.test(String(password))) {
        validationErrors.push('an upper case character');
      }
      if (!includesNumber.test(String(password))) {
        validationErrors.push('a number');
      }
      if (!includesSpecialCharacter.test(String(password))) {
        validationErrors.push('a special character');
      }

      if (validationErrors.length !== 0) {
        const last = validationErrors.pop();
        return validationErrors.length === 0
          ? last
          : `${validationErrors.join(', ')} & ${last}`;
      }
      return true;
    },
    validateName(name) {
      const nameLength = name.length;
      if (nameLength === 0) {
        return "Name can't be empty";
      }
      if (nameLength < 3) {
        return 'Name must be at least 3 characters long';
      }
      if (nameLength > 500) {
        return 'Name cannot be more than 500 characters long';
      }
      return true;
    },
  },
};
