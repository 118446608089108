<template>
  <VerticalContainer>
    <ZonesTable
      v-if="hasGatewayParam"
      is-select-zone
      :selected-id="selectedZone.id"
      :is-disabled="isLoading"
      @clickRow="selectZone"
    />
    <ErrorMessage :error-message="errorMessage" />
    <template #buttons>
      <Button
        :on-click="addGatewayToZoneClick"
        text="Add gateway"
        :is-loading="isLoading"
      />
      <Button
        text="Cancel"
        :on-click="cancel"
        :disabled="isLoading"
      />
    </template>
    <YesNoDialogue
      :is-visible="isDialogueVisible"
      :message="yesNoMessage"
      @no="() => isDialogueVisible = false"
      @yes="addGatewayToZone"
    />
  </VerticalContainer>
</template>
<script>
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import Button from '../../components/atoms/button/button';
import ErrorMessage from '../../components/atoms/message/error-message';
import YesNoDialogue from '../../components/molecules/dialogues/yes-no-dialogue';
import ZonesTable from '../../components/organisms/zones/zones-table';
import { routeNames } from '../../constants';

export default {
  name: 'SelectZone',
  components: {
    VerticalContainer,
    Button,
    ErrorMessage,
    YesNoDialogue,
    ZonesTable,
  },
  props: {
    gateway: { type: Object, default: null },
  },
  data() {
    return {
      selectedZone: { id: null },
      clientErrorMessage: '',
      isDialogueVisible: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.zones.isLoadingZone;
    },
    yesNoMessage() {
      return "This zone already has a linked gateway. If you select 'yes', the original gateway will be replaced.";
    },
    serverErrorMessage() {
      return this.$store.state.zones.errorMessage;
    },
    errorMessage() {
      return [this.clientErrorMessage, this.serverErrorMessage]
        .filter((message) => message && message !== '')
        .join(', ');
    },
    hasError() {
      return this.errorMessage !== '';
    },
    hasGatewayParam() {
      return this.gateway !== null;
    },
  },
  created() {
    if (!this.hasGatewayParam) {
      this.cancel();
    }
  },
  destroyed() {
    this.$store.commit('zones/CLEAR_ERROR_MESSAGE');
  },
  methods: {
    cancel() {
      this.goToZonesHome();
    },
    selectZone(zone) {
      this.clientErrorMessage = '';
      this.$store.commit('zones/CLEAR_ERROR_MESSAGE');
      if (zone.isSelected) {
        this.selectedZone = { id: null };
      } else {
        this.selectedZone = zone;
      }
    },
    addGatewayToZoneClick() {
      if (this.selectedZone.id === null) {
        this.clientErrorMessage = 'Please select a zone';
      } else if (this.selectedZone.hasGateway) {
        this.isDialogueVisible = true;
      } else {
        this.addGatewayToZone();
      }
    },
    async addGatewayToZone() {
      this.isDialogueVisible = false;
      await this.$store.dispatch('zones/addGatewayToZone', {
        id: this.selectedZone.id,
        gateway: this.gateway,
      });
      if (!this.hasError) {
        this.goToZonesHome();
        const selectedZone = this.$store.state.zones.zones.filter(
          ({ id }) => this.selectedZone.id === id,
        )[0];
        const { gatewayId } = selectedZone.gateways.filter(
          ({ removedOn }) => removedOn === null,
        )[0];
        this.$root.trackEvent.linkedGateway(selectedZone, gatewayId, false);
      }
    },
    goToZonesHome() {
      this.$router.replace({ name: routeNames.zones.list });
    },
  },
};
</script>
