<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :no-items-text="noItemsText"
    :with-title="withTitle"
    first-column-width="30%"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    :is-selecting="isSelectAsset"
    :is-disabled="isDisabled"
    :larger-gap-bottom="!smallerGapBottom"
    :smaller-gap-bottom="smallerGapBottom"
    @clickRow="clickRow"
  />
</template>
<script>
import ItemsTable from '../table/items-table';
import { routeNames, table } from '../../../constants';
import dateTimeHelper from '../../../mixins/date-time-helper';
import updateDateTimes from '../../../mixins/update-date-times';
import constructAssetCurrentlyIn from '../../../helpers/currently-in-helper';

export default {
  name: 'AssetsTable',
  components: {
    ItemsTable,
  },
  mixins: [dateTimeHelper, updateDateTimes],
  props: {
    isSelectAsset: { type: Boolean, default: false },
    selectedId: { type: String, default: null },
    isDisabled: { type: Boolean, default: false },
    itemIds: { type: Array, default: () => [] },
    smallerGapBottom: { type: Boolean, default: false },
    displayHasLabel: { type: Boolean, default: true },
    withTitle: { type: Boolean, default: true },
    noItemsText: { type: String, default: 'No matching results found' },
    selectItemRoute: { type: Function, default: null },
    sortOrder: { type: Array, default: () => [] },
  },
  computed: {
    headers() {
      const headers = [{ name: 'Name', key: 'name' }];

      if (this.isSelectAsset) {
        headers.unshift({
          key: table.selectColumnKey,
        });
      } else {
        headers.push(...[
          { name: 'Currently in', key: 'currentlyIn' },
          { name: 'First seen', key: 'firstSeenAt' },
          { name: 'Last seen', key: 'lastSeenAt' },
        ]);
      }

      if (this.displayHasLabel) {
        headers.push({ name: 'Has label', key: 'hasLabel' });
      }
      if (!this.isSelectAsset && this.$store.getters['assets/hasTemperatures']) {
        headers.push({ name: 'Last Temp', key: 'latestTemperature' });
      }

      return headers;
    },
    rows() {
      return this.$store.state.assets.assets
        .filter((asset) => (this.itemIds.length === 0 || this.itemIds.includes(asset.id)))
        .map((asset) => (
          {
          // need to include the timer, so that the computed property is re-computed every 10 secs
            timer: this.timer,
            id: asset.id,
            name: asset.name,
            currentlyIn: constructAssetCurrentlyIn(
              asset,
              undefined,
              this.$root.workspaceFeatures.assetFacilityEpisodes,
            ),
            firstSeenAt: this.getFirstSeen(asset, true),
            lastSeenAt: this.getLastSeen(asset, true) || 'no data',
            hasLabel: typeof asset.bleId !== 'undefined' && asset.bleId !== null,
            latestTemperature: asset.temperatures?.items?.length
              ? `${asset.temperatures.items[0].degreesCelcius.toFixed(1)}C`
              : '',
            isSelected: this.selectedId && this.selectedId === asset.id,
          }
        ));
    },
    hasMoreData() {
      return this.$store.getters['assets/hasMoreAssets'];
    },
    isLoading() {
      return this.$store.state.assets.isLoadingAssets;
    },
  },
  methods: {
    getData() {
      if (this.hasMoreData) {
        return this.$store.dispatch('assets/getAssets', { sortOrder: this.sortOrder });
      }

      return null;
    },
    clickRow(row) {
      if (this.selectItemRoute) {
        this.$router.push(this.selectItemRoute(row));
      } else if (!this.isSelectAsset) {
        this.goToAssetDetail(row);
      } else {
        this.$emit('clickRow', row);
      }
    },
    goToAssetDetail(row) {
      this.$router.push({
        name: routeNames.assets.detail,
        params: {
          id: row.id,
        },
        query: this.$route.query,
      }).catch(() => {
        // duplicate navigation to same asset - do nothing
      });
    },
  },
};
</script>
