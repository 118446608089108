import { routeNames } from '../constants';
import { notificationTypes } from '../helpers/notification-helper';

export default {
  computed: {
    notificationToActionMap() {
      const map = {
        userAddedToWorkspace: {
          text: () => 'Switch to this team',
          onClick: (action) => {
            const params = JSON.parse(action.params);
            this.$router.push({
              name: routeNames.teamHome,
              params: {
                teamId: params.workspaceId,
              },
            }).catch(() => { /* Avoid redundant navigation */ });
          },
        },
      };

      map[notificationTypes.bluetoothOff] = {
        text: () => 'Open settings',
        onClick: () => { this.$store.dispatch('mobileApp/goToBluetoothSettings'); },
      };

      map[notificationTypes.bluetoothUnauthorized] = {
        text: () => 'Open settings',
        onClick: () => { this.$store.dispatch('mobileApp/goToBluetoothSettings'); },
      };

      return map;
    },
  },
};
