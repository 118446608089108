import TeamMemberDetail from '../views/team-members/TeamMemberDetail';
import TeamMembersList from '../views/team-members/TeamMembersList';
import AddMember from '../views/team-members/AddMember';
import TeamsList from '../views/team-members/TeamsList';
import TeamDetail from '../views/team-members/TeamDetail';
import { routeNames } from '../constants';

export default [
  {
    path: 'team',
    name: routeNames.team.list,
    component: TeamMembersList,
    children: [
      {
        path: 'addMember',
        name: routeNames.team.addMember,
        component: AddMember,
        props: true,
      },
      {
        path: 'teamsList',
        name: routeNames.team.teamsListRoute,
        component: TeamsList,
        props: true,
      },
      {
        path: 'edit',
        name: routeNames.team.detail,
        component: TeamDetail,
      },
      {
        path: ':id',
        name: routeNames.team.memberDetail,
        component: TeamMemberDetail,
        props: true,
      },
    ],
  },
];
