const getDefaultState = () => ({
  includeMobileGatewayInDashboard: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_INCLUDE_MOBILE_GATEWAYS_IN_DASHBOARD(state, include = false) {
      state.includeMobileGatewayInDashboard = include;
    },
  },
  actions: {},
  getters: {},
};
