/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToAssetInSpaces = /* GraphQL */ `
  subscription SubscribeToAssetInSpaces($assetId: String!) {
    subscribeToAssetInSpaces(assetId: $assetId) {
      assetId
      spaceId
      spaceName
      facilityId
      facilityTimeZone
      facilityName
      enteredOn
      exitedOn
      hasExited
    }
  }
`;
export const subscribeToUserNotifications = /* GraphQL */ `
  subscription SubscribeToUserNotifications($userId: String!) {
    subscribeToUserNotifications(userId: $userId) {
      userId
      message
      action {
        type
        params
      }
    }
  }
`;
