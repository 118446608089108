import wrapper from '../analytics-wrapper';

export default {
  linkedLabel({
    bleId, nfcId, id, name, assetType,
  }, addedNewAsset) {
    wrapper.track('Linked label', {
      'BLE ID': bleId,
      'NFC ID': nfcId,
      'Asset ID': id,
      'Asset Name': name,
      'Asset Type': assetType?.name,
      'Was New Asset Added': addedNewAsset,
    });
  },
  removedLabel({
    bleId, nfcId, id, name, assetType,
  }) {
    wrapper.track('Removed label', {
      'BLE ID': bleId,
      'NFC ID': nfcId,
      'Asset ID': id,
      'Asset Name': name,
      'Asset Type': assetType?.name,
    });
  },
};
