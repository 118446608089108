<template>
  <a
    target="_blank"
    :href="`mailto:${email}?subject=${encodeURIComponent(subject)}`"
  >{{ email }}</a>
</template>
<script>
export default {
  props: {
    email: { type: String, default: 'support@reelables.com' },
    subject: { type: String, default: '' },
  },
};
</script>
