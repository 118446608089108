<template>
  <NotificationQueue
    :items="items"
    @remove="removeItem"
  />
</template>
<script>
import NotificationQueue from '../../molecules/notification-queue/notification-queue';

export default {
  components: {
    NotificationQueue,
  },
  computed: {
    items() {
      return this.$store.state.notifications.notifications;
    },
  },
  methods: {
    removeItem(id) {
      this.$store.commit('notifications/REMOVE_NOTIFICATION', id);
    },
  },
};
</script>
