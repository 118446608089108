<template>
  <div class="text-input-container">
    <label
      class="label"
      :for="name"
    >{{ label }}
      <input
        v-model="inputVal"
        class="text-input"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        autocapitalize="off"
        autocorrect="off"
      >
      <div
        v-if="hasError"
        class="error-message"
      >
        {{ errorMessage }}
      </div>
    </label>
  </div>
</template>
<script>
let counter = 0; // to be used for unique name attribue
export default {
  props: {
    value: { type: String, default: '' },
    label: { type: String, required: true },
    type: { type: String, default: 'text' },
    errorMessage: { type: String, default: '' },
    autocomplete: { type: String, default: 'off' },
  },
  data() {
    return {
      name: `text-input-${counter++}`,
    };
  },
  computed: {
    hasError() {
      return this.errorMessage && this.errorMessage !== '';
    },
    inputVal: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.text-input-container {
  margin: 6px 0;
  .label {
    font-size: 14px;
    line-height: 17px;
    color: $black;
  }
  .text-input {
    color: $reelables-light-grey;
    background-color: $white;
    padding: 15px 13px;
    font-size: 13px;
    line-height: 16px;
    width: 100%;
    margin: 6px 0;
    &:focus  {
      outline: solid 2px $reelables-light-grey;
    }
  }
  .error-message {
    color: $error-red;
    font-size: 12px;
    line-height: 12px;
  }
}
</style>
