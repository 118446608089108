<template>
  <div>
    <TableTitle
      v-if="withTitle"
      :height="headerHeight"
      :title="title"
    >
      <slot
        slot="buttons"
        name="buttons"
      />
    </TableTitle>
    <v-simple-table
      :key="isEditing"
      class="table-container"
      dense
      :fixed-header="height !== null"
      :height="tableHeight"
    >
      <template #default>
        <thead v-if="hasHeaders">
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="i"
              :style="columnWidth(i)"
              class="is-bold header-cell"
            >
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody
          class="table-body"
          :class="{
            'table-body-without-header': !hasHeaders,
          }"
        >
          <tr
            v-for="(row, i) in rows"
            :key="i"
            class="table-row"
            :class="{
              'is-clickable': rowIsClickable || row.isClickable,
              'is-selected': row.isSelected
            }"
            @click="() => clickRow(row)"
          >
            <td
              v-for="(col, j) in headers"
              :key="j"
              class="table-cell"
              :style="columnWidth(j)"
              :class="{
                'is-bold': j === 0,
                'is-editing': j > 0 && isEditing && row.isEditable,
                'is-editing-cell': j > 0 && isEditing
                  && editingRow === i
                  && isBoolean(row.dataType),
                'is-non-editable': j > 0 && isEditing && !row.isEditable,
                'is-select': col.key === selectColumnKey,
                'is-removable': row.isDeletable
              }"
              @keydown="() => {}"
              @click="() => isEditing &&
                row.isEditable &&
                editingRow !== i &&
                !isBoolean(row.dataType)
                ? setEditingRow(i) : false"
            >
              <div class="table-cell-content-wrapper">
                <ToggleSwitch
                  v-if="isEditing && j > 0 && isBoolean(row.dataType)"
                  :value="row[col.key]"
                  @onChange="(v) => onEdit(v, row.name, row.id)"
                />
                <ToggleSwitch
                  v-else-if="col.key === selectColumnKey"
                  :readonly="isDisabled"
                  :value="!selectingRow && row.isSelected"
                  @onChange="() => selectRow(row)"
                />
                <input
                  v-else-if="row.isEditable && j > 0 && i === editingRow"
                  :ref="`edit-input-${i}`"
                  :value="row[col.key]"
                  class="edit-input"
                  type="text"
                  autocomplete="off"
                  @keyup="(e) => onEdit(e.target.value, row.name)"
                >
                <span v-else-if="isEditing && row[col.key] === '' && row.placeholder">
                  {{ row.placeholder }}
                </span>
                <span
                  v-else
                  :class="{
                    'no-wrap-content': (j === 0 && hasHeaders)
                      || (i === 0 && !hasHeaders && j > 0),
                  }"
                >
                  {{ renderValue(row[col.key]) }}
                </span>
                <ButtonContainer
                  v-if="row.buttons && row.buttons.length && (j === 1 || row.isDeletable)"
                  class="buttons-container"
                >
                  <Button
                    v-for="(button, index) in row.buttons"
                    :key="index"
                    class="row-button"
                    v-bind="button.props"
                  />
                </ButtonContainer>
              </div>
            </td>
          </tr>
          <tr v-if="isLoading">
            <td
              v-if="hasHeaders"
              :colspan="headers.length"
              class="loader-cell"
            >
              <Loader :is-loading="true" />
            </td>
          </tr>
        </tbody>
        <slot />
        <div
          v-if="noMatchingSearchResult && !isLoading"
          class="no-results-label"
        >
          {{ noItemsText }}
        </div>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
// to be used for unique name attribue
import Loader from '../loader/loader';
import { table, inputDataTypes } from '../../../constants';
import ToggleSwitch from '../switch/toggle-switch';
import Button from '../button/button';
import ButtonContainer from '../../molecules/layout/buttons-container';
import TableTitle from '../table-title/table-title';

export default {
  name: 'ReelablesWebTable',
  components: {
    Loader,
    ToggleSwitch,
    Button,
    ButtonContainer,
    TableTitle,
  },
  props: {
    headers: { type: Array, required: true },
    rows: { type: Array, default: () => ([]) },
    firstColumnWidth: { type: String, default: null },
    height: { type: Number, default: null },
    isLoading: { type: Boolean, default: false },
    rowIsClickable: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    isSelecting: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    withTitle: { type: Boolean, default: true },
    title: { type: String, default: '' },
    noItemsText: { type: String, default: 'No matching results found' },
  },
  data() {
    return {
      editingRow: null,
      selectingRow: false,
      headerHeight: 40,
    };
  },
  computed: {
    noMatchingSearchResult() {
      return this.rows.length === 0;
    },
    hasHeaders() {
      return this.headers.filter((header) => typeof header.name !== 'undefined').length > 0;
    },
    selectColumnKey() {
      return table.selectColumnKey;
    },
    hasSelectedRow() {
      return this.rows.find((row) => row.isSelected);
    },
    tableHeight() {
      if (this.height === null && this.rows.length === 0) {
        return 160 - this.headerHeight;
      }

      return this.height - (this.withTitle ? this.headerHeight : 0);
    },
  },
  watch: {
    isEditing() {
      if (!this.isEditing) {
        this.resetEditingRow();
      }
    },
  },
  methods: {
    onEdit(value, name, id) {
      this.$emit('onChange', {
        name,
        id,
        value,
      });
    },
    setEditingRow(i) {
      this.editingRow = i;
      setTimeout(() => {
        const input = this.$refs[`edit-input-${i}`][0];
        input.focus();
        const cursorPosition = input.value.length;
        input.setSelectionRange(cursorPosition, cursorPosition);
      }, 10);
    },
    resetEditingRow() {
      this.editingRow = null;
    },
    selectRow(row) {
      // this is used to 'switch' off previously selected rows when a new one is selected
      if (this.hasSelectedRow) this.selectingRow = true;
      setTimeout(() => {
        this.$emit('clickRow', row);
      }, 300);
    },
    clickRow(row) {
      if (!this.isSelecting) {
        this.$emit('clickRow', row);
      }
    },
    renderValue(value) {
      if (value === true) return 'Yes';
      if (value === false) return 'No';

      return value;
    },
    isBoolean(type) {
      return type === inputDataTypes.boolean;
    },
    columnWidth(index) {
      if (this.firstColumnWidth) {
        const firstColumn = parseInt(this.firstColumnWidth, 10);
        const width = index === 0 ? firstColumn : (100 - firstColumn) / (this.headers.length - 1);
        return `width: ${width}%;`;
      }
      return `width: ${100 / this.headers.length}%`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';
.table-container {
  position: relative;
  background-color: transparent !important;
  .header-cell {
    font-size: 0.6875rem;
    color: $white !important;
    border: none !important;
    padding-right: 0;
    background-color: $dark-grey !important;
    box-shadow: inset 0 -1px 0 rgba($black, 0.08) !important;
  }
  .no-results-label {
    position: absolute;
    top: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    width: 100%;
    color: $dark-grey;
  }
  .table-body {
    background-color: $white !important;
    &::after {
      content: '';
      position: absolute;
      box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
      left: 0;
      right: 0;
      top: 32px; // height of table header
      bottom: 0;
      z-index: 0;
      pointer-events: none;
    }
  }
  .table-body-without-header {
    &::after {
      top: 0; // removing table header padding
    }
  }
  .table-row {
    &.is-clickable {
      cursor: pointer;
      td {
        color: $reelables-light-grey;
      }
    }
    &:hover {
      background: initial !important;
    }
    &.is-selected td {
      background-color: $white-3;
    }
  }
  .table-cell {
    font-size: 0.6875rem;
    color: $black;
    text-align: left;
    height: 35px;
    // Need to include max-width so the first column does not stretch and cause table to
    // horizontally scroll. For an unknown reason the value of the max-width does not matter.
    max-width: 0;
    padding-left: 16px;
    @include mq($from: md) {
      height: 40px;
      font-size: 0.875rem;
    }
    .no-wrap-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.is-editing {
      color: $reelables-light-grey;
      padding-right: 0;
      padding-left: 16px;
    }
    &.is-editing-cell {
      padding-right: 0;
      padding-left: 16px;
    }
    &.is-non-editable {
      color: $grey-2;
      padding-left: 16px;
    }
    &.is-select {
      max-width: 40px;
    }
    &.is-removable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: none;
    }
    .v-input--switch {
      margin-top: 0px;
    }
    .table-cell-content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      .buttons-container {
        width: auto;
        .row-button {
          min-width: auto !important;
          padding: 5px !important;
          width: auto !important;
          margin: 0 5px;
        }
      }
    }
  }
  .loader-cell {
    padding: 20px 0;
  }
  .edit-input {
    height: 100%;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    background-color: $reelables-light-grey;
    color: $white;
    &:focus {
      outline: 0;
    }
    &::selection {
      background-color: $white;
      color: $reelables-light-grey;
    }
  }
}
</style>
<style lang="scss">
@import '@/styles/_breakpoints.scss';
.v-data-table__wrapper {
  @include mq($until: md) {
    -webkit-overflow-scrolling: touch;
  }
}
</style>
