import authEvents from './mix-panel/auth-events';
import assetEvents from './mix-panel/asset-events';
import zoneEvents from './mix-panel/zone-events';
import memberEvents from './mix-panel/member-events';
import teamEvents from './mix-panel/team-events';
import labelEvents from './mix-panel/label-events';
import gatewayEvents from './mix-panel/gateway-events';

export default {
  computed: {
    trackEvent() {
      return {
        ...authEvents,
        ...assetEvents,
        ...zoneEvents,
        ...memberEvents,
        ...teamEvents,
        ...labelEvents,
        ...gatewayEvents,
      };
    },
  },
};
