<template>
  <VerticalContainer>
    <Loader :is-loading="isLoading" />
    <template v-if="!isLoading">
      <AssetTemperatureTable
        v-if="hasTemperatureData && !isChartVisible"
        :asset="asset || {}"
      >
        <Button
          v-if="getDataEnabled"
          slot="buttons"
          is-small
          text="Get data"
          :to="getDataRoute"
        />
      </AssetTemperatureTable>
      <AssetTemperatureChart
        v-if="hasTemperatureData && isChartVisible"
        :asset="asset || {}"
      />
      <ErrorMessage :error-message="errorMessage" />
    </template>
    <template #buttons>
      <Button
        v-if="hasTemperatureData"
        :text="`View ${isChartVisible ? 'Table': 'Chart'}`"
        :disabled="isLoading"
        @click="isChartVisible = !isChartVisible"
      />
      <Button
        text="Close"
        @click="closeClick"
      />
    </template>
  </VerticalContainer>
</template>
<script>
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import AssetTemperatureTable from '../../components/organisms/assets/asset-temperature-table';
import AssetTemperatureChart from '../../components/organisms/assets/asset-temperature-chart';
import Button from '../../components/atoms/button/button';
import Loader from '../../components/atoms/loader/loader';
import ErrorMessage from '../../components/atoms/message/error-message';
import { routeNames } from '../../constants';

export default {
  name: 'AssetTemperature',
  components: {
    VerticalContainer,
    AssetTemperatureTable,
    AssetTemperatureChart,
    Button,
    Loader,
    ErrorMessage,
  },
  props: {
    id: { type: String, required: true },
    isLoadingData: { type: Boolean, default: false },
  },
  data() {
    return {
      isChartVisible: false,
    };
  },
  computed: {
    countTemperatures() {
      return this.asset?.temperatures?.items?.length || 0;
    },
    asset() {
      return this.$store.state.assets.assets.find(({ id }) => id === this.id);
    },
    hasTemperatureData() {
      return this.countTemperatures > 0;
    },
    errorMessage() {
      const assetError = this.$store.state.assets.errorMessage;
      if (assetError) return assetError;
      return this.hasTemperatureData ? '' : 'No temperature data for this asset';
    },
    isLoading() {
      return (!this.hasTemperatureData && this.isLoadingData)
        || this.$store.state.assets.isLoadingAsset;
    },
    getDataEnabled() {
      return this.$root.featureToggles.includeTemperatureGetData(this.$store.state.mobileApp);
    },
    getDataRoute() {
      return { name: routeNames.assets.downloadTemperature };
    },
  },
  methods: {
    closeClick() {
      this.$router.replace({ name: routeNames.assets.detail });
    },
  },
};
</script>
