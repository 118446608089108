<template>
  <Dialogue :is-visible="isVisible">
    <p class="is-bold text-center">
      {{ title }}
    </p>
    <p class="text-center">
      {{ message }}
    </p>
    <ButtonsContainer>
      <slot />
    </ButtonsContainer>
  </Dialogue>
</template>

<script>
import Dialogue from '../../atoms/dialogue/dialogue';
import ButtonsContainer from '../layout/buttons-container';

export default {
  name: 'DialogueWithButtons',
  components: {
    Dialogue,
    ButtonsContainer,
  },
  props: {
    isVisible: { type: Boolean, default: false },
    title: { type: String, default: 'Are you sure?' },
    message: { type: String, default: '' },
  },
};
</script>
