<template>
  <RouteWithChildren :parent-level="4">
    <ItemDetail
      ref="itemDetail"
      :item-title=" (printer || {}).name || ''"
      :item="printer"
      :rows="rowsPrinterDetail"
      :is-loading="isLoadingFacilityPrinters || isLoadingFacility"
      :save-item="empty"
      :delete-item="empty"
      :delete-tracker="empty"
      :delete-item-dialogue-title="''"
      :delete-tracker-dialogue-title="''"
      :display-edit="false"
      :error="errorMessage"
      @close="close"
    >
      <template
        #explore-item-1
      >
        <ItemsTable
          :headers="tableHeaders"
          :rows="rowsPrintJobList"
          :get-data="getPrinterPrintJobs"
          :has-more-data="hasMorePrintJobs"
          :no-items-text="noPrintJobsDataText"
          :is-loading="isLoadingPrintJobs"
          :title="'Print Jobs'"
          smaller-gap-bottom
          @clickRow="clickRow"
        />
      </template>
    </Itemdetail>
  </RouteWithChildren>
</template>
<script>
import ItemDetail from '../../components/organisms/items/item-detail';
import RouteWithChildren from '../../components/organisms/layout/route-with-children';
import ItemsTable from '../../components/organisms/table/items-table';
import { routeNames, facilityTabSlugs } from '../../constants';

export default {
  name: 'FacilityPrinterDetail',
  components: {
    ItemDetail,
    RouteWithChildren,
    ItemsTable,
  },
  props: {
    printerId: { type: String, required: true },
    facilityId: { type: String, required: true },
  },
  computed: {
    isLoadingFacilityPrinters() {
      return this.$store.state.facilities.isLoadingFacilityPrinters;
    },
    isLoadingFacility() {
      return this.$store.state.facilities.isLoadingFacility;
    },
    facility() {
      return this.$store.state.facilities.facilities.find(({ id }) => id === this.facilityId);
    },
    isFacilityLoaded() {
      return this.facility && !this.facility.isSkeletonRecord;
    },
    isLoadingPrintJobs() {
      return this.$store.state.facilities.isLoadingPrintJobs;
    },
    tableHeaders() {
      return [{ name: 'Date', key: 'date' }, { name: 'Status', key: 'status' }, { name: '', key: 'action' }];
    },
    rowsPrintJobList() {
      return ((this.printer.printJobs || {}).items || [])
        .map((i) => ({ ...i, action: this.hasAsset(i) ? 'View Asset >' : null }));
    },
    rowsPrinterDetail() {
      const rows = [
        {
          name: 'Name',
          value: (this.printer || {}).name,
        },
        {
          name: 'Printer ID',
          value: (this.printer || {}).printerId,
        },
        {
          name: 'Status',
          value: (this.printer || {}).status,
        },
      ];
      return rows;
    },
    noPrintJobsDataText() {
      return 'No print jobs';
    },
    printer() {
      return ((this.facility || {}).printers || [])
        .find((g) => g.printerId === this.printerId) || {};
    },
    errorMessage() {
      return this.$store.state.facilities.errorMessage;
    },
    hasMorePrintJobs() {
      return (this.printer.printJobs || {}).nextToken !== null;
    },
  },
  watch: {
    isFacilityLoaded() {
      if (this.isFacilityLoaded) {
        this.getPrinterPrintJobs();
      }
    },
  },
  async created() {
    if (this.isFacilityLoaded) {
      await this.getPrinterPrintJobs();
    }
  },
  methods: {
    empty() {},
    close() {
      this.$router.push(
        {
          name: routeNames.facilities.detail,
          query: { tab: facilityTabSlugs.printers },
        },
      );
    },
    async getPrinterPrintJobs() {
      await this.$store.dispatch('facilities/getPrinterPrintJobs', {
        printerId: this.printerId,
        facilityId: this.facilityId,
        timeZone: this.facility.timeZone,
      });
    },
    clickRow(row) {
      if (this.hasAsset(row)) {
        this.$router.push({
          name: routeNames.facilities.printJobAssetDetail,
          params: {
            assetId: row.assetId,
            printJobId: row.id,
          },
        }).catch(() => {
        // duplicate navigation to same facility - do nothing
        });
      }
    },
    hasAsset(printJob) {
      return printJob.status === 'Succeeded';
    },
  },
};
</script>
