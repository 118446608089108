/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $requestId: String
    $name: String!
    $url: AWSURL
    $allowedDomains: [String]
  ) {
    createOrganisation(
      requestId: $requestId
      name: $name
      url: $url
      allowedDomains: $allowedDomains
    ) {
      id
      name
      url
      allowedDomains
      members {
        nextToken
        items {
          user {
            id
            givenName
            familyName
            avatarUrl
            email
          }
          role
          joinedDate
        }
      }
      assets {
        nextToken
        items {
          id
          name
          organisationId
          createdOn
          lastSeen
          assetType {
            id
            name
            organisationId
            fields {
              key
              name
              value
              values
              dataType
              options
              isMultiSelect
              isRequired
            }
            iconUrl
          }
          photo
          labelAddedOn
          nfcId
          bleId
          custody {
            nextToken
            items {
              timestamp
              users {
                id
                givenName
                familyName
                avatarUrl
                email
              }
            }
          }
          locations {
            nextToken
            items {
              timestamp
              longitude
              latitude
              error
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
              updatedAt
            }
          }
          positions {
            nextToken
            items {
              timestamp
              name
              coordinate {
                latitude
                longitude
                accuracy
              }
              floor {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
          }
          temperatures {
            nextToken
            items {
              timestamp
              degreesCelcius
              numberReadings
            }
          }
          isSpace
          numberAssetsInSpace
          inSpaces {
            assetId
            spaceId
            spaceName
            facilityId
            facilityTimeZone
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
          inFacilities {
            assetId
            facilityId
            facilityName
            facilityTimeZone
            enteredOn
            exitedOn
            hasExited
          }
          gateways {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        total
      }
      features
      buildings {
        floors {
          url
          level
          name
          topLeft {
            latitude
            longitude
            accuracy
          }
          topRight {
            latitude
            longitude
            accuracy
          }
          bottomLeft {
            latitude
            longitude
            accuracy
          }
          rotationDegrees
        }
      }
      printers {
        nextToken
        items {
          id
          name
          addedOn
          isOnline
          workspaceId
          facilityId
          printerSettings {
            labelSize
            printMethod
            template {
              selectedId
            }
            consignment {
              createConsignment
              autoPopulatePieceNumber
            }
            barcode {
              sliceStart
              sliceEnd
            }
          }
        }
      }
      dashboards {
        nextToken
        items {
          id
          name
          url
        }
      }
      facilities {
        nextToken
        items {
          id
          userFacilityId
          name
          workspaceId
          createdAt
          status
          numberOfGateways
          currentAssetsCount
          location {
            latitude
            longitude
            accuracy
          }
          floorPlans {
            items {
              id
              name
              imageKey
              uploadUrl
              imageUrl
            }
          }
          timeZone
        }
      }
      consignments {
        nextToken
        items {
          id
          trackingRef
          workspace {
            id
            name
            url
            allowedDomains
            members {
              nextToken
              items {
                user {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
                role
                joinedDate
              }
            }
            assets {
              nextToken
              items {
                id
                name
                organisationId
                createdOn
                lastSeen
                assetType {
                  id
                  name
                  organisationId
                  fields {
                    key
                    name
                    value
                    values
                    dataType
                    options
                    isMultiSelect
                    isRequired
                  }
                  iconUrl
                }
                photo
                labelAddedOn
                nfcId
                bleId
                custody {
                  nextToken
                  items {
                    timestamp
                    users {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                  }
                }
                locations {
                  nextToken
                  items {
                    timestamp
                    longitude
                    latitude
                    error
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                    updatedAt
                  }
                }
                positions {
                  nextToken
                  items {
                    timestamp
                    name
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    floor {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                }
                temperatures {
                  nextToken
                  items {
                    timestamp
                    degreesCelcius
                    numberReadings
                  }
                }
                isSpace
                numberAssetsInSpace
                inSpaces {
                  assetId
                  spaceId
                  spaceName
                  facilityId
                  facilityTimeZone
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
                inFacilities {
                  assetId
                  facilityId
                  facilityName
                  facilityTimeZone
                  enteredOn
                  exitedOn
                  hasExited
                }
                gateways {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              total
            }
            features
            buildings {
              floors {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
            printers {
              nextToken
              items {
                id
                name
                addedOn
                isOnline
                workspaceId
                facilityId
                printerSettings {
                  labelSize
                  printMethod
                  template {
                    selectedId
                  }
                  consignment {
                    createConsignment
                    autoPopulatePieceNumber
                  }
                  barcode {
                    sliceStart
                    sliceEnd
                  }
                }
              }
            }
            dashboards {
              nextToken
              items {
                id
                name
                url
              }
            }
            facilities {
              nextToken
              items {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
            }
            consignments {
              nextToken
              items {
                id
                trackingRef
                workspace {
                  id
                  name
                  url
                  allowedDomains
                  members {
                    nextToken
                    items {
                      user {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                      role
                      joinedDate
                    }
                  }
                  assets {
                    nextToken
                    items {
                      id
                      name
                      organisationId
                      createdOn
                      lastSeen
                      assetType {
                        id
                        name
                        organisationId
                        fields {
                          key
                          name
                          value
                          values
                          dataType
                          options
                          isMultiSelect
                          isRequired
                        }
                        iconUrl
                      }
                      photo
                      labelAddedOn
                      nfcId
                      bleId
                      custody {
                        nextToken
                        items {
                          timestamp
                          users {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                        }
                      }
                      locations {
                        nextToken
                        items {
                          timestamp
                          longitude
                          latitude
                          error
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                          updatedAt
                        }
                      }
                      positions {
                        nextToken
                        items {
                          timestamp
                          name
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          floor {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                      }
                      temperatures {
                        nextToken
                        items {
                          timestamp
                          degreesCelcius
                          numberReadings
                        }
                      }
                      isSpace
                      numberAssetsInSpace
                      inSpaces {
                        assetId
                        spaceId
                        spaceName
                        facilityId
                        facilityTimeZone
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      inFacilities {
                        assetId
                        facilityId
                        facilityName
                        facilityTimeZone
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      gateways {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    total
                  }
                  features
                  buildings {
                    floors {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                  printers {
                    nextToken
                    items {
                      id
                      name
                      addedOn
                      isOnline
                      workspaceId
                      facilityId
                      printerSettings {
                        labelSize
                        printMethod
                        template {
                          selectedId
                        }
                        consignment {
                          createConsignment
                          autoPopulatePieceNumber
                        }
                        barcode {
                          sliceStart
                          sliceEnd
                        }
                      }
                    }
                  }
                  dashboards {
                    nextToken
                    items {
                      id
                      name
                      url
                    }
                  }
                  facilities {
                    nextToken
                    items {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                  }
                  consignments {
                    nextToken
                    items {
                      id
                      trackingRef
                      workspace {
                        id
                        name
                        url
                        allowedDomains
                        members {
                          nextToken
                          items {
                            role
                            joinedDate
                          }
                        }
                        assets {
                          nextToken
                          items {
                            id
                            name
                            organisationId
                            createdOn
                            lastSeen
                            photo
                            labelAddedOn
                            nfcId
                            bleId
                            isSpace
                            numberAssetsInSpace
                          }
                          total
                        }
                        features
                        buildings {
                          floors {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                        printers {
                          nextToken
                          items {
                            id
                            name
                            addedOn
                            isOnline
                            workspaceId
                            facilityId
                          }
                        }
                        dashboards {
                          nextToken
                          items {
                            id
                            name
                            url
                          }
                        }
                        facilities {
                          nextToken
                          items {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                        }
                        consignments {
                          nextToken
                          items {
                            id
                            trackingRef
                          }
                        }
                      }
                      pieces {
                        nextToken
                        items {
                          id
                          trackingRef
                          consignmentId
                          workspaceId
                          label {
                            nfcId
                            bleId
                            addedOn
                          }
                          inFacilities {
                            facilityId
                            userFacilityId
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                        }
                        total
                      }
                      gateways {
                        nextToken
                        items {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      activated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      deactivated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      destination {
                        name
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                    }
                  }
                }
                pieces {
                  nextToken
                  items {
                    id
                    trackingRef
                    consignmentId
                    workspaceId
                    label {
                      nfcId
                      bleId
                      addedOn
                    }
                    inFacilities {
                      facilityId
                      userFacilityId
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                  }
                  total
                }
                gateways {
                  nextToken
                  items {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                activated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                deactivated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                destination {
                  name
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
              }
            }
          }
          pieces {
            nextToken
            items {
              id
              trackingRef
              consignmentId
              workspaceId
              label {
                nfcId
                bleId
                addedOn
              }
              inFacilities {
                facilityId
                userFacilityId
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
            }
            total
          }
          gateways {
            nextToken
            items {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          activated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          deactivated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          destination {
            name
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
        }
      }
    }
  }
`;
export const removeOrganisation = /* GraphQL */ `
  mutation RemoveOrganisation($requestId: String, $id: String!) {
    removeOrganisation(requestId: $requestId, id: $id) {
      organisations {
        nextToken
        items {
          id
          name
          url
          allowedDomains
          members {
            nextToken
            items {
              user {
                id
                givenName
                familyName
                avatarUrl
                email
              }
              role
              joinedDate
            }
          }
          assets {
            nextToken
            items {
              id
              name
              organisationId
              createdOn
              lastSeen
              assetType {
                id
                name
                organisationId
                fields {
                  key
                  name
                  value
                  values
                  dataType
                  options
                  isMultiSelect
                  isRequired
                }
                iconUrl
              }
              photo
              labelAddedOn
              nfcId
              bleId
              custody {
                nextToken
                items {
                  timestamp
                  users {
                    id
                    givenName
                    familyName
                    avatarUrl
                    email
                  }
                }
              }
              locations {
                nextToken
                items {
                  timestamp
                  longitude
                  latitude
                  error
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                  updatedAt
                }
              }
              positions {
                nextToken
                items {
                  timestamp
                  name
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  floor {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
              }
              temperatures {
                nextToken
                items {
                  timestamp
                  degreesCelcius
                  numberReadings
                }
              }
              isSpace
              numberAssetsInSpace
              inSpaces {
                assetId
                spaceId
                spaceName
                facilityId
                facilityTimeZone
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
              inFacilities {
                assetId
                facilityId
                facilityName
                facilityTimeZone
                enteredOn
                exitedOn
                hasExited
              }
              gateways {
                gatewayId
                shortId
                deviceId
                manufacturer
                productName
                addedOn
                removedOn
                status
                lastUpdated
                hasPendingCommands
                isStatic
                longitude
                latitude
                rssiFilter
              }
            }
            total
          }
          features
          buildings {
            floors {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
          printers {
            nextToken
            items {
              id
              name
              addedOn
              isOnline
              workspaceId
              facilityId
              printerSettings {
                labelSize
                printMethod
                template {
                  selectedId
                }
                consignment {
                  createConsignment
                  autoPopulatePieceNumber
                }
                barcode {
                  sliceStart
                  sliceEnd
                }
              }
            }
          }
          dashboards {
            nextToken
            items {
              id
              name
              url
            }
          }
          facilities {
            nextToken
            items {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
          }
          consignments {
            nextToken
            items {
              id
              trackingRef
              workspace {
                id
                name
                url
                allowedDomains
                members {
                  nextToken
                  items {
                    user {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                    role
                    joinedDate
                  }
                }
                assets {
                  nextToken
                  items {
                    id
                    name
                    organisationId
                    createdOn
                    lastSeen
                    assetType {
                      id
                      name
                      organisationId
                      fields {
                        key
                        name
                        value
                        values
                        dataType
                        options
                        isMultiSelect
                        isRequired
                      }
                      iconUrl
                    }
                    photo
                    labelAddedOn
                    nfcId
                    bleId
                    custody {
                      nextToken
                      items {
                        timestamp
                        users {
                          id
                          givenName
                          familyName
                          avatarUrl
                          email
                        }
                      }
                    }
                    locations {
                      nextToken
                      items {
                        timestamp
                        longitude
                        latitude
                        error
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                        updatedAt
                      }
                    }
                    positions {
                      nextToken
                      items {
                        timestamp
                        name
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        floor {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                    }
                    temperatures {
                      nextToken
                      items {
                        timestamp
                        degreesCelcius
                        numberReadings
                      }
                    }
                    isSpace
                    numberAssetsInSpace
                    inSpaces {
                      assetId
                      spaceId
                      spaceName
                      facilityId
                      facilityTimeZone
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    inFacilities {
                      assetId
                      facilityId
                      facilityName
                      facilityTimeZone
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    gateways {
                      gatewayId
                      shortId
                      deviceId
                      manufacturer
                      productName
                      addedOn
                      removedOn
                      status
                      lastUpdated
                      hasPendingCommands
                      isStatic
                      longitude
                      latitude
                      rssiFilter
                    }
                  }
                  total
                }
                features
                buildings {
                  floors {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
                printers {
                  nextToken
                  items {
                    id
                    name
                    addedOn
                    isOnline
                    workspaceId
                    facilityId
                    printerSettings {
                      labelSize
                      printMethod
                      template {
                        selectedId
                      }
                      consignment {
                        createConsignment
                        autoPopulatePieceNumber
                      }
                      barcode {
                        sliceStart
                        sliceEnd
                      }
                    }
                  }
                }
                dashboards {
                  nextToken
                  items {
                    id
                    name
                    url
                  }
                }
                facilities {
                  nextToken
                  items {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                }
                consignments {
                  nextToken
                  items {
                    id
                    trackingRef
                    workspace {
                      id
                      name
                      url
                      allowedDomains
                      members {
                        nextToken
                        items {
                          user {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                          role
                          joinedDate
                        }
                      }
                      assets {
                        nextToken
                        items {
                          id
                          name
                          organisationId
                          createdOn
                          lastSeen
                          assetType {
                            id
                            name
                            organisationId
                            iconUrl
                          }
                          photo
                          labelAddedOn
                          nfcId
                          bleId
                          custody {
                            nextToken
                          }
                          locations {
                            nextToken
                          }
                          positions {
                            nextToken
                          }
                          temperatures {
                            nextToken
                          }
                          isSpace
                          numberAssetsInSpace
                          inSpaces {
                            assetId
                            spaceId
                            spaceName
                            facilityId
                            facilityTimeZone
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          inFacilities {
                            assetId
                            facilityId
                            facilityName
                            facilityTimeZone
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          gateways {
                            gatewayId
                            shortId
                            deviceId
                            manufacturer
                            productName
                            addedOn
                            removedOn
                            status
                            lastUpdated
                            hasPendingCommands
                            isStatic
                            longitude
                            latitude
                            rssiFilter
                          }
                        }
                        total
                      }
                      features
                      buildings {
                        floors {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                      printers {
                        nextToken
                        items {
                          id
                          name
                          addedOn
                          isOnline
                          workspaceId
                          facilityId
                          printerSettings {
                            labelSize
                            printMethod
                          }
                        }
                      }
                      dashboards {
                        nextToken
                        items {
                          id
                          name
                          url
                        }
                      }
                      facilities {
                        nextToken
                        items {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                      }
                      consignments {
                        nextToken
                        items {
                          id
                          trackingRef
                          workspace {
                            id
                            name
                            url
                            allowedDomains
                            features
                          }
                          pieces {
                            nextToken
                            total
                          }
                          gateways {
                            nextToken
                          }
                          activated {
                            when
                          }
                          deactivated {
                            when
                          }
                          destination {
                            name
                          }
                        }
                      }
                    }
                    pieces {
                      nextToken
                      items {
                        id
                        trackingRef
                        consignmentId
                        workspaceId
                        label {
                          nfcId
                          bleId
                          addedOn
                        }
                        inFacilities {
                          facilityId
                          userFacilityId
                          facilityName
                          enteredOn
                          exitedOn
                          hasExited
                        }
                      }
                      total
                    }
                    gateways {
                      nextToken
                      items {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    activated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    deactivated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    destination {
                      name
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                  }
                }
              }
              pieces {
                nextToken
                items {
                  id
                  trackingRef
                  consignmentId
                  workspaceId
                  label {
                    nfcId
                    bleId
                    addedOn
                  }
                  inFacilities {
                    facilityId
                    userFacilityId
                    facilityName
                    enteredOn
                    exitedOn
                    hasExited
                  }
                }
                total
              }
              gateways {
                nextToken
                items {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              activated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              deactivated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              destination {
                name
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const setOrganisationName = /* GraphQL */ `
  mutation SetOrganisationName(
    $requestId: String
    $id: String!
    $name: String!
  ) {
    setOrganisationName(requestId: $requestId, id: $id, name: $name) {
      id
      name
      url
      allowedDomains
      members {
        nextToken
        items {
          user {
            id
            givenName
            familyName
            avatarUrl
            email
          }
          role
          joinedDate
        }
      }
      assets {
        nextToken
        items {
          id
          name
          organisationId
          createdOn
          lastSeen
          assetType {
            id
            name
            organisationId
            fields {
              key
              name
              value
              values
              dataType
              options
              isMultiSelect
              isRequired
            }
            iconUrl
          }
          photo
          labelAddedOn
          nfcId
          bleId
          custody {
            nextToken
            items {
              timestamp
              users {
                id
                givenName
                familyName
                avatarUrl
                email
              }
            }
          }
          locations {
            nextToken
            items {
              timestamp
              longitude
              latitude
              error
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
              updatedAt
            }
          }
          positions {
            nextToken
            items {
              timestamp
              name
              coordinate {
                latitude
                longitude
                accuracy
              }
              floor {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
          }
          temperatures {
            nextToken
            items {
              timestamp
              degreesCelcius
              numberReadings
            }
          }
          isSpace
          numberAssetsInSpace
          inSpaces {
            assetId
            spaceId
            spaceName
            facilityId
            facilityTimeZone
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
          inFacilities {
            assetId
            facilityId
            facilityName
            facilityTimeZone
            enteredOn
            exitedOn
            hasExited
          }
          gateways {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        total
      }
      features
      buildings {
        floors {
          url
          level
          name
          topLeft {
            latitude
            longitude
            accuracy
          }
          topRight {
            latitude
            longitude
            accuracy
          }
          bottomLeft {
            latitude
            longitude
            accuracy
          }
          rotationDegrees
        }
      }
      printers {
        nextToken
        items {
          id
          name
          addedOn
          isOnline
          workspaceId
          facilityId
          printerSettings {
            labelSize
            printMethod
            template {
              selectedId
            }
            consignment {
              createConsignment
              autoPopulatePieceNumber
            }
            barcode {
              sliceStart
              sliceEnd
            }
          }
        }
      }
      dashboards {
        nextToken
        items {
          id
          name
          url
        }
      }
      facilities {
        nextToken
        items {
          id
          userFacilityId
          name
          workspaceId
          createdAt
          status
          numberOfGateways
          currentAssetsCount
          location {
            latitude
            longitude
            accuracy
          }
          floorPlans {
            items {
              id
              name
              imageKey
              uploadUrl
              imageUrl
            }
          }
          timeZone
        }
      }
      consignments {
        nextToken
        items {
          id
          trackingRef
          workspace {
            id
            name
            url
            allowedDomains
            members {
              nextToken
              items {
                user {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
                role
                joinedDate
              }
            }
            assets {
              nextToken
              items {
                id
                name
                organisationId
                createdOn
                lastSeen
                assetType {
                  id
                  name
                  organisationId
                  fields {
                    key
                    name
                    value
                    values
                    dataType
                    options
                    isMultiSelect
                    isRequired
                  }
                  iconUrl
                }
                photo
                labelAddedOn
                nfcId
                bleId
                custody {
                  nextToken
                  items {
                    timestamp
                    users {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                  }
                }
                locations {
                  nextToken
                  items {
                    timestamp
                    longitude
                    latitude
                    error
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                    updatedAt
                  }
                }
                positions {
                  nextToken
                  items {
                    timestamp
                    name
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    floor {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                }
                temperatures {
                  nextToken
                  items {
                    timestamp
                    degreesCelcius
                    numberReadings
                  }
                }
                isSpace
                numberAssetsInSpace
                inSpaces {
                  assetId
                  spaceId
                  spaceName
                  facilityId
                  facilityTimeZone
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
                inFacilities {
                  assetId
                  facilityId
                  facilityName
                  facilityTimeZone
                  enteredOn
                  exitedOn
                  hasExited
                }
                gateways {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              total
            }
            features
            buildings {
              floors {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
            printers {
              nextToken
              items {
                id
                name
                addedOn
                isOnline
                workspaceId
                facilityId
                printerSettings {
                  labelSize
                  printMethod
                  template {
                    selectedId
                  }
                  consignment {
                    createConsignment
                    autoPopulatePieceNumber
                  }
                  barcode {
                    sliceStart
                    sliceEnd
                  }
                }
              }
            }
            dashboards {
              nextToken
              items {
                id
                name
                url
              }
            }
            facilities {
              nextToken
              items {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
            }
            consignments {
              nextToken
              items {
                id
                trackingRef
                workspace {
                  id
                  name
                  url
                  allowedDomains
                  members {
                    nextToken
                    items {
                      user {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                      role
                      joinedDate
                    }
                  }
                  assets {
                    nextToken
                    items {
                      id
                      name
                      organisationId
                      createdOn
                      lastSeen
                      assetType {
                        id
                        name
                        organisationId
                        fields {
                          key
                          name
                          value
                          values
                          dataType
                          options
                          isMultiSelect
                          isRequired
                        }
                        iconUrl
                      }
                      photo
                      labelAddedOn
                      nfcId
                      bleId
                      custody {
                        nextToken
                        items {
                          timestamp
                          users {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                        }
                      }
                      locations {
                        nextToken
                        items {
                          timestamp
                          longitude
                          latitude
                          error
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                          updatedAt
                        }
                      }
                      positions {
                        nextToken
                        items {
                          timestamp
                          name
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          floor {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                      }
                      temperatures {
                        nextToken
                        items {
                          timestamp
                          degreesCelcius
                          numberReadings
                        }
                      }
                      isSpace
                      numberAssetsInSpace
                      inSpaces {
                        assetId
                        spaceId
                        spaceName
                        facilityId
                        facilityTimeZone
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      inFacilities {
                        assetId
                        facilityId
                        facilityName
                        facilityTimeZone
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      gateways {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    total
                  }
                  features
                  buildings {
                    floors {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                  printers {
                    nextToken
                    items {
                      id
                      name
                      addedOn
                      isOnline
                      workspaceId
                      facilityId
                      printerSettings {
                        labelSize
                        printMethod
                        template {
                          selectedId
                        }
                        consignment {
                          createConsignment
                          autoPopulatePieceNumber
                        }
                        barcode {
                          sliceStart
                          sliceEnd
                        }
                      }
                    }
                  }
                  dashboards {
                    nextToken
                    items {
                      id
                      name
                      url
                    }
                  }
                  facilities {
                    nextToken
                    items {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                  }
                  consignments {
                    nextToken
                    items {
                      id
                      trackingRef
                      workspace {
                        id
                        name
                        url
                        allowedDomains
                        members {
                          nextToken
                          items {
                            role
                            joinedDate
                          }
                        }
                        assets {
                          nextToken
                          items {
                            id
                            name
                            organisationId
                            createdOn
                            lastSeen
                            photo
                            labelAddedOn
                            nfcId
                            bleId
                            isSpace
                            numberAssetsInSpace
                          }
                          total
                        }
                        features
                        buildings {
                          floors {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                        printers {
                          nextToken
                          items {
                            id
                            name
                            addedOn
                            isOnline
                            workspaceId
                            facilityId
                          }
                        }
                        dashboards {
                          nextToken
                          items {
                            id
                            name
                            url
                          }
                        }
                        facilities {
                          nextToken
                          items {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                        }
                        consignments {
                          nextToken
                          items {
                            id
                            trackingRef
                          }
                        }
                      }
                      pieces {
                        nextToken
                        items {
                          id
                          trackingRef
                          consignmentId
                          workspaceId
                          label {
                            nfcId
                            bleId
                            addedOn
                          }
                          inFacilities {
                            facilityId
                            userFacilityId
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                        }
                        total
                      }
                      gateways {
                        nextToken
                        items {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      activated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      deactivated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      destination {
                        name
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                    }
                  }
                }
                pieces {
                  nextToken
                  items {
                    id
                    trackingRef
                    consignmentId
                    workspaceId
                    label {
                      nfcId
                      bleId
                      addedOn
                    }
                    inFacilities {
                      facilityId
                      userFacilityId
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                  }
                  total
                }
                gateways {
                  nextToken
                  items {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                activated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                deactivated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                destination {
                  name
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
              }
            }
          }
          pieces {
            nextToken
            items {
              id
              trackingRef
              consignmentId
              workspaceId
              label {
                nfcId
                bleId
                addedOn
              }
              inFacilities {
                facilityId
                userFacilityId
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
            }
            total
          }
          gateways {
            nextToken
            items {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          activated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          deactivated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          destination {
            name
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
        }
      }
    }
  }
`;
export const setOrganisationUrl = /* GraphQL */ `
  mutation SetOrganisationUrl($requestId: String, $id: String!, $url: AWSURL!) {
    setOrganisationUrl(requestId: $requestId, id: $id, url: $url) {
      id
      name
      url
      allowedDomains
      members {
        nextToken
        items {
          user {
            id
            givenName
            familyName
            avatarUrl
            email
          }
          role
          joinedDate
        }
      }
      assets {
        nextToken
        items {
          id
          name
          organisationId
          createdOn
          lastSeen
          assetType {
            id
            name
            organisationId
            fields {
              key
              name
              value
              values
              dataType
              options
              isMultiSelect
              isRequired
            }
            iconUrl
          }
          photo
          labelAddedOn
          nfcId
          bleId
          custody {
            nextToken
            items {
              timestamp
              users {
                id
                givenName
                familyName
                avatarUrl
                email
              }
            }
          }
          locations {
            nextToken
            items {
              timestamp
              longitude
              latitude
              error
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
              updatedAt
            }
          }
          positions {
            nextToken
            items {
              timestamp
              name
              coordinate {
                latitude
                longitude
                accuracy
              }
              floor {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
          }
          temperatures {
            nextToken
            items {
              timestamp
              degreesCelcius
              numberReadings
            }
          }
          isSpace
          numberAssetsInSpace
          inSpaces {
            assetId
            spaceId
            spaceName
            facilityId
            facilityTimeZone
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
          inFacilities {
            assetId
            facilityId
            facilityName
            facilityTimeZone
            enteredOn
            exitedOn
            hasExited
          }
          gateways {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        total
      }
      features
      buildings {
        floors {
          url
          level
          name
          topLeft {
            latitude
            longitude
            accuracy
          }
          topRight {
            latitude
            longitude
            accuracy
          }
          bottomLeft {
            latitude
            longitude
            accuracy
          }
          rotationDegrees
        }
      }
      printers {
        nextToken
        items {
          id
          name
          addedOn
          isOnline
          workspaceId
          facilityId
          printerSettings {
            labelSize
            printMethod
            template {
              selectedId
            }
            consignment {
              createConsignment
              autoPopulatePieceNumber
            }
            barcode {
              sliceStart
              sliceEnd
            }
          }
        }
      }
      dashboards {
        nextToken
        items {
          id
          name
          url
        }
      }
      facilities {
        nextToken
        items {
          id
          userFacilityId
          name
          workspaceId
          createdAt
          status
          numberOfGateways
          currentAssetsCount
          location {
            latitude
            longitude
            accuracy
          }
          floorPlans {
            items {
              id
              name
              imageKey
              uploadUrl
              imageUrl
            }
          }
          timeZone
        }
      }
      consignments {
        nextToken
        items {
          id
          trackingRef
          workspace {
            id
            name
            url
            allowedDomains
            members {
              nextToken
              items {
                user {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
                role
                joinedDate
              }
            }
            assets {
              nextToken
              items {
                id
                name
                organisationId
                createdOn
                lastSeen
                assetType {
                  id
                  name
                  organisationId
                  fields {
                    key
                    name
                    value
                    values
                    dataType
                    options
                    isMultiSelect
                    isRequired
                  }
                  iconUrl
                }
                photo
                labelAddedOn
                nfcId
                bleId
                custody {
                  nextToken
                  items {
                    timestamp
                    users {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                  }
                }
                locations {
                  nextToken
                  items {
                    timestamp
                    longitude
                    latitude
                    error
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                    updatedAt
                  }
                }
                positions {
                  nextToken
                  items {
                    timestamp
                    name
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    floor {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                }
                temperatures {
                  nextToken
                  items {
                    timestamp
                    degreesCelcius
                    numberReadings
                  }
                }
                isSpace
                numberAssetsInSpace
                inSpaces {
                  assetId
                  spaceId
                  spaceName
                  facilityId
                  facilityTimeZone
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
                inFacilities {
                  assetId
                  facilityId
                  facilityName
                  facilityTimeZone
                  enteredOn
                  exitedOn
                  hasExited
                }
                gateways {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              total
            }
            features
            buildings {
              floors {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
            printers {
              nextToken
              items {
                id
                name
                addedOn
                isOnline
                workspaceId
                facilityId
                printerSettings {
                  labelSize
                  printMethod
                  template {
                    selectedId
                  }
                  consignment {
                    createConsignment
                    autoPopulatePieceNumber
                  }
                  barcode {
                    sliceStart
                    sliceEnd
                  }
                }
              }
            }
            dashboards {
              nextToken
              items {
                id
                name
                url
              }
            }
            facilities {
              nextToken
              items {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
            }
            consignments {
              nextToken
              items {
                id
                trackingRef
                workspace {
                  id
                  name
                  url
                  allowedDomains
                  members {
                    nextToken
                    items {
                      user {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                      role
                      joinedDate
                    }
                  }
                  assets {
                    nextToken
                    items {
                      id
                      name
                      organisationId
                      createdOn
                      lastSeen
                      assetType {
                        id
                        name
                        organisationId
                        fields {
                          key
                          name
                          value
                          values
                          dataType
                          options
                          isMultiSelect
                          isRequired
                        }
                        iconUrl
                      }
                      photo
                      labelAddedOn
                      nfcId
                      bleId
                      custody {
                        nextToken
                        items {
                          timestamp
                          users {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                        }
                      }
                      locations {
                        nextToken
                        items {
                          timestamp
                          longitude
                          latitude
                          error
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                          updatedAt
                        }
                      }
                      positions {
                        nextToken
                        items {
                          timestamp
                          name
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          floor {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                      }
                      temperatures {
                        nextToken
                        items {
                          timestamp
                          degreesCelcius
                          numberReadings
                        }
                      }
                      isSpace
                      numberAssetsInSpace
                      inSpaces {
                        assetId
                        spaceId
                        spaceName
                        facilityId
                        facilityTimeZone
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      inFacilities {
                        assetId
                        facilityId
                        facilityName
                        facilityTimeZone
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      gateways {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    total
                  }
                  features
                  buildings {
                    floors {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                  printers {
                    nextToken
                    items {
                      id
                      name
                      addedOn
                      isOnline
                      workspaceId
                      facilityId
                      printerSettings {
                        labelSize
                        printMethod
                        template {
                          selectedId
                        }
                        consignment {
                          createConsignment
                          autoPopulatePieceNumber
                        }
                        barcode {
                          sliceStart
                          sliceEnd
                        }
                      }
                    }
                  }
                  dashboards {
                    nextToken
                    items {
                      id
                      name
                      url
                    }
                  }
                  facilities {
                    nextToken
                    items {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                  }
                  consignments {
                    nextToken
                    items {
                      id
                      trackingRef
                      workspace {
                        id
                        name
                        url
                        allowedDomains
                        members {
                          nextToken
                          items {
                            role
                            joinedDate
                          }
                        }
                        assets {
                          nextToken
                          items {
                            id
                            name
                            organisationId
                            createdOn
                            lastSeen
                            photo
                            labelAddedOn
                            nfcId
                            bleId
                            isSpace
                            numberAssetsInSpace
                          }
                          total
                        }
                        features
                        buildings {
                          floors {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                        printers {
                          nextToken
                          items {
                            id
                            name
                            addedOn
                            isOnline
                            workspaceId
                            facilityId
                          }
                        }
                        dashboards {
                          nextToken
                          items {
                            id
                            name
                            url
                          }
                        }
                        facilities {
                          nextToken
                          items {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                        }
                        consignments {
                          nextToken
                          items {
                            id
                            trackingRef
                          }
                        }
                      }
                      pieces {
                        nextToken
                        items {
                          id
                          trackingRef
                          consignmentId
                          workspaceId
                          label {
                            nfcId
                            bleId
                            addedOn
                          }
                          inFacilities {
                            facilityId
                            userFacilityId
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                        }
                        total
                      }
                      gateways {
                        nextToken
                        items {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      activated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      deactivated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      destination {
                        name
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                    }
                  }
                }
                pieces {
                  nextToken
                  items {
                    id
                    trackingRef
                    consignmentId
                    workspaceId
                    label {
                      nfcId
                      bleId
                      addedOn
                    }
                    inFacilities {
                      facilityId
                      userFacilityId
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                  }
                  total
                }
                gateways {
                  nextToken
                  items {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                activated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                deactivated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                destination {
                  name
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
              }
            }
          }
          pieces {
            nextToken
            items {
              id
              trackingRef
              consignmentId
              workspaceId
              label {
                nfcId
                bleId
                addedOn
              }
              inFacilities {
                facilityId
                userFacilityId
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
            }
            total
          }
          gateways {
            nextToken
            items {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          activated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          deactivated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          destination {
            name
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
        }
      }
    }
  }
`;
export const setOrganisationAllowedDomains = /* GraphQL */ `
  mutation SetOrganisationAllowedDomains(
    $requestId: String
    $id: String!
    $domains: [String]!
  ) {
    setOrganisationAllowedDomains(
      requestId: $requestId
      id: $id
      domains: $domains
    ) {
      id
      name
      url
      allowedDomains
      members {
        nextToken
        items {
          user {
            id
            givenName
            familyName
            avatarUrl
            email
          }
          role
          joinedDate
        }
      }
      assets {
        nextToken
        items {
          id
          name
          organisationId
          createdOn
          lastSeen
          assetType {
            id
            name
            organisationId
            fields {
              key
              name
              value
              values
              dataType
              options
              isMultiSelect
              isRequired
            }
            iconUrl
          }
          photo
          labelAddedOn
          nfcId
          bleId
          custody {
            nextToken
            items {
              timestamp
              users {
                id
                givenName
                familyName
                avatarUrl
                email
              }
            }
          }
          locations {
            nextToken
            items {
              timestamp
              longitude
              latitude
              error
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
              updatedAt
            }
          }
          positions {
            nextToken
            items {
              timestamp
              name
              coordinate {
                latitude
                longitude
                accuracy
              }
              floor {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
          }
          temperatures {
            nextToken
            items {
              timestamp
              degreesCelcius
              numberReadings
            }
          }
          isSpace
          numberAssetsInSpace
          inSpaces {
            assetId
            spaceId
            spaceName
            facilityId
            facilityTimeZone
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
          inFacilities {
            assetId
            facilityId
            facilityName
            facilityTimeZone
            enteredOn
            exitedOn
            hasExited
          }
          gateways {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        total
      }
      features
      buildings {
        floors {
          url
          level
          name
          topLeft {
            latitude
            longitude
            accuracy
          }
          topRight {
            latitude
            longitude
            accuracy
          }
          bottomLeft {
            latitude
            longitude
            accuracy
          }
          rotationDegrees
        }
      }
      printers {
        nextToken
        items {
          id
          name
          addedOn
          isOnline
          workspaceId
          facilityId
          printerSettings {
            labelSize
            printMethod
            template {
              selectedId
            }
            consignment {
              createConsignment
              autoPopulatePieceNumber
            }
            barcode {
              sliceStart
              sliceEnd
            }
          }
        }
      }
      dashboards {
        nextToken
        items {
          id
          name
          url
        }
      }
      facilities {
        nextToken
        items {
          id
          userFacilityId
          name
          workspaceId
          createdAt
          status
          numberOfGateways
          currentAssetsCount
          location {
            latitude
            longitude
            accuracy
          }
          floorPlans {
            items {
              id
              name
              imageKey
              uploadUrl
              imageUrl
            }
          }
          timeZone
        }
      }
      consignments {
        nextToken
        items {
          id
          trackingRef
          workspace {
            id
            name
            url
            allowedDomains
            members {
              nextToken
              items {
                user {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
                role
                joinedDate
              }
            }
            assets {
              nextToken
              items {
                id
                name
                organisationId
                createdOn
                lastSeen
                assetType {
                  id
                  name
                  organisationId
                  fields {
                    key
                    name
                    value
                    values
                    dataType
                    options
                    isMultiSelect
                    isRequired
                  }
                  iconUrl
                }
                photo
                labelAddedOn
                nfcId
                bleId
                custody {
                  nextToken
                  items {
                    timestamp
                    users {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                  }
                }
                locations {
                  nextToken
                  items {
                    timestamp
                    longitude
                    latitude
                    error
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                    updatedAt
                  }
                }
                positions {
                  nextToken
                  items {
                    timestamp
                    name
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    floor {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                }
                temperatures {
                  nextToken
                  items {
                    timestamp
                    degreesCelcius
                    numberReadings
                  }
                }
                isSpace
                numberAssetsInSpace
                inSpaces {
                  assetId
                  spaceId
                  spaceName
                  facilityId
                  facilityTimeZone
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
                inFacilities {
                  assetId
                  facilityId
                  facilityName
                  facilityTimeZone
                  enteredOn
                  exitedOn
                  hasExited
                }
                gateways {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              total
            }
            features
            buildings {
              floors {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
            printers {
              nextToken
              items {
                id
                name
                addedOn
                isOnline
                workspaceId
                facilityId
                printerSettings {
                  labelSize
                  printMethod
                  template {
                    selectedId
                  }
                  consignment {
                    createConsignment
                    autoPopulatePieceNumber
                  }
                  barcode {
                    sliceStart
                    sliceEnd
                  }
                }
              }
            }
            dashboards {
              nextToken
              items {
                id
                name
                url
              }
            }
            facilities {
              nextToken
              items {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
            }
            consignments {
              nextToken
              items {
                id
                trackingRef
                workspace {
                  id
                  name
                  url
                  allowedDomains
                  members {
                    nextToken
                    items {
                      user {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                      role
                      joinedDate
                    }
                  }
                  assets {
                    nextToken
                    items {
                      id
                      name
                      organisationId
                      createdOn
                      lastSeen
                      assetType {
                        id
                        name
                        organisationId
                        fields {
                          key
                          name
                          value
                          values
                          dataType
                          options
                          isMultiSelect
                          isRequired
                        }
                        iconUrl
                      }
                      photo
                      labelAddedOn
                      nfcId
                      bleId
                      custody {
                        nextToken
                        items {
                          timestamp
                          users {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                        }
                      }
                      locations {
                        nextToken
                        items {
                          timestamp
                          longitude
                          latitude
                          error
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                          updatedAt
                        }
                      }
                      positions {
                        nextToken
                        items {
                          timestamp
                          name
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          floor {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                      }
                      temperatures {
                        nextToken
                        items {
                          timestamp
                          degreesCelcius
                          numberReadings
                        }
                      }
                      isSpace
                      numberAssetsInSpace
                      inSpaces {
                        assetId
                        spaceId
                        spaceName
                        facilityId
                        facilityTimeZone
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      inFacilities {
                        assetId
                        facilityId
                        facilityName
                        facilityTimeZone
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      gateways {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    total
                  }
                  features
                  buildings {
                    floors {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                  printers {
                    nextToken
                    items {
                      id
                      name
                      addedOn
                      isOnline
                      workspaceId
                      facilityId
                      printerSettings {
                        labelSize
                        printMethod
                        template {
                          selectedId
                        }
                        consignment {
                          createConsignment
                          autoPopulatePieceNumber
                        }
                        barcode {
                          sliceStart
                          sliceEnd
                        }
                      }
                    }
                  }
                  dashboards {
                    nextToken
                    items {
                      id
                      name
                      url
                    }
                  }
                  facilities {
                    nextToken
                    items {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                  }
                  consignments {
                    nextToken
                    items {
                      id
                      trackingRef
                      workspace {
                        id
                        name
                        url
                        allowedDomains
                        members {
                          nextToken
                          items {
                            role
                            joinedDate
                          }
                        }
                        assets {
                          nextToken
                          items {
                            id
                            name
                            organisationId
                            createdOn
                            lastSeen
                            photo
                            labelAddedOn
                            nfcId
                            bleId
                            isSpace
                            numberAssetsInSpace
                          }
                          total
                        }
                        features
                        buildings {
                          floors {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                        printers {
                          nextToken
                          items {
                            id
                            name
                            addedOn
                            isOnline
                            workspaceId
                            facilityId
                          }
                        }
                        dashboards {
                          nextToken
                          items {
                            id
                            name
                            url
                          }
                        }
                        facilities {
                          nextToken
                          items {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                        }
                        consignments {
                          nextToken
                          items {
                            id
                            trackingRef
                          }
                        }
                      }
                      pieces {
                        nextToken
                        items {
                          id
                          trackingRef
                          consignmentId
                          workspaceId
                          label {
                            nfcId
                            bleId
                            addedOn
                          }
                          inFacilities {
                            facilityId
                            userFacilityId
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                        }
                        total
                      }
                      gateways {
                        nextToken
                        items {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      activated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      deactivated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      destination {
                        name
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                    }
                  }
                }
                pieces {
                  nextToken
                  items {
                    id
                    trackingRef
                    consignmentId
                    workspaceId
                    label {
                      nfcId
                      bleId
                      addedOn
                    }
                    inFacilities {
                      facilityId
                      userFacilityId
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                  }
                  total
                }
                gateways {
                  nextToken
                  items {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                activated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                deactivated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                destination {
                  name
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
              }
            }
          }
          pieces {
            nextToken
            items {
              id
              trackingRef
              consignmentId
              workspaceId
              label {
                nfcId
                bleId
                addedOn
              }
              inFacilities {
                facilityId
                userFacilityId
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
            }
            total
          }
          gateways {
            nextToken
            items {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          activated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          deactivated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          destination {
            name
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
        }
      }
    }
  }
`;
export const addMemberToOrganisation = /* GraphQL */ `
  mutation AddMemberToOrganisation(
    $requestId: String
    $email: String!
    $organisationId: String!
    $role: RoleEnum!
  ) {
    addMemberToOrganisation(
      requestId: $requestId
      email: $email
      organisationId: $organisationId
      role: $role
    ) {
      user {
        id
        givenName
        familyName
        avatarUrl
        email
      }
      role
      joinedDate
    }
  }
`;
export const removeMemberFromOrganisation = /* GraphQL */ `
  mutation RemoveMemberFromOrganisation(
    $requestId: String
    $userId: String!
    $organisationId: String!
  ) {
    removeMemberFromOrganisation(
      requestId: $requestId
      userId: $userId
      organisationId: $organisationId
    ) {
      id
      name
      url
      allowedDomains
      members {
        nextToken
        items {
          user {
            id
            givenName
            familyName
            avatarUrl
            email
          }
          role
          joinedDate
        }
      }
      assets {
        nextToken
        items {
          id
          name
          organisationId
          createdOn
          lastSeen
          assetType {
            id
            name
            organisationId
            fields {
              key
              name
              value
              values
              dataType
              options
              isMultiSelect
              isRequired
            }
            iconUrl
          }
          photo
          labelAddedOn
          nfcId
          bleId
          custody {
            nextToken
            items {
              timestamp
              users {
                id
                givenName
                familyName
                avatarUrl
                email
              }
            }
          }
          locations {
            nextToken
            items {
              timestamp
              longitude
              latitude
              error
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
              updatedAt
            }
          }
          positions {
            nextToken
            items {
              timestamp
              name
              coordinate {
                latitude
                longitude
                accuracy
              }
              floor {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
          }
          temperatures {
            nextToken
            items {
              timestamp
              degreesCelcius
              numberReadings
            }
          }
          isSpace
          numberAssetsInSpace
          inSpaces {
            assetId
            spaceId
            spaceName
            facilityId
            facilityTimeZone
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
          inFacilities {
            assetId
            facilityId
            facilityName
            facilityTimeZone
            enteredOn
            exitedOn
            hasExited
          }
          gateways {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        total
      }
      features
      buildings {
        floors {
          url
          level
          name
          topLeft {
            latitude
            longitude
            accuracy
          }
          topRight {
            latitude
            longitude
            accuracy
          }
          bottomLeft {
            latitude
            longitude
            accuracy
          }
          rotationDegrees
        }
      }
      printers {
        nextToken
        items {
          id
          name
          addedOn
          isOnline
          workspaceId
          facilityId
          printerSettings {
            labelSize
            printMethod
            template {
              selectedId
            }
            consignment {
              createConsignment
              autoPopulatePieceNumber
            }
            barcode {
              sliceStart
              sliceEnd
            }
          }
        }
      }
      dashboards {
        nextToken
        items {
          id
          name
          url
        }
      }
      facilities {
        nextToken
        items {
          id
          userFacilityId
          name
          workspaceId
          createdAt
          status
          numberOfGateways
          currentAssetsCount
          location {
            latitude
            longitude
            accuracy
          }
          floorPlans {
            items {
              id
              name
              imageKey
              uploadUrl
              imageUrl
            }
          }
          timeZone
        }
      }
      consignments {
        nextToken
        items {
          id
          trackingRef
          workspace {
            id
            name
            url
            allowedDomains
            members {
              nextToken
              items {
                user {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
                role
                joinedDate
              }
            }
            assets {
              nextToken
              items {
                id
                name
                organisationId
                createdOn
                lastSeen
                assetType {
                  id
                  name
                  organisationId
                  fields {
                    key
                    name
                    value
                    values
                    dataType
                    options
                    isMultiSelect
                    isRequired
                  }
                  iconUrl
                }
                photo
                labelAddedOn
                nfcId
                bleId
                custody {
                  nextToken
                  items {
                    timestamp
                    users {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                  }
                }
                locations {
                  nextToken
                  items {
                    timestamp
                    longitude
                    latitude
                    error
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                    updatedAt
                  }
                }
                positions {
                  nextToken
                  items {
                    timestamp
                    name
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    floor {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                }
                temperatures {
                  nextToken
                  items {
                    timestamp
                    degreesCelcius
                    numberReadings
                  }
                }
                isSpace
                numberAssetsInSpace
                inSpaces {
                  assetId
                  spaceId
                  spaceName
                  facilityId
                  facilityTimeZone
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
                inFacilities {
                  assetId
                  facilityId
                  facilityName
                  facilityTimeZone
                  enteredOn
                  exitedOn
                  hasExited
                }
                gateways {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              total
            }
            features
            buildings {
              floors {
                url
                level
                name
                topLeft {
                  latitude
                  longitude
                  accuracy
                }
                topRight {
                  latitude
                  longitude
                  accuracy
                }
                bottomLeft {
                  latitude
                  longitude
                  accuracy
                }
                rotationDegrees
              }
            }
            printers {
              nextToken
              items {
                id
                name
                addedOn
                isOnline
                workspaceId
                facilityId
                printerSettings {
                  labelSize
                  printMethod
                  template {
                    selectedId
                  }
                  consignment {
                    createConsignment
                    autoPopulatePieceNumber
                  }
                  barcode {
                    sliceStart
                    sliceEnd
                  }
                }
              }
            }
            dashboards {
              nextToken
              items {
                id
                name
                url
              }
            }
            facilities {
              nextToken
              items {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
            }
            consignments {
              nextToken
              items {
                id
                trackingRef
                workspace {
                  id
                  name
                  url
                  allowedDomains
                  members {
                    nextToken
                    items {
                      user {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                      role
                      joinedDate
                    }
                  }
                  assets {
                    nextToken
                    items {
                      id
                      name
                      organisationId
                      createdOn
                      lastSeen
                      assetType {
                        id
                        name
                        organisationId
                        fields {
                          key
                          name
                          value
                          values
                          dataType
                          options
                          isMultiSelect
                          isRequired
                        }
                        iconUrl
                      }
                      photo
                      labelAddedOn
                      nfcId
                      bleId
                      custody {
                        nextToken
                        items {
                          timestamp
                          users {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                        }
                      }
                      locations {
                        nextToken
                        items {
                          timestamp
                          longitude
                          latitude
                          error
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                          updatedAt
                        }
                      }
                      positions {
                        nextToken
                        items {
                          timestamp
                          name
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          floor {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                      }
                      temperatures {
                        nextToken
                        items {
                          timestamp
                          degreesCelcius
                          numberReadings
                        }
                      }
                      isSpace
                      numberAssetsInSpace
                      inSpaces {
                        assetId
                        spaceId
                        spaceName
                        facilityId
                        facilityTimeZone
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      inFacilities {
                        assetId
                        facilityId
                        facilityName
                        facilityTimeZone
                        enteredOn
                        exitedOn
                        hasExited
                      }
                      gateways {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    total
                  }
                  features
                  buildings {
                    floors {
                      url
                      level
                      name
                      topLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      topRight {
                        latitude
                        longitude
                        accuracy
                      }
                      bottomLeft {
                        latitude
                        longitude
                        accuracy
                      }
                      rotationDegrees
                    }
                  }
                  printers {
                    nextToken
                    items {
                      id
                      name
                      addedOn
                      isOnline
                      workspaceId
                      facilityId
                      printerSettings {
                        labelSize
                        printMethod
                        template {
                          selectedId
                        }
                        consignment {
                          createConsignment
                          autoPopulatePieceNumber
                        }
                        barcode {
                          sliceStart
                          sliceEnd
                        }
                      }
                    }
                  }
                  dashboards {
                    nextToken
                    items {
                      id
                      name
                      url
                    }
                  }
                  facilities {
                    nextToken
                    items {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                  }
                  consignments {
                    nextToken
                    items {
                      id
                      trackingRef
                      workspace {
                        id
                        name
                        url
                        allowedDomains
                        members {
                          nextToken
                          items {
                            role
                            joinedDate
                          }
                        }
                        assets {
                          nextToken
                          items {
                            id
                            name
                            organisationId
                            createdOn
                            lastSeen
                            photo
                            labelAddedOn
                            nfcId
                            bleId
                            isSpace
                            numberAssetsInSpace
                          }
                          total
                        }
                        features
                        buildings {
                          floors {
                            url
                            level
                            name
                            rotationDegrees
                          }
                        }
                        printers {
                          nextToken
                          items {
                            id
                            name
                            addedOn
                            isOnline
                            workspaceId
                            facilityId
                          }
                        }
                        dashboards {
                          nextToken
                          items {
                            id
                            name
                            url
                          }
                        }
                        facilities {
                          nextToken
                          items {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                        }
                        consignments {
                          nextToken
                          items {
                            id
                            trackingRef
                          }
                        }
                      }
                      pieces {
                        nextToken
                        items {
                          id
                          trackingRef
                          consignmentId
                          workspaceId
                          label {
                            nfcId
                            bleId
                            addedOn
                          }
                          inFacilities {
                            facilityId
                            userFacilityId
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                        }
                        total
                      }
                      gateways {
                        nextToken
                        items {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      activated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      deactivated {
                        when
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                      destination {
                        name
                        where {
                          facility {
                            id
                            userFacilityId
                            name
                            workspaceId
                            createdAt
                            status
                            numberOfGateways
                            currentAssetsCount
                            timeZone
                          }
                          coordinate {
                            latitude
                            longitude
                            accuracy
                          }
                          address {
                            road
                            city
                            district
                            county
                            state
                            country
                            postalCode
                          }
                        }
                      }
                    }
                  }
                }
                pieces {
                  nextToken
                  items {
                    id
                    trackingRef
                    consignmentId
                    workspaceId
                    label {
                      nfcId
                      bleId
                      addedOn
                    }
                    inFacilities {
                      facilityId
                      userFacilityId
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                  }
                  total
                }
                gateways {
                  nextToken
                  items {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                activated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                deactivated {
                  when
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
                destination {
                  name
                  where {
                    facility {
                      id
                      userFacilityId
                      name
                      workspaceId
                      createdAt
                      status
                      numberOfGateways
                      currentAssetsCount
                      location {
                        latitude
                        longitude
                        accuracy
                      }
                      floorPlans {
                        items {
                          id
                          name
                          imageKey
                          uploadUrl
                          imageUrl
                        }
                      }
                      timeZone
                    }
                    coordinate {
                      latitude
                      longitude
                      accuracy
                    }
                    address {
                      road
                      city
                      district
                      county
                      state
                      country
                      postalCode
                    }
                  }
                }
              }
            }
          }
          pieces {
            nextToken
            items {
              id
              trackingRef
              consignmentId
              workspaceId
              label {
                nfcId
                bleId
                addedOn
              }
              inFacilities {
                facilityId
                userFacilityId
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
            }
            total
          }
          gateways {
            nextToken
            items {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          activated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          deactivated {
            when
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
          destination {
            name
            where {
              facility {
                id
                userFacilityId
                name
                workspaceId
                createdAt
                status
                numberOfGateways
                currentAssetsCount
                location {
                  latitude
                  longitude
                  accuracy
                }
                floorPlans {
                  items {
                    id
                    name
                    imageKey
                    uploadUrl
                    imageUrl
                  }
                }
                timeZone
              }
              coordinate {
                latitude
                longitude
                accuracy
              }
              address {
                road
                city
                district
                county
                state
                country
                postalCode
              }
            }
          }
        }
      }
    }
  }
`;
export const setMemberRoleInOrganisation = /* GraphQL */ `
  mutation SetMemberRoleInOrganisation(
    $requestId: String
    $userId: String!
    $organisationId: String!
    $role: RoleEnum!
  ) {
    setMemberRoleInOrganisation(
      requestId: $requestId
      userId: $userId
      organisationId: $organisationId
      role: $role
    ) {
      user {
        id
        givenName
        familyName
        avatarUrl
        email
      }
      role
      joinedDate
    }
  }
`;
export const setUserAvatar = /* GraphQL */ `
  mutation SetUserAvatar($requestId: String, $url: AWSURL!) {
    setUserAvatar(requestId: $requestId, url: $url) {
      id
      givenName
      familyName
      avatarUrl
      email
    }
  }
`;
export const addAssetToOrganisation = /* GraphQL */ `
  mutation AddAssetToOrganisation(
    $requestId: String
    $organisationId: String!
    $name: String!
    $nfcId: String
    $assetTypeId: String
    $metadata: [AssetMetadataFieldInput]
    $location: AssetLocationInput
    $isSpace: Boolean
    $gatewayId: String
    $printing: LabelPrintInput
  ) {
    addAssetToOrganisation(
      requestId: $requestId
      organisationId: $organisationId
      name: $name
      nfcId: $nfcId
      assetTypeId: $assetTypeId
      metadata: $metadata
      location: $location
      isSpace: $isSpace
      gatewayId: $gatewayId
      printing: $printing
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const editAsset = /* GraphQL */ `
  mutation EditAsset(
    $requestId: String
    $id: String!
    $name: String
    $metadata: [AssetMetadataFieldInput]
  ) {
    editAsset(
      requestId: $requestId
      id: $id
      name: $name
      metadata: $metadata
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const setAssetAssetType = /* GraphQL */ `
  mutation SetAssetAssetType(
    $requestId: String
    $id: String!
    $assetTypeId: String!
  ) {
    setAssetAssetType(
      requestId: $requestId
      id: $id
      assetTypeId: $assetTypeId
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const removeAssetFromOrganisation = /* GraphQL */ `
  mutation RemoveAssetFromOrganisation(
    $requestId: String
    $nfcId: String
    $assetId: String
    $organisationId: String!
  ) {
    removeAssetFromOrganisation(
      requestId: $requestId
      nfcId: $nfcId
      assetId: $assetId
      organisationId: $organisationId
    ) {
      nextToken
      items {
        id
        name
        organisationId
        createdOn
        lastSeen
        assetType {
          id
          name
          organisationId
          fields {
            key
            name
            value
            values
            dataType
            options
            isMultiSelect
            isRequired
          }
          iconUrl
        }
        photo
        labelAddedOn
        nfcId
        bleId
        custody {
          nextToken
          items {
            timestamp
            users {
              id
              givenName
              familyName
              avatarUrl
              email
            }
          }
        }
        locations {
          nextToken
          items {
            timestamp
            longitude
            latitude
            error
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
            updatedAt
          }
        }
        positions {
          nextToken
          items {
            timestamp
            name
            coordinate {
              latitude
              longitude
              accuracy
            }
            floor {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
        }
        temperatures {
          nextToken
          items {
            timestamp
            degreesCelcius
            numberReadings
          }
        }
        isSpace
        numberAssetsInSpace
        inSpaces {
          assetId
          spaceId
          spaceName
          facilityId
          facilityTimeZone
          facilityName
          enteredOn
          exitedOn
          hasExited
        }
        inFacilities {
          assetId
          facilityId
          facilityName
          facilityTimeZone
          enteredOn
          exitedOn
          hasExited
        }
        gateways {
          gatewayId
          shortId
          deviceId
          manufacturer
          productName
          addedOn
          removedOn
          status
          lastUpdated
          hasPendingCommands
          isStatic
          longitude
          latitude
          rssiFilter
        }
      }
      total
    }
  }
`;
export const addLabelToAsset = /* GraphQL */ `
  mutation AddLabelToAsset(
    $requestId: String
    $assetId: String!
    $nfcId: String!
    $location: AssetLocationInput
    $replaceExistingLabel: Boolean
    $printing: LabelPrintInput
  ) {
    addLabelToAsset(
      requestId: $requestId
      assetId: $assetId
      nfcId: $nfcId
      location: $location
      replaceExistingLabel: $replaceExistingLabel
      printing: $printing
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const removeLabelFromAsset = /* GraphQL */ `
  mutation RemoveLabelFromAsset(
    $requestId: String
    $assetId: String!
    $nfcId: String!
  ) {
    removeLabelFromAsset(
      requestId: $requestId
      assetId: $assetId
      nfcId: $nfcId
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const addLocationToAsset = /* GraphQL */ `
  mutation AddLocationToAsset(
    $requestId: String
    $bleId: Int!
    $nfcId: String!
    $location: AssetLocationInput!
  ) {
    addLocationToAsset(
      requestId: $requestId
      bleId: $bleId
      nfcId: $nfcId
      location: $location
    ) {
      nextToken
      items {
        timestamp
        longitude
        latitude
        error
        address {
          road
          city
          district
          county
          state
          country
          postalCode
        }
        updatedAt
      }
    }
  }
`;
export const addCustodyToAsset = /* GraphQL */ `
  mutation AddCustodyToAsset(
    $requestId: String
    $bleId: Int!
    $nfcId: String!
    $timestamp: AWSDateTime!
    $users: [UserInput]!
  ) {
    addCustodyToAsset(
      requestId: $requestId
      bleId: $bleId
      nfcId: $nfcId
      timestamp: $timestamp
      users: $users
    ) {
      nextToken
      items {
        timestamp
        users {
          id
          givenName
          familyName
          avatarUrl
          email
        }
      }
    }
  }
`;
export const addGatewayToAsset = /* GraphQL */ `
  mutation AddGatewayToAsset(
    $requestId: String
    $assetId: String!
    $gatewayId: String!
    $location: AssetLocationInput
    $replaceExistingGateway: Boolean
    $isStatic: Boolean
    $longitude: Float
    $latitude: Float
    $rssiFilter: RSSIFilterInput
  ) {
    addGatewayToAsset(
      requestId: $requestId
      assetId: $assetId
      gatewayId: $gatewayId
      location: $location
      replaceExistingGateway: $replaceExistingGateway
      isStatic: $isStatic
      longitude: $longitude
      latitude: $latitude
      rssiFilter: $rssiFilter
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const updateSpaceGateway = /* GraphQL */ `
  mutation UpdateSpaceGateway(
    $requestId: String
    $spaceId: String!
    $gatewayId: String!
    $replaceExistingGateway: Boolean
    $isStatic: Boolean
    $longitude: Float
    $latitude: Float
    $rssiFilter: RSSIFilterInput
  ) {
    updateSpaceGateway(
      requestId: $requestId
      spaceId: $spaceId
      gatewayId: $gatewayId
      replaceExistingGateway: $replaceExistingGateway
      isStatic: $isStatic
      longitude: $longitude
      latitude: $latitude
      rssiFilter: $rssiFilter
    ) {
      gatewayId
      shortId
      deviceId
      manufacturer
      productName
      addedOn
      removedOn
      status
      lastUpdated
      hasPendingCommands
      isStatic
      longitude
      latitude
      rssiFilter
    }
  }
`;
export const removeGatewayFromAsset = /* GraphQL */ `
  mutation RemoveGatewayFromAsset(
    $requestId: String
    $assetId: String!
    $gatewayId: String!
  ) {
    removeGatewayFromAsset(
      requestId: $requestId
      assetId: $assetId
      gatewayId: $gatewayId
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const addAssetToSpace = /* GraphQL */ `
  mutation AddAssetToSpace(
    $assetId: String!
    $spaceId: String!
    $spaceName: String!
    $facilityId: String
    $facilityName: String
    $facilityTimeZone: String
    $timestamp: AWSDateTime!
  ) {
    addAssetToSpace(
      assetId: $assetId
      spaceId: $spaceId
      spaceName: $spaceName
      facilityId: $facilityId
      facilityName: $facilityName
      facilityTimeZone: $facilityTimeZone
      timestamp: $timestamp
    ) {
      assetId
      spaceId
      spaceName
      facilityId
      facilityTimeZone
      facilityName
      enteredOn
      exitedOn
      hasExited
    }
  }
`;
export const removeAssetFromSpace = /* GraphQL */ `
  mutation RemoveAssetFromSpace(
    $assetId: String!
    $spaceId: String!
    $spaceName: String!
    $facilityId: String
    $facilityName: String
    $facilityTimeZone: String
    $timestamp: AWSDateTime!
    $enteredOn: AWSDateTime
  ) {
    removeAssetFromSpace(
      assetId: $assetId
      spaceId: $spaceId
      spaceName: $spaceName
      facilityId: $facilityId
      facilityName: $facilityName
      facilityTimeZone: $facilityTimeZone
      timestamp: $timestamp
      enteredOn: $enteredOn
    ) {
      assetId
      spaceId
      spaceName
      facilityId
      facilityTimeZone
      facilityName
      enteredOn
      exitedOn
      hasExited
    }
  }
`;
export const addLabelTemperatureReadings = /* GraphQL */ `
  mutation AddLabelTemperatureReadings(
    $requestId: String
    $nfcId: String!
    $readings: [TemperatureReading]!
  ) {
    addLabelTemperatureReadings(
      requestId: $requestId
      nfcId: $nfcId
      readings: $readings
    ) {
      nextToken
      items {
        timestamp
        degreesCelcius
        numberReadings
      }
    }
  }
`;
export const addPrinterToOrganisation = /* GraphQL */ `
  mutation AddPrinterToOrganisation(
    $requestId: String
    $id: String!
    $printer: PrinterInput!
    $registerNewPrinter: Boolean
  ) {
    addPrinterToOrganisation(
      requestId: $requestId
      id: $id
      printer: $printer
      registerNewPrinter: $registerNewPrinter
    ) {
      id
      name
      addedOn
      isOnline
      workspaceId
      facilityId
      printerSettings {
        labelSize
        printMethod
        template {
          selectedId
        }
        consignment {
          createConsignment
          autoPopulatePieceNumber
        }
        barcode {
          sliceStart
          sliceEnd
        }
      }
    }
  }
`;
export const setOrganisationPrinterName = /* GraphQL */ `
  mutation SetOrganisationPrinterName(
    $requestId: String
    $id: String!
    $printerId: String!
    $name: String!
  ) {
    setOrganisationPrinterName(
      requestId: $requestId
      id: $id
      printerId: $printerId
      name: $name
    ) {
      id
      name
      addedOn
      isOnline
      workspaceId
      facilityId
      printerSettings {
        labelSize
        printMethod
        template {
          selectedId
        }
        consignment {
          createConsignment
          autoPopulatePieceNumber
        }
        barcode {
          sliceStart
          sliceEnd
        }
      }
    }
  }
`;
export const setOrganisationPrinterSettings = /* GraphQL */ `
  mutation SetOrganisationPrinterSettings(
    $requestId: String
    $id: String!
    $printerId: String!
    $printerSettings: PrinterSettingsInput!
  ) {
    setOrganisationPrinterSettings(
      requestId: $requestId
      id: $id
      printerId: $printerId
      printerSettings: $printerSettings
    ) {
      id
      name
      addedOn
      isOnline
      workspaceId
      facilityId
      printerSettings {
        labelSize
        printMethod
        template {
          selectedId
        }
        consignment {
          createConsignment
          autoPopulatePieceNumber
        }
        barcode {
          sliceStart
          sliceEnd
        }
      }
    }
  }
`;
export const removePrinterFromOrganisation = /* GraphQL */ `
  mutation RemovePrinterFromOrganisation(
    $requestId: String
    $id: String!
    $printerId: String!
  ) {
    removePrinterFromOrganisation(
      requestId: $requestId
      id: $id
      printerId: $printerId
    ) {
      nextToken
      items {
        id
        name
        addedOn
        isOnline
        workspaceId
        facilityId
        printerSettings {
          labelSize
          printMethod
          template {
            selectedId
          }
          consignment {
            createConsignment
            autoPopulatePieceNumber
          }
          barcode {
            sliceStart
            sliceEnd
          }
        }
      }
    }
  }
`;
export const sendPrintJobsToPrinter = /* GraphQL */ `
  mutation SendPrintJobsToPrinter(
    $requestId: String
    $printerId: String!
    $printJobs: [PrintJobInput!]!
  ) {
    sendPrintJobsToPrinter(
      requestId: $requestId
      printerId: $printerId
      printJobs: $printJobs
    ) {
      nextToken
      items {
        id
        printerId
        workspaceId
        assetId
        createdAt
        updatedAt
        status
        origin
        replaceExistingLabel
        templateId
        includeCutLine
        isComplete
        isSuccessful
        failureReason
      }
    }
  }
`;
export const cancelPrintJob = /* GraphQL */ `
  mutation CancelPrintJob($requestId: String, $printJobId: String!) {
    cancelPrintJob(requestId: $requestId, printJobId: $printJobId) {
      nothing
    }
  }
`;
export const createConsignments = /* GraphQL */ `
  mutation CreateConsignments(
    $requestId: String
    $workspaceId: String!
    $consignments: [ConsignmentInput!]!
  ) {
    createConsignments(
      requestId: $requestId
      workspaceId: $workspaceId
      consignments: $consignments
    ) {
      nextToken
      items {
        id
        trackingRef
        workspace {
          id
          name
          url
          allowedDomains
          members {
            nextToken
            items {
              user {
                id
                givenName
                familyName
                avatarUrl
                email
              }
              role
              joinedDate
            }
          }
          assets {
            nextToken
            items {
              id
              name
              organisationId
              createdOn
              lastSeen
              assetType {
                id
                name
                organisationId
                fields {
                  key
                  name
                  value
                  values
                  dataType
                  options
                  isMultiSelect
                  isRequired
                }
                iconUrl
              }
              photo
              labelAddedOn
              nfcId
              bleId
              custody {
                nextToken
                items {
                  timestamp
                  users {
                    id
                    givenName
                    familyName
                    avatarUrl
                    email
                  }
                }
              }
              locations {
                nextToken
                items {
                  timestamp
                  longitude
                  latitude
                  error
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                  updatedAt
                }
              }
              positions {
                nextToken
                items {
                  timestamp
                  name
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  floor {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
              }
              temperatures {
                nextToken
                items {
                  timestamp
                  degreesCelcius
                  numberReadings
                }
              }
              isSpace
              numberAssetsInSpace
              inSpaces {
                assetId
                spaceId
                spaceName
                facilityId
                facilityTimeZone
                facilityName
                enteredOn
                exitedOn
                hasExited
              }
              inFacilities {
                assetId
                facilityId
                facilityName
                facilityTimeZone
                enteredOn
                exitedOn
                hasExited
              }
              gateways {
                gatewayId
                shortId
                deviceId
                manufacturer
                productName
                addedOn
                removedOn
                status
                lastUpdated
                hasPendingCommands
                isStatic
                longitude
                latitude
                rssiFilter
              }
            }
            total
          }
          features
          buildings {
            floors {
              url
              level
              name
              topLeft {
                latitude
                longitude
                accuracy
              }
              topRight {
                latitude
                longitude
                accuracy
              }
              bottomLeft {
                latitude
                longitude
                accuracy
              }
              rotationDegrees
            }
          }
          printers {
            nextToken
            items {
              id
              name
              addedOn
              isOnline
              workspaceId
              facilityId
              printerSettings {
                labelSize
                printMethod
                template {
                  selectedId
                }
                consignment {
                  createConsignment
                  autoPopulatePieceNumber
                }
                barcode {
                  sliceStart
                  sliceEnd
                }
              }
            }
          }
          dashboards {
            nextToken
            items {
              id
              name
              url
            }
          }
          facilities {
            nextToken
            items {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
          }
          consignments {
            nextToken
            items {
              id
              trackingRef
              workspace {
                id
                name
                url
                allowedDomains
                members {
                  nextToken
                  items {
                    user {
                      id
                      givenName
                      familyName
                      avatarUrl
                      email
                    }
                    role
                    joinedDate
                  }
                }
                assets {
                  nextToken
                  items {
                    id
                    name
                    organisationId
                    createdOn
                    lastSeen
                    assetType {
                      id
                      name
                      organisationId
                      fields {
                        key
                        name
                        value
                        values
                        dataType
                        options
                        isMultiSelect
                        isRequired
                      }
                      iconUrl
                    }
                    photo
                    labelAddedOn
                    nfcId
                    bleId
                    custody {
                      nextToken
                      items {
                        timestamp
                        users {
                          id
                          givenName
                          familyName
                          avatarUrl
                          email
                        }
                      }
                    }
                    locations {
                      nextToken
                      items {
                        timestamp
                        longitude
                        latitude
                        error
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                        updatedAt
                      }
                    }
                    positions {
                      nextToken
                      items {
                        timestamp
                        name
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        floor {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                    }
                    temperatures {
                      nextToken
                      items {
                        timestamp
                        degreesCelcius
                        numberReadings
                      }
                    }
                    isSpace
                    numberAssetsInSpace
                    inSpaces {
                      assetId
                      spaceId
                      spaceName
                      facilityId
                      facilityTimeZone
                      facilityName
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    inFacilities {
                      assetId
                      facilityId
                      facilityName
                      facilityTimeZone
                      enteredOn
                      exitedOn
                      hasExited
                    }
                    gateways {
                      gatewayId
                      shortId
                      deviceId
                      manufacturer
                      productName
                      addedOn
                      removedOn
                      status
                      lastUpdated
                      hasPendingCommands
                      isStatic
                      longitude
                      latitude
                      rssiFilter
                    }
                  }
                  total
                }
                features
                buildings {
                  floors {
                    url
                    level
                    name
                    topLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    topRight {
                      latitude
                      longitude
                      accuracy
                    }
                    bottomLeft {
                      latitude
                      longitude
                      accuracy
                    }
                    rotationDegrees
                  }
                }
                printers {
                  nextToken
                  items {
                    id
                    name
                    addedOn
                    isOnline
                    workspaceId
                    facilityId
                    printerSettings {
                      labelSize
                      printMethod
                      template {
                        selectedId
                      }
                      consignment {
                        createConsignment
                        autoPopulatePieceNumber
                      }
                      barcode {
                        sliceStart
                        sliceEnd
                      }
                    }
                  }
                }
                dashboards {
                  nextToken
                  items {
                    id
                    name
                    url
                  }
                }
                facilities {
                  nextToken
                  items {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                }
                consignments {
                  nextToken
                  items {
                    id
                    trackingRef
                    workspace {
                      id
                      name
                      url
                      allowedDomains
                      members {
                        nextToken
                        items {
                          user {
                            id
                            givenName
                            familyName
                            avatarUrl
                            email
                          }
                          role
                          joinedDate
                        }
                      }
                      assets {
                        nextToken
                        items {
                          id
                          name
                          organisationId
                          createdOn
                          lastSeen
                          assetType {
                            id
                            name
                            organisationId
                            iconUrl
                          }
                          photo
                          labelAddedOn
                          nfcId
                          bleId
                          custody {
                            nextToken
                          }
                          locations {
                            nextToken
                          }
                          positions {
                            nextToken
                          }
                          temperatures {
                            nextToken
                          }
                          isSpace
                          numberAssetsInSpace
                          inSpaces {
                            assetId
                            spaceId
                            spaceName
                            facilityId
                            facilityTimeZone
                            facilityName
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          inFacilities {
                            assetId
                            facilityId
                            facilityName
                            facilityTimeZone
                            enteredOn
                            exitedOn
                            hasExited
                          }
                          gateways {
                            gatewayId
                            shortId
                            deviceId
                            manufacturer
                            productName
                            addedOn
                            removedOn
                            status
                            lastUpdated
                            hasPendingCommands
                            isStatic
                            longitude
                            latitude
                            rssiFilter
                          }
                        }
                        total
                      }
                      features
                      buildings {
                        floors {
                          url
                          level
                          name
                          topLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          topRight {
                            latitude
                            longitude
                            accuracy
                          }
                          bottomLeft {
                            latitude
                            longitude
                            accuracy
                          }
                          rotationDegrees
                        }
                      }
                      printers {
                        nextToken
                        items {
                          id
                          name
                          addedOn
                          isOnline
                          workspaceId
                          facilityId
                          printerSettings {
                            labelSize
                            printMethod
                          }
                        }
                      }
                      dashboards {
                        nextToken
                        items {
                          id
                          name
                          url
                        }
                      }
                      facilities {
                        nextToken
                        items {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                      }
                      consignments {
                        nextToken
                        items {
                          id
                          trackingRef
                          workspace {
                            id
                            name
                            url
                            allowedDomains
                            features
                          }
                          pieces {
                            nextToken
                            total
                          }
                          gateways {
                            nextToken
                          }
                          activated {
                            when
                          }
                          deactivated {
                            when
                          }
                          destination {
                            name
                          }
                        }
                      }
                    }
                    pieces {
                      nextToken
                      items {
                        id
                        trackingRef
                        consignmentId
                        workspaceId
                        label {
                          nfcId
                          bleId
                          addedOn
                        }
                        inFacilities {
                          facilityId
                          userFacilityId
                          facilityName
                          enteredOn
                          exitedOn
                          hasExited
                        }
                      }
                      total
                    }
                    gateways {
                      nextToken
                      items {
                        gatewayId
                        shortId
                        deviceId
                        manufacturer
                        productName
                        addedOn
                        removedOn
                        status
                        lastUpdated
                        hasPendingCommands
                        isStatic
                        longitude
                        latitude
                        rssiFilter
                      }
                    }
                    activated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    deactivated {
                      when
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                    destination {
                      name
                      where {
                        facility {
                          id
                          userFacilityId
                          name
                          workspaceId
                          createdAt
                          status
                          numberOfGateways
                          currentAssetsCount
                          location {
                            latitude
                            longitude
                            accuracy
                          }
                          timeZone
                        }
                        coordinate {
                          latitude
                          longitude
                          accuracy
                        }
                        address {
                          road
                          city
                          district
                          county
                          state
                          country
                          postalCode
                        }
                      }
                    }
                  }
                }
              }
              pieces {
                nextToken
                items {
                  id
                  trackingRef
                  consignmentId
                  workspaceId
                  label {
                    nfcId
                    bleId
                    addedOn
                  }
                  inFacilities {
                    facilityId
                    userFacilityId
                    facilityName
                    enteredOn
                    exitedOn
                    hasExited
                  }
                }
                total
              }
              gateways {
                nextToken
                items {
                  gatewayId
                  shortId
                  deviceId
                  manufacturer
                  productName
                  addedOn
                  removedOn
                  status
                  lastUpdated
                  hasPendingCommands
                  isStatic
                  longitude
                  latitude
                  rssiFilter
                }
              }
              activated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              deactivated {
                when
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
              destination {
                name
                where {
                  facility {
                    id
                    userFacilityId
                    name
                    workspaceId
                    createdAt
                    status
                    numberOfGateways
                    currentAssetsCount
                    location {
                      latitude
                      longitude
                      accuracy
                    }
                    floorPlans {
                      items {
                        id
                        name
                        imageKey
                        uploadUrl
                        imageUrl
                      }
                    }
                    timeZone
                  }
                  coordinate {
                    latitude
                    longitude
                    accuracy
                  }
                  address {
                    road
                    city
                    district
                    county
                    state
                    country
                    postalCode
                  }
                }
              }
            }
          }
        }
        pieces {
          nextToken
          items {
            id
            trackingRef
            consignmentId
            workspaceId
            label {
              nfcId
              bleId
              addedOn
            }
            inFacilities {
              facilityId
              userFacilityId
              facilityName
              enteredOn
              exitedOn
              hasExited
            }
          }
          total
        }
        gateways {
          nextToken
          items {
            gatewayId
            shortId
            deviceId
            manufacturer
            productName
            addedOn
            removedOn
            status
            lastUpdated
            hasPendingCommands
            isStatic
            longitude
            latitude
            rssiFilter
          }
        }
        activated {
          when
          where {
            facility {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
            coordinate {
              latitude
              longitude
              accuracy
            }
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
          }
        }
        deactivated {
          when
          where {
            facility {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
            coordinate {
              latitude
              longitude
              accuracy
            }
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
          }
        }
        destination {
          name
          where {
            facility {
              id
              userFacilityId
              name
              workspaceId
              createdAt
              status
              numberOfGateways
              currentAssetsCount
              location {
                latitude
                longitude
                accuracy
              }
              floorPlans {
                items {
                  id
                  name
                  imageKey
                  uploadUrl
                  imageUrl
                }
              }
              timeZone
            }
            coordinate {
              latitude
              longitude
              accuracy
            }
            address {
              road
              city
              district
              county
              state
              country
              postalCode
            }
          }
        }
      }
    }
  }
`;
export const deactivateConsignment = /* GraphQL */ `
  mutation DeactivateConsignment(
    $requestId: String
    $workspaceId: String!
    $trackingRef: String!
  ) {
    deactivateConsignment(
      requestId: $requestId
      workspaceId: $workspaceId
      trackingRef: $trackingRef
    ) {
      id
      trackingRef
      workspace {
        id
        name
        url
        allowedDomains
        members {
          nextToken
          items {
            user {
              id
              givenName
              familyName
              avatarUrl
              email
            }
            role
            joinedDate
          }
        }
        assets {
          nextToken
          items {
            id
            name
            organisationId
            createdOn
            lastSeen
            assetType {
              id
              name
              organisationId
              fields {
                key
                name
                value
                values
                dataType
                options
                isMultiSelect
                isRequired
              }
              iconUrl
            }
            photo
            labelAddedOn
            nfcId
            bleId
            custody {
              nextToken
              items {
                timestamp
                users {
                  id
                  givenName
                  familyName
                  avatarUrl
                  email
                }
              }
            }
            locations {
              nextToken
              items {
                timestamp
                longitude
                latitude
                error
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
                updatedAt
              }
            }
            positions {
              nextToken
              items {
                timestamp
                name
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                floor {
                  url
                  level
                  name
                  topLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  topRight {
                    latitude
                    longitude
                    accuracy
                  }
                  bottomLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  rotationDegrees
                }
              }
            }
            temperatures {
              nextToken
              items {
                timestamp
                degreesCelcius
                numberReadings
              }
            }
            isSpace
            numberAssetsInSpace
            inSpaces {
              assetId
              spaceId
              spaceName
              facilityId
              facilityTimeZone
              facilityName
              enteredOn
              exitedOn
              hasExited
            }
            inFacilities {
              assetId
              facilityId
              facilityName
              facilityTimeZone
              enteredOn
              exitedOn
              hasExited
            }
            gateways {
              gatewayId
              shortId
              deviceId
              manufacturer
              productName
              addedOn
              removedOn
              status
              lastUpdated
              hasPendingCommands
              isStatic
              longitude
              latitude
              rssiFilter
            }
          }
          total
        }
        features
        buildings {
          floors {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
        printers {
          nextToken
          items {
            id
            name
            addedOn
            isOnline
            workspaceId
            facilityId
            printerSettings {
              labelSize
              printMethod
              template {
                selectedId
              }
              consignment {
                createConsignment
                autoPopulatePieceNumber
              }
              barcode {
                sliceStart
                sliceEnd
              }
            }
          }
        }
        dashboards {
          nextToken
          items {
            id
            name
            url
          }
        }
        facilities {
          nextToken
          items {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
        }
        consignments {
          nextToken
          items {
            id
            trackingRef
            workspace {
              id
              name
              url
              allowedDomains
              members {
                nextToken
                items {
                  user {
                    id
                    givenName
                    familyName
                    avatarUrl
                    email
                  }
                  role
                  joinedDate
                }
              }
              assets {
                nextToken
                items {
                  id
                  name
                  organisationId
                  createdOn
                  lastSeen
                  assetType {
                    id
                    name
                    organisationId
                    fields {
                      key
                      name
                      value
                      values
                      dataType
                      options
                      isMultiSelect
                      isRequired
                    }
                    iconUrl
                  }
                  photo
                  labelAddedOn
                  nfcId
                  bleId
                  custody {
                    nextToken
                    items {
                      timestamp
                      users {
                        id
                        givenName
                        familyName
                        avatarUrl
                        email
                      }
                    }
                  }
                  locations {
                    nextToken
                    items {
                      timestamp
                      longitude
                      latitude
                      error
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                      updatedAt
                    }
                  }
                  positions {
                    nextToken
                    items {
                      timestamp
                      name
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      floor {
                        url
                        level
                        name
                        topLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        topRight {
                          latitude
                          longitude
                          accuracy
                        }
                        bottomLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        rotationDegrees
                      }
                    }
                  }
                  temperatures {
                    nextToken
                    items {
                      timestamp
                      degreesCelcius
                      numberReadings
                    }
                  }
                  isSpace
                  numberAssetsInSpace
                  inSpaces {
                    assetId
                    spaceId
                    spaceName
                    facilityId
                    facilityTimeZone
                    facilityName
                    enteredOn
                    exitedOn
                    hasExited
                  }
                  inFacilities {
                    assetId
                    facilityId
                    facilityName
                    facilityTimeZone
                    enteredOn
                    exitedOn
                    hasExited
                  }
                  gateways {
                    gatewayId
                    shortId
                    deviceId
                    manufacturer
                    productName
                    addedOn
                    removedOn
                    status
                    lastUpdated
                    hasPendingCommands
                    isStatic
                    longitude
                    latitude
                    rssiFilter
                  }
                }
                total
              }
              features
              buildings {
                floors {
                  url
                  level
                  name
                  topLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  topRight {
                    latitude
                    longitude
                    accuracy
                  }
                  bottomLeft {
                    latitude
                    longitude
                    accuracy
                  }
                  rotationDegrees
                }
              }
              printers {
                nextToken
                items {
                  id
                  name
                  addedOn
                  isOnline
                  workspaceId
                  facilityId
                  printerSettings {
                    labelSize
                    printMethod
                    template {
                      selectedId
                    }
                    consignment {
                      createConsignment
                      autoPopulatePieceNumber
                    }
                    barcode {
                      sliceStart
                      sliceEnd
                    }
                  }
                }
              }
              dashboards {
                nextToken
                items {
                  id
                  name
                  url
                }
              }
              facilities {
                nextToken
                items {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
              }
              consignments {
                nextToken
                items {
                  id
                  trackingRef
                  workspace {
                    id
                    name
                    url
                    allowedDomains
                    members {
                      nextToken
                      items {
                        user {
                          id
                          givenName
                          familyName
                          avatarUrl
                          email
                        }
                        role
                        joinedDate
                      }
                    }
                    assets {
                      nextToken
                      items {
                        id
                        name
                        organisationId
                        createdOn
                        lastSeen
                        assetType {
                          id
                          name
                          organisationId
                          fields {
                            key
                            name
                            value
                            values
                            dataType
                            options
                            isMultiSelect
                            isRequired
                          }
                          iconUrl
                        }
                        photo
                        labelAddedOn
                        nfcId
                        bleId
                        custody {
                          nextToken
                          items {
                            timestamp
                          }
                        }
                        locations {
                          nextToken
                          items {
                            timestamp
                            longitude
                            latitude
                            error
                            updatedAt
                          }
                        }
                        positions {
                          nextToken
                          items {
                            timestamp
                            name
                          }
                        }
                        temperatures {
                          nextToken
                          items {
                            timestamp
                            degreesCelcius
                            numberReadings
                          }
                        }
                        isSpace
                        numberAssetsInSpace
                        inSpaces {
                          assetId
                          spaceId
                          spaceName
                          facilityId
                          facilityTimeZone
                          facilityName
                          enteredOn
                          exitedOn
                          hasExited
                        }
                        inFacilities {
                          assetId
                          facilityId
                          facilityName
                          facilityTimeZone
                          enteredOn
                          exitedOn
                          hasExited
                        }
                        gateways {
                          gatewayId
                          shortId
                          deviceId
                          manufacturer
                          productName
                          addedOn
                          removedOn
                          status
                          lastUpdated
                          hasPendingCommands
                          isStatic
                          longitude
                          latitude
                          rssiFilter
                        }
                      }
                      total
                    }
                    features
                    buildings {
                      floors {
                        url
                        level
                        name
                        topLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        topRight {
                          latitude
                          longitude
                          accuracy
                        }
                        bottomLeft {
                          latitude
                          longitude
                          accuracy
                        }
                        rotationDegrees
                      }
                    }
                    printers {
                      nextToken
                      items {
                        id
                        name
                        addedOn
                        isOnline
                        workspaceId
                        facilityId
                        printerSettings {
                          labelSize
                          printMethod
                          template {
                            selectedId
                          }
                          consignment {
                            createConsignment
                            autoPopulatePieceNumber
                          }
                          barcode {
                            sliceStart
                            sliceEnd
                          }
                        }
                      }
                    }
                    dashboards {
                      nextToken
                      items {
                        id
                        name
                        url
                      }
                    }
                    facilities {
                      nextToken
                      items {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                    }
                    consignments {
                      nextToken
                      items {
                        id
                        trackingRef
                        workspace {
                          id
                          name
                          url
                          allowedDomains
                          members {
                            nextToken
                          }
                          assets {
                            nextToken
                            total
                          }
                          features
                          printers {
                            nextToken
                          }
                          dashboards {
                            nextToken
                          }
                          facilities {
                            nextToken
                          }
                          consignments {
                            nextToken
                          }
                        }
                        pieces {
                          nextToken
                          items {
                            id
                            trackingRef
                            consignmentId
                            workspaceId
                          }
                          total
                        }
                        gateways {
                          nextToken
                          items {
                            gatewayId
                            shortId
                            deviceId
                            manufacturer
                            productName
                            addedOn
                            removedOn
                            status
                            lastUpdated
                            hasPendingCommands
                            isStatic
                            longitude
                            latitude
                            rssiFilter
                          }
                        }
                        activated {
                          when
                        }
                        deactivated {
                          when
                        }
                        destination {
                          name
                        }
                      }
                    }
                  }
                  pieces {
                    nextToken
                    items {
                      id
                      trackingRef
                      consignmentId
                      workspaceId
                      label {
                        nfcId
                        bleId
                        addedOn
                      }
                      inFacilities {
                        facilityId
                        userFacilityId
                        facilityName
                        enteredOn
                        exitedOn
                        hasExited
                      }
                    }
                    total
                  }
                  gateways {
                    nextToken
                    items {
                      gatewayId
                      shortId
                      deviceId
                      manufacturer
                      productName
                      addedOn
                      removedOn
                      status
                      lastUpdated
                      hasPendingCommands
                      isStatic
                      longitude
                      latitude
                      rssiFilter
                    }
                  }
                  activated {
                    when
                    where {
                      facility {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                    }
                  }
                  deactivated {
                    when
                    where {
                      facility {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                    }
                  }
                  destination {
                    name
                    where {
                      facility {
                        id
                        userFacilityId
                        name
                        workspaceId
                        createdAt
                        status
                        numberOfGateways
                        currentAssetsCount
                        location {
                          latitude
                          longitude
                          accuracy
                        }
                        floorPlans {
                          items {
                            id
                            name
                            imageKey
                            uploadUrl
                            imageUrl
                          }
                        }
                        timeZone
                      }
                      coordinate {
                        latitude
                        longitude
                        accuracy
                      }
                      address {
                        road
                        city
                        district
                        county
                        state
                        country
                        postalCode
                      }
                    }
                  }
                }
              }
            }
            pieces {
              nextToken
              items {
                id
                trackingRef
                consignmentId
                workspaceId
                label {
                  nfcId
                  bleId
                  addedOn
                }
                inFacilities {
                  facilityId
                  userFacilityId
                  facilityName
                  enteredOn
                  exitedOn
                  hasExited
                }
              }
              total
            }
            gateways {
              nextToken
              items {
                gatewayId
                shortId
                deviceId
                manufacturer
                productName
                addedOn
                removedOn
                status
                lastUpdated
                hasPendingCommands
                isStatic
                longitude
                latitude
                rssiFilter
              }
            }
            activated {
              when
              where {
                facility {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
              }
            }
            deactivated {
              when
              where {
                facility {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
              }
            }
            destination {
              name
              where {
                facility {
                  id
                  userFacilityId
                  name
                  workspaceId
                  createdAt
                  status
                  numberOfGateways
                  currentAssetsCount
                  location {
                    latitude
                    longitude
                    accuracy
                  }
                  floorPlans {
                    items {
                      id
                      name
                      imageKey
                      uploadUrl
                      imageUrl
                    }
                  }
                  timeZone
                }
                coordinate {
                  latitude
                  longitude
                  accuracy
                }
                address {
                  road
                  city
                  district
                  county
                  state
                  country
                  postalCode
                }
              }
            }
          }
        }
      }
      pieces {
        nextToken
        items {
          id
          trackingRef
          consignmentId
          workspaceId
          label {
            nfcId
            bleId
            addedOn
          }
          inFacilities {
            facilityId
            userFacilityId
            facilityName
            enteredOn
            exitedOn
            hasExited
          }
        }
        total
      }
      gateways {
        nextToken
        items {
          gatewayId
          shortId
          deviceId
          manufacturer
          productName
          addedOn
          removedOn
          status
          lastUpdated
          hasPendingCommands
          isStatic
          longitude
          latitude
          rssiFilter
        }
      }
      activated {
        when
        where {
          facility {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
          coordinate {
            latitude
            longitude
            accuracy
          }
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
        }
      }
      deactivated {
        when
        where {
          facility {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
          coordinate {
            latitude
            longitude
            accuracy
          }
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
        }
      }
      destination {
        name
        where {
          facility {
            id
            userFacilityId
            name
            workspaceId
            createdAt
            status
            numberOfGateways
            currentAssetsCount
            location {
              latitude
              longitude
              accuracy
            }
            floorPlans {
              items {
                id
                name
                imageKey
                uploadUrl
                imageUrl
              }
            }
            timeZone
          }
          coordinate {
            latitude
            longitude
            accuracy
          }
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
        }
      }
    }
  }
`;
export const addPiecesToConsignment = /* GraphQL */ `
  mutation AddPiecesToConsignment(
    $requestId: String
    $consignmentId: String!
    $pieces: [PieceInput!]!
  ) {
    addPiecesToConsignment(
      requestId: $requestId
      consignmentId: $consignmentId
      pieces: $pieces
    ) {
      nextToken
      items {
        id
        trackingRef
        consignmentId
        workspaceId
        label {
          nfcId
          bleId
          addedOn
        }
        inFacilities {
          facilityId
          userFacilityId
          facilityName
          enteredOn
          exitedOn
          hasExited
        }
      }
      total
    }
  }
`;
export const removePieceFromConsignment = /* GraphQL */ `
  mutation RemovePieceFromConsignment(
    $requestId: String
    $consignmentId: String!
    $pieceId: String!
  ) {
    removePieceFromConsignment(
      requestId: $requestId
      consignmentId: $consignmentId
      pieceId: $pieceId
    ) {
      nothing
    }
  }
`;
export const addGatewaysToConsignment = /* GraphQL */ `
  mutation AddGatewaysToConsignment(
    $requestId: String
    $consignmentId: String!
    $gateways: [ConsignmentGatewayInput!]!
  ) {
    addGatewaysToConsignment(
      requestId: $requestId
      consignmentId: $consignmentId
      gateways: $gateways
    ) {
      nextToken
      items {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const removeGatewayFromConsignment = /* GraphQL */ `
  mutation RemoveGatewayFromConsignment(
    $requestId: String
    $consignmentId: String!
    $gatewayId: String!
  ) {
    removeGatewayFromConsignment(
      requestId: $requestId
      consignmentId: $consignmentId
      gatewayId: $gatewayId
    ) {
      nothing
    }
  }
`;
export const startMobileGateway = /* GraphQL */ `
  mutation StartMobileGateway(
    $requestId: String
    $organisationId: String!
    $deviceUuid: String!
    $deviceType: DeviceType!
  ) {
    startMobileGateway(
      requestId: $requestId
      organisationId: $organisationId
      deviceUuid: $deviceUuid
      deviceType: $deviceType
    ) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
export const mobileGatewayEnterEvent = /* GraphQL */ `
  mutation MobileGatewayEnterEvent(
    $requestId: String
    $deviceUuid: String!
    $bleId: Int!
    $timestamp: AWSDateTime!
  ) {
    mobileGatewayEnterEvent(
      requestId: $requestId
      deviceUuid: $deviceUuid
      bleId: $bleId
      timestamp: $timestamp
    ) {
      nothing
    }
  }
`;
export const mobileGatewayEnterEvents = /* GraphQL */ `
  mutation MobileGatewayEnterEvents(
    $requestId: String
    $deviceUuid: String!
    $labelEvents: [labelEnterEventInput!]!
  ) {
    mobileGatewayEnterEvents(
      requestId: $requestId
      deviceUuid: $deviceUuid
      labelEvents: $labelEvents
    ) {
      nothing
    }
  }
`;
export const mobileGatewayExitEvent = /* GraphQL */ `
  mutation MobileGatewayExitEvent(
    $requestId: String
    $deviceUuid: String!
    $bleId: Int!
    $timestamp: AWSDateTime!
    $determinationTimestamp: AWSDateTime!
    $enterTimestamp: AWSDateTime
  ) {
    mobileGatewayExitEvent(
      requestId: $requestId
      deviceUuid: $deviceUuid
      bleId: $bleId
      timestamp: $timestamp
      determinationTimestamp: $determinationTimestamp
      enterTimestamp: $enterTimestamp
    ) {
      nothing
    }
  }
`;
export const mobileGatewayExitEvents = /* GraphQL */ `
  mutation MobileGatewayExitEvents(
    $requestId: String
    $deviceUuid: String!
    $labelEvents: [labelExitEventInput!]!
  ) {
    mobileGatewayExitEvents(
      requestId: $requestId
      deviceUuid: $deviceUuid
      labelEvents: $labelEvents
    ) {
      nothing
    }
  }
`;
export const mobileGatewaySnapshotEvent = /* GraphQL */ `
  mutation MobileGatewaySnapshotEvent(
    $requestId: String
    $deviceUuid: String!
    $bleIds: [Int!]!
    $timestamp: AWSDateTime!
    $metadata: [SnapshotMetadataInput]
  ) {
    mobileGatewaySnapshotEvent(
      requestId: $requestId
      deviceUuid: $deviceUuid
      bleIds: $bleIds
      timestamp: $timestamp
      metadata: $metadata
    ) {
      nothing
    }
  }
`;
export const mobileGatewayLocationEvent = /* GraphQL */ `
  mutation MobileGatewayLocationEvent(
    $requestId: String
    $deviceUuid: String!
    $locations: [LocationInput!]!
  ) {
    mobileGatewayLocationEvent(
      requestId: $requestId
      deviceUuid: $deviceUuid
      locations: $locations
    ) {
      nothing
    }
  }
`;
export const mobileGatewayLabelTemperatureReadings = /* GraphQL */ `
  mutation MobileGatewayLabelTemperatureReadings(
    $requestId: String
    $deviceUuid: String!
    $readings: [LabelTemperatureReading!]!
  ) {
    mobileGatewayLabelTemperatureReadings(
      requestId: $requestId
      deviceUuid: $deviceUuid
      readings: $readings
    ) {
      nothing
    }
  }
`;
export const startGatewayTest = /* GraphQL */ `
  mutation StartGatewayTest(
    $requestId: String
    $gatewayId: String!
    $deviceUuid: String
  ) {
    startGatewayTest(
      requestId: $requestId
      gatewayId: $gatewayId
      deviceUuid: $deviceUuid
    ) {
      testId
      gatewayId
      spaceId
      testBleId
      startedAt
      completedAt
      deviceUuid
      passed
    }
  }
`;
export const startLocateAsset = /* GraphQL */ `
  mutation StartLocateAsset(
    $requestId: String
    $nfcId: String!
    $bleId: Int!
    $startTimestamp: String!
  ) {
    startLocateAsset(
      requestId: $requestId
      nfcId: $nfcId
      bleId: $bleId
      startTimestamp: $startTimestamp
    )
  }
`;
export const stopLocateAsset = /* GraphQL */ `
  mutation StopLocateAsset(
    $requestId: String
    $locateAssetId: String!
    $stopTimestamp: String!
    $reason: String!
  ) {
    stopLocateAsset(
      requestId: $requestId
      locateAssetId: $locateAssetId
      stopTimestamp: $stopTimestamp
      reason: $reason
    ) {
      nothing
    }
  }
`;
export const addLocateAssetFeedback = /* GraphQL */ `
  mutation AddLocateAssetFeedback(
    $requestId: String
    $locateAssetId: String!
    $feedbackScore: Int!
  ) {
    addLocateAssetFeedback(
      requestId: $requestId
      locateAssetId: $locateAssetId
      feedbackScore: $feedbackScore
    ) {
      nothing
    }
  }
`;
export const registerForPushNotifications = /* GraphQL */ `
  mutation RegisterForPushNotifications(
    $requestId: String
    $token: String!
    $deviceType: DeviceType!
    $isSandbox: Boolean
    $application: ClientApplication
  ) {
    registerForPushNotifications(
      requestId: $requestId
      token: $token
      deviceType: $deviceType
      isSandbox: $isSandbox
      application: $application
    ) {
      nothing
    }
  }
`;
export const sendUserNotification = /* GraphQL */ `
  mutation SendUserNotification(
    $userId: String!
    $message: String!
    $actionType: String
    $actionParams: String
  ) {
    sendUserNotification(
      userId: $userId
      message: $message
      actionType: $actionType
      actionParams: $actionParams
    ) {
      userId
      message
      action {
        type
        params
      }
    }
  }
`;
export const activateNbIotLabel = /* GraphQL */ `
  mutation ActivateNbIotLabel(
    $requestId: String
    $token: String
    $name: String!
    $bleId: Int!
    $workspaceId: String
    $location: AssetLocationInput
  ) {
    activateNbIotLabel(
      requestId: $requestId
      token: $token
      name: $name
      bleId: $bleId
      workspaceId: $workspaceId
      location: $location
    ) {
      assetId
      token
    }
  }
`;
export const setAssetName = /* GraphQL */ `
  mutation SetAssetName($requestId: String, $nfcId: String!, $name: String!) {
    setAssetName(requestId: $requestId, nfcId: $nfcId, name: $name) {
      id
      name
      organisationId
      createdOn
      lastSeen
      assetType {
        id
        name
        organisationId
        fields {
          key
          name
          value
          values
          dataType
          options
          isMultiSelect
          isRequired
        }
        iconUrl
      }
      photo
      labelAddedOn
      nfcId
      bleId
      custody {
        nextToken
        items {
          timestamp
          users {
            id
            givenName
            familyName
            avatarUrl
            email
          }
        }
      }
      locations {
        nextToken
        items {
          timestamp
          longitude
          latitude
          error
          address {
            road
            city
            district
            county
            state
            country
            postalCode
          }
          updatedAt
        }
      }
      positions {
        nextToken
        items {
          timestamp
          name
          coordinate {
            latitude
            longitude
            accuracy
          }
          floor {
            url
            level
            name
            topLeft {
              latitude
              longitude
              accuracy
            }
            topRight {
              latitude
              longitude
              accuracy
            }
            bottomLeft {
              latitude
              longitude
              accuracy
            }
            rotationDegrees
          }
        }
      }
      temperatures {
        nextToken
        items {
          timestamp
          degreesCelcius
          numberReadings
        }
      }
      isSpace
      numberAssetsInSpace
      inSpaces {
        assetId
        spaceId
        spaceName
        facilityId
        facilityTimeZone
        facilityName
        enteredOn
        exitedOn
        hasExited
      }
      inFacilities {
        assetId
        facilityId
        facilityName
        facilityTimeZone
        enteredOn
        exitedOn
        hasExited
      }
      gateways {
        gatewayId
        shortId
        deviceId
        manufacturer
        productName
        addedOn
        removedOn
        status
        lastUpdated
        hasPendingCommands
        isStatic
        longitude
        latitude
        rssiFilter
      }
    }
  }
`;
