import Vue from 'vue';
import Vuex from 'vuex';
import mobileApp from './mobileApp';
import auth from './auth';
import assets from './assets';
import assetsFilters from './assetsFilters';
import teamMembers from './teamMembers';
import zones from './zones';
import user from './user';
import teams from './teams';
import notifications from './notifications';
import settings from './settings';
import dashboards from './dashboards';
import facilities from './facilities';
import nbIot from './nbIot';
import gateway from './gateway';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    messageFromAndroid: '',
  },
  mutations: {
    setMessageFromAndroid(state, message) {
      state.messageFromAndroid = message;
    },
  },
  actions: {
    setMessageFromAndroid(context, message) {
      context.commit('setMessageFromAndroid', message);
    },
    resetAllState(context) {
      context.commit('auth/RESET_STATE');
      context.commit('assets/RESET_STATE');
      context.commit('assets/assetInSpaces/RESET_STATE');
      context.commit('assets/assetInFacilities/RESET_STATE');
      context.commit('assetsFilters/RESET_STATE');
      context.commit('teamMembers/RESET_STATE');
      context.commit('zones/RESET_STATE');
      context.commit('user/RESET_STATE');
      context.commit('teams/RESET_STATE');
      context.commit('notifications/RESET_STATE');
      context.commit('settings/RESET_STATE');
      context.commit('dashboards/RESET_STATE');
      context.commit('facilities/RESET_STATE');
      context.commit('nbIot/RESET_STATE');
      context.commit('gateway/RESET_STATE');
      // TODO - add any new state modules here
      // do NOT reset mobileApp state
      context.commit('mobileApp/tLabels/RESET_STATE');
    },
  },
  getters: {
    isMobileApp(state) {
      return state.mobileApp.isAndroidApp || state.mobileApp.isIosApp;
    },
  },
  modules: {
    mobileApp,
    auth,
    assets,
    assetsFilters,
    teamMembers,
    zones,
    user,
    teams,
    dashboards,
    facilities,
    nbIot,
    gateway,
    notifications,
    settings,
  },
});
