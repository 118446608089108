<template>
  <RouteWithChildren :parent-level="3">
    <ItemDetail
      ref="itemDetail"
      :item-title="zone ? zone.name : ''"
      :item="zone || {}"
      :rows="rows"
      :is-loading="isLoading"
      :is-loading-in-background="isLoadingInBackground"
      :save-item="save"
      :delete-item="deleteZone"
      :delete-tracker="deleteGateway"
      :delete-item-dialogue-title="deleteZoneDialogueTitle"
      :delete-tracker-dialogue-title="deleteGatewayDialogueTitle"
      :error="errorMessage"
      :explorer-toggle-options="['Map']"
      @close="close"
      @startEditing="() => isEditing = true"
      @stopEditing="() => isEditing = false"
    >
      <template #explore-item-1>
        <div
          v-if="hasLocation"
          class="map-container"
        >
          <Map
            :items="locations"
            :includes-first-point="!hasMoreLocations"
            @clickedClusteredItems="(items) => locationClicked(items[0])"
            @clickedUnclusteredItem="locationClicked"
          >
            <div slot="popup-content">
              <LastSeenMapPopup
                :last-seen-time="lastSeenTime"
                :timer-id="timer"
              />
            </div>
          </Map>
        </div>
      </template>
    </ItemDetail>
    <ButtonsDialogue
      :is-visible="isGatewayTestResultDialogueVisible"
      title="Test Results"
      :message="gatewayTestResultMessage"
    >
      <Button
        text="Cancel"
        :on-click="() => { isGatewayTestResultDialogueVisible = false; }"
      />
    </ButtonsDialogue>

    <ButtonsDialogue
      :is-visible="isLinkingGatewayDialogueVisible"
      :title="linkGatewayDialogueTitle"
      :message="linkGatewayDialogueMessage"
    >
      <Button
        v-if="isYesButtonVisible"
        :is-loading="isYesButtonLoading"
        text="Yes"
        is-warning
        :on-click="replaceCurrentGateway"
      />
      <Button
        v-if="isRedirectButtonVisible"
        :text="redirectButtonText"
        :on-click="redirectClicked"
      />
      <Button
        :text="dismissDialogueButtonText"
        :on-click="resetLinkingDialogue"
      />
    </ButtonsDialogue>
  </RouteWithChildren>
</template>

<script>
import {
  linkTrackerStatus, routeNames, assetFilterSelectedKeys, filterKeyToQueryStringParam,
} from '../../constants';
import RouteWithChildren from '../../components/organisms/layout/route-with-children';
import ItemDetail from '../../components/organisms/items/item-detail';
import linkGatewayHelper from '../../mixins/link-gateway-helper';
import dateTimeHelper from '../../mixins/date-time-helper';
import updateDateTimes from '../../mixins/update-date-times';
import ButtonsDialogue from '../../components/molecules/dialogues/dialogue-with-buttons';
import Button from '../../components/atoms/button/button';
import Map from '../../components/atoms/map/map-box';
import LastSeenMapPopup from '../../components/atoms/map/last-seen-map-popup';

const assetsAmountRowName = '# Assets';

export default {
  name: 'ZoneDetail',
  components: {
    RouteWithChildren,
    ItemDetail,
    ButtonsDialogue,
    Button,
    Map,
    LastSeenMapPopup,
  },
  mixins: [linkGatewayHelper, dateTimeHelper, updateDateTimes],
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      isRedirectButtonVisible: false,
      redirectButtonText: 'View Zone',
      isYesButtonVisible: false,
      isYesButtonLoading: false,
      isLinkingGatewayDialogueVisible: false,
      isGatewayTestResultDialogueVisible: false,
      linkGatewayDialogueTitle: '',
      gatewayTestResultTitle: '',
      gatewayTestResultMessage: '',
      showMoreButtonDisabled: false,
      linkGatewayDialogueMessage: '',
      dismissDialogueButtonText: 'Cancel',
      deleteZoneDialogueTitle: 'Are you sure you want to delete this zone?',
      scannedGatewayForZoneId: null,
      scannedGatewayForTeamId: null,
      loadingDataForItemIds: [],
      isEditing: false,
      isGatewayTestRunning: false,
      gatewayTestStatus: 'Not started',
      lastSeenTime: null,
    };
  },
  computed: {
    locations() {
      if (!this.item?.locations?.items) {
        return [];
      }
      return this.item.locations.items
        .map(({
          longitude, latitude, timestamp, error, updatedAt,
        }) => ({
          longitude,
          latitude,
          error,
          properties: {
            id: this.item.id,
            name: this.item.name,
            timestamp: updatedAt || timestamp,
          },
        }));
    },
    hasLocation() {
      return this.locations.length > 0;
    },
    item() {
      return this.zone || {};
    },
    zone() {
      return this.$store.state.zones.zones.find(({ id }) => id === this.id);
    },
    itemAlreadyLoaded() {
      return typeof this.zone !== 'undefined';
    },
    zoneHasGateway() {
      return this.zone
        && Array.isArray(this.zone.gateways)
        && this.zone.gateways
          .filter(({ removedOn }) => !removedOn).length > 0;
    },
    gateway() {
      if (this.zoneHasGateway) {
        return this.zone
        && Array.isArray(this.zone.gateways)
        && this.zone.gateways
          .filter(({ removedOn }) => !removedOn)[0];
      }
      return null;
    },
    scannedGateway() {
      return this.$store.state.mobileApp.gateway;
    },
    scannedLabel() {
      return this.$store.state.mobileApp.label;
    },
    deleteGatewayDialogueTitle() {
      return this.itemAlreadyLoaded
        ? `Are you sure you would like to delete this gateway from ${this.zone.name}`
        : '';
    },
    isLoading() {
      return this.$store.state.zones.isLoadingZone;
    },
    isLoadingInBackground() {
      return this.loadingDataForItemIds.length > 0;
    },
    rows() {
      if (!this.itemAlreadyLoaded) return [];

      const statusRowButtons = [];
      if (!this.zoneHasGateway) {
        statusRowButtons.push({
          props: {
            text: 'Link Gateway',
            isText: true,
            isWarning: false,
            isSmall: true,
            onClick: this.linkGateway,
            disabled: this.$root.isViewer,
          },
        });
      }

      if (this.zoneHasGateway) {
        statusRowButtons.push({
          props: {
            text: 'Delete',
            isText: true,
            isWarning: true,
            isSmall: true,
            onClick: () => this.$refs.itemDetail.displayDeleteTrackerDialogue(),
            disabled: this.$root.isViewer,
          },
        });
      }

      const rows = [
        {
          // need to include the timer, so that the computed property is re-computed every 10 secs
          timer: this.isEditing ? null : this.timer,
          name: 'Name',
          value: this.zone.name,
          isEditable: true,
        },
        {
          name: assetsAmountRowName,
          value: this.hasAssets ? this.zone.numberAssetsInSpace : 'No data',
          buttons: this.hasAssets ? [{
            props: {
              text: 'View assets',
              isText: true,
              isWarning: false,
              isSmall: true,
              onClick: () => this.applyFilter(),
            },
          }] : null,
        },
        { name: 'Last seen', value: this.getLastSeen(this.zone.lastSeen || this.zone) || 'No data' },
        {
          name: 'Gateway status',
          value: this.zone.status,
          buttons: statusRowButtons,
        },
      ];
      if (this.zoneHasGateway) {
        const gatewayTestRowButtons = [];
        gatewayTestRowButtons.push({
          props: {
            text: 'Show more',
            isText: true,
            isWarning: false,
            isSmall: true,
            disabled: this.showMoreButtonDisabled,
            onClick: () => { this.isGatewayTestResultDialogueVisible = true; },
          },
        });

        if (this.$store.getters.isMobileApp && this.$root.workspaceFeatures.gatewayTestEnabled) {
          gatewayTestRowButtons.push({
            props: {
              text: 'Start test',
              disabled: this.startTestButtonDisabled || this.$root.isViewer,
              isWarning: false,
              isSmall: true,
              isLoading: this.gatewayTestStatus === 'Test running...',
              onClick: () => this.startGatewayTest(),
            },
          });
        }
        rows.push({
          name: 'Gateway test',
          value: this.gatewayTestStatus,
          buttons: gatewayTestRowButtons,
        });
        return rows;
      }
      return rows;
    },
    showDeleteButton() {
      return this.zoneHasGateway
        && (this.$store.getters['user/isAdmin'] || this.$store.getters['user/isOwner']);
    },
    errorMessage() {
      return this.$store.state.zones.errorMessage;
    },
    hasError() {
      return this.errorMessage !== '';
    },
    hasAssets() {
      return this.zone.numberAssetsInSpace !== null
          && this.zone.numberAssetsInSpace > 0;
    },
    startTestButtonDisabled() {
      return !this.$store.state.mobileApp.deviceUuid || !this.gateway;
    },
  },
  watch: {
    id() {
      this.getItemData();
    },
    async scannedGateway() {
      if (this.scannedGateway !== null) {
        this.resetLinkingDialogue(true);
        const gatewayStatus = await this.checkGateway(this.scannedGateway);
        switch (gatewayStatus.status) {
          case linkTrackerStatus.availableForLinking:
            if (this.zoneHasGateway) {
              this.linkGatewayDialogueTitle = 'Zone already has a gateway';
              this.linkGatewayDialogueMessage = "Select 'yes' to replace the original gateway.";
              this.isLinkingGatewayDialogueVisible = true;
              this.isYesButtonVisible = true;
              this.dismissDialogueButtonText = 'No';
            } else {
              await this.addGatewayToZone(this.scannedGateway);
              this.resetLinkingDialogue();
            }
            break;
          case linkTrackerStatus.noAccess:
            this.linkGatewayDialogueTitle = 'Error';
            this.linkGatewayDialogueMessage = 'This gateway is linked to a zone you do not have access to';
            this.isLinkingGatewayDialogueVisible = true;
            break;
          case linkTrackerStatus.alreadyLinked:
            this.linkGatewayDialogueTitle = 'Gateway Scanned';
            this.linkGatewayDialogueMessage = 'This gateway is already linked to a zone';
            this.isRedirectButtonVisible = true;
            this.isLinkingGatewayDialogueVisible = true;
            this.scannedGatewayForZoneId = gatewayStatus.zoneId;
            this.scannedGatewayForTeamId = gatewayStatus.teamId;
            break;
          default:
            this.linkGatewayDialogueTitle = 'Unknown Error';
            this.linkGatewayDialogueMessage = 'Please try again later';
            this.isLinkingGatewayDialogueVisible = true;
        }
      }
    },
    async scannedLabel() {
      if (this.scannedLabel !== null) {
        this.resetLinkingDialogue(true);
        this.linkGatewayDialogueTitle = 'Label Scanned';
        this.linkGatewayDialogueMessage = "You cannot link a label to a zone. Click on 'View Assets' if you would like to link a label";
        this.isLinkingGatewayDialogueVisible = true;
        this.redirectButtonText = 'View Assets';
        this.isRedirectButtonVisible = true;
      }
    },
  },
  created() {
    this.getItemData();
    if (this.zoneHasGateway) {
      this.getLatestGatewayTestDetails();
    }
  },
  methods: {
    locationClicked(locationProperties) {
      this.lastSeenTime = locationProperties.timestamp;
    },
    async getItemData() {
      if (this.itemAlreadyLoaded) this.loadingDataForItemIds.push(this.id);
      const item = await this.$store.dispatch('zones/getZone', this.id);
      this.loadingDataForItemIds = this.loadingDataForItemIds.filter((id) => id !== item?.id);
    },
    setGatewayTestResult(startedAt, passed) {
      this.gatewayTestResultMessage = `The most recent test ${(passed ? 'succeeded' : 'failed')} on ${new Date(startedAt).toLocaleString()}`;
      this.gatewayTestStatus = passed ? 'Succeeded' : 'Failed';
    },
    async getLatestGatewayTestDetails() {
      const latestGatewayTest = await this.$store.dispatch('zones/getGatewayTests', {
        gatewayId: this.gateway.gatewayId,
        limit: 1,
      });
      // eslint-disable-next-line max-len
      if (!latestGatewayTest || !Array.isArray(latestGatewayTest.items) || latestGatewayTest.items.length === 0) {
        this.showMoreButtonDisabled = true;
      } else {
        // eslint-disable-next-line max-len
        this.setGatewayTestResult(latestGatewayTest.items[0].startedAt, latestGatewayTest.items[0].passed);
      }
    },
    close() {
      this.$router.push({ name: routeNames.zones.list });
    },
    redirectClicked() {
      let route = null;
      if (this.scannedLabel !== null) {
        route = { name: routeNames.assets.list };
      } else {
        route = {
          name: routeNames.zones.detail,
          params: {
            id: this.scannedGatewayForZoneId,
            teamId: this.scannedGatewayForTeamId,
          },
        };
      }
      this.$router.push(route).catch(() => {});
      this.resetLinkingDialogue();
    },
    resetLinkingDialogue(skipStoreReset = false) {
      this.isRedirectButtonVisible = false;
      this.redirectButtonText = 'View Zone';
      this.isYesButtonVisible = false;
      this.isYesButtonLoading = false;
      this.isLinkingGatewayDialogueVisible = false;
      this.linkGatewayDialogueTitle = '';
      this.linkGatewayDialogueMessage = '';
      this.scannedGatewayForZoneId = null;
      this.scannedGatewayForTeamId = null;
      if (!skipStoreReset) {
        this.$store.commit('mobileApp/RESET_GATEWAY');
        this.$store.commit('mobileApp/RESET_LABEL');
      }
    },
    async replaceCurrentGateway() {
      this.isYesButtonLoading = true;
      await this.addGatewayToZone(this.scannedGateway);
      this.resetLinkingDialogue();
    },
    linkGateway() {
      if (this.$store.getters.isMobileApp) {
        this.$store.dispatch('mobileApp/startNfcScan');
      } else {
        this.$router.push({
          name: routeNames.zones.linkZoneGateway,
        });
      }
    },
    applyFilter() {
      this.$store.commit('assets/SET_FILTERS', [{ key: assetFilterSelectedKeys.zones, value: this.id }]);
      // If we don't already have assets - then simply by navigating
      // to the assets list, the data will be loaded
      if (this.$store.getters['assets/hasAssets']) this.$store.dispatch('assets/refreshGetAssets');
      this.$router.push({
        name: routeNames.assets.list,
        query: { [filterKeyToQueryStringParam.zones]: this.id },
      });
    },
    async save(newName) {
      const oldName = this.zone.name;
      const updatedZone = await this.$store.dispatch(
        'zones/editZone',
        { zoneId: this.id, name: newName },
      );

      if (!this.hasError) {
        this.$root.trackEvent.editedZone(updatedZone, oldName);
      }
    },
    async deleteZone() {
      const deletedZone = this.zone;
      await this.$store.dispatch('zones/removeZone', this.id);

      if (!this.hasError) {
        this.$root.trackEvent.removedZone(deletedZone);
        this.$router.push({ name: routeNames.zones.list });
      }
    },
    async deleteGateway() {
      const gatewayDeletedFromZone = this.zone;
      await this.$store.dispatch(
        'zones/removeGatewayFromZone',
        {
          zoneId: this.id,
          gatewayId: this.zone.gateways.find(({ removedOn }) => !removedOn).gatewayId,
        },
      );

      const { gatewayId } = gatewayDeletedFromZone.gateways
        .filter(({ removedOn }) => removedOn === null)[0];
      this.$root.trackEvent.removedGateway(gatewayDeletedFromZone, gatewayId);
    },
    async startGatewayTest() {
      // 1) get deviceUuid
      if (!this.$store.state.mobileApp.deviceUuid || !this.gateway) {
        return;
      }
      this.gatewayTestStatus = 'Test running...';
      this.showMoreButtonDisabled = true;
      // 2) call startGatewayTest on backend
      const response = await this.$store.dispatch('zones/startGatewayTest', {
        deviceUuid: this.$store.state.mobileApp.deviceUuid,
        gatewayId: this.gateway.gatewayId,
      });
      if (!response || !response.testBleId) {
        this.gatewayTestStatus = 'Not started';
        return;
      }

      // 3) call startLabelTest on mobileApp
      await this.$store.dispatch('mobileApp/startLabelTest', response.testBleId);

      // 4) start polling of getGatewayTest to check whether test has passed
      let counter = 0;
      const pollingInterval = 1;
      const checkGatewayTest = () => {
        setTimeout(async () => {
          const gatewayTest = await this.$store.dispatch('zones/getGatewayTest', response.testId);
          counter += 1;
          // if hasn't yet passed the test, then check again... for max 2mins
          if (!gatewayTest.passed
            && counter * pollingInterval < (process.env.VUE_APP_GATEWAY_TEST_DURATION || 10)) {
            // check again...
            checkGatewayTest();
          } else {
            this.setGatewayTestResult(gatewayTest.startedAt, gatewayTest.passed);
            this.showMoreButtonDisabled = false;
          }
        }, pollingInterval * 1000);
      };

      checkGatewayTest();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';

.map-container {
  position: relative;
  min-height: 100px;
  height: 100%;
  box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
}
.timeline-container {
  height: 100%;
  box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
}

</style>
