<template>
  <div>
    <Notification
      v-for="(item, i) in items"
      :key="item.id"
      :item="item"
      :timeout="item.persistent ? -1 : timeout"
      :is-visible="i === 0"
      :hide-close-button="items.length > 1"
      @remove="removeItem"
    >
      <v-btn
        v-if="items.length > 1"
        color="white"
        text
        @click="removeItem(item.id)"
      >
        Next ({{ items.length - 1 }} more)
      </v-btn>
    </Notification>
  </div>
</template>
<script>
import Notification from '../../atoms/notification/notification';

export default {
  components: {
    Notification,
  },
  props: {
    items: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      timeout: 5000,
      timeoutId: null,
    };
  },
  watch: {
    items() {
      this.processItems();
    },
  },
  methods: {
    processItems() {
      if (this.items && Array.isArray(this.items) && this.items.length > 0) {
        const item = this.items[0];
        if (!item.persistent) {
          this.timeoutId = setTimeout(() => {
            this.removeItem(item.id);
          }, this.timeout);
        }
      }
    },
    removeItem(id) {
      clearTimeout(this.timeoutId);
      this.$emit('remove', id);
      if (this.items.length > 0) {
        this.processItems();
      }
    },
  },
};
</script>
