import api from '../api';
import { userRoles } from '../constants';

const defaultNextToken = undefined;

const getDefaultState = () => ({
  isLoadingTeamMembers: false,
  isLoadingTeamMember: false,
  selectedTeamMember: null,
  teamMembers: [],
  // Using undefined because null means we have no more data for pagination
  teamMembersNextToken: defaultNextToken,
  errorMessage: '',
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    ADD_TEAM_MEMBER(state, teamMember) {
      state.teamMembers = [teamMember, ...state.teamMembers];
    },
    REMOVE_TEAM_MEMBER(state, removeTeamMeberId) {
      const teamMembers = state.teamMembers.filter(({ user }) => user.id !== removeTeamMeberId);
      state.teamMembers = [...teamMembers];
    },
    SET_SELECTED_TEAM_MEMBER(state, teamMember) {
      state.selectedTeamMember = teamMember;
    },
    RESET_SELECTED_TEAM_MEMBER(state) {
      state.selectedTeamMember = null;
    },
    SET_TEAM_MEMBERS(state, teamMembers) {
      state.teamMembers = [...state.teamMembers, ...teamMembers];
    },
    RESET_TEAM_MEMBERS(state) {
      state.teamMembers = [];
    },
    SET_TEAM_MEMBERS_NEXT_TOKEN(state, nextToken) {
      state.teamMembersNextToken = nextToken;
    },
    RESET_TEAM_MEMBERS_NEXT_TOKEN(state) {
      state.teamMembersNextToken = defaultNextToken;
    },
    START_LOADING_TEAM_MEMBER(state) {
      state.isLoadingTeamMember = true;
    },
    STOP_LOADING_TEAM_MEMBER(state) {
      state.isLoadingTeamMember = false;
    },
    START_LOADING_TEAM_MEMBERS(state) {
      state.isLoadingTeamMembers = true;
    },
    STOP_LOADING_TEAM_MEMBERS(state) {
      state.isLoadingTeamMembers = false;
    },
    UPDATE_TEAM_MEMBER(state, updatedTeamMember) {
      state.teamMembers = state.teamMembers
        .map((member) => (
          member.user.id === updatedTeamMember.user.id ? updatedTeamMember : member
        ));
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
  },
  actions: {
    async refreshGetTeamMembers(context) {
      context.commit('RESET_TEAM_MEMBERS');
      context.commit('RESET_TEAM_MEMBERS_NEXT_TOKEN');
      await context.dispatch('getTeamMembers');
    },
    async getTeamMember(context, teamMemberId) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_TEAM_MEMBER');
      try {
        const selectedTeamMember = await api
          .getTeamMember(context.rootState.teams.selectedTeam.id, teamMemberId);
        context.commit('SET_SELECTED_TEAM_MEMBER', selectedTeamMember);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_TEAM_MEMBER');
      }
    },
    async getTeamMembers(context) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_TEAM_MEMBERS');
      try {
        const teamMembersResponse = await api.getTeamMembers(
          context.rootState.teams.selectedTeam.id,
          context.state.teamMembersNextToken,
        );
        context.commit('SET_TEAM_MEMBERS_NEXT_TOKEN', teamMembersResponse.nextToken);
        context.commit('SET_TEAM_MEMBERS', teamMembersResponse.items);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_TEAM_MEMBERS');
      }
    },
    async removeTeamMember(context, teamMemberId) {
      context.commit('START_LOADING_TEAM_MEMBER');
      try {
        const { id } = context.rootState.teams.selectedTeam;
        await api.removeTeamMember(id, teamMemberId);
        context.commit('REMOVE_TEAM_MEMBER', teamMemberId);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message || 'Unknown error');
      } finally {
        context.commit('STOP_LOADING_TEAM_MEMBER');
      }
    },
    async updateTeamMemberRole(context, newMemberRole) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_TEAM_MEMBER');
      try {
        const teamMemberId = context.state.selectedTeamMember.user.id;
        const teamId = context.rootState.teams.selectedTeam.id;
        const newRoleResponse = await api.updateTeamMemberRole(
          teamMemberId,
          teamId,
          newMemberRole,
        );
        context.commit('SET_SELECTED_TEAM_MEMBER', newRoleResponse);
        context.commit('UPDATE_TEAM_MEMBER', newRoleResponse);
        return newRoleResponse;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message || 'Unknown error');
        return null;
      } finally {
        context.commit('STOP_LOADING_TEAM_MEMBER');
      }
    },
    async addTeamMember(context, { email, isAdmin, isViewer }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_TEAM_MEMBER');
      try {
        let role = userRoles.member;

        if (isAdmin) {
          role = userRoles.admin;
        } else if (isViewer) {
          role = userRoles.viewer;
        }

        const newTeamMember = await api.addTeamMember(
          context.rootState.teams.selectedTeam.id,
          email,
          role,
        );
        context.commit('ADD_TEAM_MEMBER', newTeamMember);
        return newTeamMember;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_TEAM_MEMBER');
      }
    },
  },
  getters: {
    hasTeamMembers(state) {
      return state.teamMembers.length > 0;
    },
    hasMoreTeamMembers(state) {
      return state.teamMembersNextToken !== null;
    },
    selectedTeamMemberName(state) {
      return `${state.selectedTeamMember?.user.givenName} ${state.selectedTeamMember?.user.familyName}`;
    },
    isSelectedTeamMemberOwner(state) {
      return state.selectedTeamMember ? state.selectedTeamMember.role === userRoles.owner : false;
    },
  },
};
