<template>
  <img
    class="eye-icon"
    :src="iconImage"
    alt="Eye icon"
  >
</template>
<script>
import IconImage from '../../../assets/icons/eye-icon.svg';

export default {
  computed: {
    iconImage() {
      return IconImage;
    },
  },
};
</script>
<style lang="scss" scoped>
.eye-icon {
  margin-bottom: 1px;
}
</style>
