<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    :is-selecting="isSelectFacility"
    :no-items-text="noDataText"
    smaller-gap-bottom
    title="Facilities"
    @clickRow="clickRow"
  />
</template>
<script>
import ItemsTable from '../table/items-table';
import { routeNames } from '../../../constants';

export default {
  name: 'FacilitiesTable',
  components: {
    ItemsTable,
  },
  props: {
    isSelectFacility: { type: Boolean, default: false },
    selectedId: { type: String, default: null },
  },
  computed: {
    noDataText() {
      return 'This team does not have any facilities. Contact support@reelables.com to set up a facility.';
    },
    headers() {
      const headers = [
        { name: 'Name', key: 'name' },
      ];

      if (!this.isSelectAsset && this.$store.getters['facilities/anyFacilitiesHaveUserFacilityId']) {
        headers.push({ name: 'User Facility ID', key: 'userFacilityId' });
      }

      headers.push(...[
        { name: '# Assets', key: 'currentAssetsCount' },
        { name: '# Gateways', key: 'numGateways' },
        { name: 'Gateway status', key: 'status' },
      ]);

      return headers;
    },
    rows() {
      return this.$store.state.facilities.facilities
        .map((facility) => (
          {
            ...facility,
            name: facility.name || '(Blank name)',
            userFacilityId: facility.userFacilityId,
            currentAssetsCount: facility.currentAssetsCount,
            numGateways: facility.numberOfGateways,
            status: facility.status,
            isSelected: this.selectedId && this.selectedId === facility.id,
          }));
    },
    hasMoreData() {
      return this.$store.getters['facilities/hasMoreFacilities'];
    },
    isLoading() {
      return this.$store.state.facilities.isLoadingFacilities;
    },
  },
  methods: {
    getData() {
      if (this.hasMoreData) {
        return this.$store.dispatch('facilities/getFacilities');
      }
      return null;
    },
    clickRow(row) {
      if (!this.isSelectFacility) {
        this.goToFacilityDetail(row);
      } else {
        this.$emit('clickRow', row);
      }
    },
    goToFacilityDetail(row) {
      this.$router.push({
        name: routeNames.facilities.detail,
        params: {
          id: row.id,
        },
      }).catch(() => {
        // duplicate navigation to same facility - do nothing
      });
    },
  },
};
</script>
