import { userRoles } from '../constants';
import api from '../api';

const getDefaultState = () => ({
  user: null,
  roleInTeam: userRoles.member,
  teamRoles: [],
  errorMessage: '',
  isLoading: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    START_LOADING(state) {
      state.isLoading = true;
    },
    STOP_LOADING(state) {
      state.isLoading = false;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TEAM_ROLES(state, teamRoles) {
      state.teamRoles = teamRoles;
    },
    SET_ROLE_IN_TEAM(state, organisationId) {
      const teamRole = state.teamRoles.find((tr) => tr.organisationId === organisationId);
      if (teamRole) {
        state.roleInTeam = teamRole.role;
      } else {
        state.roleInTeam = userRoles.member;
      }
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
  },
  actions: {
    async getUser(context, skipLoading) {
      context.commit('CLEAR_ERROR_MESSAGE');
      if (!skipLoading) context.commit('START_LOADING');
      try {
        const data = await api.getUser();
        context.commit('SET_USER', data.user);
        context.commit('SET_TEAM_ROLES', data.organisationRoles);
        context.dispatch('notifications/subscribeToUserNotifications', null, { root: true });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        if (!skipLoading) context.commit('STOP_LOADING');
      }
    },
    async setRoleInTeam(context, teamId) {
      // if user doesn't have any role (i.e. the first ever login)
      // then call getUser again
      if (!context.getters.hasRoles) {
        await context.dispatch('getUser');
      }

      context.commit('SET_ROLE_IN_TEAM', teamId);
    },
  },
  getters: {
    isAdmin(state) {
      return state.roleInTeam === userRoles.admin;
    },
    isOwner(state) {
      return state.roleInTeam === userRoles.owner;
    },
    isViewer(state) {
      return state.roleInTeam === userRoles.viewer;
    },
    hasRoles(state) {
      return state.teamRoles.length > 0;
    },
  },
};
